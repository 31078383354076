import { Box, Card, CardContent, CardHeader } from '@mui/material';

export const GETTING_STARTED_TITLE = 'Getting started';

export const GettingStarted = ({ children }) => (
  <Card className="col-span-full">
    <CardHeader title={GETTING_STARTED_TITLE} />
    <CardContent>
      <Box className="grid gap-9 lg:grid-cols-3">{children}</Box>
    </CardContent>
  </Card>
);
