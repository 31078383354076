import PropTypes from 'prop-types';

export const OrderHistoryPaymentButton = ({ label, path }) => (
  <a href={path} target="_blank" rel="noreferrer">
    <u>{label}</u>
  </a>
);

OrderHistoryPaymentButton.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
