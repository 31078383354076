import { UPDATE_PROFILE } from './profile.action';

export const initialProfileState = {
  type: 'no-type',
};

export const profileReducer = (state = initialProfileState, action = '') => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        ...action.payload.profile,
      };

    default:
      return state;
  }
};
