import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const WebhookHeaderAddButton = ({ onClick }) => (
  <Box
    className="mb-2 text-primary cursor-pointer"
    component="button"
    // required type to prevent the button from submitting the form
    type="button"
    onClick={onClick}
  >
    <AddCircleOutlineIcon />
    <span className="uppercase text-sm py-2 mx-1">Add header</span>
  </Box>
);

WebhookHeaderAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
