import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import { clearSnackbar } from '../../store/snackbar/snackbar.action';

const MaterialAlert = (props, ref) => <MuiAlert {...props} elevation={6} ref={ref} variant="filled" />;
MaterialAlert.displayName = 'MuiAlert';

export const TransitionLeft = (props) => (
  <Slide {...props} direction="left" timeout={{ enter: 750, exit: 750 }}>
    {props.children}
  </Slide>
);

export const SnackbarAlert = ({ anchorOrigin, autoHideDuration }) => {
  const dispatch = useDispatch();
  const { message, isOpen, severity } = useSelector((state) => state.snackbar);
  const handleClose = () => clearSnackbar()(dispatch);

  const Alert = React.forwardRef(MaterialAlert);

  return isOpen ? (
    <Snackbar
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      open={isOpen}
      anchorOrigin={anchorOrigin}
      TransitionComponent={TransitionLeft}
    >
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  ) : null;
};

SnackbarAlert.propTypes = {
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(['bottom', 'top']).isRequired,
    horizontal: PropTypes.oneOf(['center', 'left', 'right']).isRequired,
  }).isRequired,
  autoHideDuration: PropTypes.number.isRequired,
};
