import { Button } from '@mui/material';

const downloadFiles = async (getFileUrls) => {
  const fileUrls = await getFileUrls();
  if (fileUrls.length === 0) console.error('No valid file urls found'); // eslint-disable-line

  for (let i = 0; i < fileUrls.length; i += 1) {
    // Use an iframe to avoid pop-up blockers triggered on android
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.title = 'File download iframe';
    iframe.src = fileUrls[i];
    document.body.appendChild(iframe);
  }
};

export const DownloadFilesButton = ({ label, getFileUrls, isLoading }) => (
  <Button aria-label={label} onClick={() => downloadFiles(getFileUrls)} disabled={isLoading}>
    {label}
  </Button>
);
