import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { formErrorMessages } from '../../../../../../utils/form-error-messages';
import { REGEX } from '../../../../../../data/constants';

// TODO: rename/lift some of the props in later iteration to be more conventional
const SimulateFormExistingOrderPanel = ({ children, className, descriptionExisting, isFormDisabled, onFormSubmit, type }) => {
  const {
    handleSubmit: handleSecondFormSubmit,
    register: secondFormRegister,
    formState: { errors: secondFormValidationErrors },
  } = useForm({
    mode: 'onBlur',
  });

  return (
    <form className={className} onSubmit={handleSecondFormSubmit((data) => onFormSubmit(data, type))} data-testid="secondForm">
      <div className="flex flex-row pt-5">
        <div className="mx-10">
          <strong>OR</strong>
        </div>

        <div>
          <p className="mb-4">{descriptionExisting}</p>

          <div className="max-w-lg">
            <TextField
              className="mr-4"
              label="Existing order number"
              id="orderId"
              name="orderId"
              disabled={isFormDisabled}
              helperText={formErrorMessages(secondFormValidationErrors.orderId)}
              inputRef={secondFormRegister({ pattern: REGEX.ALPHANUMERIC })}
              error={!!secondFormValidationErrors.orderId}
            />

            <Button type="submit" disabled={isFormDisabled} className="mt-4">
              Change state
            </Button>

            {children}
          </div>
        </div>
      </div>
    </form>
  );
};

SimulateFormExistingOrderPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  descriptionExisting: PropTypes.string.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export { SimulateFormExistingOrderPanel };
