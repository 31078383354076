import { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { updateRegisterAction } from '../../../store/user/register.action';
import LabForm from '../components/LabForm';
import SandboxToggle from '../../../components/SandboxToggle';
import { getShippingCountries } from '../../api/countriesApiRequest';
import { formatShippingCountries } from '../../api/formatShippingCountries';

// TODO refactor to deduplicate code with RegisterPartner.jsx
const RegisterLab = () => {
  const [countryCodeList, setCountryCodeList] = useState([]);
  const initialLabValues = useSelector(({ user }) => user.register.lab);
  const dispatch = useDispatch();

  useEffect(() => {
    const getResult = async () => {
      try {
        const result = await getShippingCountries();
        setCountryCodeList(formatShippingCountries(result));
      } catch (error) {
        console.log('Loading country codes list failed with error:', error); // eslint-disable-line no-console
      }
    };
    getResult();
  }, []);

  const handleBack = () => {
    navigate('/register');
  };

  return (
    <div>
      <div className="text-3xl font-medium mb-4">Register as a lab</div>
      <div className="mb-7">Please provide the details of your lab.</div>

      <Box className="mb-7">
        <SandboxToggle />
      </Box>

      <LabForm
        countries={countryCodeList}
        defaultValues={initialLabValues}
        onCancel={handleBack}
        onSubmit={(lab) => {
          navigate('lab/your-details');
          dispatch(updateRegisterAction(lab, 'lab'));
        }}
      />
    </div>
  );
};

export default RegisterLab;
