import { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import TeamMemberForm from './components/TeamMemberForm';
import Loader from '../../../components/Loader';
import config from '../../../config';
import { showErrorSnackbar } from '../../../store/snackbar/snackbar.action';
import { ERROR_MESSAGE } from '../../../data/constants';

const TeamEdit = ({ userId }) => {
  if (!userId) navigate('/');

  // Pre-fetch the user and pass it down to the form
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  const { apiUrl } = config;

  const loadUser = () => {
    setLoading(true);
    axios
      .get(`${apiUrl}v1/team/${userId}`)
      .then((r) => {
        setUser(r.data);
        setLoading(false);
      })
      .catch(() => {
        showErrorSnackbar(ERROR_MESSAGE)(dispatch);
      });
  };

  useEffect(() => {
    loadUser();
    // this eslint comment is only introduced to tackle warnings as we go
    // this should be fixed and removed on refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="text-3xl mb-9 font-medium">Edit member</div>
      <div className="bg-white border-t pt-20 px-5 pb-32">
        <TeamMemberForm user={user} />
      </div>
    </div>
  );
};

export default TeamEdit;

TeamEdit.propTypes = {
  userId: PropTypes.string,
};

TeamEdit.defaultProps = {
  userId: null,
};
