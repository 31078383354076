import axios from 'axios';
import _ from 'lodash';
import { getConfig } from '../../config';

const NAMESPACE = 'v1/storefront';

export const getBaseStorefrontUrl = () => new URL(NAMESPACE, getConfig().apiUrl).href;

export const getStorefrontStore = ({ storeId, pageNumber = 1, pageSize = 10 }) =>
  `${getBaseStorefrontUrl()}/store/${storeId}?page=${pageNumber}&page_size=${pageSize}`;

export const getStorefrontCreateProduct = () => `${getBaseStorefrontUrl()}/product/create`;
export const getStorefrontAddProduct = ({ storeId, productVariationId }) => {
  const searchParameters = [
    '_method=get',
    storeId && `store=${storeId}`,
    productVariationId && `base_product_variation=${productVariationId}`,
  ]
    .filter(Boolean)
    .join('&');
  return `${getBaseStorefrontUrl()}/product/add?${searchParameters}`;
};

export const getStorefrontProducts = async () => {
  const data = await axios.get(getStorefrontCreateProduct());
  const products = _.values(data?.data?.product_variations);
  return _.fromPairs(_.map(products, (product) => [product.id, product]));
};
