export const getErrors = ({ errors, groupName, property }) => {
  if (!groupName) {
    return errors[property];
  }

  const groups = groupName.split('.');
  const result = groups.reduce((currentGroup, group) => {
    if (currentGroup && currentGroup[group]) {
      return currentGroup[group];
    }
    return undefined;
  }, errors);

  if (property) {
    return result && result[property];
  }

  return result;
};

export const getName = ({ groupName, property }) => [groupName, property].filter(Boolean).join('.');
