import axios from 'axios';
import { updatePermissionsAction } from '../../store/user/permission.action';
import { updateProfileAction } from '../../store/user/profile.action';
import { PROFILE_TYPES } from '../../utils/permissions';

const getUserInfoEndpoint = () => '/api/userinfo';

export const NO_TYPE_PARTNER = 'no-type';

export const getUserInfo = async (dispatch, userInfoEndpoint = getUserInfoEndpoint()) => {
  const { data } = await axios.get(userInfoEndpoint);
  const { permissions: permissionData = {}, profile, lab: labInfo = false, clinic: clinicInfo = false } = data.userInfo;
  dispatch(updatePermissionsAction(permissionData));

  if (labInfo) {
    return dispatch(
      updateProfileAction({
        ...profile,
        type: PROFILE_TYPES.LAB,
        lab: labInfo,
      }),
    );
  }

  if (clinicInfo) {
    return dispatch(
      updateProfileAction({
        ...profile,
        type: PROFILE_TYPES.CLINIC,
        clinic: clinicInfo,
      }),
    );
  }

  // this should not be possible as Login should not let users without a clinic or lab in
  return dispatch(
    updateProfileAction({
      ...profile,
      type: NO_TYPE_PARTNER,
    }),
  );
};
