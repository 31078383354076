import styled from 'styled-components';

const sizeMap = {
  h1: {
    height: '35px',
    fontSize: '28px',
    fontWeight: 500,
  },
  h2: {
    height: '24px',
    fontSize: '18px',
    fontWeight: 400,
  },
  div: {
    height: '22px',
    fontSize: '16px',
    fontWeight: 400,
  },
  undefined: {
    height: '22px',
    fontSize: '16px',
    fontWeight: 400,
  },
};
export const StyledHeaderTitle = styled.div`
  text-transform: ${({ textTransform }) => textTransform || 'none'};
  font-size: ${({ element }) => sizeMap[element].fontSize || '16px'};
  /* height:  ${({ element }) => sizeMap[element].height || '65px'}; */
  line-height: ${({ element }) => sizeMap[element].height || '65px'};
  margin: 16px auto;
  font-weight: ${({ element }) => sizeMap[element].fontWeight || 400};
`;
