import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../../store/snackbar/snackbar.action';
import { getWebhookRequest } from '../../../../api/webhookTriggerRequests';

export const TRIGGER_WEBHOOK_SUCCESS_MESSAGE = 'Webhook triggered successfully';
export const TRIGGER_WEBHOOK_ERROR_MESSAGE = 'Webhook did not succeed';
export const TRIGGER_WEBHOOK_LABEL = 'Trigger test';

export const TriggerWebhookAction = (props) => {
  const dispatch = useDispatch();
  const { clinicId } = useSelector(({ user }) => ({ clinicId: user.profile.clinic?.id }));
  const [isDeactivated, setIsDeactivated] = useState(false);
  const handleClick = async () => {
    if (isDeactivated) {
      return;
    }
    setIsDeactivated(true);
    try {
      await getWebhookRequest({ webhookId: props.id, clinicId });
      showSuccessSnackbar(TRIGGER_WEBHOOK_SUCCESS_MESSAGE)(dispatch);
    } catch (error) {
      showErrorSnackbar(TRIGGER_WEBHOOK_ERROR_MESSAGE)(dispatch);
    } finally {
      setIsDeactivated(false);
    }
  };
  return (
    <Button className={props.className} disabled={isDeactivated} onClick={handleClick}>
      {TRIGGER_WEBHOOK_LABEL}
    </Button>
  );
};

TriggerWebhookAction.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};
