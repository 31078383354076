import PropTypes from 'prop-types';

const actionsArrayPropType = (props, propName) => {
  const minItems = 1;
  const maxItems = 3;
  if (
    !Array.isArray(props.actions) ||
    props.actions.length < minItems ||
    props.actions.length > maxItems ||
    !props.actions.every((action) => typeof action === 'function')
  ) {
    return new Error(`${propName} needs to be an array of 1-3 actions`);
  }

  return null;
};

export const ActionsColumnCell = (props) => {
  const { actions } = props;
  return <div className="text-primary flex justify-between w-full">{actions.map((getActionComponent) => getActionComponent(props))}</div>;
};

ActionsColumnCell.propTypes = {
  id: PropTypes.string.isRequired,
  actions: actionsArrayPropType,
};
