import { string, node, number, func, bool, oneOfType, oneOf, elementType } from 'prop-types';
import * as SB from './button.style';

/**
 * @deprecated
 * use MaterialUi Button https://material-ui.com/components/buttons/#button
 */
const ButtonDeprecated = ({
  children,
  variant,
  icon: Icon,
  iconRight: IconRight,
  iconSize = 1,
  image,
  onClick,
  reff,
  color,
  count,
  disabled,
  fullWidth,
}) => (
  <SB.StyledButton
    ref={reff}
    variant={variant}
    color={color}
    onClick={onClick}
    size="small"
    iconSize={iconSize}
    disabled={disabled}
    fullWidth={fullWidth}
  >
    {Icon && !image && <Icon />}
    {image && <SB.StyledButtonImage image={image} />}
    {children && <SB.StyledButtonLabel>{children}</SB.StyledButtonLabel>}
    {IconRight && <IconRight className="ml-3" />}
    {count && <SB.StyledCount>{count}</SB.StyledCount>}
  </SB.StyledButton>
);

ButtonDeprecated.propTypes = {
  children: node,
  variant: string,
  icon: elementType,
  iconRight: elementType,
  iconSize: number,
  image: string,
  onClick: func,
  reff: oneOfType([node, oneOf([null, string])]),
  color: string,
  count: number,
  disabled: bool,
  fullWidth: bool,
};

ButtonDeprecated.defaultProps = {
  variant: 'contained',
  icon: '',
  iconRight: '',
  iconSize: 1,
  image: '',
  onClick: () => {},
  reff: null,
  color: 'primary',
  count: null,
  children: null,
  disabled: false,
  fullWidth: false,
};

export default ButtonDeprecated;
