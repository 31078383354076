import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import { Controller } from 'react-hook-form';

export const RadioButtonFormGroup = ({
  ariaLabel,
  className,
  control,
  defaultValue,
  error,
  errorMessage,
  id,
  isDisabled,
  label,
  name,
  options,
}) => (
  <FormControl className={className} component="fieldset">
    <FormLabel id={id}>{label}</FormLabel>
    <Controller
      rules={{ required: true }}
      name={name}
      control={control}
      error={error}
      defaultValue={defaultValue || ''}
      render={({ onBlur, onChange, value }) => (
        <>
          <RadioGroup row aria-labelledby={ariaLabel} data-testid="Gender" onChange={onChange} value={value} onBlur={onBlur}>
            {options.map((option) => (
              <FormControlLabel disabled={isDisabled} key={option.value} value={option.value} control={<Radio />} label={option.label} />
            ))}
          </RadioGroup>
          {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </>
      )}
    />
  </FormControl>
);

RadioButtonFormGroup.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

RadioButtonFormGroup.defaultProps = {
  error: false,
};
