import * as React from 'react';

function SvgCheckmarkOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M5.254 26.346a15.158 15.158 0 01-4.64-10.933c0-8.395 6.805-15.2 15.2-15.2 4.29 0 8.165 1.777 10.929 4.636l.004.004a15.147 15.147 0 014.267 10.56c0 8.395-6.805 15.2-15.2 15.2A15.151 15.151 0 015.25 26.342l.004.004zm19.349-2.143a12.128 12.128 0 003.564-8.603C28.167 8.88 22.72 3.433 16 3.433c-3.36 0-6.401 1.362-8.603 3.564A12.128 12.128 0 003.833 15.6c0 6.72 5.447 12.167 12.167 12.167 3.36 0 6.401-1.362 8.603-3.564zm-13.619-9.682l3.496 3.511 6.536-6.536 2.128 2.158-8.664 8.634-5.624-5.624 2.128-2.158z" />
    </svg>
  );
}

export default SvgCheckmarkOutline;
