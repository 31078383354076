import * as PropTypes from 'prop-types';
import { useEffect } from 'react';

function RouteChangeHandler({ action, location }) {
  useEffect(() => {
    action();
    // this eslint comment is only introduced to tackle warnings as we go
    // this should be fixed and removed on refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.pathname]);

  return null;
}

RouteChangeHandler.propTypes = { action: PropTypes.any };

export default RouteChangeHandler;
