export const SELECT_STOREFRONT_PRODUCT = 'SELECT_STOREFRONT_PRODUCT';
export const FETCH_STOREFRONT_PRODUCTS_REQUEST = 'FETCH_STOREFRONT_PRODUCTS_REQUEST';
export const FETCH_STOREFRONT_PRODUCTS_REQUEST_SUCCESS = 'FETCH_STOREFRONT_PRODUCTS_REQUEST_SUCCESS';
export const FETCH_STOREFRONT_PRODUCTS_REQUEST_FAILURE = 'FETCH_STOREFRONT_PRODUCTS_REQUEST_FAILURE';

export const getStorefrontProductsRequest = (params) => ({
  type: FETCH_STOREFRONT_PRODUCTS_REQUEST,
  ...params,
});

export const getStorefrontProductsSuccess = (params) => ({
  type: FETCH_STOREFRONT_PRODUCTS_REQUEST_SUCCESS,
  ...params,
});

export const getStorefrontProductsFailure = (params) => ({
  type: FETCH_STOREFRONT_PRODUCTS_REQUEST_FAILURE,
  ...params,
});

export const setStorefrontSelectedProduct = (params) => ({
  type: SELECT_STOREFRONT_PRODUCT,
  ...params,
});
