import { StyledLabTaC } from './lab.style';

const LabTermsAndConditions = () => (
  <StyledLabTaC>
    <div>
      <p style="text-align: center">
        <strong>TERMS AND CONDITIONS FOR LABORATORY TESTING SERVICES</strong>
      </p>
      <p style="text-align: right">
        <strong>Version 0.1. 06 November 2020</strong>
      </p>
      <p style="text-align: right">
        <strong />
      </p>
      <p>
        <strong />
      </p>
      <p style="text-align: left">
        <div type="button">1 definitions And interpretation</div>
      </p>
      <p>1.1 In this Agreement unless the context requires otherwise, the following definitions apply:</p>
      <table border="0" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Agreement&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>these Terms and Conditions, together with a Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Applicable Law&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any applicable law, statute, bye-law, regulation, orders, regulatory policy, guidance or industry code, rule of court or
                directive or requirement or notice of any regulatory body, delegated or subordinate legislation;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                &#8220;BCDR Plan&#8221;
                <strong />
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the business continuity and disaster recovery plan for the Services (and the people and facilities used to provide them) to
                minimise the effect of any unplanned interruption or event that would significantly impact on the ability of the Service
                Provider to perform the Services, in whole or in part, in accordance with the terms of this Agreement;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Charges&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the charges for the Services set out in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Chronomics&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                Chronomics Limited (company number <strong>11120038</strong>) whose registered office is at BizSpace Wimbledon, 8 Lombard
                Road, London, SW19 3TZ
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Chronomics Materials&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                all documents, information, software, items, data, databases and materials (including any Test Materials and/or Samples) in
                any form, to be provided by Chronomics to the Service Provider to enable the Service Provider to provide the Services;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong> &#8220;Chronomics Test Materials&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>any reagents and test materials supplied by Chronomics to the Service Provider for the purpose of the Services;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Client Premises&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the premises of any client of Chronomics at which the Service Provider may provide all or part of the Services, as set out
                in the Scope of Work;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Commencement Date&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the date of commencement of the Services set out in the Scope of Work, or if no such date is set out in the Scope of Work,
                the date of signature of the Scope of Work or the date the Service Provider registers for a Registration Account;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Confidential Information&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any and all confidential information in whatever form (whether written, oral, visual, electronic, magnetic or other media),
                however conveyed or presented, disclosed by a Party or its employees, officers, representatives, agents, contractors or
                advisers (<strong>&#8220;Personnel&#8221;</strong>
                ) to the other Party and/or its Personnel concerning the business, affairs, operations, customers, prospective customers,
                processes, budgets, pricing policies, products, strategies, opportunities, developments, trade secrets, know-how, designs,
                software, personnel and suppliers of the disclosing Party or any member of the disclosing Party&#8217;s Group together with
                all information derived by the other Party from any such information and any other information which ought reasonably be
                considered to be confidential or proprietary having regard to the nature of the information and the circumstances of the
                disclosure (whether or not it is marked &#8220;confidential&#8221;);
                <strong />
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Data Sharing and Processing Agreement&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the agreement relating to the sharing and/or processing of personal data as set out in Schedule 1;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Deliverables&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                all documents, data, reports and materials developed by the Service Provider or its agents, sub-contractors, consultants
                and/or employees in relation to the Services in any form, including without limitation the Results;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Dispute&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any dispute or claim arising out of or in connection with this Agreement or its subject matter or formation (including
                non-contractual disputes and claims);
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Documentation&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any documents or information provided by Chronomics relating to the of any use, storage or disposal of any Chronomics
                Materials;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Facility&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the laboratory or testing facility for the provision of the Services (if any) as set out in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Failed Test&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>a sample for which the Result fails to meet the quality metrics in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Good Industry Practice&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the exercise of that degree of skill, care, prudence, efficiency, foresight and timeliness as would be expected from a
                skilled and experienced company within the relevant industry or business sector, under the same or similar circumstances;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Group Company&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>in relation to a company, any member of its Group;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Group&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                in relation to a company, that company, any subsidiary or holding company from time to time of that company, and any
                subsidiary from time to time a holding company of that company;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Initial Period&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the initial period of this Agreement as set out in the Scope of Work, or if no such period is set out in a Scope of Work,
                the period of 12 months from the Commencement Date;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Insolvency Event&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>means:</p>
              <p>
                a) any petition is presented (and has not been dismissed, discharged or withdrawn after five (5) Working Days of being
                advertised), application made (and, if not made by the Party itself, has not been dismissed, discharged or withdrawn after
                fourteen (14) days), resolution proposed, notice of meeting given or other action, proceedings, procedure or step taken
                whether by a Party or any third party for, or which may (other than for the sole purpose of a scheme for a solvent
                amalgamation or solvent reconstruction of that Party) lead to:
              </p>
              <p>
                i) the suspension of payments, winding up, dissolution, administration, receivership (whether administrative or otherwise)
                or reorganisation using a voluntary arrangement, scheme of arrangement or otherwise) of a Party;
              </p>
              <p>
                ii) the appointment of a liquidator (both provisional and following a winding up), receiver (including a fixed charge
                receiver), administrative receiver, administrator, nominee, supervisor, compulsory manager or other similar officer in
                respect of a Party or any of its assets; or
              </p>
              <p>
                b) any event similar to any of those set out above in this definition occurs in relation to a Party (including in any
                jurisdiction to which it is subject);
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong> &#8220;Intellectual Property Rights&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                patents, utility models, rights to inventions, copyright and neighbouring and related rights, trade marks and service marks,
                business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair
                competition, rights in designs, database rights, rights to use, and protect the confidentiality of, confidential information
                (including know-how and trade secrets), and all other intellectual property rights, in each case whether registered or
                unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to
                claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist
                now or in the future in any part of the world;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Losses&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                losses (including all direct losses, which may include loss of profit and loss of reputation), claims, damages, liabilities,
                fines, interest, penalties, costs, charges, expenses, demands and legal and other professional costs (calculated on a full
                indemnity basis);
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Order&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>each order for Services in placed accordance with clause 3;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Registration Account&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the Service Provider&#8217;s online account with Chronomics setting out the variable and parameters of the details of the
                Services including (without limitation) name and contact details of the Service Provider, the address(es) of the Facility;
                the Charges for the Services; the Service Specification and the Service Provider&#8217;s Representatives and Named Contacts.
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Representatives&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                means the persons nominated by each Party in writing to the other from time to time to represent the Parties respectively in
                the management of the relationship between the Parties and duly authorised to settle Disputes on behalf of the respective
                Parties;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Results&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                means the result of clinical tests forming part of the Services including any medical report in respect of such results;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Sample&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                a blood, saliva or other biological sample of any person provided to the Service Provider for testing in connection with the
                Services;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Service Levels&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the service levels, standards and/or performance targets applicable to the Services as set out the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Service Provider&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the provider of laboratory testing services as set out in the Scope of Work.</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Scope of Work&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                a description of the Services setting out the variables and parameters of the Services, as agreed and signed by the parties,
                or if no Scope of Work is signed, the variables and parameters set out within the Service Provider&#8217;s Registration
                Account;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong> &#8220;Service Provider Equipment&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any equipment, including tools, systems, software, cabling or facilities, used directly or indirectly by the Service
                Provider in the supply of the Services;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong> &#8220;Service Provider Materials&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                all documents, information, software, items, data, databases, reagents and materials in any form, to be provided by the
                Service Provider in connection with the Services;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Service Specification&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the specification for the Services as set out in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Services&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the laboratory testing services to be provided by the Service Provider under this Agreement, as further described in the
                Service Specification, together with any other services which Chronomics agrees to take from the Service Provider;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Specified Equipment&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>any equipment which the Service Provider must use in the provision of the Services, as set out in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Standards&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the quality schemes or standards determined by Chronomics for the operation of the Facility or the Service Provider
                Equipment and the supply of the Services, as set out in the Scope of Work;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Working Day&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>a day (other than a Saturday, a Sunday or a public holiday in England) on which banks in London are open for business.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <div type="button"> 1.2 In this Agreement (except where the context </div>
        otherwise requires):
      </p>
      <p>
        1.2.1 reference to &#8220;a person&#8221; includes a natural person, company or unincorporated body (whether or not having separate
        legal personality);
      </p>
      <p>1.2.2 references to legislation are to that legislation as amended, extended or re-enacted from time to time;</p>
      <p>
        1.2.3 a reference to a statute or statutory provision shall include all subordinate legislation made from time to time under that
        statute or statutory provision;
      </p>
      <p>
        1.2.4 any words following the terms &#8220;including&#8221;, &#8220;include&#8221;, &#8220;in particular&#8221; or any similar terms
        shall be construed as illustrative only and shall not limit the sense of the words preceding those terms;
      </p>
      <p>1.2.5 any obligation on a Party not to do something includes an obligation not to allow that thing to be done.</p>
      <p>
        1.3 The Schedules form part of this Agreement and shall have effect as if set out in full in the body of this Agreement. Any
        reference to this Agreement includes the Schedules.
      </p>
      <p>
        <div type="button">
          1.4 The terms and conditions of this Agreement apply to the provision of Services by the Service Provider to Chronomics to the
          exclusion of any other terms that the Service Provider may seek to impose or incorporate, or which are implied by law, trade
          custom, practice or course of dealing.
        </div>
      </p>
      <p>
        <div type="button">2 Supply of Services</div>
      </p>
      <p>
        2.1 During the term of this Agreement, the Service Provider shall provide the Services to Chronomics in accordance these terms and
        conditions and the Scope of Work.
      </p>
      <p>
        <div type="button">
          2.2 Chronomics appoints the Service Provider to provide the Services on a non-exclusive basis and nothing in this Agreement shall
          prevent Chronomics from arranging for any other person to provide for Chronomics, services which are the same as or similar to the
          Services or from providing such services for itself.
        </div>
      </p>
      <p>
        2.3 The Service Provider shall meet any Service Levels for the Services specified in the Scope of Work, or that Chronomics notifies
        to the Service Provider in writing from time to time, and time is of the essence in relation to any of those Service Levels.
      </p>
      <p>
        <div type="button">2.4 In providing the Services, the Service Provider shall:</div>
      </p>
      <p>
        <div type="button">
          2.4.1 co-operate with Chronomics in all matters relating to the Services, and comply with all instructions of Chronomics;
        </div>
      </p>
      <p>
        <div type="button">
          2.4.2 perform the Services with the due care, skill and diligence in accordance with best practice in the provision of laboratory
          testing services;
        </div>
      </p>
      <p>
        2.4.3 obtain at its own expense all licences, permits and consents necessary for the provision of the Services in its country of
        operation;
      </p>
      <p>
        <div type="button">
          2.4.4 use personnel who are suitably skilled and experienced to perform tasks assigned to them in connection with the Services,
          and in sufficient number to ensure that the Service Provider&apos;s obligations are fulfilled in accordance with the Agreement;
        </div>
      </p>
      <p>
        <div type="button">
          2.4.5 use the best quality equipment, materials, standards, methods and techniques in carrying out the Services, and ensure that
          all Service Provider Equipment used in the Services will be free from defects in workmanship, installation and design;
        </div>
      </p>
      <p>
        2.4.6 comply with any instructions or directions of Chronomics in use, storage or disposal of any Chronomics Materials, as set out
        in the Documentation or notified by Chronomics to the Service Provider from time to time;
      </p>
      <p>
        <div type="button">
          2.4.7 observe all health and safety rules and regulations and any other security requirements that apply at any of Client
          Premises, as notified by Chronomics from time to time;
        </div>
      </p>
      <p>
        <div type="button">
          2.4.8 obtain any government approval required for this Agreement in the country of the Service Provider before the Commencement
          Date, and shall provide Chronomics with a certified copy of such approval on request;
        </div>
      </p>
      <p>
        2.4.9 not do or omit to do anything which may cause Chronomics to lose any licence, authority, consent or permission on which it
        relies for the purposes of conducting its business.
      </p>
      <p>
        2.5 The Service Provider shall use Chronomic&#8217;s Laboratory Information Management System for the purpose of results reporting,
        and shall comply with all Chronomics terms of use in respect of such use.
      </p>
      <p>
        <div type="button">3 DEMAND Planning and </div>
        Orders
      </p>
      <p>3.1 The Parties shall frequently by telephone (or another appropriate means of communication) to discuss:</p>
      <p>
        3.1.1 Chronomics&#8217; projected testing requirements based on a rolling weekly projection (or other projection as agreed by the
        Parties) (&#8220;Projected Testing Requirements&#8221;); and
      </p>
      <p>
        3.1.2 the Service Provider&#8217;s maximum available testing capacity for such week or other projected period (&#8220;Available
        Testing Capacity&#8221;).
      </p>
      <p>3.2 Projected Testing Requirements shall be discussed in good faith, but shall not be binding on Chronomics.</p>
      <p>
        3.3 Chronomics shall provide purchase orders (&#8220;Orders&#8221;) to the Service Provider for the Services on an as-needed basis
        subject to demand for the Services.
      </p>
      <p>
        3.4 Chronomics&#8217; may, in its discretion confirm its testing requirements up to 24 hours in advance of any Order
        (&#8220;Confirmed Testing Requirements&#8221;); and the Service Provider shall make available testing capacity to meet such
        Confirmed Testing Requirements (&#8220;Confirmed Testing Capacity&#8221;). Confirmed Testing Requirements shall be binding on the
        Service Provider.
      </p>
      <p>
        3.5 Each Order shall set out the total volume of Services ordered, on a per unit basis. Chronomics may deliver or cause to be
        delivered to the Service Provider, Samples, in single units or batches, up to the total volume set out in an Order.
      </p>
      <p>
        3.6 The Service Provider shall accept any Orders placed in accordance with this Agreement, but shall not be required to accept
        Orders for Services in excess of the Available Testing Capacity unless agreed by Service Provider (acting reasonably).
      </p>
      <p>3.7 Each Party shall cooperate with the other with regards to:</p>
      <p>3.7.1 projections and available testing capacity;</p>
      <p>3.7.2 submission and fulfilment of Orders;</p>
      <p>
        3.7.3 delivery maintenance of any Chronomics Test Materials (pursuant to clause 4) in sufficient quantities for the Service Provider
        to carry out Services covered by an Order;
      </p>
      <p>3.7.4 advising each other of anticipated bottle necks or increase or demand for the Services.</p>
      <p>
        <div type="button">4 facility, equipment AND TEST MATERIALS</div>
      </p>
      <p>
        4.1 The Service Provider shall carry out the Services at the Facility, and not at any other premises or location without the prior
        written consent of Chronomics.
      </p>
      <p>
        4.2 Chronomics may request that all or part of the Services are carried out at any Client Premises, and Chronomics shall be
        responsible for obtaining all necessary permissions and consents for the Service Provider to enter the Client Premises.
      </p>
      <p>
        4.3 Where Chronomics requires the Service Provider to use any Specified Equipment for the performance of the Services (as set out in
        the Scope of Work) the Service Provider shall, prior to the Commencement Date, at its cost, procure, install and test the Specified
        Equipment at the Facility in accordance with the specification set out in the Scope of Work. Chronomics shall provide reasonable
        assistance to the Service Provider in the procurement and installation of such Specified Equipment.
      </p>
      <p>4.4 The Service Provider shall:</p>
      <p>4.4.1 operate a clean laboratory facility in accordance with any applicable standards;</p>
      <p>
        <div type="button">
          4.4.2 provide all Service Provider Equipment and Service Provider Materials, and such other items as are required to provide the
          Services, other than any Chronomics Materials;
        </div>
      </p>
      <p>
        4.4.3 during the term of the Agreement, ensure the Service Provider Equipment is available, in good working order, and maintained
        throughout the term of this Agreement.
      </p>
      <p>
        4.5 Responsibility for reagents and materials used in the Services will be agreed by the Parties and set out in the Scope of Work.
        Where it is agreed that Chronomics Test Materials will be used in the Services, the Supplier shall not use any other reagents of
        materials to carry out the Services without the prior written consent of Chronomics.
      </p>
      <p>
        4.6 Where the Service Provider is responsible for sourcing and providing reagents and materials used in the Services as set out in
        the Scope of Work (&#8220;
        <strong>Service Provider Test Materials</strong>
        &#8221;), the Service Provider shall
      </p>
      <p>4.6.1 maintain sufficient stocks of Service Provider Test Materials to meet any Orders; and</p>
      <p>4.6.2 ensure Service Provider Test Materials are of goods quality, free from defects, and comply with Applicable Laws;</p>
      <p>
        4.7 Where Chronomics Test Materials are to be used in the Services (as set out in the Scope of Work), Chronomics shall deliver to
        the Service Provider, prior to the date of commencement of any Services, sufficient quantities of Chronomics Test Materials
        (together with applicable Documentation) as required to meet any Order for the Services.
      </p>
      <p>
        4.8 Chronomics shall arrange shipping of any Chronomics Test Materials, and may seek reimbursement of shipping costs by the Service
        Provider where agreed by the Parties and set out in the Scope of Work.
      </p>
      <p>
        4.9 The Service Provider shall comply with any Documentation relating to Chronomics Materials, and shall only make use of Chronomics
        Test Materials for the purposes authorised in this Agreement and for no other purpose.
      </p>
      <p>
        <div type="button">5 Quality control</div>
        and Audit
      </p>
      <p>
        <div type="button">5.1 The Service Provider shall ensure that the Services comply with:</div>
      </p>
      <p>5.1.1 the Specification;</p>
      <p>
        5.1.2 the Standards and any other specifications, standards and directions relating to the Services as set out in the Scope of Work
        or notified in writing by Chronomics from time to time; and
      </p>
      <p>5.1.3 all Applicable Laws relating to the provision of the Services.</p>
      <p>5.2 The Service Provider shall promptly provide Chronomics on request with copies of all:</p>
      <p>5.2.1 communications, relating to the Services with any regulatory, industry or other authority; and</p>
      <p>
        5.2.2 any audit reports, checklists, evidence of corrective actions and other information relating to the Service Provider&#8217;s
        compliance with the Standards.
      </p>
      <p>
        5.3 The Service Provider shall allow Chronomics and any regulators or auditors of or other advisers to Chronomics to access the
        Facility, Service Provider&#8217;s Personnel and relevant records as may be reasonably required in order to:
      </p>
      <p>5.3.1 fulfil any legally enforceable request by any regulatory body; or</p>
      <p>5.3.2 undertake verifications of the accuracy of the Charges or identify suspected fraud; or</p>
      <p>
        5.3.3 undertake verification that the Services are being provided and all obligations of the Service Provider are being performed in
        accordance with this Agreement.
      </p>
      <p>
        5.4 Chronomics shall use its reasonable endeavours to ensure that the conduct of each audit does not unreasonably disrupt the
        Service Provider or delay the provision of the Services by the Service Provider and that, where possible, individual audits are
        co-ordinated with each other to minimise any disruption.
      </p>
      <p>
        5.5 Subject to clause 12, the Service Provider shall provide Chronomics (and its auditors and other advisers) with all reasonable
        co-operation, access and assistance in relation to each audit.
      </p>
      <p>
        5.6 Chronomics shall provide at least five (5) Working Days&apos; notice of its intention to conduct an audit unless such audit is
        conducted in respect of a suspected fraud, in which event no notice shall be required.
      </p>
      <p>
        5.7 The Parties shall bear their own costs and expenses incurred in respect of compliance with their obligations under this
        <a href="https://uk.practicallaw.thomsonreuters.com/0-202-4551?transitionType=Default&amp;contextData=(sc.Default)&amp;firstPage=true#co_anchor_a446105">
          clause 5
        </a>
        , unless the audit identifies a material breach of this Agreement by the Service Provider, in which case the Service Provider shall
        reimburse Chronomics for all its reasonable costs incurred in the course of the audit.
      </p>
      <p>
        <div type="button">6 Remedies</div>
      </p>
      <p>
        <div type="button">
          6.1 If the Service Provider fails to perform the Services in accordance with the applicable Service Levels and/or in accordance
          with clause
        </div>
        2 or clause 5.1, Chronomics shall, without limiting or affecting other rights or remedies available to it, have one or more of the
        following rights:
      </p>
      <p>
        <div type="button">6.1.1 to invoke the remediation procedure set out in clauses</div>
        6.4 to 6.7 (inclusive);
      </p>

      <h1>
        <strong>18. </strong>
        <strong>Compliance with Law</strong>
      </h1>
      <p>Each Party will, at all times, comply with its respective obligations under Data Protection Laws.</p>
      <h1>
        <strong>19. </strong>
        <strong>Governing Law and Jurisdictions</strong>
      </h1>
      <h2>19.1 This Agreement will adopt the governing law provision in the relevant act.</h2>
      <h2>
        19.2 This Data Sharing and Processing Schedule will be governed by the laws of England and Wales and any disputes will be subject to
        the exclusive jurisdiction of the courts of England and Wales.
      </h2>
      <h1>
        <strong>20. </strong>
        <strong>Interpretation</strong>
      </h1>

      <h2>20.1 Where this Schedule and the Agreement conflict:</h2>
      <h3>20.1.1 this Agreement takes precedence in relation to Processing of Personal Data; and</h3>
      <h3>20.1.2 all other provisions of the Agreement will continue to apply.</h3>
    </div>
    <br style="clear: both" />
    <p>ANNEX 1 &#8211; Service Provider as Controller or Processor</p>
    <p>
      <strong>Part 1 </strong>
    </p>
    <p>
      The Service Provider is a Controller in respect of any aspect of the Services it provides to Chronomics in accordance with the
      services schedule in the Agreement, which concerns:
    </p>
    <p>&#167; reporting of results to a government or regulatory authority;</p>
    <p>&#167; retention of samples and/or results in accordance with its own retention policies and procedures</p>
    <p>
      <strong>Part 2 </strong>
    </p>
    <p>
      The Service Provider is a Processor in respect of any aspect of the Services
      <strong>
        <u> </u>
      </strong>{' '}
      it provides to Chronomics in accordance with the services schedule in the Agreement, other than as set out in Part 1 of this Annex 1.
    </p>
  </StyledLabTaC>
);

export default LabTermsAndConditions;
