import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Link, navigate, useLocation } from '@reach/router';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { formErrorMessages } from '../../utils/form-error-messages';
import { REGEX } from '../../data/constants';
import connectToStorefront from '../../components/auth/connectToStorefront';
import SandboxToggle from '../../components/SandboxToggle';
import { SignInButton } from '../../components/button/signInButton.component';
import { getPartnerLogin } from '../../components/auth/partnerLogin';

export const STOREFRONT_PATH = '/admin/clinics/storefront';
export const LOGIN_PATH = '/';
export const CREDENTIALS_ERROR = 'Wrong credentials.';

export const Login = () => {
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const { isPartnerBrandingLoading } = useSelector((state) => state.partnerBranding);

  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  async function onFormSubmit(signInData) {
    setIsLoginLoading(true);

    const onError = () => {
      setError('email', { type: 'server', message: CREDENTIALS_ERROR });
      setError('password', { type: 'server', message: CREDENTIALS_ERROR });
    };

    const onSuccess = (response) => {
      if (parsedQueryString.shopify) {
        connectToStorefront({
          shopify: parsedQueryString.shopify,
          onSuccess: () => {
            navigate(STOREFRONT_PATH);
          },
          onError: (message) => {
            setError('email', { type: 'server', message });
          },
        });
      } else {
        const redirect = response?.data?.redirect || parsedQueryString.redirect || LOGIN_PATH;
        navigate(redirect);
      }
    };

    try {
      await getPartnerLogin({ signInData, location, onError, onSuccess });
    } catch (error) {
      onError();
    } finally {
      setIsLoginLoading(false);
    }
  }

  return (
    <form className="m-auto w-4/5" onSubmit={handleSubmit(onFormSubmit)}>
      <div className="font-medium text-3xl mb-9">Sign in to your account</div>

      <Box className="mb-9">
        <SandboxToggle />
      </Box>

      <TextField
        name="email"
        label="Email"
        disabled={isLoginLoading}
        inputRef={register({ required: true, pattern: REGEX.EMAIL })}
        error={!!errors.email}
        helperText={formErrorMessages(errors.email)}
        inputProps={{
          tabIndex: '1',
        }}
      />
      <Link to="reset" className="float-right text-sm underline mb-0.5">
        Forgotten your password?
      </Link>
      <TextField
        name="password"
        label="Password"
        type="password"
        disabled={isLoginLoading}
        inputRef={register({ required: true })}
        error={!!errors.password}
        helperText={formErrorMessages(errors.password)}
        inputProps={{
          tabIndex: '2',
        }}
      />
      <input name="accepted_terms" value="on" ref={register} className="hidden" readOnly />
      <input name="accepted_cookie_and_privacy" value="on" ref={register} className="hidden" readOnly />
      {isPartnerBrandingLoading === false && <SignInButton isLoginLoading={isLoginLoading} />}
    </form>
  );
};
