import { useSelector } from 'react-redux';
import { Dashboard, Orders, Tests, Lab, Manifest, UsersInCircle, Dev } from '../../../icons';
import { useDataApi } from '../../../hooks';
import { Loader } from '../../../components';
import { filterBasedOnPermissions, getOrdersLandingHref } from '../../../utils/permissions';
import { MenuItem } from './MenuItem';
import { COMPONENTS, PERMISSIONS_PER_COMPONENT } from '../available-routes/permissionsPerComponent';

/**
 * @typedef {Object<string, any>} MenuItem
 * @property {string} label
 * @property {string} href
 * @property {string} title
 * @property {JSX.Element} Icon
 * @property {string} permissions
 */

/**
 * @returns {MenuItem[]} menuItems
 */
function getMenuItems({ developer, team, orders, clinic }) {
  return [
    {
      label: 'Dashboard',
      href: 'clinics/dashboard',
      title: 'View dashboard',
      Icon: Dashboard,
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.DASHBOARD],
    },
    {
      label: 'Orders',
      href: getOrdersLandingHref({ orders, clinic }),
      title: 'View order history or add new order',
      Icon: Orders,
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.ORDERS],
    },
    {
      label: 'Test Type',
      href: 'clinics/lab',
      title: 'Lab management.',
      Icon: Lab,
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.LAB],
    },
    {
      label: 'COVID-19 Test Results',
      href: 'clinics/covid_results?status=registered',
      title: 'View covid-19 results, stats and registered tests',
      Icon: Tests,
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.COVID_TEST_RESULTS],
    },
    {
      label: 'Test Results',
      href: 'clinics/test_results?status=registered',
      title: 'View test results',
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.TEST_RESULTS],
      Icon: Tests,
    },
    {
      label: 'Tests',
      href: 'clinics/lab/barcodes_add',
      title: 'View covid-19 results, stats and registered tests',
      Icon: Tests,
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.BARCODE],
    },
    team && {
      label: 'Team',
      href: 'clinics/team',
      title: 'Team members',
      Icon: UsersInCircle,
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.TEAM],
    },
    developer && {
      label: 'Developer',
      href: 'clinics/developer',
      title: 'Developer tools',
      Icon: Dev,
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.DEVELOPER],
    },
    {
      label: 'Storefront',
      href: 'clinics/storefront',
      title: 'Storefront',
      Icon: Manifest,
      permissions: PERMISSIONS_PER_COMPONENT[COMPONENTS.STOREFRONT],
    },
  ].filter(Boolean);
}

/**
 * @param {{isCurrent: boolean}} isCurrent ;
 * @returns {{className: string}}
 */
function linkClassName({ isCurrent }) {
  let className = 'h-16 flex px-6 items-center bg-gradient-to-r via-gray-100 to-gray-100 no-underline';
  if (isCurrent) {
    className += ' text-primary cr-gradient-brand-left from-gray-100';
  } else {
    className += ' hover:from-gray-100';
  }
  return { className };
}

export const hasLoaded = ({ isLoading, permissions, config }) => Boolean(permissions && !isLoading && config);

const Menu = () => {
  const [{ isLoading, data: config }] = useDataApi('/config');

  const { permissions, clinic } = useSelector(({ user }) => ({ permissions: user.permission, clinic: user.profile.clinic }));

  if (!hasLoaded({ permissions, isLoading, config })) {
    return <Loader />;
  }

  const allMenuItems = getMenuItems({ ...config.enabledFeatures, clinic });
  const allowedMenuItems = filterBasedOnPermissions(allMenuItems, permissions);

  return (
    <ul className="flex-shrink-0 h-full bg-white w-60">
      {allowedMenuItems.map(({ href, label, Icon }) => (
        <MenuItem key={label} href={href} label={label} linkClassName={linkClassName} Icon={Icon} />
      ))}
    </ul>
  );
};

export default Menu;
