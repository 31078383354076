import { v4 as uuidv4 } from 'uuid';

export const getHeaderFormValidationKey = (headerIndex) => `headers-${headerIndex}`;

export const getDefaultHeaderKeys = (headers) => Object.keys(headers).map((key, index) => getHeaderFormValidationKey(index));

export const WEBHOOK_KEY_IDENTIFIER = 'key';
export const WEBHOOK_VALUE_IDENTIFIER = 'value';

export const getDefaultHeadersValues = (headers) =>
  Object.fromEntries(
    Object.entries(headers).flatMap(([key, value]) => {
      const id = uuidv4();
      const formValidationKey = getHeaderFormValidationKey(id);
      return [
        [[formValidationKey, WEBHOOK_KEY_IDENTIFIER].join('-'), key],
        [[formValidationKey, WEBHOOK_VALUE_IDENTIFIER].join('-'), value],
      ];
    }),
  );

export const getHeadersFromDefaultValues = (headers = {}) =>
  Object.keys(headers)
    .filter((key) => key.endsWith(WEBHOOK_KEY_IDENTIFIER))
    .map((key) => key.replace(`-${WEBHOOK_KEY_IDENTIFIER}`, ''));

export const removeHeader = ({ formValidationKey, headers, onCallBack }) => {
  const newHeaders = headers.filter((header) => header !== formValidationKey);

  onCallBack(newHeaders);
};
