import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import getSymbolFromCurrency from 'currency-symbol-map';
import { PaginatedDataGrid } from '../../../../components/paginated-data-grid';
import { getStorefrontProductEditPathname } from '../../../../constants';

export const getStorefrontProductColumns = () => [
  {
    field: 'title',
    headerName: 'Product',
    flex: 1,
    sortable: false,
  },
  {
    field: 'price',
    headerName: 'Retail Price',
    flex: 1,
    sortable: false,
    renderCell: ({ value, row }) => `${getSymbolFromCurrency(row.currency)}${value}`,
  },
  {
    field: 'pivot',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => {
      const { sync_status: syncStatus } = value;
      return syncStatus === 'COMPLETE' ? 'Synced' : 'Failed';
    },
  },
  {
    field: 'id',
    headerName: 'Actions',
    width: 200,
    sortable: false,
    renderCell: ({ value, row }) => {
      const className = 'mr-4';
      return (
        <div className="flex justify-center">
          <Button
            component="button"
            type="button"
            className={className}
            variant="outlined"
            color="primary"
            href={getStorefrontProductEditPathname({ storeId: value, storefrontProductVariationId: row.id })}
          >
            Edit
          </Button>
          <Button className={className} color="error" onClick={() => {}}>
            Remove
          </Button>
        </div>
      );
    },
  },
];

export const StorefrontProductsTable = ({ className, hasError, onChange, isLoading, rows, rowCount }) => (
  <PaginatedDataGrid
    className={className}
    columns={getStorefrontProductColumns()}
    rows={rows}
    hasError={hasError}
    isLoading={isLoading}
    onChange={onChange}
    rowCount={rowCount}
  />
);

StorefrontProductsTable.propTypes = {
  className: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      clinic_id: PropTypes.number.isRequired,
      product_variation_id: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      pivot: PropTypes.shape({
        store_id: PropTypes.number.isRequired,
        product_variation_id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        external_product_id: PropTypes.string.isRequired,
        sync_status: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
};
