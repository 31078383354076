import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import React from 'react';
import { formErrorMessages } from '../../../../utils/form-error-messages';
import { REGEX } from '../../../../data/constants';
import { getErrors, getName } from '../../../../utils/formHelpers';

export const USER_FORM_LABELS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
};

export const CreateOrderUserFormGroup = ({ className, control, errors, groupName, isDisabled, getErrorClasses }) => {
  const nameInputRules = {
    required: true,
    pattern: REGEX.NAME,
    minLength: 2,
  };
  return (
    <>
      <Controller
        as={TextField}
        className={className}
        disabled={isDisabled}
        error={!!getErrors({ errors, groupName, property: USER_FORM_LABELS.FIRST_NAME })}
        helperText={formErrorMessages(getErrors({ errors, groupName, property: USER_FORM_LABELS.FIRST_NAME }))}
        id={getName({ groupName, property: USER_FORM_LABELS.FIRST_NAME })}
        label="First name"
        name={getName({ groupName, property: USER_FORM_LABELS.FIRST_NAME })}
        data-testid={getName({ groupName, property: USER_FORM_LABELS.FIRST_NAME })}
        control={control}
        rules={nameInputRules}
        defaultValue={''}
      />

      <Controller
        as={TextField}
        className={className}
        disabled={isDisabled}
        error={!!getErrors({ errors, groupName, property: USER_FORM_LABELS.LAST_NAME })}
        helperText={formErrorMessages(getErrors({ errors, groupName, property: USER_FORM_LABELS.LAST_NAME }))}
        id={getName({ groupName, property: USER_FORM_LABELS.LAST_NAME })}
        label="Last name"
        name={getName({ groupName, property: USER_FORM_LABELS.LAST_NAME })}
        data-testid={getName({ groupName, property: USER_FORM_LABELS.LAST_NAME })}
        control={control}
        rules={nameInputRules}
        defaultValue={''}
      />

      <Controller
        as={TextField}
        className={className}
        disabled={isDisabled}
        error={!!getErrors({ errors, groupName, property: USER_FORM_LABELS.EMAIL })}
        helperText={formErrorMessages(getErrors({ errors, groupName, property: USER_FORM_LABELS.EMAIL }))}
        id={getName({ groupName, property: USER_FORM_LABELS.EMAIL })}
        label="Email address"
        name={getName({ groupName, property: USER_FORM_LABELS.EMAIL })}
        data-testid={getName({ groupName, property: USER_FORM_LABELS.EMAIL })}
        control={control}
        rules={{ required: true, pattern: REGEX.EMAIL }}
        defaultValue={''}
      />

      <Controller
        as={TextField}
        className={className}
        classes={getErrorClasses(USER_FORM_LABELS.PHONE_NUMBER)}
        label="Phone Number"
        id={getName({ groupName, property: USER_FORM_LABELS.PHONE_NUMBER })}
        name={getName({ groupName, property: USER_FORM_LABELS.PHONE_NUMBER })}
        disabled={isDisabled}
        error={!!getErrors({ errors, groupName, property: USER_FORM_LABELS.PHONE_NUMBER })}
        helperText={formErrorMessages(getErrors({ errors, groupName, property: USER_FORM_LABELS.PHONE_NUMBER }))}
        data-testid={getName({ groupName, property: USER_FORM_LABELS.PHONE_NUMBER })}
        control={control}
        rules={{
          required: true,
          pattern: { value: REGEX.PHONE_NUMBER, message: 'Invalid format. Phone number must be in format e.g. +447789123456' },
        }}
        defaultValue={''}
      />
      <FormHelperText className={'ml-0 mr-0 mt-0 mb-4'}>For example, +447789123456</FormHelperText>
    </>
  );
};

CreateOrderUserFormGroup.propTypes = {
  className: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  groupName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  getErrorClasses: PropTypes.func,
};

CreateOrderUserFormGroup.defaultProps = {
  getErrorClasses: () => {},
};
