import { Box, Icon, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Trash } from '../../../../../icons';
import { formErrorMessages } from '../../../../../utils/form-error-messages';
import { WEBHOOK_KEY_IDENTIFIER, WEBHOOK_VALUE_IDENTIFIER } from './headerHelpers';

export const WebhookHeader = ({ baseName, errors, isDisabled, onClick, register, trigger }) => {
  const className = 'mr-4 max-w-fit';
  const crossReferencedKey = `${baseName}-${WEBHOOK_KEY_IDENTIFIER}`;
  const crossReferencedValue = `${baseName}-${WEBHOOK_VALUE_IDENTIFIER}`;
  return (
    <Box className="flex justify-between">
      <TextField
        className={className}
        autoComplete="off"
        label="Key"
        name={crossReferencedKey}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        error={!!errors?.[crossReferencedKey]}
        helperText={formErrorMessages(errors?.[crossReferencedKey])}
        inputRef={register()}
        onBlur={() => trigger(crossReferencedValue)}
      />
      <TextField
        className={className}
        autoComplete="off"
        label="Value"
        name={crossReferencedValue}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        inputRef={register()}
        error={!!errors?.[crossReferencedValue]}
        helperText={formErrorMessages(errors?.[crossReferencedValue])}
        onBlur={() => trigger(crossReferencedKey)}
      />
      <Box className="p-2.5" onClick={onClick}>
        <Icon color="primary" component={Trash} className="text-lg cursor-pointer" />
      </Box>
    </Box>
  );
};

WebhookHeader.propTypes = {
  baseName: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

WebhookHeader.defaultProps = {};
