import { createFilterOptions } from '@mui/material/Autocomplete';
import _ from 'lodash';

export const isCountryOptionSeparator = (option) => option.country_code === '-';

export const getOptionLabel = (option) => {
  if (!option) return '';
  if (isCountryOptionSeparator(option)) return '---';
  return `+${option.dialing_code} (${option.country_code})`;
};

export const getUniqueFilterOptions = (opts, state) => {
  const filterOptions = createFilterOptions();
  if (state.inputValue?.length === 0) {
    return filterOptions(opts, state);
  }
  const uniqueOptions = opts.filter((value, index, self) => index === _.findLastIndex(self, (t) => t.title === value.title));
  return filterOptions(uniqueOptions, state);
};
