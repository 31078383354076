import MaterialDialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { DialogWithInput } from './DialogWithInput';
import { DialogContentWithActions } from './DialogContentWithActions';
import { DialogTitle } from './DialogTitle';

export const Dialog = ({
  ariaDescribedBy,
  ariaLabelledBy,
  hasCloseButton,
  children,
  descriptionId,
  fullWidth,
  isPrimaryActionClickable,
  isSecondaryActionClickable,
  message,
  onClickPrimary,
  onClickSecondary,
  onClose,
  onSubmit,
  open,
  primaryButtonLabel,
  primaryButtonType,
  secondaryButtonLabel,
  secondaryButtonType,
  title,
  titleId,
}) => {
  const shouldDisplayDialogWithInput = onSubmit && children;
  const shouldDisplayDialogWithMessage = !shouldDisplayDialogWithInput && message;
  const shouldDisplayChildren = !shouldDisplayDialogWithInput && !shouldDisplayDialogWithMessage;

  return (
    <MaterialDialog fullWidth={fullWidth} open={open} onClose={onClose} aria-labelledby={ariaLabelledBy} aria-describedby={ariaDescribedBy}>
      <DialogTitle title={title} titleId={titleId} hasCloseButton={hasCloseButton} onClick={onClose} />
      {shouldDisplayDialogWithInput && (
        <DialogWithInput
          isPrimaryActionClickable={isPrimaryActionClickable}
          isSecondaryActionClickable={isSecondaryActionClickable}
          onClickPrimary={onClickPrimary}
          onClickSecondary={onClickSecondary}
          primaryButtonLabel={primaryButtonLabel}
          primaryButtonType={primaryButtonType}
          secondaryButtonLabel={secondaryButtonLabel}
          secondaryButtonType={secondaryButtonType}
          onSubmit={onSubmit}
        >
          {children}
        </DialogWithInput>
      )}
      {shouldDisplayDialogWithMessage && (
        <DialogContentWithActions
          descriptionId={descriptionId}
          isPrimaryActionClickable={isPrimaryActionClickable}
          isSecondaryActionClickable={isSecondaryActionClickable}
          message={message}
          onClickPrimary={onClickPrimary}
          onClickSecondary={onClickSecondary}
          primaryButtonLabel={primaryButtonLabel}
          primaryButtonType={primaryButtonType}
          secondaryButtonLabel={secondaryButtonLabel}
          secondaryButtonType={secondaryButtonType}
        />
      )}
      {shouldDisplayChildren && children}
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  descriptionId: PropTypes.string,
  isPrimaryActionClickable: PropTypes.bool,
  isSecondaryActionClickable: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.string]),
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  primaryButtonType: PropTypes.string,
  secondaryButtonType: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleId: PropTypes.string,
};

Dialog.defaultProps = {
  ariaDescribedBy: 'dialog-title',
  ariaLabelledBy: 'dialog-description',
  fullWidth: false,
};
