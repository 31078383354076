import * as React from 'react';

function SvgTrash(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path stroke="currentColor" d="M1.5 3.5h9v12h-9z" />
        <path fill="currentColor" d="M4 6h1v7H4zM7 6h1v7H7z" />
        <path stroke="currentColor" d="M.5 1.5h11v2H.5z" />
        <path fill="currentColor" d="M4 0h4v1H4z" />
      </g>
    </svg>
  );
}

export default SvgTrash;
