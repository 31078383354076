import { MenuItem } from '@mui/material';
import { useMemo } from 'react';

import PropTypes from 'prop-types';
import * as CustomMuiStyle from '../covid-results/shared.style';
import { ButtonDeprecated } from '../../../components';
import { Arrow } from '../../../icons';

const getPageIndex = (start, limit) => {
  if (start && limit !== 0) {
    return Math.floor(start / limit);
  }

  return 0;
};
export const TestResultsPagination = ({ filterState, handleChangeRowsPerPage, handleChangePage, numberOfPages }) => {
  const pageIndex = useMemo(() => getPageIndex(filterState.start, filterState.limit), [filterState.start, filterState.limit]);
  const pageSizeSelection = [10, 25, 50, 100];

  return (
    <CustomMuiStyle.StyledPagination>
      <span>Rows per page</span>
      <CustomMuiStyle.StyledSelect value={filterState.limit} onChange={handleChangeRowsPerPage}>
        {pageSizeSelection.map((howMany) => (
          <MenuItem key={howMany} value={howMany}>
            {howMany}
          </MenuItem>
        ))}
      </CustomMuiStyle.StyledSelect>

      {numberOfPages > 1 && (
        <>
          <CustomMuiStyle.StyledPageLabel>
            Page {pageIndex + 1} of {numberOfPages}
          </CustomMuiStyle.StyledPageLabel>

          <CustomMuiStyle.StyledPageButtonWrapper>
            <ButtonDeprecated
              icon={Arrow}
              variant="transparent"
              iconSize={1.5}
              onClick={() => handleChangePage(-filterState.limit)}
              disabled={pageIndex === 0}
            />
            <CustomMuiStyle.StyledRotate180Dg>
              <ButtonDeprecated
                icon={Arrow}
                variant="transparent"
                iconSize={1.5}
                onClick={() => handleChangePage(filterState.limit)}
                disabled={pageIndex === numberOfPages - 1}
              />
            </CustomMuiStyle.StyledRotate180Dg>
          </CustomMuiStyle.StyledPageButtonWrapper>
        </>
      )}
    </CustomMuiStyle.StyledPagination>
  );
};

TestResultsPagination.propTypes = {
  filterState: PropTypes.object.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number.isRequired,
};
