import axios from 'axios';
import _ from 'lodash';
import config from '../../config';
import { WEBHOOK_KEY_IDENTIFIER, WEBHOOK_VALUE_IDENTIFIER } from '../admin/developer/webhooks/headers/headerHelpers';

export const API_NAMESPACE = 'notifications';
export const NOTIFICATION_PATH = '/v1/webhooks';

export const getNotificationUrl = ({ apiUrl = config?.notificationApiUrl, isDevelopment = config?.isDevelopment }) => {
  if (isDevelopment) {
    return new URL(NOTIFICATION_PATH, apiUrl).href;
  }

  const path = _.join(
    _.map([API_NAMESPACE, NOTIFICATION_PATH], (urlSegment) => _.trim(urlSegment, '/')),
    '/',
  );
  return new URL(path, apiUrl).href;
};

export const formatData = ({ eventType, formData }) => {
  const {
    value: { url, isActive, ...headersInput },
  } = formData;

  const headerKeys = Object.keys(headersInput).filter(
    (key) => key.startsWith('header') && key.endsWith(WEBHOOK_KEY_IDENTIFIER) && headersInput[key],
  );
  const headerEntries = headerKeys.map((key) => {
    const valueKey = `${key.replace(WEBHOOK_KEY_IDENTIFIER, WEBHOOK_VALUE_IDENTIFIER)}`;
    return [headersInput[key], headersInput[valueKey]];
  });

  const headers = Object.fromEntries(headerEntries);

  return { url, active: isActive, eventType, headers };
};

export const putEventNotifications = async ({ eventType, formData, existingData }, endpoint = getNotificationUrl({})) => {
  const formattedData = formatData({ eventType, formData });
  const submittingData = Object.values({ ...existingData, [eventType]: formattedData });

  const response = await axios.put(endpoint, submittingData);
  return response?.data;
};
