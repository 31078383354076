import _ from 'lodash';
import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import config from '../../config';
import {
  getPartnerBrandingFailure,
  getPartnerBrandingRequest,
  getPartnerBrandingSuccess,
} from '../../store/partnerBranding/partnerBranding.action';

export const API_NAMESPACE = 'partners';
export const CONFIGURATIONS_PATH = 'v1/configurations/partner-branding';

export const getPartnerConfigurationUrl = ({ apiUrl = config?.partnerServiceUrl, isDevelopment = config?.isDevelopment }) => {
  if (isDevelopment) {
    return new URL(CONFIGURATIONS_PATH, apiUrl).href;
  }

  const path = _.join(
    _.map([API_NAMESPACE, CONFIGURATIONS_PATH], (urlSegment) => _.trim(urlSegment, '/')),
    '/',
  );
  return new URL(path, apiUrl).href;
};

export const getPartnerConfigurationUrlWithParams = ({ partnerCode, partnerId }) => {
  const key = partnerCode ? 'partnerCode' : 'partnerId';
  const value = partnerCode || partnerId;
  return [getPartnerConfigurationUrl({}), `${key}=${value}`].join('?');
};

export const getPartnerBranding = async ({ dispatch, partnerCode, partnerId }) => {
  dispatch(getPartnerBrandingRequest({}));
  try {
    const { data } = await axios.get(getPartnerConfigurationUrlWithParams({ partnerCode, partnerId }));
    dispatch(getPartnerBrandingSuccess({ partnerBranding: data }));
  } catch (error) {
    dispatch(getPartnerBrandingFailure({ error: error?.message || error }));
    if (error?.response?.status === 404) {
      return;
    }

    datadogRum.addError(error);
  }
};
