import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { StyledDialogIconButton, StyledDialogTitle } from './DialogTitle.style';

export const ON_CLICK_ERROR = 'onClick function is required';
export const DialogTitle = ({ hasCloseButton, onClick, title, titleId }) => (
  <StyledDialogTitle id={titleId} title={title}>
    {hasCloseButton && (
      <StyledDialogIconButton aria-label="close" onClick={onClick}>
        <Close />
      </StyledDialogIconButton>
    )}
    {title}
  </StyledDialogTitle>
);

DialogTitle.propTypes = {
  hasCloseButton: PropTypes.bool,
  onClick: (props, propName) => {
    if (props.hasCloseButton === true && (props[propName] === undefined || typeof props[propName] !== 'function')) {
      return new Error('onClick function is required');
    }
    return null;
  },
  title: PropTypes.string.isRequired,
  titleId: PropTypes.string,
};

DialogTitle.defaultProps = {
  hasCloseButton: false,
  titleId: 'dialog-title',
};
