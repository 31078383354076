import { baseTheme } from './base';

/** https://lodash.com/docs/4.17.15#merge doesn't deep merge objects exactly as desired
 * the function below needs extending for each new styling property but works for the current use case
 * * */
export const mergeThemes = (customTheme, theme = baseTheme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: {
      DEFAULT: customTheme.primaryColour,
    },
    background: {
      ...theme.colors.background,
      header: customTheme.headerBackground,
    },
  },
});
