import axios from 'axios';
import qs from 'qs';

export const getTestByKitId = async (modalKitId) => {
  const { data } = await axios.get(`/tests/v2/?kitId=${modalKitId}`);
  return data?.data;
};

export const getTestReport = async (reportUrlPath) => {
  const { data } = await axios.get(reportUrlPath);
  return data;
};

export const getResults = async (query) => {
  const { data } = await axios.get(`/admin/clinics/test_results/tableData/v2?${qs.stringify(query)}`);
  return data;
};

export const getBiomarkerResultsForTestId = async (testId) => {
  const { data } = await axios.get(`/tests/v2/${testId}/biomarker-results`);
  return data?.data;
};
