import * as React from 'react';

function SvgDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M5.535 10.597l-1.327 1.327 11.927 11.927 11.927-11.927-1.327-1.327-10.6 10.6z" />
    </svg>
  );
}

export default SvgDown;
