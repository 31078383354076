import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import { FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import { formErrorMessages } from '../../utils/form-error-messages';
import { getErrors, getName } from '../../utils/formHelpers';

export const DATE_FIELDS = {
  YEAR: {
    property: 'year',
    label: 'YYYY',
    nonUSOrder: 3,
    USOrder: 3,
    pattern: '\\d{4}',
  },
  MONTH: {
    property: 'month',
    label: 'MM',
    nonUSOrder: 2,
    USOrder: 1,
    pattern: '\\d{1,2}',
  },
  DAY: {
    property: 'day',
    label: 'DD',
    nonUSOrder: 1,
    USOrder: 2,
    pattern: '\\d{1,2}',
  },
};

export const getSortedDateFields = (isUSOrder) =>
  Object.values(DATE_FIELDS).sort((a, b) => (isUSOrder ? a.USOrder - b.USOrder : a.nonUSOrder - b.nonUSOrder));

const formHelperClasses = {
  root: 'ml-0 mr-0 mt-0',
};

export const DateField = ({
  className,
  errors,
  formHelperText,
  label,
  getErrorClasses,
  groupName,
  rootClassName,
  isDisabled,
  isUSFormat,
  onBlur,
  control,
}) => {
  const groupError = getErrors({ errors, groupName });
  return (
    <FormControl className={rootClassName} component="fieldset" role="group" aria-describedby="dob-hint" onBlur={onBlur}>
      <FormLabel component="legend" className="mb-2">
        {label}
      </FormLabel>
      {groupError && (
        <FormHelperText classes={formHelperClasses} error>
          {groupError.message}
        </FormHelperText>
      )}
      <Box className="flex flex-row">
        {getSortedDateFields(isUSFormat).map((field) => (
          <Controller
            as={TextField}
            key={getName({ groupName, property: field.property })}
            autoComplete={`bday-${field.property}`}
            className={className}
            classes={getErrorClasses(field.property)}
            disabled={isDisabled}
            error={!!getErrors({ errors, groupName, property: field.property })}
            helperText={formErrorMessages(getErrors({ errors, groupName, property: field.property }))}
            id={getName({ groupName, property: field.property })}
            label={field.label}
            name={getName({ groupName, property: field.property })}
            rules={{
              required: true,
              pattern: new RegExp(field.pattern),
            }}
            inputProps={{
              inputMode: 'numeric',
            }}
            control={control}
            defaultValue={''}
          />
        ))}
      </Box>
      {formHelperText && <FormHelperText classes={formHelperClasses}>{formHelperText}</FormHelperText>}
    </FormControl>
  );
};

DateField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  formHelperText: PropTypes.string,
  getErrorClasses: PropTypes.func,
  groupName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isUSFormat: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  rootClassName: PropTypes.string,
};

DateField.defaultProps = {
  className: '',
  getErrorClasses: () => {},
  isUSFormat: false,
  onBlur: () => {},
  rootClassName: '',
};
