export const getOverrides = (theme) => ({
  root: {
    color: theme.colors.gray[500],
  },
  filled: {
    '&$shrink': {
      transform: 'translate(12px, 5px) scale(0.75)',
    },
  },
});
