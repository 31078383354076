import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { getPartnerBranding } from '../app/api/partnerServiceRequests';
import { NO_PARTNER_BRANDING } from '../store/partnerBranding/partnerBranding.action';

export const LOCAL_STORAGE_ITEM_PARTNER_CODE = 'partnerCode';
export const LOCAL_STORAGE_ITEM_PARTNER_PRIMARY_COLOR = 'partnerPrimaryColor';

export const usePartnerBranding = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const parsedQueryString = queryString.parse(location.search);
  const [partnerCode] = useState(parsedQueryString.partnerCode);
  const { isPartnerBrandingLoading, hasBranding, clinic, ...partnerBranding } = useSelector((state) => ({
    ...state.partnerBranding,
    clinic: state.user?.profile?.clinic,
  }));

  useEffect(() => {
    if (partnerCode) {
      localStorage.setItem(LOCAL_STORAGE_ITEM_PARTNER_CODE, partnerCode);
      localStorage.setItem(LOCAL_STORAGE_ITEM_PARTNER_PRIMARY_COLOR, partnerBranding.primaryColour);
    }
  }, [partnerBranding.primaryColour, partnerCode]);

  useEffect(() => {
    if (clinic?.id || partnerCode) {
      getPartnerBranding({ dispatch, partnerId: clinic?.id, partnerCode });
      return;
    }

    dispatch({ type: NO_PARTNER_BRANDING });
  }, [dispatch, clinic?.id, partnerCode]);

  return {
    isPartnerBrandingLoading,
    hasBranding,
    ...partnerBranding,
  };
};
