import { Router } from '@reach/router';
import LabDefault from './LabDefault';
import LabCovid19Terms from './LabCovid19Terms';
import LabTermsAndConditions from './LabTermsAndConditions';
import ReloadRoute from '../../../components/ReloadRoute';

const Lab = () => (
  <div>
    <Router path="/">
      <LabDefault path="/" />
      <LabCovid19Terms path="sla/:slaId" />
      <LabTermsAndConditions path="terms_and_conditions" />
      <ReloadRoute default />
      {/* <LabEpigenetic path="epigenetic" />
        <LabDna path="dna" />
        <LabDeclined path="decline" /> */}
    </Router>
  </div>
);

export default Lab;
