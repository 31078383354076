import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';
import { MenuItem, Popper, Tab, Table, TableCell, TableRow, Tabs, TextField } from '@mui/material';
import Select from '@mui/material/Select';

export const StyledMenuItem = styled(MenuItem)`
  && {
    display: flex;
    justify-content: center;
  }
`;

export const StyledBox = styled.div`
  &:not(:last-child) {
    padding: 10px 0;
  }

  &:last-child {
    padding-top: 10px;
  }
`;

export const StyledGroupTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;

export const StyledPopper = styled(Popper)`
  position: relative;
  z-index: 999;

  & > .MuiPaper-root {
    margin-top: 10px;
    border: 1px solid #cccccc;
  }

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    right: 24px;
    background-color: #fff;
    width: 14px;
    height: 14px;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    transform: rotate(45deg);
    border: 1px solid #cccccc;
  }
`;

export const StyledTable = styled(Table)`
  && .MuiTableCell-stickyHeader {
    height: 24px;
    font-size: 18px;
    font-weight: 500;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 140px;
  background-color: #f9f9f9;
`;

export const StyledTextInput = styled(TextField)`
  background-color: #f9f9f9;

  && > label:not(.Mui-focused) {
    padding-left: 1rem;
    line-height: 24px;
    height: 24px;
    top: -11px;
  }
`;

export const StyledContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  & > div:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const StyledGridSWrapper = styled.div`
  display: flex;
  align-items: center;

  &:last-child > button {
    margin-left: 1rem;
  }

  & > div:not(:first-child) {
    margin-left: 12px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const StyledGridSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  border-bottom: 1px solid #a1a1a1;
  font-size: 20px;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const StyledGridHeaderWrapper = styled.div`
  position: sticky;
  top: 24px;
  left: -1px;
  right: -1px;

  &:focus-within {
    // 3 is enough
    z-index: 3;
  }
`;

export const StyledDropDownTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
`;

export const StyledGridHeader = styled.div``;

export const StyledTabs = styled(Tabs)`
  border-bottom-width: 0px;

  .MuiTabs-flexContainer {
    flex-direction: column;
  }

  && {
    overflow: initial;
    font-family: futura-pt, Arial, Helvetica, sans-serif;
  }

  && .MuiTabs-scroller {
    overflow: initial;
    margin-bottom: -2px;
  }

  & .MuiTabs-indicator {
    height: 3px;
  }

  @media screen and (min-width: 768px) {
    .MuiTabs-flexContainer {
      flex-direction: row;
    }
  }
`;

export const StyledGridHeaderSubTitle = styled(Tab)`
  margin-right: 36px;
  height: 48px;
  color: #1b1d26;
  border-bottom: 1px solid ${({ active }) => (active ? '#1b1d26' : 'transparent')};

  &:hover {
    color: #1b1d26;
  }

  && {
    text-transform: capitalize;
    font-size: 21px;
    line-height: 27px;
    font-weight: normal;
    font-family: futura-pt, Arial, Helvetica, sans-serif;
  }
`;

export const StyledLink = styled.a`
  font-size: 18px;
  text-decoration: none;
  color: #555;
  border-bottom: 1px solid #555;
`;

export const StyledGridHeaderTitle = styled.div`
  /* text-transform: uppercase; */
  font-size: 28px;
  /* font-weight: bold; */
`;

export const StyledGridItem = styled.div`
  align-self: auto;
  background-color: ${({ header }) => (header ? 'transparent' : '#fff')};
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 10px;
  position: relative;
  font-weight: ${({ header }) => (header ? 'bold' : 'normal')};

  &::after {
    position: absolute;
    background-color: #fff;
    width: 1rem;
    bottom: 0;
    top: 0;
    right: -1rem;
    ${({ last, header }) => (last || header ? '' : `content: ''`)}
  }
`;

export const StyledTestList = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(8, auto);
`;

export const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    background-color: #f9f9f9;
    padding: 0.5rem 0.75rem;
    min-width: 40px;
  }
`;

export const StyledPageLabel = styled.div`
  font-size: 16px;
  justify-self: end;
`;

export const StyledPageButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-column: 4/5;
`;

export const StyledRotate180Dg = styled.div`
  transform: rotate(180deg);
`;

export const StyledPagination = styled.div`
  display: grid;
  grid-gap: 12px;
  align-items: center;
  padding: 24px 0;
  grid-template-columns: 1fr;
  width: fit-content;

  & .MuiSelect-select {
    background-color: #fff;
  }

  @media screen and (min-width: 768px) {
    bottom: 0;
    right: 74px;
    left: 328px;
    grid-template-columns: auto auto 1fr auto auto;
    width: initial;
  }
`;

export const StyledCovid19 = styled.div`
  padding: 0px;
`;
export const StyledTableDividerCell = styled(TableCell)`
  && {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border: 0;
    padding: 0;
    height: 12px;
  }
`;

export const StyledTableRow = styled(TableRow)`
  background-color: #fff;
  margin-bottom: 8px;
  &:last-child > ${StyledTableDividerCell} {
    display: none;
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 0;
    font-family: futura-pt, Arial, Helvetica, sans-serif;
    color: #555;
    font-size: 18px;
  }
`;

export const StyledTableCellHeader = styled(TableCell)`
  && {
    font-family: futura-pt, Arial, Helvetica, sans-serif;
  }
`;

export const StyledSearchBox = styled.div`
  padding: 24px 0;

  & .MuiFilledInput-root {
    background-color: #fff;
  }
`;

export const useStyles = makeStyles({
  root: {
    background: '#1B1D26',
    width: '100%',
  },
  container: {},
});
