import { useState, useEffect } from 'react';
import qs from 'qs';
import { navigate, useLocation } from '@reach/router';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box } from '@mui/material';
import { initialState, testStatusesMeta, testStatusesOrder } from './test-results.config';
import * as CustomMuiStyle from '../covid-results/shared.style';
import { Dialog } from '../../../components/dialog/Dialog';
import { TestResultsHeader } from './TestResultsHeader';
import { TestResultsTable } from './TestResultsTable';
import { TestResultsPagination } from './TestResultsPagination';
import { Loader } from '../../../components';
import { TestResultDetails } from './TestResultDetails';
import { getBiomarkerResultsForTestId, getResults, getTestByKitId, getTestReport } from './TestResultsApiRequest';
import { DownloadFilesButton } from '../../../components/button/downloadFilesButton.component';

/* Convert query part of url to a structure with filterState */
const processQueryToFilterState = (locationSearch) => {
  const filterState = {};

  const query = qs.parse(locationSearch, {
    ignoreQueryPrefix: true,
  });

  /* helper functions to push query items to filterState if given parameter exists in query */
  const pushParamIfExistsInQuery = (paramName) => {
    if (query[paramName]) {
      filterState[paramName] = query[paramName];
    }
  };
  const pushParamIfIsIntegerInQuery = (paramName) => {
    const theNumber = parseInt(query[paramName], 10);
    if (!Number.isNaN(theNumber)) {
      filterState[paramName] = theNumber;
    }
  };

  /* push params as they are in the incoming query */
  pushParamIfExistsInQuery('status');
  pushParamIfExistsInQuery('productTestType');
  pushParamIfExistsInQuery('orderNumber');
  pushParamIfExistsInQuery('username');
  pushParamIfIsIntegerInQuery('startTime');
  pushParamIfIsIntegerInQuery('endTime');
  pushParamIfIsIntegerInQuery('start');
  pushParamIfIsIntegerInQuery('limit');

  /* some adjustments to incoming data */
  if (!(filterState.status && testStatusesMeta[filterState.status])) {
    filterState.status = 'registered';
  }
  if (!filterState.start) {
    filterState.start = 0;
  }
  if (!filterState.limit) {
    filterState.limit = 10;
  }

  return filterState;
};

const buildQueryFromFilterState = (filterState) => {
  const queryString = {};

  const pushParamIfExistsInFilterState = (paramName) => {
    if (filterState[paramName]) {
      queryString[paramName] = filterState[paramName];
    }
  };

  pushParamIfExistsInFilterState('status');
  pushParamIfExistsInFilterState('productTestType');
  pushParamIfExistsInFilterState('orderNumber');
  pushParamIfExistsInFilterState('username');
  pushParamIfExistsInFilterState('startTime');
  pushParamIfExistsInFilterState('endTime');
  pushParamIfExistsInFilterState('start');
  pushParamIfExistsInFilterState('limit');

  return queryString;
};

const getNumberOfPages = (testsData, filterState) => {
  if (testsData.count && testsData.count[filterState.status] && filterState.limit !== 0) {
    return Math.ceil(testsData.count[filterState.status] / filterState.limit);
  }

  return 1;
};

const TestResults = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [resultDetails, setResultDetails] = useState([]);
  const [error, setError] = useState('');
  /* Tests data (fetched from API) */
  const [testsData, setTestData] = useState(initialState);
  /* Setup the filterState from location url */
  const location = useLocation();

  const [filterState, setFilterState] = useState(processQueryToFilterState(location.search));

  // TODO: improve this as useEffect does not shallow or deep compare
  // it checks whether its reference changes or not
  // so even if the values are identical, there is a performance cost of rerunning this and side-effects
  useEffect(() => {
    setFilterState(processQueryToFilterState(location.search));
  }, [location.search]);

  useEffect(() => {
    if (openModal === false) {
      setError('');
      setResultDetails([]);
    }
  }, [openModal]);

  useEffect(() => {
    const fetchTestResults = async () => {
      const query = buildQueryFromFilterState(filterState);
      try {
        const data = await getResults(query);
        setTestData(data);
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    };
    fetchTestResults();
  }, [filterState]);

  const getModalContent = async (modalKitId) => {
    setIsLoading(true);
    try {
      const testResponse = await getTestByKitId(modalKitId);
      if (testResponse?.length === 1) {
        const biomarkerResults = await getBiomarkerResultsForTestId(testResponse[0].id);
        const updatedTestResponse = testResponse.map((test) => ({ ...test, biomarkerResults }));
        setResultDetails(updatedTestResponse);
      }
    } catch (e) {
      console.log(e); // eslint-disable-line
      setError('Error retrieving data');
    }

    setIsLoading(false);
  };

  /* Set helper variables derived from filterState */
  const selectedTabIdx = testStatusesOrder.indexOf(filterState.status);

  const numberOfPages = getNumberOfPages(testsData, filterState);

  /* Actions */
  const navigateToNewState = () => {
    const query = buildQueryFromFilterState(filterState);
    navigate(`/admin/clinics/test_results?${qs.stringify(query)}`);
  };

  /* UI handlers */
  const setFilterByStatusChange = (e, newSelectedTabIdx) => {
    filterState.status = testStatusesOrder[newSelectedTabIdx];
    filterState.start = 0;
    navigateToNewState();
  };

  const handleChangeRowsPerPage = (e) => {
    filterState.start = 0;
    filterState.limit = e.target.value;
    navigateToNewState();
  };

  const handleChangePage = (moveBy) => {
    filterState.start += moveBy;
    navigateToNewState();
  };

  const handleFiltersSubmit = (newFilters) => {
    /* clear currently setup filters */
    delete filterState.orderNumber;
    delete filterState.username;
    delete filterState.startTime;
    delete filterState.endTime;
    delete filterState.productTestType;

    /* introduce new filters */
    if (newFilters.orderNumber !== '') {
      filterState.orderNumber = newFilters.orderNumber;
    }
    if (newFilters.username !== '') {
      filterState.username = newFilters.username;
    }
    if (newFilters.productTestType !== '') {
      filterState.productTestType = newFilters.productTestType;
    }
    if (newFilters.startTime !== '') {
      filterState.startTime = new Date(newFilters.startTime).getTime() / 1000;
    }
    if (newFilters.endTime !== '') {
      filterState.endTime = new Date(newFilters.endTime).getTime() / 1000;
    }

    /* call for new data */
    filterState.start = 0;
    navigateToNewState();
  };

  const getTestReportUrls = async (reportUrlPaths) => {
    setIsDownloadLoading(true);
    const promises = reportUrlPaths.map((path) => getTestReport(path));
    const results = await Promise.all(promises).catch((err) => {
      console.error(err); // eslint-disable-line
      return [];
    });
    setIsDownloadLoading(false);
    return results.map(({ downloadLink }) => downloadLink);
  };

  const shouldDisplayError = error !== '';
  const shouldDisplayNoData = !shouldDisplayError && resultDetails?.length === 0;
  const shouldDisplayData = !shouldDisplayError && resultDetails?.length === 1;
  return (
    <CustomMuiStyle.StyledCovid19>
      <TestResultsHeader
        selectedTabIdx={selectedTabIdx}
        setFilterByStatusChange={setFilterByStatusChange}
        filterState={filterState}
        handleFiltersSubmit={handleFiltersSubmit}
        testsData={testsData}
      />
      <TestResultsTable setModalKitId={getModalContent} filterState={filterState} testsData={testsData} setOpenModal={setOpenModal} />
      <Dialog open={openModal} onClose={() => setOpenModal(false)} title="Result" hasCloseButton fullWidth>
        <DialogContent>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {shouldDisplayData && (
                <>
                  {resultDetails[0].reportUrlPaths?.length && (
                    <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                      <DownloadFilesButton
                        style={{ justifyContent: 'space-between' }}
                        label={'Download Results PDF'}
                        getFileUrls={() => getTestReportUrls(resultDetails[0].reportUrlPaths)}
                        isLoading={isDownloadLoading}
                      />
                    </Box>
                  )}
                  <TestResultDetails resultDetails={resultDetails} />
                </>
              )}
              {shouldDisplayNoData && <DialogContentText>No results</DialogContentText>}
              {shouldDisplayError && <DialogContentText>{error}</DialogContentText>}
            </>
          )}
        </DialogContent>
      </Dialog>
      <TestResultsPagination
        filterState={filterState}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        numberOfPages={numberOfPages}
      />
    </CustomMuiStyle.StyledCovid19>
  );
};

export default TestResults;
