import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions } from './DialogActions';

const getContent = ({ descriptionId, message }) => (
  <DialogContent key={descriptionId}>
    <DialogContentText id={descriptionId}>{message}</DialogContentText>
  </DialogContent>
);

const getMessage = ({ descriptionId, message }) => {
  if (Array.isArray(message)) {
    return message.map((label, index) => getContent({ descriptionId: `${descriptionId}-${index}`, message: label }));
  }

  return (
    <DialogContent key="content">
      <DialogContentText id={descriptionId}>{message}</DialogContentText>
    </DialogContent>
  );
};

export const DialogContentWithActions = ({
  descriptionId,
  isPrimaryActionClickable,
  isSecondaryActionClickable,
  message,
  onClickPrimary,
  onClickSecondary,
  primaryButtonLabel,
  primaryButtonType,
  secondaryButtonLabel,
  secondaryButtonType,
}) => (
  <>
    {getMessage({
      descriptionId,
      message,
    })}
    <DialogActions
      key="actions"
      isPrimaryActionClickable={isPrimaryActionClickable}
      isSecondaryActionClickable={isSecondaryActionClickable}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClickSecondary}
      primaryButtonLabel={primaryButtonLabel}
      primaryButtonType={primaryButtonType}
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonType={secondaryButtonType}
    />
  </>
);

DialogContentWithActions.propTypes = {
  descriptionId: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.string]).isRequired,
  isPrimaryActionClickable: PropTypes.bool,
  isSecondaryActionClickable: PropTypes.bool,
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  primaryButtonType: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonType: PropTypes.string,
};

DialogContentWithActions.defaultProps = {
  descriptionId: 'dialog-description',
  titleId: 'dialog-title',
};
