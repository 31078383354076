import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from './snackbar.action';

export const initialSnackbarState = { isOpen: false, message: '', severity: null };

export const snackbarReducer = (state = initialSnackbarState, action = '') => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        isOpen: true,
        message: action.message,
        severity: action.severity,
      };

    case CLOSE_SNACKBAR: {
      return {
        isOpen: false,
        message: '',
        severity: null,
      };
    }

    default:
      return state;
  }
};
