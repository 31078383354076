import { Link } from '@reach/router';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { PaginatedDataGrid } from '../../../components/paginated-data-grid';
import { getDeleteUser, getTeamEndpoint, getResetPassword } from './TeamApiRequests';
import useDataApi from '../../../hooks/use-data-api';
import { getDefaultColumns } from './getDefaultColumns';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../../components/paginated-data-grid/PaginatedDataGrid';
import { ResetIconAction } from './actions/ResetIconAction';
import { EditIconRedirectAction } from './actions/EditIconRedirectAction';
import { DeleteIconAction } from './actions/DeleteIconAction';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../store/snackbar/snackbar.action';
import { ERROR_MESSAGE } from '../../../data/constants';

const DEFAULT_PAGE = 1;
export const DELETE_SUCCESS = 'User deleted successfully!';
export const RESET_SUCCESS = 'We have e-mailed a password reset link!';

const onClickDelete = async ({ id, dispatch, onClickDeleteCallback }) => {
  try {
    await getDeleteUser(id);
    onClickDeleteCallback(id);
    showSuccessSnackbar(DELETE_SUCCESS)(dispatch);
  } catch (error) {
    showErrorSnackbar(ERROR_MESSAGE)(dispatch);
  }
};

const onClickReset = async ({ id, dispatch }) => {
  try {
    await getResetPassword(id);
    showSuccessSnackbar(RESET_SUCCESS)(dispatch);
  } catch (error) {
    showErrorSnackbar(ERROR_MESSAGE)(dispatch);
  }
};

const TeamDefault = () => {
  const [{ data, isLoading: isDataLoading, isError: hasError }, setUrl, refreshData] = useDataApi(
    getTeamEndpoint(DEFAULT_ROWS_PER_PAGE_OPTIONS[0], DEFAULT_PAGE),
    { data: [] },
  );

  const dispatch = useDispatch();

  const actions = [
    ({ id }) => <ResetIconAction key={`reset-${id}`} id={id} onClickReset={async () => onClickReset({ id, dispatch })} />,
    ({ id }) => <EditIconRedirectAction key={`edit-${id}`} url={`edit/${id}`} />,
    ({ id }) => (
      <DeleteIconAction
        key={`delete-${id}`}
        id={id}
        onClickDelete={async () =>
          onClickDelete({
            id,
            dispatch,
            onClickDeleteCallback: refreshData,
          })
        }
      />
    ),
  ];

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-3xl">Team</h1>
      <Link className="self-end mb-3 no-underline" to="add">
        <Button>Add a user</Button>
      </Link>
      <div className="flex-1">
        <PaginatedDataGrid
          columns={getDefaultColumns({ actions })}
          rows={data.data}
          hasError={hasError}
          isLoading={isDataLoading}
          onChange={(pageSize, pageNumber) => setUrl(getTeamEndpoint(pageSize, pageNumber))}
          rowCount={data?.meta?.total || 0}
        />
      </div>
    </div>
  );
};

export default TeamDefault;
