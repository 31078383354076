import { Box, Divider, FormControlLabel, FormGroup, Switch, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { WebhookComponentActions } from './WebhookComponentActions';
import { formErrorMessages } from '../../../../utils/form-error-messages';
import { getValidationSchema, useValidationResolver } from './helpers/validationHelpers';
import { WebhookHeaders } from './headers';
import {
  getDefaultHeaderKeys,
  getDefaultHeadersValues,
  getHeaderFormValidationKey,
  getHeadersFromDefaultValues,
  removeHeader,
} from './headers/headerHelpers';

export const WebhookComponent = ({ onClickPrimary, onClickSecondary, webhook }) => {
  const defaultValues = useMemo(
    () => ({
      url: webhook.url,
      isActive: webhook.active,
      ...(webhook?.headers && getDefaultHeadersValues(webhook.headers)),
    }),
    [webhook.active, webhook.headers, webhook.url],
  );

  const [headers, setHeaders] = useState(
    webhook?.headers ? getHeadersFromDefaultValues(defaultValues) : getDefaultHeaderKeys(new Array(1).fill('')),
  );

  useEffect(() => {
    if (webhook?.headers) {
      setHeaders(getHeadersFromDefaultValues(defaultValues));
    }
  }, [defaultValues, webhook?.headers]);

  const validationSchema = useMemo(() => getValidationSchema(headers), [headers]);
  const resolver = useValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
    trigger,
  } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues,
    shouldFocusError: true,
  });
  const handleAddHeaderClick = () => {
    const newHeaderId = getHeaderFormValidationKey(uuidv4());
    setHeaders([...headers, newHeaderId]);
  };

  const isFormValid = isDirty && isValid;
  const canSubmit = !isSubmitting && isFormValid;

  return (
    <Box
      p={3}
      component="form"
      sx={{
        width: '50%',
        margin: 'auto',
      }}
      onSubmit={handleSubmit((formData) => onClickPrimary(formData))}
    >
      <Box className="my-2 flex justify-between">
        <Typography variant="h7">{webhook.eventName}</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                inputRef={register()}
                defaultChecked={defaultValues.isActive}
                label={`${webhook.eventType}-isActive`}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={isSubmitting}
                aria-disabled={isSubmitting}
              />
            }
            label="Active"
            labelPlacement="start"
            name="isActive"
          />
        </FormGroup>
      </Box>

      <TextField
        autoComplete="off"
        name="url"
        label="URL"
        defaultValue={defaultValues.url}
        disabled={isSubmitting}
        aria-disabled={isSubmitting}
        inputRef={register()}
        error={!!errors?.url}
        helperText={formErrorMessages(errors?.url)}
      />
      <WebhookHeaders
        headers={headers}
        onRemoveHeader={(formValidationKey) => removeHeader({ onCallBack: setHeaders, formValidationKey, headers })}
        isDisabled={isSubmitting}
        errors={errors}
        register={register}
        trigger={trigger}
        onAddHeader={handleAddHeaderClick}
      />
      <Divider />
      <WebhookComponentActions onClickSecondary={onClickSecondary} isDisabledSecondary={isSubmitting} isDisabledPrimary={!canSubmit} />
    </Box>
  );
};

WebhookComponent.propTypes = {
  webhook: PropTypes.shape({
    eventType: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  onClickPrimary: PropTypes.func.isRequired,
  onClickSecondary: PropTypes.func.isRequired,
};

WebhookComponent.defaultProptypes = {
  webhook: {
    active: false,
  },
};
