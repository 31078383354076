import { Helmet } from 'react-helmet';

export const PARTNER_APP_NAME = 'Partner app';

export const WhiteLabelledMetadata = () => (
  <Helmet>
    <meta name="description" content={PARTNER_APP_NAME} />
    <title>{PARTNER_APP_NAME}</title>
  </Helmet>
);
