import PropTypes from 'prop-types';
import { DialogActions } from './DialogActions';

export const DialogWithInput = ({
  children,
  isPrimaryActionClickable,
  isSecondaryActionClickable,
  onClickPrimary,
  onClickSecondary,
  onSubmit,
  primaryButtonLabel,
  primaryButtonType,
  secondaryButtonLabel,
  secondaryButtonType,
}) => (
  <form data-testid="dialog-form" onSubmit={onSubmit}>
    {children}
    <DialogActions
      isPrimaryActionClickable={isPrimaryActionClickable}
      isSecondaryActionClickable={isSecondaryActionClickable}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClickSecondary}
      primaryButtonLabel={primaryButtonLabel}
      primaryButtonType={primaryButtonType}
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonType={secondaryButtonType}
    />
  </form>
);

DialogWithInput.propTypes = {
  isPrimaryActionClickable: PropTypes.bool,
  isSecondaryActionClickable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  primaryButtonType: PropTypes.string,
  secondaryButtonType: PropTypes.string,
};

DialogWithInput.defaultProps = {
  isPrimaryActionClickable: true,
};
