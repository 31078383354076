import * as React from 'react';

function SvgDev(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect stroke="currentColor" x={1.5} y={1.5} width={21} height={21} rx={2} />
        <path stroke="currentColor" strokeLinecap="square" d="M1.5 5.5h21" />
        <circle fill="currentColor" cx={3.5} cy={3.5} r={1} />
        <circle fill="currentColor" cx={5.5} cy={3.5} r={1} />
        <circle fill="currentColor" cx={7.5} cy={3.5} r={1} />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.055 9.541l4.455 4.455-4.455 4.455M8.955 18.451L4.5 13.996l4.455-4.455"
        />
        <path stroke="currentColor" strokeLinecap="round" d="M12.924 9.513l-2 9" />
      </g>
    </svg>
  );
}

export default SvgDev;
