import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import queryString from 'query-string';
import { Link, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { ProductsSelect } from '../../../../components/select/ProductsSelect';
import { StorefrontPage } from '../common/StorefrontPage';
import { getStorefrontProducts } from '../../../api';
import { getStorefrontProductAddPathname } from '../../../../constants';
import {
  getStorefrontProductsFailure,
  getStorefrontProductsRequest,
  getStorefrontProductsSuccess,
  setStorefrontSelectedProduct,
} from '../../../../store/storefront/products.action';
import { showErrorSnackbar } from '../../../../store/snackbar/snackbar.action';

export const STOREFRONT_FETCH_PRODUCTS_ERROR = 'Error retrieving products from store';

export const formatProductsForSelect = (products) =>
  _.map(_.values(products), ({ product_code: productCode, title, id }) => ({
    label: `[${productCode}] ${title}`,
    value: id,
  }));

export const StorefrontCreateProduct = ({ headerLabel, subheaderLabel }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { store: storeId } = queryString.parse(location.search);

  const { error, isStoreLoading, storeProducts, selectedProduct } = useSelector((state) => ({
    storeProducts: state.storefront.products,
    isStoreLoading: state.storefront.isStoreLoading,
    error: state.storefront.error,
    selectedProduct: state.storefront.selectedProduct,
  }));
  useEffect(() => {
    const getProducts = async () => {
      dispatch(getStorefrontProductsRequest());
      try {
        const products = await getStorefrontProducts();

        dispatch(getStorefrontProductsSuccess({ products }));
      } catch (e) {
        dispatch(getStorefrontProductsFailure({ error: e?.message || JSON.stringify(e) }));
      }
    };

    if (_.isEmpty(storeProducts)) {
      getProducts();
    }
  }, [dispatch, storeProducts]);

  useEffect(() => {
    if (error) {
      showErrorSnackbar(STOREFRONT_FETCH_PRODUCTS_ERROR)(dispatch);
    }
  }, [dispatch, error]);

  const products = useMemo(() => formatProductsForSelect(storeProducts), [storeProducts]);
  const canInteract = !isStoreLoading && !error;
  const isButtonEnabled = canInteract && !!selectedProduct;

  return (
    <StorefrontPage header={headerLabel} subHeader={subheaderLabel}>
      <Box className="grid gap-y-12 w-1/2">
        <ProductsSelect
          onChange={(e) =>
            dispatch(
              setStorefrontSelectedProduct({
                selectedProduct: storeProducts[e.target.value],
              }),
            )
          }
          products={products}
          defaultValue=""
          isDisabled={!canInteract && products.length === 0}
        />
        <Button
          component={Link}
          variant="contained"
          color="primary"
          disabled={!isButtonEnabled}
          to={getStorefrontProductAddPathname({
            productVariationId: selectedProduct?.id,
            storeId,
          })}
        >
          Next
        </Button>
      </Box>
    </StorefrontPage>
  );
};

StorefrontCreateProduct.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  subheaderLabel: PropTypes.string.isRequired,
  storeId: PropTypes.string,
};
