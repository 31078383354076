import * as React from 'react';

function SvgSearch(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M20.433 0C14.053 0 8.866 5.186 8.866 11.567s5.187 11.567 11.567 11.567S32 17.948 32 11.567 26.814 0 20.433 0zm0 1.322c5.666 0 10.245 4.579 10.245 10.245s-4.579 10.245-10.245 10.245c-5.666 0-10.245-4.579-10.245-10.245S14.767 1.322 20.433 1.322zM12.14 19.168a.66.66 0 00-.436.194L.468 30.598a.658.658 0 000 .934.658.658 0 00.934 0l11.236-11.236a.658.658 0 000-.934.659.659 0 00-.468-.194l-.032.001h.002z" />
    </svg>
  );
}

export default SvgSearch;
