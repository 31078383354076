export const getOverrides = (theme) => ({
  formControl: {
    'label + &': {
      marginTop: 0,
    },
  },
  underline: {
    '&:before': {
      borderBottomColor: theme.colors.gray[300],
    },
    '&:hover:before': {
      borderBottomColor: theme.colors.gray[400],
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.colors.gray[300],
      borderBottomWidth: '1px',
    },
  },
});
