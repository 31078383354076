import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';

import { getUniqueFilterOptions, isCountryOptionSeparator } from '../../../../utils/countryAutocomplete';

export const CreateOrderDropdown = ({
  control,
  error,
  getOptionLabel,
  helperText,
  taxHelperText,
  isDisabled,
  label,
  name,
  options,
  testId,
  onValueChange,
}) => (
  <Controller
    defaultValue={null}
    name={name}
    control={control}
    disabled={isDisabled}
    rules={{ required: true }}
    onChange={([, data]) => data}
    render={({ onChange, onBlur, value, ref }) => (
      <Autocomplete
        data-testid={testId}
        disabled={isDisabled}
        onBlur={onBlur}
        value={value}
        ref={ref}
        options={options}
        onChange={(_event, newVal) => {
          if (onValueChange) {
            onValueChange(newVal);
          }
          return onChange(newVal);
        }}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={isCountryOptionSeparator}
        filterOptions={getUniqueFilterOptions}
        isOptionEqualToValue={({ value: optionValue, id }, autocomplete) => optionValue === autocomplete?.value || id === autocomplete?.id}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={(params) => (
          <>
            <TextField {...params} label={label} helperText={helperText} error={!!error} />
            {taxHelperText && <FormHelperText>{taxHelperText}</FormHelperText>}
          </>
        )}
      />
    )}
  />
);

CreateOrderDropdown.propTypes = {
  control: PropTypes.shape({}).isRequired,
  error: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  taxHelperText: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  testId: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
};
