import * as React from 'react';

function SvgAddOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M17.52 14.08h6.08v3.04h-6.08v6.08h-3.04v-6.08H8.4v-3.04h6.08V8h3.04v6.08zM16 30.8C7.605 30.8.8 23.995.8 15.6S7.605.4 16 .4s15.2 6.805 15.2 15.2S24.395 30.8 16 30.8zm0-3.04c6.716 0 12.16-5.444 12.16-12.16S22.716 3.44 16 3.44 3.84 8.884 3.84 15.6 9.284 27.76 16 27.76z" />
    </svg>
  );
}

export default SvgAddOutline;
