import { SET_LAB_SLA } from './lab.action';

export const initialLabState = {};

export const labReducer = (state = initialLabState, action = '') => {
  switch (action.type) {
    case SET_LAB_SLA:
      return {
        ...state,
        ...action.payload.data,
      };

    default:
      return state;
  }
};
