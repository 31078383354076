import { Provider } from 'react-redux';
import { render } from 'react-dom';
import Axios from 'axios';
import { navigate } from '@reach/router';
import { App } from './app';
import './stories/index.css';
import { getHomePathname, getLogoutPathname } from './constants';
import store from './store';
import { Monitoring } from './components/monitoring/Monitoring';

// Define Axios header so it simulates the browser AJAX requests
// axios.defaults.baseURL = apiUrl;
Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const bearerToken = localStorage.getItem('access_token');
if (bearerToken) {
  Axios.defaults.headers.common = { Authorization: `Bearer ${bearerToken}` };
}

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('access_token');
      Axios.post(getLogoutPathname()).then(navigate(getHomePathname())).catch(/* Swallow errors */).finally(getHomePathname());
    }
    return Promise.reject(error);
  },
);

render(
  <Monitoring>
    <Provider store={store}>
      <App />
    </Provider>
  </Monitoring>,
  document.querySelector('#root'),
);
