const headingTypography = {
  fontWeight: 500,
};

export const overrides = {
  h1: {
    ...headingTypography,
  },
  h2: {
    ...headingTypography,
  },
  h3: {
    ...headingTypography,
  },
  h4: {
    ...headingTypography,
  },
  h5: {
    ...headingTypography,
  },
  h6: {
    fontWeight: 500,
  },
};
