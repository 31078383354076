import { Link } from '@reach/router';

export const MenuItem = ({ href, label, linkClassName, Icon }) => (
  <li key={`${href}-${label}`}>
    <Link className="h-16 flex px-6" to={href} getProps={linkClassName}>
      <Icon className="text-2xl mr-3" />
      <div>{label}</div>
    </Link>
  </li>
);
