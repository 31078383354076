import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Fragment } from 'react';
import * as CustomMuiStyle from '../covid-results/shared.style';
import { modalColumns } from './test-results.config';

export const TestResultDetails = ({ resultDetails }) => (
  <TableContainer>
    <CustomMuiStyle.StyledTable stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {modalColumns.map((column) => (
            <CustomMuiStyle.StyledTableCellHeader key={column.id} align={column.align}>
              {column.label}
            </CustomMuiStyle.StyledTableCellHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {resultDetails[0].biomarkerResults.map((row) => (
          <Fragment key={row.id}>
            <CustomMuiStyle.StyledTableRow hover role="checkbox" tabIndex={-1}>
              {modalColumns.map((column) => (
                <CustomMuiStyle.StyledTableCell key={column.id} align={column.align}>
                  {column.formatForDisplay(row)}
                </CustomMuiStyle.StyledTableCell>
              ))}
            </CustomMuiStyle.StyledTableRow>
            <TableRow tabIndex={-1}>
              <CustomMuiStyle.StyledTableDividerCell colSpan="8" />
            </TableRow>
          </Fragment>
        ))}
      </TableBody>
    </CustomMuiStyle.StyledTable>
  </TableContainer>
);
