import * as React from 'react';

function SvgLogoChronomics(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 152 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
      <path d="M14.8834 0V11.2423H4.53952V0H0.922485V20H4.53952V14.7857H14.8834V20H18.5004V0H14.8834Z" fill="currentColor"/>
      <path d="M93.7037 16.4282H89.3083V3.57056H93.7037C97.6423 3.57056 99.5178 6.11388 99.5178 10.0001C99.5178 13.857 97.6172 16.4282 93.7037 16.4282ZM93.9713 0H85.6909V20H93.9713C99.7862 20 103.271 15.9432 103.271 10.0001C103.271 4.08472 99.8139 0 93.9713 0Z" fill="currentColor"/>
      <path d="M114.188 0V20H128.014V16.3711H117.805V0H114.188Z" fill="currentColor"/>
      <path d="M137.815 0V20H151.077V16.4283H141.433V10.4842H151.077V7.05653H141.433V3.57153H151.077V0H137.815Z" fill="currentColor"/>
      <path d="M44.0884 10.6885C44.0884 14.4018 42.2431 16.427 39.0753 16.427C35.9102 16.427 34.0625 14.4018 34.0625 10.6885V0H30.5012V11.0532C30.5012 16.1174 33.509 20 39.0753 20C44.6433 20 47.6506 16.1174 47.6506 11.0532V0H44.0884V10.6885Z" fill="currentColor"/>
      <path d="M67.2519 10.2865H62.6162V3.51457H67.2519C70.0122 3.51457 71.1901 4.8868 71.1901 6.91458C71.1901 8.94357 70.0122 10.2865 67.2519 10.2865ZM74.9151 6.91458C74.9151 3.14293 72.5036 0 67.8955 0H58.9979V20H62.6162V13.8289H67.4667L71.6201 20H75.9607L71.2701 13.1135C73.6568 11.9717 74.9151 9.60037 74.9151 6.91458Z" fill="currentColor"/>
    </svg>
  );
}

export default SvgLogoChronomics;
