import PropTypes from 'prop-types';

export const validateSizes = (props, propName, componentName) => {
  if (props.srcSet && !props.sizes) {
    return new Error(`A value of type string for sizes is required when using srcSet in '${componentName}'.`);
  }

  return null;
};

export const ResponsiveImage = ({ alt, className, sizes, src, srcSet }) => {
  if (srcSet && sizes) {
    return (
      <picture>
        <source srcSet={srcSet} sizes={sizes} />
        <img className={className} alt={alt} src={src} />
      </picture>
    );
  }

  return <img className={className} alt={alt} src={src} />;
};

ResponsiveImage.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  sizes: validateSizes,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
};
