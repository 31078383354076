import { Link } from '@reach/router';
import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

export const IconRedirectAction = ({ name, icon, url }) => (
  <Link to={url} style={{ color: 'inherit' }}>
    <SvgIcon title={name} component={icon} className="cursor-pointer" />
  </Link>
);

IconRedirectAction.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};
