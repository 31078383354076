import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import MaterialDialogActions from '@mui/material/DialogActions';

export const DialogActions = ({
  onClickPrimary,
  onClickSecondary,
  primaryButtonLabel,
  secondaryButtonLabel,
  primaryButtonType,
  secondaryButtonType,
  isPrimaryActionClickable,
  isSecondaryActionClickable,
}) => (
  <MaterialDialogActions>
    {secondaryButtonLabel && (
      <Button
        color="secondary"
        disabled={!isSecondaryActionClickable}
        type={secondaryButtonType}
        onClick={() => onClickSecondary && onClickSecondary()}
      >
        {secondaryButtonLabel}
      </Button>
    )}
    <Button disabled={!isPrimaryActionClickable} type={primaryButtonType} onClick={() => onClickPrimary && onClickPrimary()} autoFocus>
      {primaryButtonLabel}
    </Button>
  </MaterialDialogActions>
);

DialogActions.propTypes = {
  isPrimaryActionClickable: PropTypes.bool,
  isSecondaryActionClickable: PropTypes.bool,
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
  primaryButtonLabel: PropTypes.string.isRequired,
  primaryButtonType: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonType: PropTypes.string,
};

DialogActions.defaultProps = {
  isPrimaryActionClickable: true,
  isSecondaryActionClickable: true,
};
