import { Router } from '@reach/router';

import ConnectStorefrontWelcome from './ConnectStorefrontWelcome';
import { GuestLayout } from '../../../components/layouts/GuestLayout';

const ConnectStorefront = () => (
  <GuestLayout logoUrl="/">
    <Router className="w-full h-full flex p-4 md:px-24" path="/">
      <ConnectStorefrontWelcome path="/" />
    </Router>
  </GuestLayout>
);

export default ConnectStorefront;
