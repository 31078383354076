import { Button, Link } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import PropTypes from 'prop-types';

const DOCS_DOMAIN = 'https://docs.hurdle.bio';

const baseLinks = [
  {
    title: 'Quickstart Guide',
    text: 'Learn about key concepts and starter code.',
    linkTitle: 'Get started',
    linkHref: new URL('/docs', DOCS_DOMAIN).href,
  },
  {
    title: 'API Docs',
    text: 'Explore our full API docs.',
    linkTitle: 'Get started',
    linkHref: new URL('/reference', DOCS_DOMAIN).href,
  },
];

export const GettingStartedLinks = ({ links }) =>
  [...baseLinks, ...links].map(({ title, text, linkTitle, linkHref }) => (
    <div className="flex flex-col" key={title}>
      <div className="flex-1">
        <header className="text-2xl font-medium mb-2">{title}</header>
        <p>{text}</p>
      </div>
      <Link href={linkHref}>
        <Button variant="text" color="primary" startIcon={<ArrowForward />}>
          {linkTitle}
        </Button>
      </Link>
    </div>
  ));

GettingStartedLinks.proptypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      linkTitle: PropTypes.string.isRequired,
      linkHref: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
