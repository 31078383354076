import { TextField, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { string, arrayOf, func, shape } from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { CircleArrow } from '../../../icons';
import { formErrorMessages } from '../../../utils/form-error-messages';
import { countryPropType } from '../utils';
import { getUniqueFilterOptions, isCountryOptionSeparator } from '../../../utils/countryAutocomplete';

const PartnerForm = ({ countries, defaultValues, onSubmit, onCancel }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="partnerName"
        label="Company Name"
        inputRef={register({ required: true, minLength: 2 })}
        error={errors.partnerName}
        helperText={formErrorMessages(errors.partnerName, 2)}
      />
      <TextField
        name="partnerAddress1"
        label="Address line 1"
        inputRef={register({ required: true, minLength: 2 })}
        error={errors.partnerAddress1}
        helperText={formErrorMessages(errors.partnerAddress1, 2)}
      />
      <TextField
        name="partnerAddress2"
        label="Address line 2"
        inputRef={register({ minLength: 2 })}
        error={errors.partnerAddress2}
        helperText={errors.partnerAddress2 ? formErrorMessages(errors.partnerAddress2, 2) : 'Optional'}
      />
      <TextField
        name="city"
        label="City"
        inputRef={register({ required: true, minLength: 3 })}
        error={errors.city}
        helperText={formErrorMessages(errors.city, 3)}
      />
      <TextField
        name="postCode"
        label="Post Code"
        inputRef={register({ required: true })}
        error={errors.postCode}
        helperText={formErrorMessages(errors.postCode)}
      />
      <Controller
        name="country"
        control={control}
        rules={{ required: true }}
        render={({ onChange, onBlur, value, name, ref }, { invalid }) => (
          <Autocomplete
            onBlur={onBlur}
            value={value}
            ref={ref}
            options={countries}
            filterOptions={getUniqueFilterOptions}
            getOptionLabel={(option) => option.title}
            getOptionDisabled={isCountryOptionSeparator} // TODO remove the '------' option from API results
            onChange={(event, newVal) => onChange(newVal)}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params} // eslint-disable-line react/jsx-props-no-spreading
                name={name}
                label="Country"
                error={invalid}
                helperText={formErrorMessages(errors.country)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        )}
      />
      <div className="flex justify-between">
        <Button variant="outlined" startIcon={<CircleArrow />} onClick={onCancel}>
          Customer Type
        </Button>
        <Button type="submit" endIcon={<CircleArrow className="transform rotate-180" />}>
          Submit Your Details
        </Button>
      </div>
    </form>
  );
};

PartnerForm.defaultProps = {
  defaultValues: {
    partnerName: null,
    partnerAddress1: null,
    partnerAddress2: null,
    city: null,
    postCode: null,
    country: {
      title: null,
      country_code: null,
      dialing_code: null,
    },
  },
};

PartnerForm.propTypes = {
  countries: arrayOf(countryPropType).isRequired,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  defaultValues: shape({
    partnerName: string.isRequired,
    partnerAddress1: string.isRequired,
    partnerAddress2: string.isRequired,
    city: string.isRequired,
    postCode: string.isRequired,
    country: countryPropType.isRequired,
  }),
};

export default PartnerForm;
