import { Button, CircularProgress } from '@mui/material';

export const SignInButton = ({ isLoginLoading, partnerPrimaryColour = null }) => (
  <Button
    className="float-right"
    style={{ backgroundColor: partnerPrimaryColour }}
    type="submit"
    disabled={isLoginLoading}
    startIcon={isLoginLoading && <CircularProgress />}
  >
    Sign In
  </Button>
);
