import { formatISO } from 'date-fns';
import { fakerEN_GB as faker } from '@faker-js/faker';

// from https://bitbucket.org/chronomics/package-service/src/a3e1cac4ae3b11995388bf1eb559d4813762d81f/app/service/internal/idGenerator.ts?at=main#lines-26
const KIT_ID_LENGTH = 4;
const DEBUGGING_FIXED_STRING = `PDTEST_`;
const CARRIERS = {
  DPD_UK: 'DPD_UK',
  DPD_LOCAL: 'DPD_LOCAL',
  ROYAL_MAIL: 'ROYAL_MAIL',
};
export const WEBHOOKS = {
  ORDER_CREATED: 'ORDER.CREATED',
  ORDER_DISPATCHED: 'ORDER.DISPATCHED',
  ORDER_OUT_FOR_DELIVERY: 'ORDER.OUT_FOR_DELIVERY',
  ORDER_RECEIVED: 'ORDER.RECEIVED',
  ORDER_RETURN_OUT_FOR_DELIVERY: 'ORDER.RETURN_OUT_FOR_DELIVERY',
  ORDER_RETURN_RECEIVED: 'ORDER.RETURN_RECEIVED',
  PHLEBOTOMY_NURSE_ASSIGNED: 'PHLEBOTOMY.NURSE_ASSIGNED',
  PHLEBOTOMY_APPOINTMENT_SCHEDULED: 'PHLEBOTOMY.APPOINTMENT_SCHEDULED',
  PHLEBOTOMY_APPOINTMENT_RESCHEDULED: 'PHLEBOTOMY.APPOINTMENT_RESCHEDULED',
  PHLEBOTOMY_APPOINTMENT_CANCELLED: 'PHLEBOTOMY.APPOINTMENT_CANCELLED',
  TEST_REGISTERED: 'TEST.REGISTERED',
  TEST_LAB_ACCESSIONED: 'TEST.LAB_ACCESSIONED',
  TEST_PROCESSED: 'TEST.PROCESSED',
  TEST_CLINICAL_COMMENTARY_UPLOADED: 'TEST.CLINICAL_COMMENTARY_UPLOADED',
};

const generateFakeKitId = () =>
  `${DEBUGGING_FIXED_STRING}${faker.string.alpha({
    length: { max: KIT_ID_LENGTH, min: KIT_ID_LENGTH },
    casing: 'upper',
  })}${faker.string.numeric({
    length: {
      min: KIT_ID_LENGTH,
      max: KIT_ID_LENGTH,
    },
  })}`;

const getBasePayloadFields = (clinicId) => ({
  clinicId,
  kitId: generateFakeKitId(),
  orderNumber: `${DEBUGGING_FIXED_STRING}${faker.string.alpha({ length: { max: 2, min: 2 }, casing: 'upper' })}${faker.string.numeric()}`,
  productCode: 'C1BLP14500',
});

const getTrackingPayloadFields = () => ({
  trackingCode: faker.string.alphanumeric({ length: 8 }),
  trackingUrl: `https://www.example.com/?code=${faker.string.alphanumeric({ length: 8 })}`,
  carrier: faker.helpers.enumValue(CARRIERS),
  kits: [{ kitId: generateFakeKitId() }],
  email: 'pd_test@example.com',
});

export const WEBHOOK_PAYLOADS = {
  [WEBHOOKS.PHLEBOTOMY_APPOINTMENT_RESCHEDULED]: (clinicId) => ({
    ...getBasePayloadFields(clinicId),
    scheduledDate: formatISO(new Date()),
    type: 'MOBILE',
    partnerUserId: faker.string.uuid(),
  }),
  [WEBHOOKS.PHLEBOTOMY_APPOINTMENT_CANCELLED]: (clinicId) => ({
    ...getBasePayloadFields(clinicId),
    type: 'MOBILE',
    partnerUserId: faker.string.uuid(),
  }),
  [WEBHOOKS.ORDER_OUT_FOR_DELIVERY]: (clinicId) => ({
    ...getBasePayloadFields(clinicId),
    ...getTrackingPayloadFields(),
    outForDeliveryAt: formatISO(new Date()),
  }),
  [WEBHOOKS.ORDER_RECEIVED]: (clinicId) => ({
    ...getBasePayloadFields(clinicId),
    ...getTrackingPayloadFields(),
    receivedAt: formatISO(new Date()),
  }),
  [WEBHOOKS.ORDER_RETURN_OUT_FOR_DELIVERY]: (clinicId) => ({
    ...getBasePayloadFields(clinicId),
    ...getTrackingPayloadFields(),
    outForDeliveryAt: formatISO(new Date()),
  }),
  [WEBHOOKS.ORDER_RETURN_RECEIVED]: (clinicId) => ({
    ...getBasePayloadFields(clinicId),
    ...getTrackingPayloadFields(),
    receivedAt: formatISO(new Date()),
  }),
};

/* we do not support all webhooks */
export const isTriggerableEvent = (webhookId) => Object.keys(WEBHOOK_PAYLOADS).includes(webhookId);
