import * as React from 'react';

function SvgUsersInCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <g transform="translate(8)">
          <circle cx={4} cy={2} r={1.5} />
          <path
            d="M4 4.25c1.036 0 1.973.42 2.652 1.098a3.738 3.738 0 011.09 2.402h0H.258a3.738 3.738 0 011.09-2.402A3.738 3.738 0 014 4.25z"
            strokeWidth={0.5}
          />
          <path d="M4 4.5c.966 0 1.841.392 2.475 1.025a3.49 3.49 0 01.99 1.975h0-6.93a3.49 3.49 0 01.99-1.975A3.489 3.489 0 014 4.5z" />
        </g>
        <g transform="translate(16 8)">
          <circle cx={4} cy={2} r={1.5} />
          <path
            d="M4 4.25c1.036 0 1.973.42 2.652 1.098a3.738 3.738 0 011.09 2.402h0H.258a3.738 3.738 0 011.09-2.402A3.738 3.738 0 014 4.25z"
            strokeWidth={0.5}
          />
          <path d="M4 4.5c.966 0 1.841.392 2.475 1.025a3.49 3.49 0 01.99 1.975h0-6.93a3.49 3.49 0 01.99-1.975A3.489 3.489 0 014 4.5z" />
        </g>
        <g transform="translate(8 16)">
          <circle cx={4} cy={2} r={1.5} />
          <path
            d="M4 4.25c1.036 0 1.973.42 2.652 1.098a3.738 3.738 0 011.09 2.402h0H.258a3.738 3.738 0 011.09-2.402A3.738 3.738 0 014 4.25z"
            strokeWidth={0.5}
          />
          <path d="M4 4.5c.966 0 1.841.392 2.475 1.025a3.49 3.49 0 01.99 1.975h0-6.93a3.49 3.49 0 01.99-1.975A3.489 3.489 0 014 4.5z" />
        </g>
        <g transform="translate(0 8)">
          <circle cx={4} cy={2} r={1.5} />
          <path
            d="M4 4.25c1.036 0 1.973.42 2.652 1.098a3.738 3.738 0 011.09 2.402h0H.258a3.738 3.738 0 011.09-2.402A3.738 3.738 0 014 4.25z"
            strokeWidth={0.5}
          />
          <path d="M4 4.5c.966 0 1.841.392 2.475 1.025a3.49 3.49 0 01.99 1.975h0-6.93a3.49 3.49 0 01.99-1.975A3.489 3.489 0 014 4.5z" />
        </g>
        <path
          d="M16.431 20.913a9.941 9.941 0 003.582-2.88c.165-.213.367-.51.514-.734M7.666 20.91a9.941 9.941 0 01-3.581-2.88 12.363 12.363 0 01-.515-.733M16.417 3.06a9.941 9.941 0 013.581 2.88c.166.213.368.509.515.733M7.652 3.057a9.941 9.941 0 00-3.581 2.88c-.165.213-.368.509-.515.733"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default SvgUsersInCircle;
