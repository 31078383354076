export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const SEVERITIES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const showSuccessSnackbar = (message) => (dispatch) => {
  dispatch({
    type: OPEN_SNACKBAR,
    severity: SEVERITIES.SUCCESS,
    message,
  });
};

export const showErrorSnackbar = (message) => (dispatch) => {
  dispatch({
    type: OPEN_SNACKBAR,
    severity: SEVERITIES.ERROR,
    message,
  });
};

export const showWarningSnackbar = (message) => (dispatch) => {
  dispatch({
    type: OPEN_SNACKBAR,
    severity: SEVERITIES.WARNING,
    message,
  });
};

export const showInfoSnackbar = (message) => (dispatch) => {
  dispatch({
    type: OPEN_SNACKBAR,
    severity: SEVERITIES.INFO,
    message,
  });
};

export const clearSnackbar = () => (dispatch) => {
  dispatch({ type: CLOSE_SNACKBAR });
};
