import { Button, TextField, InputAdornment, Tooltip, IconButton } from '@mui/material';
import { QuestionMark } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { formErrorMessages } from '../../../../utils/form-error-messages';
import { REGEX } from '../../../../data/constants';
import { ProductsSelect } from '../../../../components/select/ProductsSelect';
import { passwordValidator } from '../../../../utils/validators';
import { SimulateFormResponse } from './SimulateFormResponse';

export const SimulateForm = ({
  type,
  descriptionNew,
  descriptionExisting,
  productVariations,
  isProductVariationsDisabled,
  onFormSubmit,
  orderResponseData,
  isStandalone,
  isFormDisabled,
}) => {
  const [product, setProduct] = useState('');

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      product,
    },
  });

  const {
    handleSubmit: handleSecondFormSubmit,
    register: secondFormRegister,
    formState: { errors: secondFormValidationErrors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      product,
    },
  });

  const standaloneModeNotPossibleTypes = ['order', 'seed'];
  return (
    <div className="flex row">
      <form key={1} className="px-5" onSubmit={handleSubmit((data) => onFormSubmit(data, type, product))} data-testid="form">
        <div className="flex flex-row pt-5">
          <div>
            <p className="mb-4">{descriptionNew}</p>
            <div className="max-w-lg">
              {type === 'seed' && (
                <TextField
                  className="mb-4 mr-4"
                  label="Batch Count"
                  id="batch"
                  name="batch"
                  disabled={isFormDisabled}
                  helperText={formErrorMessages(errors.batch)}
                  inputRef={register({ pattern: REGEX.DIGITS_BATCH_COUNTS })}
                  error={!!errors.batch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="note: Maximum batch count is 50.">
                          <IconButton>
                            <QuestionMark />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
              <TextField
                className="mb-4 mr-4"
                label="Email"
                id="email"
                name="email"
                disabled={isFormDisabled}
                helperText={formErrorMessages(errors.email)}
                inputRef={register({ pattern: REGEX.EMAIL })}
                error={!!errors.email}
                InputProps={
                  type === 'seed'
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title="note: provided email will be subaddressed(joe+123@doe.com) to generate multiple datasets with same mailbox. choose dummy
                  email if you are not looking for mail notifications."
                            >
                              <IconButton>
                                <QuestionMark />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
              {(type === 'register' || type === 'labAccession' || type === 'result') && (
                <TextField
                  className="mb-4 mr-4"
                  label="Password"
                  id="password"
                  name="password"
                  disabled={isFormDisabled}
                  helperText={formErrorMessages(errors.password)}
                  inputRef={register({ required: true, validate: passwordValidator })}
                  error={!!errors.password}
                />
              )}

              <Controller
                name="product"
                className="mb-4 mr-4"
                control={control}
                render={() => (
                  <ProductsSelect
                    onChange={(e) => setProduct(e.target.value)}
                    defaultValue=""
                    products={productVariations}
                    isDisabled={isProductVariationsDisabled || isFormDisabled}
                  />
                )}
              />

              <Button data-testid="button" type="submit" disabled={isFormDisabled || !product} className="mt-4">
                Generate
              </Button>
              <p>
                <i>This may take a little while to emulate, please be patient and wait for the results to display.</i>
              </p>
            </div>

            {!isStandalone && orderResponseData && <SimulateFormResponse data={orderResponseData} isStandalone={false} />}
          </div>
        </div>
      </form>
      <form
        key={2}
        className="px-5"
        onSubmit={handleSecondFormSubmit((data) => onFormSubmit(data, type, product))}
        data-testid="secondForm"
      >
        <div className="flex flex-row pt-5">
          {!standaloneModeNotPossibleTypes.includes(type) && (
            <>
              <div className="mx-10">
                <strong>OR</strong>
              </div>

              <div>
                <p className="mb-4">{descriptionExisting}</p>

                <div className="max-w-lg">
                  {type === 'register' && (
                    <>
                      <TextField
                        className="mb-4 mr-4"
                        label="Email"
                        id="existingEmail"
                        name="existingEmail"
                        disabled={isFormDisabled}
                        helperText={formErrorMessages(secondFormValidationErrors.existingEmail)}
                        inputRef={secondFormRegister({ pattern: REGEX.EMAIL })}
                        error={!!secondFormValidationErrors.existingEmail}
                      />
                      <TextField
                        className="mb-4 mr-4"
                        label="Password"
                        id="existingPassword"
                        name="existingPassword"
                        disabled={isFormDisabled}
                        helperText={formErrorMessages(secondFormValidationErrors.existingPassword)}
                        inputRef={secondFormRegister({ required: true, validate: passwordValidator })}
                        error={!!secondFormValidationErrors.existingPassword}
                      />
                    </>
                  )}

                  {type === 'register' ? (
                    <TextField
                      className="mr-4"
                      label="Existing kit id"
                      id="kitId"
                      name="kitId"
                      disabled={isFormDisabled}
                      helperText={formErrorMessages(secondFormValidationErrors.kitId)}
                      inputRef={secondFormRegister({ pattern: REGEX.ALPHANUMERIC })}
                      error={!!secondFormValidationErrors.kitId}
                    />
                  ) : (
                    <TextField
                      className="mr-4"
                      label="Existing order number"
                      id="orderId"
                      name="orderId"
                      disabled={isFormDisabled}
                      helperText={formErrorMessages(secondFormValidationErrors.orderId)}
                      inputRef={secondFormRegister({ pattern: REGEX.ALPHANUMERIC })}
                      error={!!secondFormValidationErrors.orderId}
                    />
                  )}

                  <Button data-testid="secondFormButton" type="submit" disabled={isFormDisabled} className="mt-4">
                    Change state
                  </Button>

                  {isStandalone && orderResponseData && <SimulateFormResponse data={orderResponseData} isStandalone />}
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
