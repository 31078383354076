import * as React from 'react';

function SvgUsers(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M16 5.333c-2.938 0-5.333 2.396-5.333 5.333s2.396 5.333 5.333 5.333c2.938 0 5.333-2.396 5.333-5.333S18.937 5.333 16 5.333zm0 1.334c2.217 0 4 1.783 4 4s-1.783 4-4 4-4-1.783-4-4 1.783-4 4-4zM16 16a10.64 10.64 0 00-7.542 3.125 10.64 10.64 0 00-3.102 6.831l-.047.711h21.38l-.047-.711a10.636 10.636 0 00-3.102-6.831A10.64 10.64 0 0015.998 16zm0 1.333a9.298 9.298 0 016.599 2.734c1.384 1.384 2.235 3.236 2.534 5.266H6.867c.298-2.029 1.149-3.881 2.534-5.266A9.294 9.294 0 0116 17.333zM16 16c-2.594 0-4.959.972-7.513 2.445-.89-.665-1.96-1.112-3.154-1.112a5.324 5.324 0 00-3.771 1.563 5.322 5.322 0 00-1.508 3.01l-.109.76h6.341c-.43 1.043-.851 2.102-.93 3.279l-.047.721 21.375-.333-.07-.719c-.103-1.051-.489-2-.875-2.94l6.166-.024-.005-1.012a5.328 5.328 0 00-1.56-2.836c-.956-.909-2.182-1.577-3.603-1.577-1.255 0-1.991.072-3.781 1.19C21.185 16.86 18.57 16 15.998 16zm0 1.333c2.389 0 4.56.894 6.211 2.367l.32.284 1.29-.495c.848-.819 2.076-1.161 2.99-1.11 1.108.062 1.994.677 2.683 1.389.526.543.759 1.177.974 1.665l-6.673-.009.484.927c.425.814.627 1.742.797 2.674l-18.182.284c.159-1.052.37-2.092.849-2.997l.518-.979H1.756c.203-.532.354-1.099.75-1.495a3.978 3.978 0 012.828-1.172c1.033 0 1.968.389 2.677 1.029l.365.328.422-.25c2.611-1.547 4.814-2.44 7.203-2.44zM26.667 12C25.202 12 24 13.202 24 14.667s1.202 2.667 2.667 2.667c1.465 0 2.667-1.202 2.667-2.667S28.132 12 26.667 12zm0 1.333c.744 0 1.333.589 1.333 1.333s-.589 1.333-1.333 1.333-1.333-.589-1.333-1.333.589-1.333 1.333-1.333zM5.333 12c-1.465 0-2.667 1.202-2.667 2.667s1.202 2.667 2.667 2.667C6.798 17.334 8 16.132 8 14.667S6.798 12 5.333 12zm0 1.333c.744 0 1.333.589 1.333 1.333s-.589 1.333-1.333 1.333S4 15.41 4 14.666s.589-1.333 1.333-1.333z" />
    </svg>
  );
}

export default SvgUsers;
