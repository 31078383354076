import * as React from 'react';

function SvgArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M15.918 6.78a.663.663 0 01.44 1.137l-8.862 8.862 8.862 8.862a.663.663 0 010 .942.663.663 0 01-.942 0L6.083 17.25a.663.663 0 010-.942l9.333-9.333a.666.666 0 01.504-.195h-.002zm10.636 9.334h-20c-.369 0-.667.297-.667.667s.297.667.667.667h20c.369 0 .667-.297.667-.667s-.297-.667-.667-.667z" />
    </svg>
  );
}

export default SvgArrow;
