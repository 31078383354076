import { useEffect, useState } from 'react';
import { navigate, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { useDataApi } from '../../hooks';
import { AvailableRoutes } from './available-routes/AvailableRoutes';
import Location from './location-change/Location';
import { AdminLayout } from './_layout';
import { Loader } from '../../components';
import { getUserInfo } from '../api/userInfoRequests';
import { usePartnerBranding } from '../../hooks/use-partner-branding';

export const hasLoaded = (...args) => args.every((arg) => Boolean(arg));

export const LOGIN_PATHNAME = '/login';
export const PROFILE_PATHNAME = '/admin/profile';
const isProfilePage = (pathname) => pathname === PROFILE_PATHNAME;

const Admin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [{ isLoading: isConfigLoading, data: config }] = useDataApi('/config');

  const {
    adminUserId,
    partnerType,
    permissions = {},
    clinic,
    fname,
    isRequiredChangePassword,
  } = useSelector(({ user: { profile, permission } = { profile: {} } }) => ({
    adminUserId: profile.id,
    partnerType: profile.type,
    permissions: permission,
    clinic: profile.clinic,
    fname: profile.fname,
    isRequiredChangePassword: Boolean(profile?.require_password_change),
  }));

  const { hasBranding: hasPartnerBranding, isPartnerBrandingLoading } = usePartnerBranding();

  const shouldRedirect = isRequiredChangePassword && !isProfilePage(location.pathname);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserInfo(dispatch).catch(() => {
      navigate(LOGIN_PATHNAME);
    });
  }, [dispatch]);

  useEffect(() => {
    if (shouldRedirect) {
      navigate(PROFILE_PATHNAME);
    }
  }, [shouldRedirect]);

  useEffect(() => {
    if (!shouldRedirect && hasLoaded(partnerType, adminUserId, !isConfigLoading, !isPartnerBrandingLoading)) {
      setIsLoading(false);
    }
  }, [partnerType, adminUserId, isConfigLoading, shouldRedirect, isPartnerBrandingLoading]);

  /** reach-router facilitates accessibility by focusing on navigation
   * on most browsers' implementation this leads to auto-scrolling as a side effect
   * <Location> is an implementation of the recommended fix here: https://stackoverflow.com/a/56996986
   * */
  return (
    <>
      {!isLoading && (
        <AdminLayout hasPartnerBranding={hasPartnerBranding}>
          <AvailableRoutes
            clinic={clinic}
            partnerType={partnerType}
            permissions={permissions}
            isDeveloperEnabled={config.enabledFeatures.developer}
            isTeamEnabled={config.enabledFeatures.team}
            isOrdersEnabled={config.enabledFeatures.orders}
            isStorefrontEnabled={config.enabledFeatures.storefront}
            fname={fname}
          />
          <Location />
        </AdminLayout>
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default Admin;
