import * as React from 'react';

function SvgExport(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M4.945 11.085a.904.904 0 001.548.598l9.328-9.328 9.328 9.328a.904.904 0 001.282 0 .904.904 0 000-1.282L16.462.432a.904.904 0 00-1.282 0l-9.969 9.969a.901.901 0 00-.265.685v-.002zm9.969 11.739V2.886c0-.502.404-.906.906-.906s.906.404.906.906v19.938c0 .502-.404.906-.906.906s-.906-.404-.906-.906zM4.882 14.667c-1.474 0-2.655 1.245-2.655 2.719v9.063c0 1.474 1.181 2.719 2.655 2.719H26.76c1.474 0 2.655-1.245 2.655-2.719v-9.063c0-1.474-1.181-2.719-2.655-2.719h-4.291a.906.906 0 000 1.812h4.291c.459 0 .843.378.843.906v9.063c0 .528-.383.906-.843.906H4.882c-.459 0-.843-.378-.843-.906v-9.063c0-.528.383-.906.843-.906h3.565a.906.906 0 100-1.812z" />
    </svg>
  );
}

export default SvgExport;
