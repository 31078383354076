import {
  TextField,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  InputAdornment,
  IconButton,
  FilledInput,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { formErrorMessages } from '../../../utils/form-error-messages';
import { countryPropType } from '../utils';
import { CircleArrow, Eye } from '../../../icons';
import { REGEX } from '../../../data/constants';
import { passwordValidator } from '../../../utils/validators';
import { getOptionLabel, getUniqueFilterOptions, isCountryOptionSeparator } from '../../../utils/countryAutocomplete';

const PartnerUserForm = ({ countries, defaultValues, onSubmit, onCancel }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword((isShown) => !isShown);
  const preventDefault = (e) => e.preventDefault();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="firstName"
        label="First Name"
        inputRef={register({ required: true, minLength: 2 })}
        error={errors.firstName}
        helperText={formErrorMessages(errors.firstName, 2)}
      />
      <TextField
        name="lastName"
        label="Last Name"
        inputRef={register({ required: true, minLength: 3 })}
        error={errors.lastName}
        helperText={formErrorMessages(errors.lastName, 3)}
      />
      <div className="grid grid-cols-3 gap-4">
        <Controller
          name="countryCode"
          control={control}
          rules={{ required: true }}
          render={({ onChange, onBlur, value, name, ref }, { invalid }) => (
            <Autocomplete
              onBlur={onBlur}
              value={value}
              ref={ref}
              options={countries}
              filterOptions={getUniqueFilterOptions}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={isCountryOptionSeparator}
              isOptionEqualToValue={(option) => option.selected}
              onChange={(event, newVal) => onChange(newVal)}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {getOptionLabel(option)}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params} // eslint-disable-line react/jsx-props-no-spreading
                  name={name}
                  label="Country Code"
                  error={invalid}
                  helperText={formErrorMessages(errors.countryCode)}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          )}
        />
        <TextField
          className="col-span-2"
          name="phoneNumber"
          label="Phone Number"
          type="number"
          inputRef={register({ required: true, pattern: REGEX.DIGITS })}
          error={errors.phoneNumber}
          helperText={formErrorMessages(errors.phoneNumber)}
        />
      </div>
      <TextField
        name="email"
        label="Email Address"
        inputRef={register({ required: true, pattern: REGEX.EMAIL })}
        error={errors.email}
        helperText={formErrorMessages(errors.email)}
      />
      <FormControl error={errors.password} variant="filled">
        <InputLabel htmlFor="input-password">Password</InputLabel>
        <FilledInput
          id="input-password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          inputRef={register({ required: true, validate: passwordValidator })}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                onMouseDown={preventDefault}
                size="large"
              >
                <Eye className={showPassword ? 'text-primary' : ''} />
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{formErrorMessages(errors.password)}</FormHelperText>
      </FormControl>
      <FormControl error={errors.agree}>
        <FormControlLabel
          label={
            <div>
              Please confirm that you have read and accept our&nbsp;
              <a href="https://hurdle.bio/cookie-policy" rel="noreferrer" title="Cookie policy" target="_blank">
                cookie policy
              </a>
              &nbsp;and&nbsp;
              <a href="https://hurdle.bio/privacy-policy/" rel="noreferrer" title="privacy policy" target="_blank">
                privacy policy
              </a>
            </div>
          }
          control={
            <Checkbox
              name="agree"
              color="default"
              inputRef={register({
                required: 'You MUST confirm that you have read and accept our cookie policy and privacy policy',
                validate: {
                  required: (isChecked) => isChecked,
                },
              })}
            />
          }
        />
        <FormHelperText>{formErrorMessages(errors.agree)}</FormHelperText>
      </FormControl>
      <FormControl error={errors.agree}>
        <FormControlLabel
          label={
            <div>
              Please confirm that you have read and accept our&nbsp;
              <a href="https://hurdle.bio/partner-terms-conditions/" rel="noreferrer" title="privacy policy" target="_blank">
                terms and conditions
              </a>
            </div>
          }
          control={
            <Checkbox
              name="agreeTerms"
              color="default"
              inputRef={register({
                required: 'You MUST confirm that you have read and accept our terms and conditions',
                validate: {
                  required: (isChecked) => isChecked,
                },
              })}
            />
          }
        />
        <FormHelperText>{formErrorMessages(errors.agreeTerms)}</FormHelperText>
      </FormControl>
      <hr className="mt-5 mb-2" />
      <div className="flex justify-between">
        <Button variant="outlined" startIcon={<CircleArrow />} onClick={onCancel}>
          Partner Details
        </Button>
        <Button type="submit" endIcon={<CircleArrow className="transform rotate-180" />}>
          Submit Your Details
        </Button>
      </div>
    </form>
  );
};

PartnerUserForm.defaultProps = {
  defaultValues: {
    firstName: null,
    lastName: null,
    countryCode: null,
    phoneNumber: null,
    email: null,
    password: null,
    agree: null,
    agreeTerms: null,
  },
};

PartnerUserForm.propTypes = {
  countries: PropTypes.arrayOf(countryPropType).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    countryCode: countryPropType.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    agree: PropTypes.bool.isRequired, // TODO needed?
  }),
};

export default PartnerUserForm;
