import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { withTabs } from '../../../components/hoc/withTabs';
import { Table } from '../../../components/table/Table';
import { getOrdersUrl } from '../../api/phpApiRequests';
import { Pagination } from '../../../components/pagination';
import { SearchBox } from '../../../components/search-box';
import { OrderHistoryExport } from './OrderHistoryExport';
import { formatOrders } from './helpers/formatOrders';
import { Loader } from '../../../components';
import { COLUMN_HEADERS, formatOrderHistoryRow } from './helpers/formatOrderHistoryRow';

const OrderHistory = ({ initialPageState, orders, isOrdersLoading, getOrders }) => {
  const [pageState, setPageState] = useState(initialPageState);
  const [formattedRows, setFormattedRows] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    if (formattedData?.length > 0) {
      setFormattedRows(formattedData.map(formatOrderHistoryRow({ onClick: setSelectedOrder, selectedOrder })));
    }
  }, [formattedData, setSelectedOrder, selectedOrder]);

  useEffect(() => {
    const formatData = async () => Promise.all(formatOrders(orders?.data));
    if (!isOrdersLoading && orders?.meta?.total > 0) {
      formatData().then((response) => setFormattedData(response));
    }
  }, [orders?.data, orders?.meta?.total, isOrdersLoading]);

  useEffect(() => {
    if (!isOrdersLoading && pageState) {
      getOrders(getOrdersUrl(pageState));
    }
  }, [isOrdersLoading, pageState, pageState?.limit, pageState?.page, pageState?.search, getOrders]);

  const handlePageChange = (newLimit, newCurrentPage) => {
    setPageState({ ...pageState, limit: newLimit, page: newCurrentPage });
  };

  return (
    <>
      {isOrdersLoading && <Loader />}
      {!isOrdersLoading && (
        <>
          <SearchBox onChange={(search) => setPageState({ ...pageState, search })} />
          <Table classNames="border-collapse" rows={formattedRows || []} columns={Object.values(COLUMN_HEADERS)} />
        </>
      )}
      <Pagination
        isLoading={isOrdersLoading}
        onChange={handlePageChange}
        totalRecords={orders?.meta?.total}
        numberOfRecordsPerPage={[10, 25, 50, 100]}
      />
    </>
  );
};

OrderHistory.propTypes = {
  orders: PropTypes.shape({
    meta: PropTypes.shape({
      total: PropTypes.number.isRequired,
    }),
    data: PropTypes.array.isRequired,
  }).isRequired,
  getOrders: PropTypes.func.isRequired,
  isOrdersLoading: PropTypes.bool.isRequired,
  initialPageState: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    search: PropTypes.string,
  }).isRequired,
};

export { OrderHistory as __TEST_OrderHistory }; // eslint-disable-line camelcase

export default withTabs(
  OrderHistory,
  {
    tabsHeader: 'Order History',
    ariaLabel: 'Create or view orders',
  },
  OrderHistoryExport,
);
