import PropTypes from 'prop-types';

const onOpenModal = (itemId, setItemId, setOpen) => {
  setOpen(true);
  setItemId(itemId);
};

export const ModalButton = ({ buttonText, itemId, setItemId, setOpen }) => (
  <div>
    <button onClick={() => onOpenModal(itemId, setItemId, setOpen)}>{buttonText}</button>
  </div>
);

ModalButton.propTypes = {
  itemId: PropTypes.string,
  setOpen: PropTypes.func,
  setItem: PropTypes.func,
};
