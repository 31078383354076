export const labMenu = [
  {
    label: 'Saliva PCR - General Testing',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000000',
    sla: {
      service_description: {
        tests: 'COVID testing using saliva samples for general testing',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Subsequent processing, qPCR and data reporting via secure API link to Chronomics’ software portal or via upload of a .csv file to the Chronomics Laboratory Dashboard. Samples may be used for general testing purposes. \n' +
          '\n' +
          'Reporting of results to the client as well as PHE will be conducted by Chronomics in accordance with the government guidelines.',
        test_materials: 'The Test Materials will be exclusively from Chronomics; sample collection kit used is OME 505',
        service_levels: 'Service Levels will be as defined in Schedule 1B. Service level turnaround would be 24-36 hours.',
        liquidated_damages:
          'Where results are reported after the 24-hour Service Levels maximum period the Service Provider will  receive 50% of the price per delayed test where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g.,  equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 24-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration).\n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with CUSTOMER if requested. ',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£5,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Saliva PCR - Fit to Fly',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000001',
    sla: {
      service_description: {
        tests: 'COVID testing using saliva samples for general testing',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Subsequent processing, qPCR and data reporting via secure API link to Chronomics’ software portal or via upload of a .csv file to the Chronomics Laboratory Dashboard. Samples may be used for test-to-release purposes. For test-to-release samples it is the sole responsibility of Chronomics to ensure sampling occurs in accordance with the timelines defined by government guidelines. \n' +
          '\n' +
          'Reporting of results to the client as well as PHE will be conducted by Chronomics in accordance with the government guidelines.',
        test_materials: 'The Test Materials will be exclusively from Chronomics; sample collection kit used is OME 505',
        service_levels: 'Service Levels will be as defined in Schedule 1B. Service level turnaround would be 24-36 hours.',
        liquidated_damages:
          'Where results are reported after the 24-hour Service Levels maximum period the Service Provider will  receive 50% of the price per delayed test where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g.,  equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 24-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration).\n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with CUSTOMER if requested.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£5,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Swab PCR - Fit to fly',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000003',
    sla: {
      service_description: {
        tests: 'COVID testing using swab samples for Fit to Fly',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Subsequent processing, qPCR and data reporting to Chronomics.\n' +
          '\n' +
          'COVID-19 (SARS-CoV-2) testing by qRT-PCR, including sample receipt and logging, sample extraction using validated extraction kit, qRT-PCR using a validated, CE-IVD marked testing kit, return of data via secure API link to Chronomics’ software portal or via upload of a .csv file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          'Reporting of results to the client as well as PHE will be conducted by Chronomics in accordance with the government guidelines.\n' +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. Positive samples may be stored and used for test development or optimisation as required by the Chronomics.',
        test_materials:
          'The Test Materials will be exclusively from Chronomics. Covid-19 tests taken by a traveler for fit-to-fly purposes. Standard flock or sponge swab designed for PCR. Purpose designed and validated swab kits.',
        service_levels:
          'Expected turnaround time is 24 hours from receipt, the service provider aims to process samples in line with internal service quality indicators, this includes >99% processed within 24 hours from sample receipt at service provider site.',
        liquidated_damages:
          'Where results are reported after the 24-hour Service Levels maximum period the Service Provider will  receive 50% of the price per delayed test where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g.,  equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 24-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration) \n' +
          'The Service provider will also be on the government approved providers list for day2 and day8 coronavirus testing for international arrivals.\n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with CUSTOMER if requested.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£5,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Swab PCR - Test to Release',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000004',
    sla: {
      service_description: {
        tests: 'PCR swab tests for Test to Release\n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Subsequent processing, qPCR and data reporting to Chronomics, as well as sequencing of positive samples. \n' +
          '\n' +
          'COVID-19 (SARS-CoV-2) testing by qRT-PCR and where required, including sample receipt and logging, sample extraction using validated extraction kit, qRT-PCR using a validated, CE-IVD marked testing kit, return of data via secure API link to Chronomics’ software portal or via upload of a .csv file to the Chronomics Laboratory Dashboard, and direct upload of sequencing data to Public Health England (PHE) portal as required.\n' +
          '\n' +
          'Reporting of results to the client as well as PHE will be conducted by Chronomics in accordance with the government guidelines.\n' +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. Positive samples may be stored and used for test development or optimisation as required by the Chronomics. ',
        test_materials:
          'The Test Materials will be exclusively from Chronomics. Covid-19 tests taken by a traveler for  on day 5 following their arrival into the United Kingdom.\n' +
          'Standard flock or sponge swab designed for PCR. Purpose designed and validated swab kits.',
        service_levels:
          'Expected turnaround time is 24 hours from receipt, the service provider aims to process samples in line with internal service quality indicators, this includes >99% processed within 24 hours from sample receipt at service provider site. ',
        liquidated_damages:
          'Where results are reported after the 24-hour Service Levels maximum period the Service Provider will  receive 50% of the price per delayed test where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g.,  equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 24-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration) \n' +
          'The Service provider will also be on the government approved providers list for day2 and day8 coronavirus testing for international arrivals.\n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with CUSTOMER if requested.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£5,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Swab PCR - Day 2',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000005',
    sla: {
      service_description: {
        tests: 'PCR swab tests for Day 2 program',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Subsequent processing, qPCR and data reporting to Chronomics, as well as sequencing of positive samples. \n' +
          '\n' +
          'COVID-19 (SARS-CoV-2) testing by qRT-PCR and where required (Positive samples with Ct < 30) WGS, including sample receipt and logging, sample extraction using validated extraction kit, qRT-PCR using a validated, CE-IVD marked testing kit, WGS using validated and approved methodology, return of data via secure API link to Chronomics’ software portal or via upload of a .csv file to the Chronomics Laboratory Dashboard, and direct upload of sequencing data to Public Health England (PHE) portal as required.\n' +
          '\n' +
          'Reporting of results to the client as well as PHE will be conducted by Chronomics in accordance with the government guidelines.\n' +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. Positive samples may be stored and used for test development or optimisation as required by the Chronomics. ',
        test_materials:
          'TThe Test Materials will be exclusively from Chronomics. Covid-19 tests taken by a traveler for fit-to-fly purposes, on day 2 or day 8 following their arrival into the United Kingdom.\n' +
          'Standard flock or sponge swab designed for PCR. Purpose designed and validated swab kits.',
        service_levels:
          'Expected turnaround time is 24 hours from receipt, the service provider aims to process samples in line with internal service quality indicators, this includes >99% processed within 24 hours from sample receipt at service provider site.',
        liquidated_damages:
          'Where results are reported after the 24-hour Service Levels maximum period the Service Provider will  receive 50% of the price per delayed test where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g.,  equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 24-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration) \n' +
          'The Service provider will also be on the government approved providers list for day2 and day8 coronavirus testing for international arrivals.\n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with CUSTOMER if requested.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£5,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Swab PCR - Day 8',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000006',
    sla: {
      service_description: {
        tests: 'PCR swab tests for Day 8 program\n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Subsequent processing, qPCR and data reporting to Chronomics, as well as sequencing of positive samples. \n' +
          '\n' +
          'COVID-19 (SARS-CoV-2) testing by qRT-PCR and where required (Positive samples with Ct < 30) WGS, including sample receipt and logging, sample extraction using validated extraction kit, qRT-PCR using a validated, CE-IVD marked testing kit, WGS using validated and approved methodology, return of data via secure API link to Chronomics’ software portal or via upload of a .csv file to the Chronomics Laboratory Dashboard, and direct upload of sequencing data to Public Health England (PHE) portal as required.\n' +
          '\n' +
          'Reporting of results to the client as well as PHE will be conducted by Chronomics in accordance with the government guidelines.\n' +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. Positive samples may be stored and used for test development or optimisation as required by the Chronomics. ',
        test_materials:
          'The Test Materials will be exclusively from Chronomics. Covid-19 tests taken by a traveler for fit-to-fly purposes, on day 2 or day 8 following their arrival into the United Kingdom.\n' +
          'Standard flock or sponge swab designed for PCR. Purpose designed and validated swab kits.',
        service_levels:
          'Expected turnaround time is 24 hours from receipt, the service provider aims to process samples in line with internal service quality indicators, this includes >99% processed within 24 hours from sample receipt at service provider site. ',
        liquidated_damages:
          'Where results are reported after the 24-hour Service Levels maximum period the Service Provider will  receive 50% of the price per delayed test where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g.,  equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 24-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration) \n' +
          'The Service provider will be on the government approved providers list for day2 and day8 coronavirus testing for international arrivals.\n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with CUSTOMER if requested.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£5,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Day 2, Day 8 sequencing',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000007',
    sla: {
      service_description: {
        tests: 'Genome sequencing of Day 2 positive COVID-19 samples.',
        description_of_services:
          'The service provider shall carry out genome sequencing of positive day 2 samples provided by Chronomics and its partners. Sequencing shall be performed using a targeted sequencing method specific to SARS-CoV-2 or equivalent amplicon or sequence bait capture methods. \n' +
          'All samples with cycle threshold (CT) values of <30 (defined as equivalent to ~10,000-1,000 viral genome copies/ml) must be sequenced. \n' +
          'All SARS-CoV-2 genomes must have minimal sequencing coverage of 50% at >= 30x coverage.\n' +
          'For samples with a CT <20, defined as viral genome copy number equivalent of <1,000,000 viral genome copies per ml, 95% of samples should have a reference genome coverage breadth of >97% at >=30x coverage.\n' +
          'For CT above 20, defined as viral genome copy number equivalent of >1,000 per ml, 95% of samples should have a reference genome coverage breadth of >75% at >=30x coverage.\n' +
          'For sequenced samples, the service provider must report a sorted BAM file containing all reads aligning to the SARS-CoV-2 reference genome with unaligned reads removed and human reads removed. The service provider shall report results to both Chronomics as well as PHE',
        test_materials:
          'Covid-19 tests taken by a traveler on day 2 following their arrival into the United Kingdom. Standard flock or sponge swab designed for PCR. Purpose designed and validated swab kits.',
        service_levels: 'Service level turnaround would be 144 hours from sample collection.',
        liquidated_damages:
          'Where results are reported after the 144-hour Service Levels maximum period the Service Provider will receive  50% of the price per delayed test where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g.,, equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 24-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The service provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with the applicable law or applicable standards. The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration) Labs Must self-declare as approved providers. Additionally, the service provider will be on the approved government private providers list for day 2 and day 8 travel testing, Chronomics will be declared as a partner. The service provider furthermore ensures test manufacturers are providing data to the MHRA demonstrating the suitability of the device for identifying SARS-CoV-2 Variants of Concern.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£5,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'SeqSecure',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000008',
    sla: {
      service_description: {
        tests: 'Genome sequencing of day 2 positive COVID samples.',
        description_of_services:
          'Chronomics shall carry out the genome sequencing of any positive day 2 samples detected by the company. The company shall immediately notify Chronomics of all test results run in the day 2 and day 8 program by providing the information as described in Appendix 1. In case of a positive day 2 sample Chronomics shall notify the company of the Location where the Services will be performed, and the company shall arrange shipping of the sample to the location, at the cost of the Company. Purpose designed and validated swab kits should be used by the company and the company is to ensure the samples are preserved and transported according to government guidelines. (preferred: transfer of original extract frozen at -20°; acceptable:  shipped at 4°C using standard cold box systems in virus transport medium (VTM) within 6 hours of testing positive). The company should maintain and evidence the storage conditions, including temperature logging.',
        test_materials:
          'Covid-19 tests taken by a traveler on day 2 following their arrival into the United Kingdom. Standard flock or sponge swab designed for PCR. Purpose designed and validated swab kits. ',
        service_levels: 'Service level turnaround would be 144 hours.',
        liquidated_damages: '',
      },
      facility_and_equipment: {
        applicable_standards:
          'Chronomics will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with the applicable law or applicable standards.',
      },
      charges: {
        price_per_test: '£5 for every test run in the day 2 and day 8 program. OR £70 per sample.',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Menopause Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000013',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the MENOPAUSE PROFILE, which includes the following biomarker tests: Sodium, Urea, Creatinine, Albumin, Total Protein, Alkaline Phosphatase (ALP), Alanine aminotranferase (ALT), Total Bilirubin, C-Reactive Protein (CRP), Vitamin B12 Vitamin D (25-OH), Cortisol, Iron, Ferritin, Transferrin Saturation (serum Fe/TIBCx100), Unsaturated Iron Binding Capacity (UIBC), Total Iron Binding Capacity (TIBC), Magnesium (serum), Cholesterol, Cholesterol:HDL ratio, High Density Lipoprotein (HDL), Low Density Lipoprotein (LDL), Non-HDL Cholesterol, Triglyceride, Thyroid Stimulating Hormone (TSH), Free Thyroxine (Free T4), either with or without the supervision of a healthcare professional.\n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Vitamin Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000014',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the VITAMIN PROFILE, which includes the following biomarker tests: Vitamin D, Vitamin B12 and Folate (Vitamin B9), either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Thyroid Function Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000015',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the THYROID FUNCTION PROFILE, which includes the following biomarker tests: TSH, Free T4 (Thyroxine), either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Heart Health Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000016',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the HEART HEALTH PROFILE, which includes the following biomarker tests: Total Cholesterol, High-Density Lipoprotein (HDL), Low-Density Lipoprotein (LDL), Total Cholesterol: HDL Ratio, Non-HDL Cholesterol, Triglyceride, High Sensitivity C-Reactive Protein, HbA1C, either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Energy & Fatigue Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000017',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the ENERGY & FATIGUE PROFILE, which includes the following biomarker tests: Iron, Total Iron Binding Capacity, Unsaturated Iron Binding Capacity, Transferrin, Vitamin D, Thyroid Stimulating Hormone (TSH), Free T4 (Thyroxine), Full Blood Count (with 5 part White Cell Differential), either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Erectile Dysfunction Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000018',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the ERECTILE DYSFUNCTION PROFILE, which includes the following biomarker tests: Total Cholesterol, High-Density Lipoprotein (HDL), Low-Density Lipoprotein (LDL), Total Cholesterol: HDL Ratio, Non-HDL Cholesterol, Triglyceride, HbA1C (Glycosylates Haemoglobin), Thyroid Stimulating Hormone (TSH), Prolactin, Testosterone, Total Prostate Specific Antigen, either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'COVID-19 Antibody AntiSpike Test',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000019',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the COVID quantitative antibody test, either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Female Hormone Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000020',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the FEMALE HORMONE PROFILE, which includes the following biomarker tests: Oestradiol (17-Beta), Follicular Stimulating Hormone (FSH), Luteinizing Hormone (LH), Prolactin, either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Male Hormone Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000021',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the MALE HORMONE PROFILE, which includes the following biomarker tests: Albumin (Liver Protein), Oestradiol, Follicular Stimulating Hormone (FSH), Luteinizing Hormone (LH), Prolactin, Sex Hormone Binding Globulin (SHBG), Testosterone, Free Testosterone Calculation, DHEA-Sulphate, Free Androgen Index, either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Well Man Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000022',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the WELL MAN PROFILE, which includes the following biomarker tests: Albumin, Globulin, Total Protein, Alkaline Phosphatase (ALP), Alanine transaminase (ALT), Aspartate aminotransferase (AST), Gamma GT (GGT), Total Bilirubin, Total Cholesterol, High-Density Lipoprotein (HDL), Low-Density Lipoprotein (LDL), Total Cholesterol: HDL Ratio, Non-HDL Cholesterol, Triglyceride, Urea, Creatinine, Glomerular Filtration Rate, Iron, Total Iron Binding Capacity, Unsaturated Iron Binding Capacity, Ferritin, Transferrin, Creatine Kinase, Calcium, Adjusted calcium, Urate (Uric Acid), Magnesium, Vitamin D, Vitamin B12, Folate (Vitamin B9), Thyroid Stimulating Hormone (TSH), Free T4 (Thyroxine), Testosterone, Full Blood Count (with 5 part White Cell Differential), either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Well Woman Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000023',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the WELL WOMAN PROFILE, which includes the following biomarker tests: Globulin, Total Protein, Alkaline Phosphatase (ALP), Alanine transaminase (ALT), Aspartate aminotransferase (AST), Albumin, Gamma GT (GGT), Total Bilirubin, Total Cholesterol, High-Density Lipoprotein (HDL), Low-Density Lipoprotein (LDL), Total Cholesterol: HDL Ratio, Non-HDL Cholesterol, Triglyceride, Urea, Creatinine, Glomerular Filtration Rate, High Sensitivity C-Reactive Protein, HbA1c (Glycosylated Haemoglobin), Iron, Total Iron Binding Capacity, Unsaturated Iron Binding Capacity, Ferritin, Transferrin, Creatine Kinase, Calcium, Adjusted calcium, Urate (Uric Acid), Magnesium, Vitamin D, Vitamin B12, Folate (Vitamin B9), Thyroid Stimulating Hormone (TSH), Free T4 (Thyroxine), Oestradiol, Follicular Stimulating Hormone (FSH), Luteinizing Hormone (LH), Full Blood Count (with 5 part White Cell Differential), either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'Sports Fitness Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000024',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the SPORTS FITNESS PROFILE, which includes the following biomarker tests: Albumin, Globulin, Total Protein, Alkaline Phosphatase (ALP), Alanine transaminase (ALT), Aspartate aminotransferase (AST), Gamma GT (GGT), Total Bilirubin, Total Cholesterol, High-Density Lipoprotein (HDL), Low-Density Lipoprotein (LDL), Total Cholesterol: HDL Ratio, Non-HDL Cholesterol, Triglyceride, Urea, Creatinine, Glomerular Filtration Rate, Iron, Total Iron Binding Capacity, Unsaturated Iron Binding Capacity, Transferrin, Creatine Kinase, Calcium, Adjusted calcium, Urate (Uric Acid), Vitamin D, Vitamin B12, Testosterone, Full Blood Count (with 5 part White Cell Differential), either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
  {
    label: 'General Profile',
    disabled: false,
    termsAgreed: false,
    slaId: 'SLA000025',
    sla: {
      service_description: {
        tests:
          'Capillary blood sample for laboratory analysis of the GENERAL PROFILE, which includes the following biomarker tests: Albumin, Globulin, Total Protein, Alkaline Phosphatase (ALP), Alanine transaminase (ALT), Aspartate aminotransferase (AST), Gamma GT (GGT), Total Bilirubin, Total Cholesterol, High-Density Lipoprotein (HDL), Low-Density Lipoprotein (LDL), Total Cholesterol, HDL Ratio, Non-HDL Cholesterol, Triglyceride, Urea, Creatinine, Glomerular Filtration Rate, Calcium, Corrected Calcium, Urate (Uric Acid),  HbA1c (Glycosylated Haemoglobin), Iron, Total Iron Binding Capacity, Unsaturated Iron Binding Capacity, Transferrin, Creatine Kinase, Full Blood Count (with 5 part White Cell Differential), either with or without the supervision of a healthcare professional. \n',
        description_of_services:
          'Receipt of Chronomics’ samples and logging. Samples to be scanned as received no longer than one hour following delivery during Service Provider’s opening hours. Samples received outside Service Provider’s opening hours should be scanned within one hour after opening time. Subsequent processing the samples for requested blood testing parameters, details of testing parameters, with 3 unique sample identifiers will be shared by Chronomics and the return values/data will be transferred by laboratory via one of the following methods:  \n' +
          '\n' +
          "1. Secure API link to Chronomics' software portal. \n" +
          '\n' +
          ' 2. Upload of a .csv or HL7 file to the Chronomics Laboratory Dashboard. \n' +
          '\n' +
          '3. Using the Secure File Transfer Protocol (SFTP) to either: \n' +
          "i. Allow Chronomics to pick up HL7 results files from the service provider's SFTP server \n" +
          "ii. Allow the service provider to drop the HL7 results files on Chronomics' SFTP server. \n" +
          '\n' +
          'The service provider will dispose of all samples received after successful reporting of a result. \n',
        test_materials:
          'The Test Materials will be exclusively from Chronomics; Sample collection kits are manufactured in the UK and will be dispatched within 24 working hours from order receipt.',
        service_levels:
          'Service Levels will be as defined in SLA. \n' +
          'Service level turnaround would be 24-48 hours.\n' +
          'Response time to Chronomics sample related queries would be 3 hours, with a resolution within 18 hours. \n',
        liquidated_damages:
          'Where results are reported after the 48-hour Service Levels maximum period the Service Provider will receive no payment for any delayed test, where the delay is due to an issue which is not under the Service Provider’s direct control. Should the delay be demonstrated to be outside the Service Provider’s control, e.g. equipment failure, power failure the Liquidated Damages will be zero. In such cases the service provider will advise Chronomics of the issue and expected delay with a 12-hour notice to allow alternatives to be put in place.',
      },
      facility_and_equipment: {
        applicable_standards:
          'The Service Provider will operate the Facility and provide the Services in accordance with its internal Quality Management System and in line with Good Industry Practice. \n' +
          'The service provider will initiate and fulfil the UKAS accreditation process and have quality management systems operating in accordance with ISO15189 (Medical Laboratory) or ISO 17025 (Laboratory testing and calibration), and any other standards applicable to the tests. \n' +
          'The Service Provider agrees to process all samples according to their Quality Management System (QMS) process and to share proof or evidence of their QMS with Chronomics if requested. \n' +
          'The Service Provider will be on the CQC Register.',
      },
      limitation_of_liability: {
        general_limitation_of_liability: '100% of Charges for each period as defined in the accepted Orders',
        limitation_of_liability_in_relation_to_any_indemnity: '£10,000,000 per claim',
      },
      permitted_subcontractors: 'None',
    },
  },
];

export const labManifest = {
  head: [
    {
      key: 'title',
      label: 'Title',
    },
    {
      key: 'testsUploaded',
      label: 'No. Tests uploaded',
    },
    {
      key: 'dateCreated',
      label: 'Date created',
    },
    {
      key: 'file',
      label: 'Action',
    },
  ],
  body: Array.from(
    {
      length: 10,
    },
    () => ({
      title: 'Order 2564',
      testsUploaded: '12',
      dateCreated: '12/09/20',
      file: 'manifest-order.pdf',
    }),
  ),
};

export const labBarcode = {
  head: [
    {
      key: 'barCode',
      label: 'Barcode',
    },
    {
      key: 'dateRevceived',
      label: 'Date received at lab',
    },
    {
      key: 'error',
      label: 'Error/warning',
    },
  ],
  body: [
    {
      barCode: '56000000001234',
      dateRevceived: '12/09/20',
      error: 'Barcode already marked as received',
    },
    {
      barCode: '56000000001234',
      dateRevceived: '12/09/20',
      error: "Barcode doesn't exist",
    },
    {
      barCode: '56000000001234',
      dateRevceived: '12/09/20',
      error: '',
    },
    {
      barCode: '56000000001234',
      dateRevceived: '12/09/20',
      error: '',
    },
    {
      barCode: '56000000001234',
      dateRevceived: '12/09/20',
      error: 'Barcode already marked as received',
    },
    {
      barCode: '56000000001234',
      dateRevceived: '12/09/20',
      error: '',
    },
    {
      barCode: '56000000001234',
      dateRevceived: '12/09/20',
      error: '',
    },
    {
      barCode: '56000000001234',
      dateRevceived: '12/09/20',
      error: 'Barcode already marked as received',
    },
  ],
};
