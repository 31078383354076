import { combineReducers } from 'redux';

import userReducer from './user';
import { snackbarReducer } from './snackbar/snackbar.reducer';
import { partnerBrandingReducer } from './partnerBranding/partnerBranding.reducer';
import { productsReducer } from './storefront/products.reducer';

const createReducer = (asyncReducers) =>
  combineReducers({
    user: userReducer,
    storefront: productsReducer,
    snackbar: snackbarReducer,
    partnerBranding: partnerBrandingReducer,
    ...asyncReducers,
  });

export default createReducer;
