import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import * as _ from 'lodash';
import { Loader } from '../../../components';
import { useDataApi } from '../../../hooks';
import { OrderHistoryTrackingList } from './OrderHistoryTrackingList';

export const TRACKING_NOT_FOUND = 'No shipping information available.';

const formatTrackerResponse = ({ trackerType, trackingUrl, trackingCode }) => {
  const tracker = {
    ...(trackingUrl && { trackingUrl }),
    ...(trackingCode && { trackingCode }),
  };
  return !_.isEmpty(tracker) && { ...tracker, trackerType };
};

export const OrderHistoryTracking = ({ orderNumber }) => {
  const [{ data: orderTrackingHistory, isLoading: isOrderTrackingHistoryLoading }, setUrl] = useDataApi(null, null, true);

  useEffect(() => {
    if (orderNumber) {
      setUrl(`/orders/v3/${orderNumber}/tracking`);
    }
  }, [setUrl, orderNumber]);

  const trackers = useMemo(() => {
    if (orderTrackingHistory) {
      const mergedTrackers = orderTrackingHistory.outbound
        .map((tracker) => ({ ...tracker, trackerType: 'Tracking to Patient' }))
        .concat(orderTrackingHistory.return.map((tracker) => ({ ...tracker, trackerType: 'Tracking to Laboratory' })));
      const mappedTrackers = _.map(mergedTrackers, formatTrackerResponse);
      return _.filter(mappedTrackers, Boolean);
    }
    return [];
  }, [orderTrackingHistory]);

  const hasTrackers = trackers?.length > 0;
  return (
    <div className="absolute top-16 right-0 w-full h-24">
      <Divider variant="middle" />
      <div
        className="m-2 h-20"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}
      >
        {isOrderTrackingHistoryLoading && <Loader />}
        {!isOrderTrackingHistoryLoading && hasTrackers && <OrderHistoryTrackingList consignmentTrackers={trackers} />}
        {!isOrderTrackingHistoryLoading && !hasTrackers && <div>{TRACKING_NOT_FOUND}</div>}
      </div>
    </div>
  );
};

OrderHistoryTracking.propTypes = {
  orderNumber: PropTypes.string.isRequired,
};
