import { useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Arrow } from '../../icons';
import * as CustomMuiStyle from '../../app/admin/covid-results/shared.style';
import PaginationSelect from './PaginationSelect';

// TODO: Arrow SVGs

const Pagination = ({ isLoading, onChange, totalRecords, numberOfRecordsPerPage = [10, 25, 50, 100] }) => {
  const [visibleRows, setVisibleRows] = useState(numberOfRecordsPerPage[0]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const hasMounted = useRef(false);
  // this eslint comment is only introduced to tackle warnings as we go
  // this should be fixed and removed on refactor
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
    // this eslint comment is only introduced to tackle warnings as we go
    // this should be fixed and removed on refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleRows]);

  // this eslint comment is only introduced to tackle warnings as we go
  // this should be fixed and removed on refactor
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (hasMounted.current) {
      await onChange(visibleRows, currentPage);
    }
    hasMounted.current = true;
    // this eslint comment is only introduced to tackle warnings as we go
    // this should be fixed and removed on refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleRows, currentPage]);

  // this eslint comment is only introduced to tackle warnings as we go
  // this should be fixed and removed on refactor
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setNumberOfPages(Math.ceil(totalRecords / visibleRows));
  }, [visibleRows, totalRecords]);

  const navigateBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const navigateForward = () => {
    if (totalRecords > visibleRows && currentPage < numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <CustomMuiStyle.StyledPagination>
      <span>Rows per page</span>
      <PaginationSelect
        onChange={(e) => setVisibleRows(e.target.value)}
        defaultValue={visibleRows}
        selectOptions={numberOfRecordsPerPage}
      />
      {!isLoading && (
        <>
          <CustomMuiStyle.StyledPageLabel>
            Page {currentPage} of {numberOfPages}
          </CustomMuiStyle.StyledPageLabel>
          <CustomMuiStyle.StyledPageButtonWrapper>
            <IconButton data-testid="back-button" aria-label="Back" onClick={navigateBack} disabled={currentPage < 2} size="large">
              <Arrow />
            </IconButton>
            <IconButton
              data-testid="next-button"
              aria-label="Forward"
              onClick={navigateForward}
              disabled={currentPage >= numberOfPages}
              size="large"
            >
              <Arrow style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
          </CustomMuiStyle.StyledPageButtonWrapper>
        </>
      )}
    </CustomMuiStyle.StyledPagination>
  );
};

export default Pagination;

Pagination.propTypes = {
  isLoading: PropTypes.bool,
};

Pagination.defaultProps = {
  isLoading: false,
};
