import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { CreateOrderDropdown } from './CreateOrderDropdown';
import { formErrorMessages } from '../../../../utils/form-error-messages';
import { isCountryOptionSeparator } from '../../../../utils/countryAutocomplete';
import { getErrors, getName } from '../../../../utils/formHelpers';

const getCountryTitleLabel = (option) => {
  if (!option) return '';
  if (isCountryOptionSeparator(option)) return '---';
  return `${option?.title} - (${option?.country_code})`;
};

export const CreateOrderAddressForm = ({ className, control, countryCodeList, errors, groupName, isDisabled, isUSOrder }) => (
  <>
    <CreateOrderDropdown
      control={control}
      error={!!getErrors({ errors, groupName, property: 'countryCode' })}
      getOptionLabel={(countryCode) => getCountryTitleLabel(countryCode)}
      helperText={formErrorMessages(getErrors({ errors, groupName, property: 'countryCode' }))}
      isDisabled={isDisabled}
      label="Country"
      name={getName({ groupName, property: 'countryCode' })}
      options={countryCodeList}
      testId={getName({ groupName, property: 'countryCode' })}
    />
    <Controller
      as={TextField}
      className={className}
      label="Address Line 1"
      id={getName({ groupName, property: 'address1' })}
      name={getName({ groupName, property: 'address1' })}
      disabled={isDisabled}
      error={!!getErrors({ errors, groupName, property: 'address1' })}
      helperText={formErrorMessages(getErrors({ errors, groupName, property: 'address1' }))}
      rules={{ required: true }}
      control={control}
      defaultValue={''}
    />

    <Controller
      as={TextField}
      className={className}
      label="Address Line 2"
      id={getName({ groupName, property: 'address2' })}
      name={getName({ groupName, property: 'address2' })}
      disabled={isDisabled}
      error={!!getErrors({ errors, groupName, property: 'address2' })}
      helperText={formErrorMessages(getErrors({ errors, groupName, property: 'address2' }))}
      rules={{ required: false }}
      control={control}
      defaultValue={''}
    />

    <Controller
      as={TextField}
      className={className}
      label="City"
      id={getName({ groupName, property: 'city' })}
      name={getName({ groupName, property: 'city' })}
      disabled={isDisabled}
      error={!!getErrors({ errors, groupName, property: 'city' })}
      helperText={formErrorMessages(getErrors({ errors, groupName, property: 'city' }))}
      rules={{ required: true }}
      control={control}
      defaultValue={''}
    />

    {!isUSOrder && (
      <>
        <Controller
          as={TextField}
          className={className}
          label="County"
          id={getName({ groupName, property: 'county' })}
          name={getName({ groupName, property: 'county' })}
          disabled={isDisabled}
          error={!!getErrors({ errors, groupName, property: 'county' })}
          helperText={formErrorMessages(getErrors({ errors, groupName, property: 'county' }))}
          rules={{ required: false }}
          control={control}
          defaultValue={''}
          data-testid="County"
        />
        <Controller
          as={TextField}
          className={className}
          label="Postcode"
          id={getName({ groupName, property: 'postcode' })}
          name={getName({ groupName, property: 'postcode' })}
          disabled={isDisabled}
          error={!!getErrors({ errors, groupName, property: 'postcode' })}
          helperText={formErrorMessages(getErrors({ errors, groupName, property: 'postcode' }))}
          rules={{ required: true }}
          control={control}
          defaultValue={''}
          data-testid="Postcode"
        />
      </>
    )}

    {isUSOrder && (
      <>
        <Controller
          as={TextField}
          className={className}
          label="State"
          name={getName({ groupName, property: 'state' })}
          disabled={isDisabled}
          error={!!getErrors({ errors, groupName, property: 'state' })}
          helperText={formErrorMessages(getErrors({ errors, groupName, property: 'state' }))}
          rules={{ required: true }}
          control={control}
          defaultValue={''}
          data-testid="County"
        />
        <Controller
          as={TextField}
          className={className}
          label="Zip"
          id={getName({ groupName, property: 'postcode' })}
          name={getName({ groupName, property: 'postcode' })}
          disabled={isDisabled}
          error={!!getErrors({ errors, groupName, property: 'postcode' })}
          helperText={formErrorMessages(getErrors({ errors, groupName, property: 'postcode' }))}
          rules={{ required: true }}
          control={control}
          defaultValue={''}
          data-testid="Postcode"
        />
      </>
    )}
  </>
);

CreateOrderAddressForm.propTypes = {
  className: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  countryCodeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.shape({}).isRequired,
  groupName: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  isUSOrder: PropTypes.bool.isRequired,
};
