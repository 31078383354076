import Joi from 'joi';
import { useCallback } from 'react';
import { WEBHOOK_KEY_IDENTIFIER, WEBHOOK_VALUE_IDENTIFIER } from '../headers/headerHelpers';

const getCrossValidatedFields = (label) => {
  const schema = Joi.string().allow('', null);
  return Joi.alternatives().conditional(label, {
    is: Joi.string().not(null, ''),
    then: Joi.string().required(),
    otherwise: schema,
  });
};

export const getComputedHeadersSchema = (computedHeaders) =>
  computedHeaders &&
  computedHeaders.reduce((allComputedHeaders, computedHeader) => {
    const crossReferencedKey = `${computedHeader}-${WEBHOOK_KEY_IDENTIFIER}`;
    const crossReferencedValue = `${computedHeader}-${WEBHOOK_VALUE_IDENTIFIER}`;
    return {
      ...allComputedHeaders,
      [crossReferencedKey]: getCrossValidatedFields(`/${crossReferencedValue}`),
      [crossReferencedValue]: getCrossValidatedFields(`/${crossReferencedKey}`),
    };
  }, {});

export const getValidationSchema = (computedHeaders) =>
  Joi.object({
    url: Joi.string().uri().required(),
    isActive: Joi.bool().required(),
    ...getComputedHeadersSchema(computedHeaders),
  });

export const getMappedErrors = (error) => ({
  values: {},
  errors: error.details.reduce(
    (allErrors, currentError) => ({
      ...allErrors,
      [currentError.path]: {
        type: currentError.type ?? 'validation',
        message: currentError.message?.replace(`"${currentError.path}"`, '').trim(),
      },
    }),
    {},
  ),
});

export const useValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        if (values?.error) {
          return getMappedErrors(values.error);
        }

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return getMappedErrors(errors);
      }
    },
    [validationSchema],
  );
