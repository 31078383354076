import tailwindConfig from './tailwind.theme';

/**
 * The default theme to load
 */
export const DEFAULT_THEME = 'base';

export const themes = {
  tailwindConfig,
};
