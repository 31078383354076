import { useState } from 'react';
import PropTypes from 'prop-types';
import { BagArrowsCircle } from '../../../../icons';
import { IconAction } from '../../../../components/paginated-data-grid/actions';
import { Dialog } from '../../../../components/dialog/Dialog';

export const ResetIconAction = ({ onClickReset }) => {
  const [showDialog, setShowDialog] = useState(false);

  const onOpenDialog = () => setShowDialog(true);
  const onCloseDialog = () => setShowDialog(false);

  const onClickResetButton = async () => {
    await onClickReset();
    onCloseDialog();
  };

  return (
    <IconAction name="Reset Password" icon={BagArrowsCircle} onClick={onOpenDialog}>
      <Dialog
        title="Reset Password"
        message="Do you really want to reset this user's password?"
        primaryButtonLabel="Reset"
        secondaryButtonLabel="Cancel"
        open={showDialog}
        onClose={onCloseDialog}
        onClickPrimary={onClickResetButton}
        onClickSecondary={onCloseDialog}
      />
    </IconAction>
  );
};

ResetIconAction.propTypes = {
  id: PropTypes.number.isRequired,
  onClickReset: PropTypes.func.isRequired,
};
