import axios from 'axios';
import config from '../../config';

const connectToStorefront = ({ shopify, onSuccess, onError }) => {
  const { apiUrl } = config;

  axios
    .post(`${apiUrl}v1/storefront/connect`, { shopify })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      const response = error.response.data;
      if (response.error.errors.general) {
        onError(response.error.errors.general);
      } else if (response.error.message) {
        onError(response.error.message);
      } else {
        onError('Unable to connect at this time.');
      }
    });
};

export default connectToStorefront;
