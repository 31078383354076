import { CardHeader } from '@mui/material';
import { GettingStarted, GettingStartedLinks } from '..';
import { gettingStartedLinks } from './data';

const PartnerDashboard = ({ fname }) => {
  const titleText = `Hi ${fname}!`;

  // TODO: refactor following material-ui principles
  // use of Card below does not follow material-ui design principles
  // should not be copied to other component
  return (
    <div className="grid lg:grid-cols-2">
      <div className="flex-grow ml-6">
        <CardHeader title={titleText} />
      </div>
      <GettingStarted>
        <GettingStartedLinks links={gettingStartedLinks} />
      </GettingStarted>
    </div>
  );
};

export default PartnerDashboard;
