import PropTypes from 'prop-types';

export const countryPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  country_code: PropTypes.string.isRequired,
  dialing_code: PropTypes.string.isRequired,
});

export const makeRegisterPartnerPayload = (
  {
    email,
    firstName,
    lastName,
    password,
    countryCode: { title: countyTitle, country_code: countryCode, dialing_code: dialingCode },
    phoneNumber,
    agree,
  },
  { labName, labAddress1, labAddress2, city, postCode, country: { title: countryTitle } },
) => ({
  email,
  fname: firstName,
  lname: lastName,
  password,
  county: countyTitle,
  country_code: countryCode,
  dialing_code: dialingCode,
  mobile_country: countryCode,
  mobile: phoneNumber,
  accepted_dna_services: agree ? '1' : '0',

  name: labName,
  address1: labAddress1,
  address2: labAddress2,
  city,
  postcode: postCode,
  country: countryTitle,

  accepted_lab_terms: '1',
  type: 'lab',
});
