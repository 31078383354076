import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { assignWebhookSettings, DEFAULT_ROWS, getColumns } from './helpers/dataGridHelpers';
import { PaginatedDataGrid } from '../../../../components/paginated-data-grid';
import { useDataApi } from '../../../../hooks';
import { getNotificationUrl, putEventNotifications } from '../../../api/notificationRequests';
import { WebhookComponent } from './WebhookComponent';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../store/snackbar/snackbar.action';
import { TriggerWebhookAction } from './actions/TriggerWebhookAction';
import { isTriggerableEvent } from './helpers/webhookHelpers';

export const WEBHOOKS_UPDATE_ERROR_MESSAGE = 'There was an error updating the webhook. Try again later or contact support.';
export const WEBHOOKS_UPDATE_SUCCESS_MESSAGE = 'Webhook updated successfully';

const mapWebhookData = (data) =>
  Object.fromEntries(data.map(({ eventType, ...restOfEvent }) => [eventType, { ...restOfEvent, eventType }]));

const Webhooks = () => {
  const dispatch = useDispatch();
  const [{ data, isLoading: isDataLoading, isError: hasError }, , setIsRefreshing] = useDataApi(getNotificationUrl({}), DEFAULT_ROWS);

  const [editingWebhook, setEditingWebhook] = useState(null);

  const memoizedMappedData = useMemo(() => mapWebhookData(data), [data]);
  const actions = [
    (props) => (
      <Button
        key={`edit-${props.id}`}
        aria-label={`Edit ${props.id} webhook`}
        onClick={() =>
          setEditingWebhook({
            ...memoizedMappedData[props.id],
            eventType: props.id,
            eventName: props.label,
          })
        }
      >
        Edit
      </Button>
    ),
    (props) =>
      props.row.active &&
      isTriggerableEvent(props.id) && <TriggerWebhookAction className="mr-5" key={`trigger-${props.id}`} id={props.id} />,
  ];

  const handleSubmit = async (formData) => {
    try {
      const { eventType } = editingWebhook;
      await putEventNotifications({
        eventType,
        formData,
        existingData: memoizedMappedData,
      });
      showSuccessSnackbar(WEBHOOKS_UPDATE_SUCCESS_MESSAGE)(dispatch);
      setIsRefreshing();
      setEditingWebhook(null);
    } catch (error) {
      showErrorSnackbar(WEBHOOKS_UPDATE_ERROR_MESSAGE)(dispatch);
    }
  };

  if (editingWebhook) {
    return (
      <Paper>
        <WebhookComponent
          key={editingWebhook.id}
          webhook={editingWebhook}
          onClickPrimary={handleSubmit}
          onClickSecondary={() => setEditingWebhook(null)}
        />
      </Paper>
    );
  }

  return (
    <div className="h-full flex flex-col mt-5">
      <p className="pb-5">
        Read more about{' '}
        <a href="https://docs.hurdle.bio/docs/webhooks" target="_blank" rel="noreferrer">
          how our webhooks work
        </a>{' '}
        and{' '}
        <a href="https://docs.hurdle.bio/docs/notification-event-schemas" target="_blank" rel="noreferrer">
          event schemas
        </a>
        .
      </p>
      <PaginatedDataGrid
        columns={getColumns({ actions })}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        hasError={hasError}
        hideFooter={true}
        isLoading={isDataLoading}
        onChange={() => {}}
        rowCount={data?.length ?? 0}
        rowHeight={72}
        rows={assignWebhookSettings(data ?? [])}
      />
    </div>
  );
};

export { Webhooks };
