import { node, string } from 'prop-types';

import * as SH from './header.style';

/**
 * @deprecated
 * use html tags and Tailwind utility classes
 */
const Header = ({ children, textTransform, element }) => (
  <SH.StyledHeaderTitle textTransform={textTransform} element={element}>
    {children}
  </SH.StyledHeaderTitle>
);

Header.propTypes = {
  children: node,
  textTransform: string,
  element: string,
};

Header.defaultProps = {
  children: null,
  textTransform: '',
  element: 'div',
};

export default Header;
