import { OrderHistoryPaymentButton } from '../OrderHistoryPaymentButton';
import { getPaymentLink } from '../../../api/paymentApi';

export const OrderStatus = {
  CREATED: 'Created',
  CONFIRMED: 'Confirmed',
  CANCELLED: 'Cancelled',
  DISPATCHED: 'Dispatched',
  REFUNDED: 'Refunded',
  PROCESSING: 'Processing',
};

export const PAYMENT_CONFIRMED_TEXT = 'Payment confirmed';
export const PENDING_PAYMENT_TEXT = 'Pending payment';
export const PAY_NOW_TEXT = 'Pay now';
const getPaymentLabel = (paymentConfirmed, hostedInvoiceUrl) => {
  if (paymentConfirmed) {
    return PAYMENT_CONFIRMED_TEXT;
  }
  if (hostedInvoiceUrl) {
    return <OrderHistoryPaymentButton label={PAY_NOW_TEXT} path={hostedInvoiceUrl} />;
  }
  return PENDING_PAYMENT_TEXT;
};

export const getPaymentStatus = async (order) => {
  if (!order.paymentConfirmed && order.stripeInvoiceId) {
    try {
      const { data: hostedInvoiceUrl } = await getPaymentLink(order);
      return {
        paymentLabel: getPaymentLabel(order.paymentConfirmed, hostedInvoiceUrl),
      };
    } catch {
      // TODO: decide on how to display error or log
    }
  }
  return {
    paymentLabel: getPaymentLabel(order.paymentConfirmed, null),
  };
};

const getStatus = (order) => {
  if (order.refunded_at) return OrderStatus.REFUNDED;
  if (order.deleted_at) return OrderStatus.CANCELLED;
  if (order.dispatched_at) return OrderStatus.DISPATCHED;
  if (order.processing_at) return OrderStatus.PROCESSING;
  if (order.payment_confirmed) return OrderStatus.CONFIRMED;
  return OrderStatus.CREATED;
};

export const formatOrders = (orders) =>
  orders?.map(async (order) => {
    const formattedOrder = {
      firstName: order.shipping_address.delivery_fname,
      lastName: order.shipping_address.delivery_lname,
      orderNumber: order.order_number,
      createdAt: order.created_at,
      paymentConfirmed: order.payment_confirmed,
      stripeInvoiceId: order.stripe_invoice_id,
      testTitle: order.items[0]?.title,
      orderStatus: getStatus(order),
    };

    return {
      ...formattedOrder,
      ...(await getPaymentStatus(formattedOrder)),
    };
  });
