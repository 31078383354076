export const DEFAULT_API_ERROR = 'Error. Please try again.';

function getErrorObject(response) {
  if (response.data.error && response.data.error.errors) {
    return response.data.error.errors;
  }

  if (response.data.errors) {
    return response.data.errors;
  }

  return null;
}

function getFirstError(errorsObj) {
  if (Array.isArray(errorsObj)) {
    return errorsObj[0];
  }

  return Object.values(errorsObj)[0];
}

function getIndividualErrorMessage(error) {
  if (Array.isArray(error)) {
    return error[0];
  }
  return error;
}

function getGeneralErrorMessage({ data }) {
  if (data.error && data.error.message) {
    return data.error.message;
  }

  if (data.message) {
    return data.message;
  }

  return null;
}

export function getFirstApiError(errorObj, defaultError) {
  let errorMessage;

  // not always a api error
  if (errorObj.response) {
    const mainErrorObj = getErrorObject(errorObj.response);

    if (mainErrorObj) {
      const error = getFirstError(mainErrorObj);
      errorMessage = getIndividualErrorMessage(error);
    } else {
      errorMessage = getGeneralErrorMessage(errorObj.response);
    }
  }

  return errorMessage || defaultError || DEFAULT_API_ERROR;
}
