import { useState, useEffect, Fragment } from 'react';
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormHelperText,
  FormControl,
  FormLabel,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import config from '../../../../config';

import { formErrorMessages } from '../../../../utils/form-error-messages';
import { QuestionMarkCircle } from '../../../../icons';
import { REGEX, ERROR_MESSAGE } from '../../../../data/constants';
import Loader from '../../../../components/Loader';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../store/snackbar/snackbar.action';

const fieldRequiredMessage = 'Field is required.';
const formatNotValidMessage = 'Format not valid.';
export const EDIT_USER_LABEL = 'Save changes';
export const ADD_USER_LABEL = 'Add user';

const TeamMemberForm = ({ user }) => {
  const nav = useNavigate();
  const [perms, setPerms] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const { apiUrl } = config;
  const confirmButtonText = user ? EDIT_USER_LABEL : ADD_USER_LABEL;
  const dispatch = useDispatch();

  const loadPermissions = () => {
    setLoading(true);
    axios
      .get(`${apiUrl}v1/team/roles`)
      .then((r) => {
        setPerms(r.data);
        setLoading(false);
      })
      .catch(() => {
        showErrorSnackbar(ERROR_MESSAGE)(dispatch);
      });
  };

  useEffect(() => {
    loadPermissions();
    // this eslint comment is only introduced to tackle warnings as we go
    // this should be fixed and removed on refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFormSubmit(signInData) {
    setLoading(true);

    // If we're updating, we add the id to the URL and PUT method
    const request = user ? axios.put(`${apiUrl}v1/team/${user.id}`, signInData) : axios.post(`${apiUrl}v1/team`, signInData);

    request
      .then(() => {
        showSuccessSnackbar('User created or updated!')(dispatch);
        nav(-1);
      })
      .catch((e) => {
        const responseData = e.response.data;
        if (responseData.error.code === 'validation_error' && responseData.error.errors)
          Object.entries(responseData.error.errors).forEach(([k, v]) => {
            setError(k, { type: 'server', message: v[0] });
          });
      })
      .finally(() => setLoading(false));
  }

  return (
    <form className="max-w-lg m-auto" onSubmit={handleSubmit(onFormSubmit)}>
      <header className="text-lg font-medium border-b mb-6">Team member details</header>
      <div className="flex flex-col">
        <TextField
          className="mb-11"
          label="Email address"
          name="email"
          disabled={!!user || loading}
          error={!!errors.email}
          helperText={formErrorMessages(errors.email)}
          inputRef={register({ required: true, pattern: REGEX.EMAIL })}
          inputProps={{
            tabIndex: 0,
          }}
          defaultValue={user ? user.email : ''}
        />
        <TextField
          className="mb-11"
          label="First name"
          id="fname"
          name="fname"
          disabled={loading}
          inputRef={register({
            required: fieldRequiredMessage,
            pattern: {
              message: formatNotValidMessage,
              value: REGEX.NAME,
            },
          })}
          error={!!errors.fname}
          helperText={formErrorMessages(errors.fname)}
          inputProps={{
            tabIndex: 0,
          }}
          defaultValue={user ? user.fname : ''}
        />
        <TextField
          id="lname"
          className="mb-11"
          label="Last name"
          name="lname"
          disabled={loading}
          inputRef={register({
            required: fieldRequiredMessage,
            pattern: {
              message: formatNotValidMessage,
              value: REGEX.NAME,
            },
          })}
          error={!!errors.lname}
          helperText={formErrorMessages(errors.lname)}
          inputProps={{
            tabIndex: 0,
          }}
          defaultValue={user ? user.lname : ''}
        />
      </div>
      <FormControl component="fieldset" error={!!errors.adminRole}>
        {loading ? (
          <Loader />
        ) : (
          <RadioGroup aria-label="role" name="adminRole" className="mt-5 mb-9" defaultValue={user && user.role_code}>
            <FormLabel component="legend">Role</FormLabel>
            <FormHelperText>{formErrorMessages(errors.adminRole)}</FormHelperText>
            <div className="grid grid-cols-7 items-center">
              {perms &&
                Object.entries(perms).map(([k, v]) => (
                  <Fragment key={k}>
                    <FormControlLabel
                      className="col-span-3"
                      value={k}
                      control={
                        <Radio color="default" name="adminRole" inputRef={register({ required: true })} inputProps={{ tabIndex: 0 }} />
                      }
                      label={v.title}
                    />
                    <div className="col-span-4">{v.description}</div>
                    <QuestionMarkCircle className="justify-self-end hidden" />
                  </Fragment>
                ))}
            </div>
          </RadioGroup>
        )}
      </FormControl>
      <div className="text-right pt-3 border-t">
        <Button variant="outlined" onClick={() => nav(-1)}>
          Cancel
        </Button>
        <Button className="ml-5" type="submit" disabled={loading} startIcon={loading && <CircularProgress />}>
          {confirmButtonText}
        </Button>
      </div>
    </form>
  );
};

TeamMemberForm.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
};

TeamMemberForm.defaultProps = {
  user: null,
};

export default TeamMemberForm;
