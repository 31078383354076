import TeamMemberForm from './components/TeamMemberForm';

const TeamAdd = () => (
  <div>
    <div className="text-3xl mb-9 font-medium">Add a team member</div>
    <div className="bg-white border-t pt-20 px-5 pb-32">
      <TeamMemberForm />
    </div>
  </div>
);

export default TeamAdd;
