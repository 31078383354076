import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Redirect, Router } from '@reach/router';
import { useEffect, useState } from 'react';
import { SnackbarAlert } from '../components/snackbar/Snackbar';
import { Register } from './register';
import { RedirectStore } from './connect/store';
import { ConnectStorefront } from './connect/storefront';
import { Admin } from './admin';
import ReloadRoute from '../components/ReloadRoute';
import { Auth } from './auth';
import { themes } from '../stories/tailwind';
import { getMuiTheme } from '../stories/MaterialUi/theme';
import { mergeThemes } from '../stories/tailwind/mergeThemes';
import { getConfig } from '../config';

export const App = () => {
  const [theme, setTheme] = useState(themes.tailwindConfig.theme);
  const { isPartnerBrandingLoading, hasBranding, ...partnerBranding } = useSelector((state) => state.partnerBranding);
  const { shouldUseWhileLabelling } = getConfig();

  useEffect(() => {
    if (shouldUseWhileLabelling && !isPartnerBrandingLoading && hasBranding) {
      setTheme(
        mergeThemes({
          primaryColour: partnerBranding.primaryColour,
          headerBackground: partnerBranding.headerBackground,
        }),
      );
    }
  }, [shouldUseWhileLabelling, isPartnerBrandingLoading, hasBranding, partnerBranding.primaryColour, partnerBranding.headerBackground]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getMuiTheme(theme)}>
        <SnackbarAlert anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={5000} />
        <Router className="h-full">
          {/* Redirects for old pages */}
          <Redirect from="admin/clinics/register" to="/admin/" noThrow />
          <Redirect from="admin/clinics/clients" to="/admin/" noThrow />

          <Register path="register/*" />
          <ConnectStorefront path="connect/*" />
          <RedirectStore path="store" />
          <Auth path="login/*" />
          <Admin path="admin/*" />
          <ReloadRoute default />
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
