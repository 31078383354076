import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { StorefrontAddButtonLink } from './StorefrontAddButtonLink';
import { getStorefrontStorePathname } from '../../../../constants';

export const StorefrontLinks = ({ storeId }) => {
  const baseClassName = 'mr-6';
  return (
    <>
      <Button component="button" type="button" color="secondary" className={baseClassName} href={getStorefrontStorePathname(storeId)}>
        View Store
      </Button>
      <StorefrontAddButtonLink className={baseClassName} storeId={storeId} />
    </>
  );
};

StorefrontLinks.proptypes = {
  storeId: PropTypes.number.isRequired,
};
