import axios from 'axios';
import config from '../../config';

const getAccessToken = ({ email, password, onSuccess, onError }) => {
  const { apiUrl } = config;

  const bodyFormData = new FormData();
  bodyFormData.append('email', email);
  bodyFormData.append('password', password);

  // TODO: refactor to handle error and success callbacks separately for readability and separation of concerns
  axios
    .post(`${apiUrl}v1/auth/login_token`, bodyFormData)
    .then(({ data }) => {
      if (data && data.access_token) {
        localStorage.setItem('access_token', data.access_token);
        axios.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem('access_token')}` };
        onSuccess();
      } else {
        onError(data);
      }
    })
    .catch((error) => {
      onError(error);
    });
};

export default getAccessToken;
