import * as React from 'react';

function SvgQuestionMarkCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <circle stroke="currentColor" fill="none" cx={8} cy={8} r={7.5} />
      <text fontFamily="HelveticaNeue, Helvetica Neue" fontSize={11} fill="currentColor">
        <tspan x={5} y={12}>
          {'?'}
        </tspan>
      </text>
    </svg>
  );
}

export default SvgQuestionMarkCircle;
