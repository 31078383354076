import axios from 'axios';
import getAccessToken from './getAccessToken';

export const getPartnerLogin = async ({ signInData, location, onError, onSuccess }) => {
  // This is not a standard API call as it sets cookies (old laravel login) so need to
  // use explicit path, ie dashboard.hurdle.bio.
  const baseUrl = `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`;

  const response = await axios.post(`${baseUrl}/api/v2/partners/auth/login`, signInData);
  getAccessToken({
    email: signInData.email,
    password: signInData.password,
    onSuccess: () => onSuccess(response),
    onError,
  });
};
