import { Card, Button, Link, CardHeader } from '@mui/material';
import { LabDashboardImage } from '../../../../icons';

const LAB_PATHNAME = '/admin/clinics/lab';

const LabDashboard = () => (
  <div className="grid gap-9 lg:grid-cols-2">
    <Card className="flex flex-col lg:flex-row col-span-full">
      <div className="flex-grow">
        <CardHeader title="Setup your lab for testing" />
        <p className="mb-8">
          Set the types of test your lab provides in test types.
          <br />
          Once approved by our team you can start to provide these types of testing.
        </p>
        <Link href={LAB_PATHNAME}>
          <Button className="mb-10 lg:mb-0 no-underline">Begin Lab Setup</Button>
        </Link>
      </div>
      <LabDashboardImage className="flex-shrink-0 -mt-4 -mb-4 -mr-6 self-end lg:self-auto" width={498} height={301} />
    </Card>
  </div>
);

export default LabDashboard;
