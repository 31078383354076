import { UPDATE_PERMISSIONS } from './permission.action';

export const initialPermissionState = {};

export const permissionReducer = (state = initialPermissionState, action = '') => {
  switch (action.type) {
    case UPDATE_PERMISSIONS:
      return {
        ...state,
        ...action.payload.permission,
      };

    default:
      return state;
  }
};
