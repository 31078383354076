import * as React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as CustomMuiStyle from '../../app/admin/covid-results/shared.style';
import SvgSearch from '../../icons/Search';

const SearchBox = ({ onChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const hasMounted = useRef(false);
  const { handleSubmit, register } = useForm();
  // this eslint comment is only introduced to tackle warnings as we go
  // this should be fixed and removed on refactor
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (hasMounted.current) {
      await onChange(searchTerm);
    }
    hasMounted.current = true;
    // this eslint comment is only introduced to tackle warnings as we go
    // this should be fixed and removed on refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const onFormSubmit = async (formData) => {
    setSearchTerm(formData.search);
  };

  return (
    <CustomMuiStyle.StyledSearchBox>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <TextField
          fullWidth
          variant="filled"
          name="search"
          id="search"
          label="Search patient name/address/order number"
          helperText={null}
          inputRef={register({ required: false })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" aria-label="Search" edge="end" color="primary" size="large">
                  <SvgSearch style={{ transform: 'scaleX(-1)' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </CustomMuiStyle.StyledSearchBox>
  );
};

export default SearchBox;
