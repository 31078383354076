import { Router } from '@reach/router';
import { GuestLayout } from '../../components/layouts/GuestLayout';
import { Login } from './Login';
import ForgotPassword from './ForgotPassword';

const Auth = () => (
  <GuestLayout logoUrl="/login">
    <Router className="w-full h-full flex items-center py-4" path="/">
      <Login path="/" />
      <ForgotPassword path="/reset" />
    </Router>
  </GuestLayout>
);

export default Auth;
