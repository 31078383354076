import { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { SimulateFormNewOrderPanel } from '../panel/SimulateFormNewOrderPanel';
import { SimulateFormExistingOrderPanel } from '../panel/SimulateFormExistingOrderPanel';
import { SimulateFormResultResponse } from './SimulateFormResultResponse';

const SimulateFormResult = ({
  type,
  descriptionNew,
  descriptionExisting,
  productVariations,
  isProductVariationsDisabled,
  onFormSubmit,
  orderResponseData,
  isFormDisabled,
}) => {
  const [product, setProduct] = useState('');
  const shouldDisplayResults = !_.isEmpty(orderResponseData);
  const className = 'px-5';
  return (
    <Box>
      <Box className="flex row">
        <SimulateFormNewOrderPanel
          className={className}
          descriptionNew={descriptionNew}
          isFormDisabled={isFormDisabled}
          isProductVariationsDisabled={isProductVariationsDisabled}
          onFormSubmit={onFormSubmit}
          orderResponseData={orderResponseData}
          product={product}
          productVariations={productVariations}
          setProduct={setProduct}
          type={type}
        />
        <SimulateFormExistingOrderPanel
          className={className}
          descriptionExisting={descriptionExisting}
          isFormDisabled={isFormDisabled}
          onFormSubmit={onFormSubmit}
          orderResponseData={orderResponseData}
          type={type}
        />
      </Box>
      {shouldDisplayResults && (
        <Box className={className}>
          <Divider className="my-6" />
          <SimulateFormResultResponse />
        </Box>
      )}
    </Box>
  );
};

SimulateFormResult.propTypes = {
  type: PropTypes.string.isRequired,
  descriptionNew: PropTypes.string.isRequired,
  descriptionExisting: PropTypes.string.isRequired,
  productVariations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isProductVariationsDisabled: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  orderResponseData: PropTypes.object,
  isFormDisabled: PropTypes.bool.isRequired,
};

export { SimulateFormResult };
