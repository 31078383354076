import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';
import ReloadRoute from '../../../components/ReloadRoute';

export const DASHBOARD_PATHNAME = '/admin/clinics/dashboard';

export const getDisplayName = (WrappedComponent) => WrappedComponent.displayName || WrappedComponent.name || 'Component';

export const WithAuthorization = (WrappedComponent) => {
  const WithAuthorizationWrapped = (props) => {
    const { isEnabledViaConfiguration = true, userPermissions, requiredPermission } = props;
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
      /*
       Relying on re-assigning window location
       to hit the proxy routing in case the route is supported by php
      */
      if (!isEnabledViaConfiguration) {
        setShouldRedirect(true);
        return;
      }

      if (!userPermissions.includes(requiredPermission)) {
        navigate(DASHBOARD_PATHNAME);
      }
      // this eslint comment is only introduced to tackle warnings as we go
      // this should be fixed and removed on refactor
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!shouldRedirect) {
      return <WrappedComponent {...props} />;
    }

    return <ReloadRoute />;
  };

  WithAuthorizationWrapped.displayName = `WithAuthorization(${getDisplayName(WrappedComponent)})`;

  return WithAuthorizationWrapped;
};
