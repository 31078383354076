import * as React from 'react';

function SvgCircleArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M15.147 6.916a.896.896 0 01.592 1.531L8.3 15.886l7.439 7.439a.896.896 0 010 1.268.896.896 0 01-1.268 0l-8.074-8.074a.896.896 0 010-1.268l8.074-8.074a.893.893 0 01.635-.264l.043.001h-.002zm9.826 8.073H7.078c-.497 0-.897.4-.897.897s.4.897.897.897h17.895c.497 0 .897-.4.897-.897s-.4-.897-.897-.897zM16.002.636c8.412 0 15.25 6.838 15.25 15.25s-6.838 15.25-15.25 15.25c-8.412 0-15.25-6.838-15.25-15.25S7.59.636 16.002.636zm0 1.794C8.56 2.43 2.546 8.444 2.546 15.886S8.56 29.342 16.002 29.342c7.442 0 13.456-6.014 13.456-13.456S23.444 2.43 16.002 2.43z" />
    </svg>
  );
}

export default SvgCircleArrow;
