import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { PaginatedDataGrid } from '../../../components/paginated-data-grid';
import { StorefrontWarning } from './common/StorefrontWarning';
import { getStorefrontColumns } from './getStorefrontColumns';
import { isPaymentTypeCard } from '../../../utils/permissions';
import { StorefrontPage } from './common/StorefrontPage';

export const NO_STORES_CREATED = 'No stores created.';

const shouldDisplayPaymentWarning = ({ hasDefaultCardSetUp, paymentType }) => !hasDefaultCardSetUp && isPaymentTypeCard(paymentType);

export const Storefront = () => {
  const { hasDefaultCardSetUp, paymentType, stores } = useSelector(({ user: { profile } = { profile: {} } }) => ({
    hasDefaultCardSetUp: profile?.clinic?.hasDefaultCardSetUp,
    paymentType: profile?.clinic?.payment_type,
    stores: profile?.clinic?.stores,
  }));
  const hasStores = stores?.length !== 0;
  return (
    <StorefrontPage header="Storefront">
      <Box className="grid gap-y-2">
        <Typography>Connect an existing store or create a new one by selecting a platform to sell your products on.</Typography>
        {shouldDisplayPaymentWarning({
          hasDefaultCardSetUp,
          paymentType,
        }) && <StorefrontWarning />}
        {!hasStores && <Typography variant="h6">{NO_STORES_CREATED}</Typography>}
        <Typography>Please note a Shipping address phone number is required by our couriers to process orders.</Typography>
        <Typography>
          Please make this required in your Store &#62; Settings &#62; Checkout &#62; Customer information &#62; &#34;Shipping address phone
          number&#34;
        </Typography>
      </Box>
      <Box className="w-full h-full grid grid-rows-1">
        {hasStores && <PaginatedDataGrid columns={getStorefrontColumns()} rows={stores} isLoading={false} rowCount={stores?.length || 0} />}
      </Box>
    </StorefrontPage>
  );
};
