// to be used together with .cr-datagrid-simple
export const simpleDataGridProps = {
  className: 'cr-datagrid-simple',
  autoHeight: true,
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  hideFooterPagination: true,
  hideFooterRowCount: true,
  rowHeight: 44,
  headerHeight: 44,
};

export const getOverrides = (theme) => {
  const { borderColor } = theme;
  return {
    root: {
      border: 'none',
      fontSize: '1.125rem',
      borderRadius: 0,
      borderTop: `1px solid ${borderColor?.colors?.gray[300] || borderColor?.colors?.currentColor}`,
      '& .MuiDataGrid-columnsContainer': {
        borderBottom: 'none',
      },
      '& .MuiDataGrid-window': {
        overflowX: 'hidden',
      },
      '& .MuiDataGrid-cell': {
        borderBottom: 'none',
        outline: 'none !important',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-row': {
        backgroundColor: theme.colors.white,
        marginBottom: 12,
      },

      // simpleDataGrid
      '&.cr-datagrid-simple': {
        '& .MuiDataGrid-colCellWrapper': {
          borderBottomWidth: 1,
        },
        '& .MuiDataGrid-row': {
          marginBottom: 0,
          borderBottomWidth: 1,

          '&:hover': {
            backgroundColor: 'initial',
          },
        },
      },
    },
  };
};
