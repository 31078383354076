import axios from 'axios';
import config from '../../config';

export const ORDERS_PATH = '/dashboard/v1/orders';
export const EXPORT_ORDERS_PATH = '/dashboard/v1/orders/export';
export const EXPORT_COVID_RESULTS_PATH = '/dashboard/v1/clinics/covid_results/export';
export const EXPORT_TEST_RESULTS_PATH = '/dashboard/v1/clinics/test_results/export';

const getOrderEndpoint = (orderApiUrl = config.apiUrl) => new URL(ORDERS_PATH, orderApiUrl).href;
const getExportOrdersEndpoint = (orderApiUrl = config.apiUrl) => new URL(EXPORT_ORDERS_PATH, orderApiUrl).href;
export const getCovidResultsExportEndpoint = (status, orderApiUrl = config.apiUrl) => {
  const url = new URL(EXPORT_COVID_RESULTS_PATH, orderApiUrl);
  url.searchParams.append('with_children', 'yes');
  url.searchParams.append('status', status);
  return url.href;
};

export const getTestResultsExportEndpoint = (status, orderApiUrl = config.apiUrl) => {
  const url = new URL(EXPORT_TEST_RESULTS_PATH, orderApiUrl);
  url.searchParams.append('with_children', 'yes');
  url.searchParams.append('status', status);
  return url.href;
};

export const getQueryParameters = ({ limit, page, search }) =>
  [limit && `count=${limit}`, page > 1 && `page=${page}`, search && `search=${search}`].filter((value) => Boolean(value)).join('&');

export const getOrdersUrl = ({ endpoint = getOrderEndpoint(), limit, page, search }) =>
  [endpoint, getQueryParameters({ limit, page, search })].filter((value) => Boolean(value)).join('?');

export const requestOrdersExport = async (endpoint = getExportOrdersEndpoint()) => axios.get(endpoint);

export const requestCovidResultsExport = async (status) => axios.get(getCovidResultsExportEndpoint(status));
export const requestTestResultsExport = async (status) => axios.get(getTestResultsExportEndpoint(status));
