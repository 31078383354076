import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { labMenu } from './lab.data';
import * as LS from './lab.style';
import { CheckmarkOutline, AddOutline } from '../../../icons';
import { SET_LAB_SLA, setLabSLA } from '../../../store/user/lab.action';
import config from '../../../config';

const LabHeader = () => {
  const dispatch = useDispatch();
  const labSla = useSelector(({ user }) => user.lab.data);
  const [labTests, setLabTests] = useState();
  const { apiUrl } = config;

  useEffect(() => {
    axios
      .get(`${apiUrl}v1/lab-sla`)
      .then(({ data }) => {
        dispatch(setLabSLA({ data: data.labSla }, SET_LAB_SLA));
      })
      .catch(() => {});
    // this eslint comment is only introduced to tackle warnings as we go
    // this should be fixed and removed on refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tests = [];
    if (labSla && labSla.length) {
      labSla.forEach((item) => {
        const findTest = labMenu.find((data) => data.slaId === item.sla_identifier);
        if (findTest) {
          findTest.termsAgreed = item.sla_agreement_accepted_on;
          findTest.status = item.status;
          tests.push(findTest);
        }
      });
      setLabTests(tests);
    }
  }, [labSla]);

  const handleActionButton = async (termsAgreed, slaId, status) => {
    if (!status) {
      navigate(`/admin/clinics/lab/sla/${slaId}`);
    }
  };

  return (
    <LS.ContentNoMargin>
      <LS.StyledLabHeader>
        <LS.StyledLabTitle>Lab details</LS.StyledLabTitle>
        <LS.StyledLabDescription>
          This is where you can set up your lab to provide the different testing services. Select the test types you would like to provide.
        </LS.StyledLabDescription>
      </LS.StyledLabHeader>
      <LS.StyledLabNavigation>
        {labTests &&
          labTests.map(({ slaId, label, disabled, termsAgreed, status }) => (
            <LS.StyledLabNavigationLink
              key={slaId}
              termsAgreed={termsAgreed}
              disabled={disabled}
              onClick={() => (!disabled ? handleActionButton(termsAgreed, slaId, status) : {})}
            >
              <LS.StyledLabNavigationLinkContent>
                <LS.StyledLabLabel>{label}</LS.StyledLabLabel>
                {!disabled && (
                  <IconButton className="absolute top-0 right-0" size="large">
                    {status === 'approved' && <CheckmarkOutline className="text-success" />}
                    {!status && <AddOutline className="text-primary cursor-pointer" />}
                    {status === 'pending' && <LS.StyledLabStatus>Pending</LS.StyledLabStatus>}
                    {status === 'declined' && <LS.StyledLabStatus>Declined</LS.StyledLabStatus>}
                  </IconButton>
                )}
              </LS.StyledLabNavigationLinkContent>
            </LS.StyledLabNavigationLink>
          ))}
      </LS.StyledLabNavigation>
    </LS.ContentNoMargin>
  );
};

export default LabHeader;
