import { useLocation, useParams } from '@reach/router';
import axios from 'axios';
import { useSelector } from 'react-redux';
import LabAcceptTerms from './LabAcceptTerms';
import config from '../../../config';

const LabCovid19Terms = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const labSla = useSelector(({ user }) => user.lab.data);
  const { slaId } = params;
  const { apiUrl } = config;

  const findSlaId = labSla ? labSla.find((item) => item.sla_identifier === slaId) : {};

  const handleShowList = async () => {
    if (pathname) {
      try {
        await axios.post(`${apiUrl}v1/lab-sla`, {
          sla_id: findSlaId.sla_id,
        });

        window.location = '/admin/clinics/lab';
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  return (
    <div>
      <LabAcceptTerms setShowList={handleShowList} />
    </div>
  );
};

export default LabCovid19Terms;
