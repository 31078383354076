import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import config from '../../config';

const { env, datadogRumEnable, datadogRumApplicationId, datadogRumClientToken } = config;

export const Monitoring = ({ children }) => {
  useEffect(() => {
    if (!datadogRumEnable) return;

    datadogRum.init({
      applicationId: datadogRumApplicationId,
      clientToken: datadogRumClientToken,
      site: 'datadoghq.com',
      service: 'partner-dashboard',
      env,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }, []);

  return children;
};
