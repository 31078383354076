import PropTypes from 'prop-types';
import { useState } from 'react';
import { IconAction } from '../../../../components/paginated-data-grid/actions';
import { Dialog } from '../../../../components/dialog/Dialog';
import { Trash } from '../../../../icons';

export const DeleteIconAction = ({ onClickDelete }) => {
  const [showDialog, setShowDialog] = useState(false);

  const onOpenDialog = () => setShowDialog(true);
  const onCloseDialog = () => setShowDialog(false);

  const onClickDeleteButton = async () => {
    await onClickDelete();
    onCloseDialog();
  };

  return (
    <IconAction name="Delete User" icon={Trash} onClick={onOpenDialog}>
      <Dialog
        title="[WARNING] DELETE USER"
        message="Do you really want to DELETE THIS USER FOREVER? This action is irreversible."
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        open={showDialog}
        onClose={onCloseDialog}
        onClickPrimary={onClickDeleteButton}
        onClickSecondary={onCloseDialog}
      />
    </IconAction>
  );
};

DeleteIconAction.propTypes = {
  id: PropTypes.number.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};
