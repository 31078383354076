import PropTypes from 'prop-types';

export const SimulateFormResponse = ({ data, isStandalone }) => {
  const informationToDisplay = [
    data.orderNumber && `Order Number: ${data.orderNumber}`,
    data.linkedOrderAndKit && `Kit ID: ${data.linkedOrderAndKit.kitId}`,
    data.linkedOrderAndKit && `Barcode: ${data.linkedOrderAndKit.barcode}`,
    !isStandalone && data.statusUpdate && `Update: ${data.statusUpdate}`,
  ].filter(Boolean);
  return (
    <div className="pt-4">
      {informationToDisplay.map((responseItem, index) => (
        <div key={`response-item-${index}`}>
          {responseItem}
          <br />
        </div>
      ))}
    </div>
  );
};
SimulateFormResponse.propTypes = {
  isStandalone: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    orderNumber: PropTypes.string,
    linkedOrderAndKit: PropTypes.shape({
      kitId: PropTypes.string,
      barcode: PropTypes.string,
    }),
    statusUpdate: PropTypes.string,
  }).isRequired,
};
