import * as React from 'react';

function SvgLab(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M14.882 4.458a2.01 2.01 0 00-2.828 0l-1.886 1.886a2.01 2.01 0 000 2.828l1.972 1.972.943-.943-1.972-1.972a.657.657 0 010-.943L12.997 5.4a.657.657 0 01.943 0l7.542 7.542a.657.657 0 010 .943l-1.886 1.886a.657.657 0 01-.943 0l-1.897-1.897-.943.943 1.897 1.897a2.01 2.01 0 002.828 0l1.886-1.886a2.01 2.01 0 000-2.828l-7.542-7.542zm-5.185-3.3L6.869 3.986l3.771 3.771 2.828-2.828-3.771-3.771zm0 1.886l1.886 1.886-.943.943-1.886-1.886.943-.943zm12.256 10.371l-2.828 2.828 1.414 1.414a2.001 2.001 0 003.414-1.414c0-.511-.198-1.026-.586-1.414l-1.414-1.414zm0 1.886l.471.471c.133.133.195.3.195.471s-.062.338-.195.471c-.133.133-.3.195-.471.195s-.338-.062-.471-.195l-.471-.471.943-.943zM11.111.687a.666.666 0 010 .943L7.34 5.401a.666.666 0 11-.943-.943L10.168.687a.666.666 0 01.943 0zm5.657 9.428c-1.296-1.296-3.418-1.296-4.714 0s-1.296 3.418 0 4.714c1.296 1.296 3.418 1.296 4.714 0s1.296-3.418 0-4.714zm-.943.943c.787.787.787 2.042 0 2.828s-2.042.787-2.828 0c-.787-.787-.787-2.042 0-2.828s2.042-.787 2.828 0zm-4.138-.448a8.007 8.007 0 00-6.138 7.781c0 4.41 3.59 8 8 8v-1.333a6.657 6.657 0 01-6.667-6.667 6.663 6.663 0 015.115-6.484l-.31-1.297zm.315 2.677a5.34 5.34 0 00-3.786 5.104 5.343 5.343 0 005.333 5.333v-1.333a3.99 3.99 0 01-4-4 3.996 3.996 0 012.839-3.828l-.385-1.276zm.214 11.771v6.667h8.089l-.833-6.667h-7.255zm1.333 1.333h4.745l.5 4h-5.245v-4zm-7.333 4a.667.667 0 100 1.334h20a.667.667 0 100-1.334h-20zm6.666-8v1.333h12c.376 0 .667.291.667.667s-.291.667-.667.667h-12v1.333h12c1.097 0 2-.903 2-2s-.903-2-2-2h-12z" />
    </svg>
  );
}

export default SvgLab;
