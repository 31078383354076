import { format } from 'date-fns';
import * as _ from 'lodash';
import { ModalButton } from '../../../components/modal/ModalButton';

export const UNKNOWN_PRODUCT = 'Unknown';

export const initialState = {
  status: 'registered',
  rows: [],
  count: {},
};

export const testStatusesMeta = {
  registered: {
    label: 'Registered',
  },
  processing: {
    label: 'Processing',
  },
  processed: {
    label: 'Results',
  },
  all: {
    label: 'All',
  },
};

export const testStatusesOrder = ['registered', 'processed', 'all']; /* 'processing', */

const TEST_STATUS_PROCESSED = 'processed';

/**
 * @param {number} seconds - datetime in seconds since Epoch
 * @returns {string} formattedDate
 */
const convertDateToDisplay = (seconds) => {
  let outValue;
  if (seconds) {
    outValue = format(seconds * 1000, 'MMM dd H:mm');
  }
  return outValue;
};

const showModalButton = (id, setOpen, setItemId) => {
  const buttonText = <u>Details</u>;
  return (
    <ModalButton
      displayName={`ModalButton-${id}`}
      buttonText={buttonText}
      itemId={id}
      setItemId={setItemId}
      setOpen={setOpen}
    ></ModalButton>
  );
};

export const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.name,
  },
  {
    id: 'orderId',
    label: 'Order number',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.order_number,
  },
  {
    id: 'productTitle',
    label: 'Test name',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => (row.product ? row.product.title : UNKNOWN_PRODUCT),
  },
  {
    id: 'barcodeNumber',
    label: 'Barcode',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.barcode_number || UNKNOWN_PRODUCT,
  },
  {
    id: 'kitId',
    label: 'Kit ID',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.kit_id || UNKNOWN_PRODUCT,
  },
  {
    id: 'latestResults',
    label: 'Result',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row, setOpen, setItem) =>
      row.status === TEST_STATUS_PROCESSED ? showModalButton(row.kit_id, setOpen, setItem) : '',
    hideOn: ['registered', 'processing'],
  },
  {
    id: 'dateRegistered',
    label: 'Registered',
    minWidth: 70,
    formatForDisplay: (row) => convertDateToDisplay(row.registered_at),
    align: 'left',
  },
  /* {
    id: 'dateReceived',
    label: 'Received',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => convertDateToDisplay(row.processing_at),
    hideOn: ['registered'],
  },
  {
    id: 'dateResults',
    label: 'Results',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => convertDateToDisplay(row.processed_at),
    hideOn: ['registered', 'processing'],
  }, */
];

const NON_NUMERIC_REGEX_PATTERN = /[^\d.]+/g; // Matches numbers and decimals

export const showNormalRange = (interpretationData) => {
  const normalRange = interpretationData?.value?.NORMAL;
  if (!normalRange) {
    return '';
  }
  if (Array.isArray(normalRange)) {
    if (normalRange.length === 2) {
      return `${normalRange[0].replace(NON_NUMERIC_REGEX_PATTERN, '')} - ${normalRange[1].replace(NON_NUMERIC_REGEX_PATTERN, '')}`;
    }
  }
  return '';
};

export const modalColumns = [
  {
    id: 'name',
    label: 'Biomarker',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.biomarker.name,
  },
  {
    id: 'result',
    label: 'Result',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.result,
  },
  {
    id: 'normalRange',
    label: 'Normal range',
    minWidth: 170,
    align: 'left',
    formatForDisplay: (row) => showNormalRange(row.interpretationData),
  },
  {
    id: 'unit',
    label: 'Units',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.unit,
  },
  {
    id: 'interpretation',
    label: 'Interpretation',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => _.capitalize(row.interpretation),
  },
];
