import { ClickAwayListener, Grow, MenuList, Paper } from '@mui/material';
import { arrayOf, bool, node, number, shape, string, elementType } from 'prop-types';
import { useRef, useState } from 'react';
import { ButtonDeprecated } from '../../../components';
// TODO split styles according to use and rename
import * as CustomMuiStyle from './shared.style';
import { StyledContent } from './shared.style';

/**
 * @deprecated
 * use components from MaterialUi instead
 */
const DropDownDeprecated = ({ label, children, itemList, icon, iconRight, image }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (e, item) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }

    if (e.target && e.target.lastChild && e.target.lastChild.id === 'menu-productTestType') {
      return;
    }

    if (item && item.onClick) {
      item.onClick();
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <div>
      <ButtonDeprecated
        variant="transparent"
        icon={icon}
        iconRight={iconRight}
        image={image}
        fullWidth
        reff={anchorRef}
        ariaHaspopup
        onClick={handleToggle}
      >
        {label}
      </ButtonDeprecated>
      <CustomMuiStyle.StyledPopper open={open} anchorEl={anchorRef.current} transition disablePortal placement="bottom-end">
        {({ TransitionProps: { in: In, onEnter, onExited }, placement }) => (
          <Grow
            in={In}
            onEnter={onEnter}
            onExited={onExited}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  {children && <StyledContent>{children}</StyledContent>}
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {itemList.map((item, i) => (
                      <CustomMuiStyle.StyledMenuItem key={i}>
                        <ButtonDeprecated variant="transparent" onClick={(e) => handleClose(e, item)} key={item.label} fullWidth>
                          {item.label}
                        </ButtonDeprecated>
                      </CustomMuiStyle.StyledMenuItem>
                    ))}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </CustomMuiStyle.StyledPopper>
    </div>
  );
};

DropDownDeprecated.propTypes = {
  label: string,
  children: node,
  itemList: arrayOf(
    shape({
      firstName: string,
      lastName: string,
      orderId: string,
      latestResults: string,
      toRerun: bool,
      dateRegistered: number,
      dateResults: number,
      status: string,
    }),
  ),
  icon: elementType,
  iconRight: elementType,
  image: string,
};

DropDownDeprecated.defaultProps = {
  label: '',
  children: null,
  itemList: [],
  icon: null,
  iconRight: '',
  image: '',
};

export { DropDownDeprecated };
