import { Switch } from '@mui/material';
import isSandboxEnv from '../utils/sandbox';

export default function SandboxToggle() {
  const redirectEnvironment = (event) => {
    // Might be nicer to store absolute URLs in env vars but this works
    if (event.target.checked) {
      window.location.href = window.location.href.replace('dashboard.', 'dashboard.sandbox.');
    } else {
      window.location.href = window.location.href.replace('dashboard.sandbox.', 'dashboard.');
    }
  };

  return (
    <>
      <Switch defaultChecked={isSandboxEnv()} onChange={redirectEnvironment} />
      <a href="https://docs.hurdle.bio/docs/environments" target="_blank" rel="noreferrer">
        Sandbox mode
      </a>
    </>
  );
}
