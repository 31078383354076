export const getOverrides = (theme) => ({
  root: {
    borderRadius: 2,
    backgroundColor: theme.colors.background.header,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: '5rem',
    minHeight: '5rem',
    flexShrink: 0,
    flexBasis: '0px',
    boxShadow: 'unset',
    borderColor: theme.colors.gray[200],
    borderBottomWidth: '1px',
  },
});
