import * as React from 'react';

function SvgPencil(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M12.364 3.757l-9.4 9.4-2.118.003-.003-2.125 9.4-9.399 2.121 2.121z" stroke="currentColor" />
        <path fill="currentColor" d="M2.464 10.121l1.415 1.415-.707.707-1.415-1.415z" />
      </g>
    </svg>
  );
}

export default SvgPencil;
