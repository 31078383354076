export const UPDATE_REGISTER = '[user register] UPDATE_REGISTER';
export const CREATE_USER_REGISTER = '[user register] CREATE_USER_REGISTER';

export const updateRegisterAction = (data, type) => ({
  type: UPDATE_REGISTER,
  payload: {
    [type]: data,
  },
});

export const createUserRegisterAction = (data) => ({
  type: CREATE_USER_REGISTER,
  payload: data,
});
