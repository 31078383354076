import { useLocation } from '@reach/router';
import { string } from 'prop-types';

export const Iframe = ({ baseUrl }) => {
  const location = useLocation();
  return <iframe src={`${baseUrl}${location.search}`} width="100%" height="100%"></iframe>;
};

Iframe.propTypes = {
  baseUrl: string.isRequired,
};
