import { StorefrontLinks } from './common/StorefrontLinks';
import { ShopifyLogo } from './common/ShopifyLogo';

export const VALID_STOREFRONT_TYPE = 'shopify';

const isValidStorefrontType = (type) => type?.toLowerCase() === VALID_STOREFRONT_TYPE;

export const getStorefrontColumns = () => [
  {
    field: 'type',
    headerName: '',
    sortable: false,
    renderCell: (params) => (isValidStorefrontType(params.value) ? <ShopifyLogo /> : ''),
  },
  {
    field: 'title',
    headerName: 'Store name',
    sortable: false,
    flex: 1,
  },
  {
    field: 'id',
    headerName: 'Links',
    sortable: false,
    flex: 1,
    renderCell: (params) => <StorefrontLinks storeId={params.value} />,
  },
];
