import { node, string } from 'prop-types';
import { LogoWithLink } from './LogoWithLink';
import { usePartnerBranding } from '../../hooks/use-partner-branding';

const baseLayout = 'h-40 md:h-auto md:flex-1 flex-shrink-0 flex';

export const GuestLayout = ({ children, logoUrl }) => {
  const { hasBranding, isPartnerBrandingLoading, ...partnerBranding } = usePartnerBranding();
  const shouldApplyCustomBranding = !isPartnerBrandingLoading && hasBranding;

  return (
    <div className="h-full flex flex-col md:flex-row bg-white">
      {shouldApplyCustomBranding && (
        <div className={`${baseLayout} justify-center items-center`} style={{ background: partnerBranding.pageBackground }}>
          <LogoWithLink customLogoUrl={partnerBranding.headerLogoUrl} path="/" className="text-white" />
        </div>
      )}
      {!shouldApplyCustomBranding && (
        <div className={`${baseLayout} justify-center items-center cr-gradient-brand-horizontal`}>
          <LogoWithLink path={logoUrl} className="text-white" title="Hurdle" />
        </div>
      )}
      <div className="flex-1 md:overflow-auto flex flex-col">
        <div className="flex-1">{children}</div>
        <div className="flex flex-wrap justify-between p-3 text-gray-500">
          <div className="flex justify-around flex-grow">
            <a target="_blank" rel="noreferrer" href="https://hurdle.bio/partner-terms-conditions/">
              Terms of Service
            </a>
            <a target="_blank" rel="noreferrer" href="https://hurdle.bio/cookie-policy/">
              Cookie Policy
            </a>
            <a target="_blank" rel="noreferrer" href="https://hurdle.bio/privacy-policy/">
              Privacy Policy
            </a>
          </div>
          <div className="flex justify-around flex-grow">
            <a target="_blank" rel="noreferrer" href="https://help.hurdle.bio/contact-us">
              Contact
            </a>
            <a target="_blank" rel="noreferrer" href="https://hurdle.bio/">
              hurdle.bio
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestLayout;

GuestLayout.propTypes = {
  children: node.isRequired,
  logoUrl: string,
};

GuestLayout.defaultProps = {
  logoUrl: '/',
};
