import * as SR from './register.style';
import { Header } from '../../components';

const RegisterWelcome = () => (
  <div className="h-full flex flex-col justify-center">
    <Header element="h1">Register for the Hurdle platform</Header>
    <Header element="h2">What type of customer are you?</Header>

    <SR.StyledVerticalSpace height="30" />

    <SR.StyledGroupTwo gap={36}>
      <SR.StyledNavButtonLink to="/register/partner">A partner company requiring testing services</SR.StyledNavButtonLink>
      <SR.StyledNavButtonLink to="/register/lab">A lab providing testing services</SR.StyledNavButtonLink>
    </SR.StyledGroupTwo>
  </div>
);

export default RegisterWelcome;
