import PropTypes from 'prop-types';
import { TopBar } from './TopBar';
import Menu from './Menu';
import { WhiteLabelledMetadata } from './WhiteLabelledMetadata';
import { getConfig } from '../../../config';

export const AdminLayout = ({ children, hasPartnerBranding }) => (
  <>
    {getConfig().shouldUseWhileLabelling && hasPartnerBranding && <WhiteLabelledMetadata />}
    <div className="h-screen flex flex-col">
      <TopBar />
      <div className="flex-1 flex">
        <Menu />
        <div className="flex-1 overflow-auto">{children}</div>
      </div>
    </div>
  </>
);

AdminLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  hasPartnerBranding: PropTypes.bool,
};
