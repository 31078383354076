import axios from 'axios';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import * as SR from './storefront.style';

import { Header } from '../../../components';
import connectToStorefront from '../../../components/auth/connectToStorefront';

const ConnectStorefrontWelcome = () => {
  const [loggedInAs, setLoggedInAs] = useState(null);
  const [error, setError] = useState(null);
  const parsedQueryString = queryString.parse(window.location.search);

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      axios.get('/api/userinfo').then(({ data }) => {
        const { isAuth, profile, clinic = false } = data.userInfo;

        // Connect only works with clinic account
        if (isAuth && clinic) {
          setLoggedInAs(`${clinic.name} (${profile.email})`);
        }
      });
    }
  });

  function onConnectExisting() {
    connectToStorefront({
      shopify: parsedQueryString.shopify,
      onSuccess: () => {
        navigate('/admin/clinics/storefront');
      },
      onError: (msg) => {
        setError(msg);
      },
    });
  }

  if (!parsedQueryString.shopify) {
    return (
      <div className="h-full flex flex-col justify-center">
        <Header element="h2">This link has expired. Please return to your storefront and try again.</Header>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full flex flex-col justify-center">
        <Header element="h2">Error: {error}</Header>
        <p>Please return to your storefront and try again.</p>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col justify-center">
      <Header element="h1">Connect your store with Hurdle</Header>
      <Header element="h2">You need a partner account to get started. Login or register below.</Header>

      {loggedInAs && (
        <div>
          You are logged in as {loggedInAs}.<SR.StyledButton onClick={onConnectExisting}> Link account</SR.StyledButton>
          <SR.StyledVerticalSpace height="30" />
          OR
        </div>
      )}

      <SR.StyledVerticalSpace height="30" />

      <SR.StyledGroupTwo gap={36}>
        <SR.StyledNavButtonLink to={`/login${window.location.search}`}>Login</SR.StyledNavButtonLink>
        <SR.StyledNavButtonLink to={`/register/partner${window.location.search}`}>Register</SR.StyledNavButtonLink>
      </SR.StyledGroupTwo>
    </div>
  );
};

export default ConnectStorefrontWelcome;
