import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

export const ProductsSelect = ({ onChange, defaultValue, products, isDisabled }) => (
  <FormControl>
    <InputLabel id="product-select-label">Select Product</InputLabel>
    <Select
      defaultValue={defaultValue}
      displayEmpty
      onChange={onChange}
      name="product"
      label="Select Product"
      labelId="product-select-label"
      disabled={isDisabled}
    >
      {products?.length !== 0 &&
        products.map((product) => (
          <MenuItem key={product?.value || product} value={product?.value || product}>
            {product?.label || product}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
);

ProductsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  products: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  ]),
  isDisabled: PropTypes.bool.isRequired,
};
