// @ts-nocheck
import { createTheme } from '@mui/material/styles';

import * as Alert from './Alert';
import * as AppBar from './AppBar';
import * as Autocomplete from './Autocomplete';
import * as Button from './Button';
import * as ButtonBase from './ButtonBase';
import * as Card from './Card';
import * as CardHeader from './CardHeader';
import * as CircularProgress from './CircularProgress';
import * as DataGrid from './DataGrid';
import * as FilledInput from './FilledInput';
import * as FormControl from './FormControl';
import * as Input from './Input';
import * as InputBase from './InputBase';
import * as InputLabel from './InputLabel';
import * as Paper from './Paper';
import * as Select from './Select';
import * as Tab from './Tab';
import * as Tabs from './Tabs';
import * as TablePagination from './TablePagination';
import * as TabPanel from './TabPanel';
import * as TextField from './TextField';
import * as OutlinedInput from './OutlinedInput';
import * as Typography from './Typography';

export const getMuiTheme = (theme) =>
  createTheme({
    palette: {
      background: {
        header: theme.colors.background.header,
        main: theme.colors.background.DEFAULT,
      },
      primary: {
        main: theme.colors.primary.DEFAULT,
      },
      secondary: {
        main: theme.colors.secondary.DEFAULT,
      },
      alternative: {
        main: theme.colors.gray[200],
      },
      text: {
        primary: theme.colors.gray[600],
      },
      success: {
        main: theme.colors.success.DEFAULT,
      },
      error: {
        main: theme.colors.error.DEFAULT,
      },
    },
    typography: {
      fontFamily: theme.fontFamily.futuraPT,
      textTransform: 'none',
      fontSize: 16,
    },
    components: {
      MuiAppBar: {
        styleOverrides: AppBar.getOverrides(theme),
      },
      MuiSelect: {
        defaultProps: Select.props,
      },
      MuiPaper: {
        defaultProps: Paper.props,
      },
      MuiFormControl: {
        defaultProps: FormControl.props,
      },
      MuiCircularProgress: {
        defaultProps: CircularProgress.props,
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            zIndex: '2000000 !important',
          },
        },
      },
      MuiAlert: {
        styleOverrides: Alert.overrides,
      },
      MuiAutocomplete: {
        styleOverrides: Autocomplete.overrides,
      },
      MuiButton: {
        defaultProps: Button.props,
        styleOverrides: Button.getOverrides(theme),
      },
      MuiButtonBase: {
        defaultProps: ButtonBase.props,
      },
      MuiCard: {
        styleOverrides: Card.overrides,
        defaultProps: Card.props,
      },
      MuiCardHeader: {
        styleOverrides: CardHeader.overrides,
      },
      MuiDataGrid: {
        styleOverrides: DataGrid.getOverrides(theme),
      },
      MuiTextField: {
        defaultProps: TextField.props,
      },
      MuiFilledInput: {
        styleOverrides: FilledInput.getOverrides(theme),
      },
      MuiInputBase: {
        styleOverrides: InputBase.overrides,
      },
      MuiInput: {
        styleOverrides: Input.getOverrides(theme),
      },
      MuiInputLabel: {
        styleOverrides: InputLabel.getOverrides(theme),
      },
      MuiTab: {
        styleOverrides: Tab.overrides,
      },
      MuiTabPanel: {
        styleOverrides: TabPanel.overrides,
      },
      MuiTabs: {
        styleOverrides: Tabs.getOverrides(theme),
        defaultProps: Tabs.getProps(theme),
      },
      MuiOutlinedInput: {
        styleOverrides: OutlinedInput.overrides,
      },
      MuiTablePagination: {
        styleOverrides: TablePagination.getOverrides(theme),
      },
      MuiTypography: {
        styleOverrides: Typography.overrides,
      },
    },
  });
