import { navigate } from '@reach/router';
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLabDetails } from '../../../store/user/register.selectors';
import LabUserForm from '../components/LabUserForm';
import { makeRegisterPartnerPayload } from '../utils';
import config from '../../../config';
import { getShippingCountries } from '../../api/countriesApiRequest';
import { formatShippingCountries } from '../../api/formatShippingCountries';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../store/snackbar/snackbar.action';

const RegisterYourDetails = () => {
  const [countryCodeList, setCountryCodeList] = useState([]);
  const initialYourDetailsValues = useSelector(({ user }) => user.register.yourDetails);
  const labDetails = useSelector(getLabDetails);
  const dispatch = useDispatch();
  const { apiUrl } = config;

  useEffect(() => {
    const getResult = async () => {
      try {
        const countries = await getShippingCountries();
        setCountryCodeList(formatShippingCountries(countries));
      } catch (error) {
        console.log('Loading country codes list failed with error:', error); // eslint-disable-line no-console
      }
    };
    getResult();
  }, []);

  const toastErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error && error.response.data.error.errors) {
      const errorProps = Object.keys(error.response.data.error.errors);
      if (errorProps.length) {
        errorProps.forEach((item) => {
          if (Array.isArray(error.response.data.error.errors[item]) && error.response.data.error.errors[item].length) {
            showErrorSnackbar(error.response.data.error.errors[item][0])(dispatch);
          }
        });
      }
    } else {
      showErrorSnackbar(error.message)(dispatch);
    }
  };

  const handleBack = () => {
    navigate('/register/lab');
  };

  // this eslint comment is only introduced to tackle warnings as we go
  // this should be fixed and removed on refactor
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(async (formData) => {
    try {
      const payload = makeRegisterPartnerPayload(formData, labDetails);
      await axios.post(`${apiUrl}v1/register-partner`, payload);
      showSuccessSnackbar('The Account was created with success.')(dispatch);
      navigate('complete');
    } catch (error) {
      toastErrors(error);
    }
  });

  return (
    <div>
      <div className="text-3xl font-medium mb-4">Register Your Details!</div>
      <div className="mb-7">Please provide your details</div>

      <LabUserForm defaultValues={initialYourDetailsValues} countries={countryCodeList} onSubmit={handleSubmit} onCancel={handleBack} />
    </div>
  );
};

export default RegisterYourDetails;
