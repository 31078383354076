import axios from 'axios';
import config from '../../config';

export const SHIPPING_COUNTRIES_PATH = 'v1/products/shiping_countrys';

export const getShippingCountriesHref = (apiUrl = config?.apiUrl) => new URL(SHIPPING_COUNTRIES_PATH, apiUrl).href;

export const getShippingCountries = async (apiUrl = config?.apiUrl) => {
  const shippingCountryUrl = getShippingCountriesHref(apiUrl);
  try {
    return await axios.get(shippingCountryUrl);
  } catch {
    // TODO add sentry
    return [];
  }
};
