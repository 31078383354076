const { baseTheme } = require('./base');

module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkMode: false,
  important: '#root',
  theme: baseTheme,
  variants: {
    extend: {},
  },
};
