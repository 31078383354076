import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { TextField } from '@mui/material';
import { formErrorMessages } from '../../../../utils/form-error-messages';
import { REGEX } from '../../../../data/constants';
import { Dialog } from '../../../../components/dialog/Dialog';

export const ApiKeyDialog = ({ title, message, open, loading, primaryButtonLabel, secondaryButtonLabel, onSubmit, onClose, apiKey }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <>
      {open && !apiKey && (
        <Dialog
          primaryButtonLabel={primaryButtonLabel}
          primaryButtonType="submit"
          onSubmit={handleSubmit(onSubmit)}
          open={open}
          secondaryButtonLabel={secondaryButtonLabel}
          onClickSecondary={onClose}
          onClose={onClose}
          fullWidth
          title={title}
          isPrimaryActionClickable={!loading}
          isSecondaryActionClickable={!loading}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span>{message}</span>
            </DialogContentText>
            <TextField
              fullWidth
              placeholder={'my-api-key'}
              margin="dense"
              variant="standard"
              disabled={loading}
              id="name"
              type="name"
              name="keyName"
              control={control}
              helperText={formErrorMessages(errors.keyName)}
              required
              inputRef={register({ required: true, pattern: REGEX.NONEMPTYSTRING })}
              error={!!errors.keyName}
            />
          </DialogContent>
        </Dialog>
      )}
      {open && apiKey && (
        <Dialog
          open={open}
          message={[message, apiKey]}
          onClose={onClose}
          fullWidth
          title={title}
          onClickPrimary={onClose}
          primaryButtonLabel={primaryButtonLabel}
        />
      )}
    </>
  );
};

ApiKeyDialog.propTypes = {
  isSecondaryActionClickable: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
};

ApiKeyDialog.defaultProps = {
  onClose: null,
};
