import { useState, useReducer, useEffect } from 'react';
import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';

/**
 * @template T
 * @typedef {Object<string, any>} ApiCallState
 * @property {boolean} isLoading
 * @property {boolean} isError
 * @property {T} data

 * @typedef {Object<string, any>} ApiCallAction
 * @property {string} type
 * @property {T} [payload]
 */

const ACTION_TYPES = {
  INIT: 'init',
  IS_REFRESHING: 'isRefreshing',
  SUCCESS: 'success',
  ERROR: 'error',
};

/**
 * @template T
 * @param {ApiCallState<T>} state
 * @param {ApiCallAction<T>} action
 */
function dataFetchReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.IS_REFRESHING:
      return {
        ...state,
        isRefreshing: true,
        isLoading: true,
        isError: false,
      };
    case ACTION_TYPES.INIT:
      return {
        ...state,
        isRefreshing: false,
        isLoading: true,
        isError: false,
      };
    case ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isRefreshing: false,
        data: action.payload,
      };
    case ACTION_TYPES.ERROR:
      return {
        ...state,
        isLoading: false,
        isRefreshing: false,
        isError: true,
      };
    default:
      throw new Error();
  }
}

const getData = ({ dispatch, url }) => {
  let cancel;

  dispatch({ type: ACTION_TYPES.INIT });

  axios
    .get(url, {
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
    .then((response) => {
      dispatch({ type: ACTION_TYPES.SUCCESS, payload: response.data });
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        datadogRum.addError(`${url}: ${JSON.stringify(error)}`);
        dispatch({ type: ACTION_TYPES.ERROR });
      }
    });

  return cancel;
};

/**
 * @template T
 * @param {string|null} initialUrl
 * @param {T} [initialData]
 * @param {boolean} initialIsLoading
 * @returns {[ApiCallState<T>, import('react').Dispatch<import('react').SetStateAction<string>>]} [state, setUrl]
 */
export default function useDataApi(initialUrl = null, initialData = null, initialIsLoading = false) {
  const [url, setUrl] = useState(initialUrl);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: initialIsLoading,
    isError: false,
    isRefreshing: false,
    data: initialData,
  });

  useEffect(() => {
    if (url) {
      getData({ dispatch, url });
    }
  }, [url]);

  useEffect(() => {
    if (state.isRefreshing && url) {
      getData({ dispatch, url });
    }
  }, [state.isRefreshing, url]);

  return [state, setUrl, () => dispatch({ type: ACTION_TYPES.IS_REFRESHING })];
}
