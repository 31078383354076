import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { requestTestResultsExport } from '../../api/phpApiRequests';
import { Export } from '../../../icons';
import * as CustomMuiStyle from '../covid-results/shared.style';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../store/snackbar/snackbar.action';
import { DropDownDeprecated } from '../covid-results/covid-19.dropdown';
import { DATA_EXPORT_SUCCESS_MESSAGE, DATA_EXPORT_FAILURE_MESSAGE } from '../../../data/constants';

export const TEST_RESULTS_EXPORT_LABEL = 'All results';

const handleTestResultsExportClick = async (dispatch, status) => {
  try {
    const response = await requestTestResultsExport(status);
    if (response && response.status === 200) {
      showSuccessSnackbar(DATA_EXPORT_SUCCESS_MESSAGE)(dispatch);
    } else {
      showErrorSnackbar(DATA_EXPORT_FAILURE_MESSAGE)(dispatch);
    }
  } catch (error) {
    showErrorSnackbar(DATA_EXPORT_FAILURE_MESSAGE)(dispatch);
  }
};

export const TestResultsExport = ({ status }) => {
  const dispatch = useDispatch();

  return (
    <DropDownDeprecated
      variant="transparent"
      label="EXPORT"
      icon={Export}
      itemList={[
        {
          label: TEST_RESULTS_EXPORT_LABEL,
          onClick: () => handleTestResultsExportClick(dispatch, status),
        },
      ]}
    >
      <CustomMuiStyle.StyledDropDownTitle>Export table data to a CSV file</CustomMuiStyle.StyledDropDownTitle>
    </DropDownDeprecated>
  );
};

TestResultsExport.propTypes = {
  status: PropTypes.string.isRequired,
};
