import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { WebhookHeader } from './WebhookHeader';
import { WebhookHeaderAddButton } from './WebhookHeaderAddButton';

export const WebhookHeaders = ({ headers, onAddHeader, onRemoveHeader, isDisabled, errors, register, trigger }) => (
  <>
    <Box className="mb-5 mt-10">
      <Typography>Headers</Typography>
    </Box>
    {headers?.map((formValidationKey) => (
      <WebhookHeader
        key={formValidationKey}
        onClick={() => onRemoveHeader(formValidationKey)}
        isDisabled={isDisabled}
        baseName={formValidationKey}
        errors={errors}
        register={register}
        trigger={trigger}
      />
    ))}
    <WebhookHeaderAddButton onClick={onAddHeader} />
  </>
);

WebhookHeaders.propTypes = {
  headers: PropTypes.array.isRequired,
  onAddHeader: PropTypes.func.isRequired,
  onRemoveHeader: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};
