import { Fragment, useState, useEffect } from 'react';
import qs from 'qs';
import axios from 'axios';
import { navigate, useLocation } from '@reach/router';
import { Button, MenuItem, TableBody, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { initialState, testStatusesMeta, testStatusesOrder, columns } from './covid-19.config';
import { DropDownDeprecated } from './covid-19.dropdown';
// TODO split styles according to use and rename
import * as CustomMuiStyle from './shared.style';
import { Filter, Arrow } from '../../../icons';
import { Header, ButtonDeprecated } from '../../../components';
import { CovidResultsExport } from './CovidResultsExport';

/* Convert query part of url to a structure with filterState */
const processQueryToFilterState = (locationSearch) => {
  const filterState = {};

  const query = qs.parse(locationSearch, {
    ignoreQueryPrefix: true,
  });

  /* helper functions to push query items to filterState if given parameter exists in query */
  const pushParamIfExistsInQuery = (paramName) => {
    if (query[paramName]) {
      filterState[paramName] = query[paramName];
    }
  };
  const pushParamIfIsIntegerInQuery = (paramName) => {
    const theNumber = parseInt(query[paramName], 10);
    if (!Number.isNaN(theNumber)) {
      filterState[paramName] = theNumber;
    }
  };

  /* push params as they are in the incoming query */
  pushParamIfExistsInQuery('status');
  pushParamIfExistsInQuery('productTestType');
  pushParamIfExistsInQuery('orderNumber');
  pushParamIfExistsInQuery('username');
  pushParamIfIsIntegerInQuery('startTime');
  pushParamIfIsIntegerInQuery('endTime');
  pushParamIfIsIntegerInQuery('start');
  pushParamIfIsIntegerInQuery('limit');

  /* some adjustments to incoming data */
  if (!(filterState.status && testStatusesMeta[filterState.status])) {
    filterState.status = 'registered';
  }
  if (!filterState.start) {
    filterState.start = 0;
  }
  if (!filterState.limit) {
    filterState.limit = 10;
  }

  return filterState;
};

const buildQueryFromFilterState = (filterState) => {
  const queryString = {};

  const pushParamIfExistsInFilterState = (paramName) => {
    if (filterState[paramName]) {
      queryString[paramName] = filterState[paramName];
    }
  };

  pushParamIfExistsInFilterState('status');
  pushParamIfExistsInFilterState('productTestType');
  pushParamIfExistsInFilterState('orderNumber');
  pushParamIfExistsInFilterState('username');
  pushParamIfExistsInFilterState('startTime');
  pushParamIfExistsInFilterState('endTime');
  pushParamIfExistsInFilterState('start');
  pushParamIfExistsInFilterState('limit');

  return queryString;
};

const CovidResults = () => {
  const classes = CustomMuiStyle.useStyles();

  /* Setup the filterState from location url */
  const location = useLocation();
  const filterState = processQueryToFilterState(location.search);

  /* Set helper variables derived from filterState */
  const pageIdx = Math.floor(filterState.start / filterState.limit);
  const selectedTabIdx = testStatusesOrder.indexOf(filterState.status);

  /* Tests data (fetched from API) */
  const [testsData, setTestData] = useState(initialState);
  const [axiosRequestId, setAxiosRequestId] = useState(0);
  const numberOfPages = Math.ceil(testsData.count[filterState.status] / filterState.limit);

  /* Actions */
  const navigateToNewState = () => {
    const query = buildQueryFromFilterState(filterState);
    navigate(`/admin/clinics/covid_results?${qs.stringify(query)}`);
  };

  const fetchTestResults = async () => {
    const query = buildQueryFromFilterState(filterState);
    const currentRequestId = axiosRequestId + 1;
    setAxiosRequestId(currentRequestId);
    try {
      const { data } = await axios.get(`/api/clinics/covid_results/tableData?${qs.stringify(query)}`);

      /* check if the response is coming from THIS request */
      data.rows = data.rows.map(({ firstName, lastName, ...otherProps }) => ({
        ...otherProps,
        name: `${firstName} ${lastName}`,
      }));

      setTestData(data);
    } catch (e) {
      console.log('axios says:', e); // eslint-disable-line
    }
  };

  /* UI handlers */
  const setFilterByStatusChange = (e, newSelectedTabIdx) => {
    filterState.status = testStatusesOrder[newSelectedTabIdx];
    filterState.start = 0;
    navigateToNewState();
  };

  const handleChangeRowsPerPage = (e) => {
    filterState.start = 0;
    filterState.limit = e.target.value;
    navigateToNewState();
  };

  const handleChangePage = (moveBy) => {
    filterState.start += moveBy;
    navigateToNewState();
  };

  const handleOpenFile = (id) => {
    axios
      .request({
        url: `/admin/clinics/covid_result/${id}/download_certificate`,
        method: 'GET',
      })
      .then((data) => {
        if (data) {
          const link = document.createElement('a');
          link.href = data.data;
          link.setAttribute('download', 'download');
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line
      });
    return false;
  };
  const handleFiltersSubmit = (newFilters) => {
    /* clear currently setup filters */
    delete filterState.orderNumber;
    delete filterState.username;
    delete filterState.startTime;
    delete filterState.endTime;
    delete filterState.productTestType;

    /* introduce new filters */
    if (newFilters.orderNumber !== '') {
      filterState.orderNumber = newFilters.orderNumber;
    }
    if (newFilters.username !== '') {
      filterState.username = newFilters.username;
    }
    if (newFilters.productTestType !== '') {
      filterState.productTestType = newFilters.productTestType;
    }
    if (newFilters.startTime !== '') {
      filterState.startTime = new Date(newFilters.startTime).getTime() / 1000;
    }
    if (newFilters.endTime !== '') {
      filterState.endTime = new Date(newFilters.endTime).getTime() / 1000;
    }

    /* call for new data */
    filterState.start = 0;
    navigateToNewState();
  };

  /* Fetch data if not fetched already */
  useEffect(() => {
    fetchTestResults();
    // this eslint comment is only introduced to tackle warnings as we go
    // this should be fixed and removed on refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <CustomMuiStyle.StyledCovid19>
      <CustomMuiStyle.StyledGridHeaderWrapper>
        <CustomMuiStyle.StyledGridHeader>
          <Header element="h1">COVID-19 Tests</Header>
        </CustomMuiStyle.StyledGridHeader>
        <CustomMuiStyle.StyledGridSubHeader>
          <CustomMuiStyle.StyledGridSWrapper>
            <CustomMuiStyle.StyledTabs
              value={selectedTabIdx}
              onChange={setFilterByStatusChange}
              aria-label="Filter test results by status"
              TabIndicatorProps={{
                style: {
                  background: '#1B1D26',
                  borderRadius: '2px',
                },
              }}
            >
              {testStatusesOrder.map((testStatusId) => {
                const meta = testStatusesMeta[testStatusId];
                return (
                  <CustomMuiStyle.StyledGridHeaderSubTitle
                    label={`${meta.label} (${testsData.count[testStatusId] || 0})`}
                    index={meta.label}
                    key={meta.label + 100}
                  />
                );
              })}
            </CustomMuiStyle.StyledTabs>
          </CustomMuiStyle.StyledGridSWrapper>

          <CustomMuiStyle.StyledGridSWrapper>
            <CovidResultsExport status={filterState.status} />
            <DropDownDeprecated variant="transparent" label="FILTER" icon={Filter}>
              <div>
                <CustomMuiStyle.StyledDropDownTitle>Filter table data</CustomMuiStyle.StyledDropDownTitle>
                <Formik
                  initialValues={{
                    productTestType: filterState.productTestType || '',
                    orderNumber: filterState.orderNumber || '',
                    username: filterState.username || '',
                    startTime: (filterState.startTime && new Date(filterState.startTime * 1000).toISOString().slice(0, 10)) || '',
                    endTime: (filterState.endTime && new Date(filterState.endTime * 1000).toISOString().slice(0, 10)) || '',
                  }}
                  enableReinitialize
                  onSubmit={(newFilters, { setSubmitting }) => {
                    handleFiltersSubmit(newFilters);
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    // errors,
                    // touched,
                    handleChange,
                    handleBlur,
                    // handleSubmit,
                    // isSubmitting,
                    isValid,
                    // resetForm,
                    /* and other goodies */
                  }) => (
                    <Form name="filter" id="filter">
                      <CustomMuiStyle.StyledBox>
                        <CustomMuiStyle.StyledGroupTwo>
                          <Field
                            focused
                            component={TextField}
                            name="startTime"
                            id="startTime"
                            type="date"
                            label="Start Date"
                            helperText="Please Enter your Start Date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.startTime}
                            format="dd/MM/yyyy"
                          />
                          <Field
                            focused
                            component={TextField}
                            name="endTime"
                            id="endTime"
                            type="date"
                            label="End Date"
                            helperText="Please Enter your End Date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.endTime}
                            format="dd/MM/yyyy"
                          />
                        </CustomMuiStyle.StyledGroupTwo>
                      </CustomMuiStyle.StyledBox>
                      <CustomMuiStyle.StyledBox>
                        <Field
                          component={TextField}
                          fullWidth
                          name="orderNumber"
                          id="orderNumber"
                          type="text"
                          label="Order number"
                          helperText="Please Enter your order number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.orderNumber}
                        />
                      </CustomMuiStyle.StyledBox>
                      <CustomMuiStyle.StyledBox>
                        <Field
                          component={TextField}
                          fullWidth
                          name="username"
                          id="username"
                          type="text"
                          label="First Name/Surname"
                          helperText="Please Enter your First Name/Surname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username}
                        />
                      </CustomMuiStyle.StyledBox>
                      <CustomMuiStyle.StyledBox>
                        <CustomMuiStyle.StyledSelect
                          style={{ width: '100%' }}
                          id="productTestType"
                          name="productTestType"
                          value={values.productTestType}
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Test Type
                          </MenuItem>
                          <MenuItem value="PCR">PCR</MenuItem>
                          <MenuItem value="COVID-19 Rapid Antigen">COVID-19 Rapid Antigen</MenuItem>
                          <MenuItem value="AntiSpike">AntiSpike</MenuItem>
                        </CustomMuiStyle.StyledSelect>
                      </CustomMuiStyle.StyledBox>
                      <CustomMuiStyle.StyledBox>
                        <CustomMuiStyle.StyledGroupTwo>
                          <Button disabled={!isValid} type="submit">
                            Submit
                          </Button>
                          <Button
                            color="primary"
                            variant="outlined"
                            type="reset"
                            onClick={() => {
                              handleFiltersSubmit({});
                            }}
                          >
                            Reset
                          </Button>
                        </CustomMuiStyle.StyledGroupTwo>
                      </CustomMuiStyle.StyledBox>
                    </Form>
                  )}
                </Formik>
              </div>
            </DropDownDeprecated>
          </CustomMuiStyle.StyledGridSWrapper>
        </CustomMuiStyle.StyledGridSubHeader>
      </CustomMuiStyle.StyledGridHeaderWrapper>
      <div>
        <TableContainer className={classes.container}>
          <CustomMuiStyle.StyledTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns
                  .filter(({ hideOn }) => !(hideOn || []).includes(filterState.status))
                  .map((column) => (
                    <CustomMuiStyle.StyledTableCellHeader key={column.id} align={column.align}>
                      {column.label}
                    </CustomMuiStyle.StyledTableCellHeader>
                  ))}
                {!['registered', 'processing'].includes(filterState.status) && (
                  <CustomMuiStyle.StyledTableCellHeader>Action</CustomMuiStyle.StyledTableCellHeader>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {testsData.rows.map((row) => (
                <Fragment key={row.id}>
                  <CustomMuiStyle.StyledTableRow hover role="checkbox" tabIndex={-1}>
                    {columns
                      .filter(({ hideOn }) => !(hideOn || []).includes(filterState.status))
                      .map((column) => (
                        <CustomMuiStyle.StyledTableCell key={column.id} align={column.align}>
                          {column.formatForDisplay(row)}
                        </CustomMuiStyle.StyledTableCell>
                      ))}
                    {!['registered', 'processing'].includes(filterState.status) && (
                      <CustomMuiStyle.StyledTableCell>
                        <CustomMuiStyle.StyledLink
                          onClick={() => {
                            handleOpenFile(row.id);
                            return false;
                          }}
                          href="#"
                        >
                          Download Certificate
                        </CustomMuiStyle.StyledLink>
                      </CustomMuiStyle.StyledTableCell>
                    )}
                  </CustomMuiStyle.StyledTableRow>
                  <TableRow tabIndex={-1}>
                    <CustomMuiStyle.StyledTableDividerCell colSpan="8" />
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </CustomMuiStyle.StyledTable>
        </TableContainer>
      </div>

      <CustomMuiStyle.StyledPagination>
        <span>Rows per page</span>
        <CustomMuiStyle.StyledSelect value={filterState.limit} onChange={handleChangeRowsPerPage}>
          {[10, 25, 50, 100].map((howMany) => (
            <MenuItem key={howMany} value={howMany}>
              {howMany}
            </MenuItem>
          ))}
        </CustomMuiStyle.StyledSelect>

        {numberOfPages > 1 && (
          <>
            <CustomMuiStyle.StyledPageLabel>
              Page {pageIdx + 1} of {numberOfPages}
            </CustomMuiStyle.StyledPageLabel>

            <CustomMuiStyle.StyledPageButtonWrapper>
              <ButtonDeprecated
                icon={Arrow}
                variant="transparent"
                iconSize={1.5}
                onClick={() => handleChangePage(-filterState.limit)}
                disabled={pageIdx === 0}
              />
              <CustomMuiStyle.StyledRotate180Dg>
                <ButtonDeprecated
                  icon={Arrow}
                  variant="transparent"
                  iconSize={1.5}
                  onClick={() => handleChangePage(filterState.limit)}
                  disabled={pageIdx === numberOfPages - 1}
                />
              </CustomMuiStyle.StyledRotate180Dg>
            </CustomMuiStyle.StyledPageButtonWrapper>
          </>
        )}
      </CustomMuiStyle.StyledPagination>
    </CustomMuiStyle.StyledCovid19>
  );
};

export default CovidResults;
