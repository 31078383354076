import * as React from 'react';

function SvgFilter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M15.333 20.042l.401-.081c.927-.188 1.599-1.012 1.599-1.96s-.673-1.772-1.599-1.96l-.401-.081-.401.082c-.926.188-1.599 1.013-1.599 1.96s.672 1.772 1.599 1.96l.401.082zm12-8.001l.401-.081c.927-.188 1.599-1.012 1.599-1.96s-.673-1.771-1.599-1.959l-.402-.081-.401.082c-.926.188-1.598 1.012-1.598 1.959s.672 1.772 1.599 1.96l.401.082zm-24 0l.401-.081c.927-.188 1.599-1.012 1.599-1.96S4.66 8.229 3.734 8.041l-.402-.081-.401.082c-.926.188-1.598 1.012-1.598 1.959s.672 1.772 1.599 1.96l.401.082zM27.334 32a.667.667 0 01-.667-.667V13.266a3.335 3.335 0 010-6.533V.666a.667.667 0 011.333 0v6.067a3.333 3.333 0 010 6.533v18.067a.667.667 0 01-.666.667zm-12 0a.667.667 0 01-.667-.667V21.267a3.335 3.335 0 010-6.534V.667a.667.667 0 011.333 0v14.066a3.335 3.335 0 010 6.534v10.066a.667.667 0 01-.666.667zm-12 0a.667.667 0 01-.667-.667V13.266a3.335 3.335 0 010-6.533V.666A.667.667 0 014 .666v6.067a3.333 3.333 0 010 6.533v18.067a.667.667 0 01-.666.667z" />
    </svg>
  );
}

export default SvgFilter;
