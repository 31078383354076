import * as React from 'react';

function SvgOrders(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M22.667 30.667V5.332c0-1.465.476-2.852 1.318-3.982-2.156.009-5.158.018-8.149.018-3.366 0-6.071-.012-8.04-.035-1.249.013-2.196.358-2.815 1.024-.541.582-.774 1.347-.874 1.887l-.106.569v25.854h18.667zM24 32H2.666V3.999h.129c.145-.78.474-1.76 1.208-2.55C4.884.501 6.161.014 7.796-.001c1.94.023 4.645.035 8.039.035 5.372 0 10.642-.03 10.693-.03l.32.608a5.321 5.321 0 00-2.849 4.718zm5.175-28c-.375-1.536-1.372-2.667-2.508-2.667S24.534 2.464 24.159 4h5.016zm1.492 1.333h-8c0-2.946 1.791-5.333 4-5.333s4 2.388 4 5.333zm-23.334 4a.667.667 0 100 1.334h12a.667.667 0 100-1.334h-12zm0 6.667a.667.667 0 100 1.334h12a.667.667 0 100-1.334h-12zm0 6.667a.667.667 0 100 1.334h12a.667.667 0 100-1.334h-12z" />
    </svg>
  );
}

export default SvgOrders;
