function isStringMatching(string, regex) {
  return !!string && String(string).trim().match(regex);
}

export const passwordValidator = {
  passMinLength: (password) => password.length >= 8 || 'Should be at least 8 characters long',
  passMaxLength: (password) => password.length <= 256 || 'should be at most 256 characters long',
  passUppercase: (password) => isStringMatching(password, /[A-Z]/gm) || 'Should have at least one upper case letter from A to Z.',
  passLowercase: (password) => isStringMatching(password, /[a-z]/gm) || 'Should have at least one lower case letter from a to z.',
  passDigit: (password) => isStringMatching(password, /[0-9]/gm) || 'Should have at least one digit from 0 to 9.',
};
