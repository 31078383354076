// This module is currently not used
// we may need in future if decide to switch back on the T&C page

import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { useState } from 'react';
import { updateRegisterAction } from '../../../store/user/register.action';
import TermsAndConditions from '../TermsAndConditions';
import { CircleArrow } from '../../../icons';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../store/snackbar/snackbar.action';
import config from '../../../config';

const RegisterLabAcceptTerms = () => {
  const [, lab, yourDetails] = useSelector(({ user }) => [user.register.acceptTerms, user.register.lab, user.register.yourDetails]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { apiUrl } = config;

  const dispatch = useDispatch();

  const handleBack = () => {
    navigate('/register/lab/your-details');
  };

  function onSubmit() {
    dispatch(updateRegisterAction({ agree: true }, 'acceptTerms'));
    const body = {
      email: yourDetails.email,
      fname: yourDetails.firstName,
      lname: yourDetails.lastName,
      password: yourDetails.password,
      county: yourDetails.countryCode.title,
      country_code: yourDetails.countryCode.country_code,
      dialing_code: yourDetails.countryCode.dialing_code,
      mobile_country: yourDetails.countryCode.country_code,
      mobile: yourDetails.phoneNumber,
      accepted_dna_services: yourDetails.agree ? '1' : '0',

      name: lab.labName,
      address1: lab.labAddress1,
      address2: lab.labAddress2,
      city: lab.city,
      postcode: lab.postCode,
      country: lab.country.title,

      accepted_lab_terms: '1',
      type: 'lab',
    };

    const createUser = async () => {
      try {
        // Create User

        await axios.post(`${apiUrl}v1/register-partner`, body);

        showSuccessSnackbar('The Account was created with success.')(dispatch);

        navigate('complete');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.errors) {
          const errorProps = Object.keys(error.response.data.error.errors);
          if (errorProps.length) {
            errorProps.forEach((item) => {
              if (Array.isArray(error.response.data.error.errors[item]) && error.response.data.error.errors[item].length) {
                showErrorSnackbar(error.response.data.error.errors[item][0])(dispatch);
              }
            });
          }
        } else {
          showErrorSnackbar(error.message)(dispatch);
        }
      }
    };

    createUser();
  }

  return (
    <div className="flex flex-col">
      <div className="text-3xl font-medium mb-4">Chronomics terms of service</div>
      <div className="my-4">Summary</div>
      <div className="flex-1">
        <TermsAndConditions />
      </div>
      <FormControlLabel
        className="self-end mr-0 mt-8 mb-3"
        control={
          <Checkbox color="default" checked={termsAccepted} onChange={(event) => setTermsAccepted(event.target.checked)} name="agree" />
        }
        label="I accept the Terms of service"
      />
      <div className="flex justify-between">
        <Button variant="outlined" startIcon={<CircleArrow />} onClick={handleBack}>
          Your details
        </Button>
        <Button disabled={!termsAccepted} onClick={onSubmit}>
          Complete registration
        </Button>
      </div>
    </div>
  );
};

export default RegisterLabAcceptTerms;
