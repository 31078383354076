import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';

export const StyledDialogIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
  }
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  margin: 10px 0;
`;
