import { Cancel as Cross, CheckCircle as Tick } from '@mui/icons-material';
import { ActionsColumnCell } from '../../../../../components/paginated-data-grid/ActionsColumnCell';

export const DEFAULT_URL = 'Not set';

export const getColumns = ({ actions }) => [
  {
    field: 'eventName',
    headerName: 'Event',
    width: 250,
    sortable: false,
  },
  {
    field: 'url',
    headerName: 'URL',
    flex: 0.8,
    sortable: false,
    renderCell: (params) => (params.value === DEFAULT_URL ? <span className="text-gray-500">{params.value}</span> : params.value),
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 100,
    sortable: false,
    renderCell: (params) => (params.value ? <Tick color="success" /> : <Cross color="error" />),
  },
  {
    field: 'id',
    headerName: 'Actions',
    width: 300,
    sortable: false,
    renderCell: (params) => <ActionsColumnCell {...params} label={params.value} actions={actions} />,
  },
];

const createDefaultEvent = (eventName, id) => ({ eventName, id, url: DEFAULT_URL, active: false });

export const DEFAULT_ROWS = [
  createDefaultEvent('Order created', 'ORDER.CREATED'),
  createDefaultEvent('Order dispatched', 'ORDER.DISPATCHED'),
  createDefaultEvent('Order out for delivery', 'ORDER.OUT_FOR_DELIVERY'),
  createDefaultEvent('Order delivered', 'ORDER.RECEIVED'),
  createDefaultEvent('Order return out for delivery', 'ORDER.RETURN_OUT_FOR_DELIVERY'),
  createDefaultEvent('Order return delivered', 'ORDER.RETURN_RECEIVED'),
  createDefaultEvent('Nurse assigned', 'PHLEBOTOMY.NURSE_ASSIGNED'),
  createDefaultEvent('Appointment scheduled', 'PHLEBOTOMY.APPOINTMENT_SCHEDULED'),
  createDefaultEvent('Appointment rescheduled', 'PHLEBOTOMY.APPOINTMENT_RESCHEDULED'),
  createDefaultEvent('Appointment cancelled', 'PHLEBOTOMY.APPOINTMENT_CANCELLED'),
  createDefaultEvent('Appointment completed', 'PHLEBOTOMY.APPOINTMENT_COMPLETED'),
  createDefaultEvent('Test registered', 'TEST.REGISTERED'),
  createDefaultEvent('Test received at lab', 'TEST.LAB_ACCESSIONED'),
  createDefaultEvent('Test results ready', 'TEST.PROCESSED'),
  createDefaultEvent('Test commentary uploaded', 'TEST.CLINICAL_COMMENTARY_UPLOADED'),
];

export const assignWebhookSettings = (data) =>
  DEFAULT_ROWS.map((defaultRow) => {
    const webhook = data.find((d) => d.eventType === defaultRow.id);
    if (!webhook) return defaultRow;

    return {
      eventName: defaultRow.eventName,
      id: defaultRow.id,
      url: webhook.url,
      active: webhook.active,
    };
  });
