export const overrides = {
  root: {
    fontSize: 18,
  },
  formControl: {
    'label + &': {
      marginTop: 0,
    },
  },
};
