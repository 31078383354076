import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { getStorefrontProductCreationPathname } from '../../../../constants';

export const StorefrontAddButtonLink = ({ className, storeId }) => (
  <Button component="button" type="button" className={className} href={getStorefrontProductCreationPathname(storeId)}>
    Add Product
  </Button>
);

StorefrontAddButtonLink.proptypes = {
  className: PropTypes.string,
  storeId: PropTypes.number.isRequired,
};
