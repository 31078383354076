import { LOCAL_STORAGE_ITEM_PARTNER_CODE } from '../hooks/use-partner-branding';

const getStoredPartnerCode = () => localStorage.getItem(LOCAL_STORAGE_ITEM_PARTNER_CODE);

export const getPathWithSearch = (pathname, search) => [pathname, search].filter(Boolean).join('');

export const getLoginPathname = () => {
  const partnerCode = getStoredPartnerCode();
  return getPathWithSearch('/login', partnerCode && `?partnerCode=${partnerCode}`);
};

export const getHomePathname = () => {
  const partnerCode = getStoredPartnerCode();
  return getPathWithSearch('/', partnerCode && `?partnerCode=${partnerCode}`);
};

export const getLogoutPathname = () => '/api/v2/partners/auth/logout';

export const getProfilePathname = () => '/admin/profile';

export const getBillingPathname = () => '/admin/clinics/billing';

export const getStorefrontPathname = () => '/admin/clinics/storefront';

export const getShopifyAssetPathname = () => '/admin-assets/img/shopify.png';

export const getStorefrontStorePathname = (id) => [getStorefrontPathname(), 'store', id].join('/');

export const getStorefrontProductEditingBasePathname = (id) => [getStorefrontPathname(), 'products', id].join('/');
export const getStorefrontProductCreationBasePathname = () => [getStorefrontPathname(), 'products', 'create'].join('/');
export const getStorefrontProductAddBasePathname = () => [getStorefrontPathname(), 'products', 'add'].join('/');
export const getStorefrontRedirectSearch = (id) => `redirect=${encodeURIComponent(getStorefrontStorePathname(id).replace('/', ''))}`;
export const getStorefrontStoreSearch = (id) => `store=${id}`;
export const getStorefrontProductSearch = (id) => `base_product_variation=${id}`;

export const getStorefrontSearchParameters = ({ storeId, productVariationId }) =>
  [
    storeId && getStorefrontStoreSearch(storeId),
    productVariationId && getStorefrontProductSearch(productVariationId),
    storeId && getStorefrontRedirectSearch(storeId),
  ]
    .filter(Boolean)
    .join('&');

export const getStorefrontProductCreationPathname = (storeId) =>
  [getStorefrontProductCreationBasePathname(), getStorefrontSearchParameters({ storeId })].join('?');

export const getStorefrontProductEditPathname = ({ storeId, productVariationId }) =>
  [getStorefrontProductEditingBasePathname(productVariationId), getStorefrontRedirectSearch(storeId)].join('?');

export const getStorefrontProductAddPathname = ({ storeId, productVariationId }) =>
  [
    getStorefrontProductAddBasePathname(),
    getStorefrontSearchParameters({
      storeId,
      productVariationId,
    }),
  ].join('?');
