import axios from 'axios';
import config from '../../config';

export const SIMULATOR_PATH = 'data-simulator/simulate';

const { simulatorApiUrl } = config;
const dataSimulatorEndpoint = (testDataType, standalone, batchCount) =>
  batchCount
    ? new URL(`${SIMULATOR_PATH}/${testDataType}?batchCount=${batchCount}`, simulatorApiUrl).href
    : new URL(`${SIMULATOR_PATH}/${testDataType}?standalone=${standalone}`, simulatorApiUrl).href;

export const createTestData = async (
  testDataType,
  standalone,
  batchCount,
  payload,
  endpoint = dataSimulatorEndpoint(testDataType, standalone, batchCount),
) => axios.post(endpoint, payload);
