export const getOverrides = (theme) => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: theme.colors.gray[100],
    '&:hover': {
      backgroundColor: theme.colors.gray[200],
    },
  },
  input: {
    paddingTop: 20,
  },
  underline: {
    '&:before': {
      borderBottomColor: theme.colors.gray[300],
    },
    '&:hover:before': {
      borderBottomColor: theme.colors.gray[400],
    },
  },
});
