import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as CustomMuiStyle from '../covid-results/shared.style';
import { columns } from './test-results.config';

export const TestResultsTable = ({ filterState, setOpenModal, setModalKitId, testsData }) => {
  const classes = CustomMuiStyle.useStyles();

  return (
    <TableContainer className={classes.container}>
      <CustomMuiStyle.StyledTable stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns
              .filter(({ hideOn }) => !(hideOn || []).includes(filterState.status))
              .map((column) => (
                <CustomMuiStyle.StyledTableCellHeader key={column.id} align={column.align}>
                  {column.label}
                </CustomMuiStyle.StyledTableCellHeader>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {testsData.rows.map((row) => (
            <Fragment key={row.id}>
              <CustomMuiStyle.StyledTableRow hover role="checkbox" tabIndex={-1}>
                {columns
                  .filter(({ hideOn }) => !(hideOn || []).includes(filterState.status))
                  .map((column) => (
                    <CustomMuiStyle.StyledTableCell key={column.id} align={column.align}>
                      {column.formatForDisplay(row, setOpenModal, setModalKitId)}
                    </CustomMuiStyle.StyledTableCell>
                  ))}
              </CustomMuiStyle.StyledTableRow>
              <TableRow tabIndex={-1}>
                <CustomMuiStyle.StyledTableDividerCell colSpan="8" />
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </CustomMuiStyle.StyledTable>
    </TableContainer>
  );
};

TestResultsTable.propTypes = {
  filterState: PropTypes.object.isRequired,
  setModalKitId: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  testsData: PropTypes.object.isRequired,
};
