import { useState, useMemo } from 'react';
import { Tabs, Tab } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import Simulator from './simulate';
import ApiKeys from './api-keys/ApiKeys';
import { Webhooks } from './webhooks';
import { getConfig } from '../../../config';

export const getTabs = () => [
  {
    label: 'Test data',
    value: 'simulator',
    getPanel: () => <Simulator />,
    isVisible: getConfig()?.env !== 'prod',
  },
  {
    label: 'API keys',
    value: 'apiKeys',
    getPanel: () => <ApiKeys />,
    isVisible: true,
  },
  {
    label: 'Webhooks',
    value: 'webhooks',
    getPanel: () => <Webhooks />,
    isVisible: true,
  },
];

const getVisibleTabs = () => getTabs().filter(({ isVisible }) => isVisible);

export const Developer = () => {
  const tabs = useMemo(() => getVisibleTabs(), []);

  const [selectedTabIndex, setSelectedTabIndex] = useState(tabs[0].value);
  const selectTab = (event, newTab) => setSelectedTabIndex(newTab);

  return (
    <div className="flex flex-col h-full">
      <h1 className="text-3xl font-medium mb-7">Developers</h1>
      <Tabs value={selectedTabIndex} onChange={selectTab}>
        {tabs.map(({ value, label }) => (
          <Tab key={value} value={value} label={label} />
        ))}
      </Tabs>
      <TabContext value={selectedTabIndex}>
        {tabs.map(({ value, getPanel }) => (
          <TabPanel className="flex-1 relative" value={value} key={value}>
            {getPanel()}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};
