import { combineReducers } from 'redux';

import { permissionReducer } from './permission.reducer';
import { profileReducer } from './profile.reducer';
import { registerReducer } from './register.reducer';
import { labReducer } from './lab.reducer';

const reducer = combineReducers({
  register: registerReducer,
  permission: permissionReducer,
  profile: profileReducer,
  lab: labReducer,
});

export default reducer;
