import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { TextField } from '@mui/material';
import { formErrorMessages } from '../../utils/form-error-messages';
import { showErrorSnackbar, showSuccessSnackbar } from '../../store/snackbar/snackbar.action';
import { REGEX, ERROR_MESSAGE } from '../../data/constants';
import { ResetPasswordButton } from '../../components/button/resetPasswordButton.component';
import { LOCAL_STORAGE_ITEM_PARTNER_PRIMARY_COLOR } from '../../hooks/use-partner-branding';

const ForgotPassword = () => {
  const partnerPrimaryColor = localStorage.getItem(LOCAL_STORAGE_ITEM_PARTNER_PRIMARY_COLOR);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function onFormSubmit(formData) {
    setIsLoading(true);
    axios
      .post('/api/v2/partners/auth/password/email', formData)
      .then(() => {
        showSuccessSnackbar('We have e-mailed your password reset link!')(dispatch);
        reset();
      })
      .catch(() => {
        showErrorSnackbar(ERROR_MESSAGE)(dispatch);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <form className="self-center m-auto w-4/5" onSubmit={handleSubmit(onFormSubmit)}>
      <div className="text-3xl font-medium mb-2">Forgotten password?</div>
      <div className="mb-10">Enter your email address to reset your password</div>
      <TextField
        name="email"
        label="Email address"
        disabled={isLoading}
        inputRef={register({ required: true, pattern: REGEX.EMAIL })}
        error={errors.email}
        helperText={formErrorMessages(errors.email)}
      />
      <ResetPasswordButton isLoading={isLoading} partnerPrimaryColour={partnerPrimaryColor} />
    </form>
  );
};

export default ForgotPassword;
