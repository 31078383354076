export default function ReloadRoute(routeData) {
  if (window.location.port !== '3000') {
    /* it's either called:
    - BEFORE the route happens (inside the Router block), then it needs to look at routeData
    - AFTER it happened, as simply component to be reneder, then just read window.location */
    if (routeData && routeData.location) {
      window.location = routeData.location.href;
    } else {
      window.location.reload();
    }
  }

  return null;
}
