// source https://httpstatuses.com/
export const HTTP_STATUS_CODES = {
  // 1×× Informational
  100: 'Continue',
  101: 'Switching Protocols',
  102: 'Processing',
  // 2×× Success
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  226: 'IM Used',
  // 3×× Redirection
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  // 4×× Client Error
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'Connection Closed Without Response',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request',
  // 5×× Server Error
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  599: 'Network Connect Timeout Error',
};

export const REGEX = {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  DIGITS: /^\d+$/,
  DIGITS_BATCH_COUNTS: /^(0?\d|[1-4]\d|50)$/, // less than 51
  NAME: /^\p{L}[\p{L} '-]+$/u,
  // strictly matches a string of hyphen, underscore and alphanumeric characters
  NONEMPTYSTRING: /^[a-zA-Z0-9_-]+$/,
  ALPHANUMERIC: /^[A-Za-z0-9]*$/,
  PHONE_NUMBER: /^\+[1-9]\d{1,14}$/, // E.164 phone format
};

export const ERROR_MESSAGE = 'Something has gone wrong, please retry later.';

export const DATA_EXPORT_SUCCESS_MESSAGE = 'Check your email for a download link.';
export const DATA_EXPORT_FAILURE_MESSAGE = 'Export failed. Please try again.';

export const TEST_USER_DASHBOARD_ENVIRONMENT_URLS = {
  // cannot run both front-end simultaneously locally
  test: 'https://app.stage.hurdle.bio',
  local: 'https://app.stage.hurdle.bio',
  stage: 'https://app.stage.hurdle.bio',
  sandbox: 'https://app.sandbox.hurdle.bio',
  prod: 'https://app.hurdle.bio',
};
