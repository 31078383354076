import MenuItem from '@mui/material/MenuItem';
import * as CustomMuiStyle from '../../app/admin/covid-results/shared.style';

const PaginationSelect = ({ onChange, defaultValue, selectOptions }) => (
  <CustomMuiStyle.StyledSelect value={defaultValue} onChange={onChange}>
    {selectOptions.map((howMany) => (
      <MenuItem key={howMany} value={howMany}>
        {howMany}
      </MenuItem>
    ))}
  </CustomMuiStyle.StyledSelect>
);

export default PaginationSelect;
