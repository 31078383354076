export const overrides = {
  root: {
    minWidth: 'min-content',
    width: '100%',
    minHeight: '64px',
    maxHeight: '800px',
    padding: '0.5rem',
    color: 'white',
    boxShadow: '1px 10px 0 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%)',
    cursor: 'pointer',
    overflow: 'hidden',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  action: {
    padding: '0.25rem',
    marginTop: '-0.5rem',
  },
};
