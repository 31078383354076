import styled from 'styled-components';

const variantBackgroundColor = {
  contained: '#fff',
  transparent: 'transparent',
};

const variantBorderColor = {
  contained: '#1b1d26',
  transparent: 'transparent',
};

export const StyledButtonImage = styled.div`
  width: 21px;
  height: 21px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  background-image: url(${({ image }) => image});
`;

export const StyledButtonLabel = styled.span`
  font-size: 16px;
  height: 21px;
  line-height: 21px;
  white-space: nowrap;
  &:not(:first-child) {
    margin-left: 6px;
  }
`;

export const StyledCount = styled.span`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d52027;
  color: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  padding: 0;
  font-size: 10px;
`;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  color: #1b1d26;
  background-color: ${({ variant }) => variantBackgroundColor[variant]};
  border: 1px solid ${({ variant }) => variantBorderColor[variant]};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  border-radius: 2px;
  cursor: pointer;
  transition: 250ms;
  align-items: center;
  position: relative;
  outline: none;

  svg {
    color: #ffaf4b;
  }

  &:disabled {
    color: #808080;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${variantBackgroundColor.contained};
    border-color: ${variantBorderColor.transparent};
  }
`;
