import * as React from 'react';

function SvgTests(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M31.808 7.849L9.181 30.476a5.332 5.332 0 11-7.542-7.542L24.266.307zm-1.886 0l-5.657-5.657L2.58 23.877a4 4 0 00-.161 5.486l.161.171a4 4 0 005.486.161l.171-.161zm1.886 0l-3.771 3.771-7.542-7.542L24.266.307zm-1.886 0l-5.657-5.657-1.886 1.886 5.657 5.657zm-16.06 12.68a.668.668 0 011.02.85l-.077.092-7.333 7.333a.668.668 0 01-1.02-.85l.077-.092zm9.471-5.862a.667.667 0 01.12 1.323l-.12.011H10.266a.667.667 0 01-.12-1.323l.12-.011z" />
    </svg>
  );
}

export default SvgTests;
