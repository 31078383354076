import * as React from 'react';

function SvgDashboard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M29.333 30.667c.736 0 1.334-.599 1.334-1.334V16c0-.735-.599-1.333-1.334-1.333h-9.332c-.736 0-1.334.598-1.334 1.333v13.333c0 .736.599 1.334 1.334 1.334h9.332zm-17.333 0c.735 0 1.333-.599 1.333-1.334V24c0-.735-.598-1.333-1.333-1.333H2.666c-.735 0-1.333.598-1.333 1.333v5.333c0 .736.598 1.334 1.333 1.334H12zm0-13.333c.735 0 1.333-.599 1.333-1.334V2.667c0-.735-.598-1.333-1.333-1.333H2.666c-.735 0-1.333.598-1.333 1.333V16c0 .736.598 1.334 1.333 1.334H12zm17.333-8c.736 0 1.334-.598 1.334-1.333V2.667c0-.735-.599-1.333-1.334-1.333h-9.332c-.736 0-1.334.598-1.334 1.333v5.334c0 .735.599 1.333 1.334 1.333h9.332zm0 22.666h-9.332a2.667 2.667 0 01-2.668-2.668V15.999a2.667 2.667 0 012.668-2.666h9.332a2.667 2.667 0 012.668 2.666v13.333A2.667 2.667 0 0129.333 32zM12 32H2.666A2.667 2.667 0 010 29.332v-5.333a2.667 2.667 0 012.666-2.666H12a2.667 2.667 0 012.666 2.666v5.333A2.667 2.667 0 0112 32zm0-13.333H2.666A2.667 2.667 0 010 15.999V2.666A2.667 2.667 0 012.666 0H12a2.667 2.667 0 012.666 2.666v13.333A2.667 2.667 0 0112 18.667zm17.333-8h-9.332a2.667 2.667 0 01-2.668-2.666V2.667A2.667 2.667 0 0120.001.001h9.332a2.667 2.667 0 012.668 2.666v5.334a2.667 2.667 0 01-2.668 2.666z" />
    </svg>
  );
}

export default SvgDashboard;
