import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Loader } from '../index';

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

export const PaginatedDataGrid = ({
  autoHeight,
  className,
  columns,
  disableColumnMenu,
  disableRowSelectionOnClick,
  disableSelectionOnClick,
  hasError,
  hideFooter,
  isLoading,
  onChange,
  pagination,
  paginationMode,
  rows,
  rowCount,
  rowHeight,
  rowsPerPageOptions,
  sortingMode,
}) => {
  const [pageSize, setPageSize] = useState(rowsPerPageOptions[0]);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    setPageNumber(0);
  }, [pageSize]);

  useEffect(() => {
    if (onChange) {
      const non0IndexedPageNumber = pageNumber + 1;
      onChange(pageSize, non0IndexedPageNumber);
    }
  }, [onChange, pageSize, pageNumber]);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && hasError && <div>Something unexpected happened. Please try again later or contact us.</div>}
      {!isLoading && !hasError && (
        <DataGrid
          autoHeight={autoHeight}
          className={className}
          columns={columns}
          disableColumnMenu={disableColumnMenu}
          disableSelectionOnClick={disableSelectionOnClick}
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          hideFooter={hideFooter}
          loading={isLoading}
          onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
          onPageSizeChange={(size) => setPageSize(size)}
          page={pageNumber}
          pageSize={pageSize}
          pagination={pagination}
          paginationMode={paginationMode}
          rowCount={rowCount}
          rowHeight={rowHeight}
          rows={rows}
          rowsPerPageOptions={rowsPerPageOptions}
          sortingMode={sortingMode}
        />
      )}
    </>
  );
};

PaginatedDataGrid.propTypes = {
  autoHeight: PropTypes.bool,
  className: PropTypes.string,
  disableColumnMenu: PropTypes.bool,
  disableRowSelectionOnClick: PropTypes.bool,
  disableSelectionOnClick: PropTypes.bool,
  hideFooter: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  pagination: PropTypes.bool,
  paginationMode: PropTypes.string,
  rows: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowHeight: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sortingMode: PropTypes.string,
};

PaginatedDataGrid.defaultProps = {
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  disableRowSelectionOnClick: true,
  hideFooter: false,
  hasError: false,
  pagination: true,
  paginationMode: 'server',
  rowHeight: 72,
  rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
  sortingMode: 'server',
};
