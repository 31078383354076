import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { ApiKeyDialog } from './ApiKeyDialog';
import { createApiKey, getApiKeys } from '../../../api/partnerApiKeyServiceApiRequests';
import { PaginatedDataGrid } from '../../../../components/paginated-data-grid';
import { getDefaultColumns } from './getDefaultColumns';
import { DeleteIconAction } from './actions/DeleteIconAction';
import { showErrorSnackbar } from '../../../../store/snackbar/snackbar.action';
import { ERROR_MESSAGE } from '../../../../data/constants';

export const ERROR = 'Something has gone wrong, please retry later.';

const getSortedApiKeys = async (partnerId) => {
  const keys = await getApiKeys({ partnerId });
  return keys?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
};

const defaultDialogState = {
  open: false,
  title: '',
  message: '',
  onClose: () => {},
};

const ApiKeys = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isKeyNameError] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dialogState, setDialogState] = useState(defaultDialogState);
  const partnerType = useSelector(({ user }) => user.profile.type);
  const clinicOrLabId = useSelector(({ user }) => user.profile.clinic.id);

  useEffect(() => {
    if (isDataLoading) {
      const fetchData = async () => {
        setData(await getSortedApiKeys(clinicOrLabId));
        setIsDataLoading(false);
      };

      fetchData();
    }
  }, [clinicOrLabId, isDataLoading]);

  useEffect(() => {
    if (isDataLoading) {
      setDialogState(defaultDialogState);
    }
  }, [isDataLoading]);

  const closeDialog = () => {
    setDialogState(defaultDialogState);
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    const keyName = { keyName: formData.keyName };
    try {
      const response = await createApiKey(keyName, clinicOrLabId, partnerType);
      setDialogState({
        open: true,
        title: 'Your new API key has been generated',
        message: `Warning: This will only be shown once so copy it and store it securely.`,
        apiKey: `${response.data.apiKey}`,
        primaryButtonLabel: 'Close',
        onClose: () => setIsDataLoading(true),
      });
    } catch (error) {
      showErrorSnackbar(ERROR_MESSAGE)(dispatch);
    }

    setLoading(false);
  };

  const onClick = () => {
    setDialogState({
      open: true,
      title: 'Generate API key',
      message: 'Please provide a user-friendly name for your API Key.',
      apiKey: '',
      primaryButtonLabel: 'Generate',
      secondaryButtonLabel: 'Close',
      onClose: closeDialog,
    });
  };

  const actions = [
    ({ id }) => (
      <DeleteIconAction
        key={`delete-${id}`}
        id={id}
        partnerId={clinicOrLabId}
        partnerType={partnerType}
        onClickPrimaryCallback={() => setIsDataLoading(true)}
      />
    ),
  ];
  return (
    <div className="h-full flex flex-col mt-5">
      <Button className="absolute right-0 -top-12" disabled={dialogState.open} onClick={onClick}>
        Generate API key
      </Button>
      <ApiKeyDialog
        open={dialogState.open}
        title={dialogState.title}
        message={dialogState.message}
        apiKey={dialogState.apiKey}
        primaryButtonLabel={dialogState.primaryButtonLabel}
        secondaryButtonLabel={dialogState.secondaryButtonLabel}
        onSubmit={onSubmit}
        onClose={dialogState.onClose}
        loading={loading}
        isKeyNameError={isKeyNameError}
      />
      <PaginatedDataGrid
        paginationMode={'client'}
        columns={getDefaultColumns({ actions })}
        rows={data}
        isLoading={isDataLoading}
        rowCount={data.length}
        onChange={() => {}}
      />
    </div>
  );
};

export default ApiKeys;
