import axios from 'axios';
import config from '../../../config';

export const getTeamUrl = (path = 'v1/team') => new URL(path, config.apiUrl).href;

const getQueryOptions = (pageSize, pageNumber) =>
  [pageSize && `page_size=${pageSize}`, pageNumber && `page=${pageNumber}`].filter(Boolean).join('&');

export const getTeamEndpoint = (pageSize, pageNumber) => [getTeamUrl(), getQueryOptions(pageSize, pageNumber)].filter(Boolean).join('?');

export const getResetPassword = async (id) => axios.post(getTeamUrl(`v1/team/resetPassword/${id}`));

export const getDeleteUser = async (id) => axios.delete(getTeamUrl(`v1/team/${id}`));
