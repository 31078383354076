import { navigate } from '@reach/router';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { updateRegisterAction } from '../../../store/user/register.action';
import PartnerUserForm from '../components/PartnerUserForm';
import config from '../../../config';
import getAccessToken from '../../../components/auth/getAccessToken';
import connectToStorefront from '../../../components/auth/connectToStorefront';
import { getFirstApiError } from '../../../utils/api-errors';
import { getShippingCountries } from '../../api/countriesApiRequest';
import { formatShippingCountries } from '../../api/formatShippingCountries';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../store/snackbar/snackbar.action';

const RegisterPartnerYourDetails = () => {
  const [countryCodeList, setCountryCodeList] = useState([]);
  const initialYourDetailsValues = useSelector(({ user }) => user.register.yourDetails);
  const [partner] = useSelector(({ user }) => [user.register.partner]);
  const dispatch = useDispatch();
  const { apiUrl } = config;

  useEffect(() => {
    const getResult = async () => {
      try {
        const countries = await getShippingCountries();
        setCountryCodeList(formatShippingCountries(countries));
      } catch (error) {
        console.log('Loading country codes list failed with error:', error); // eslint-disable-line no-console
      }
    };
    getResult();
  }, []);

  const handleBack = () => {
    navigate('/register/partner');
  };

  function onSubmit(yourDetails) {
    dispatch(updateRegisterAction({ agree: true }, 'acceptTerms'));
    const body = {
      email: yourDetails.email,
      fname: yourDetails.firstName,
      lname: yourDetails.lastName,
      password: yourDetails.password,
      county: yourDetails.countryCode.title,
      country_code: yourDetails.countryCode.country_code,
      dialing_code: yourDetails.countryCode.dialing_code,
      mobile_country: yourDetails.countryCode.country_code,
      mobile: yourDetails.phoneNumber,
      accepted_dna_services: yourDetails.agree ? '1' : '0',

      name: partner.partnerName,
      address1: partner.partnerAddress1,
      address2: partner.partnerAddress2,
      city: partner.city,
      postcode: partner.postCode,
      country: partner.country.title,

      accepted_lab_terms: '1',
      type: 'partner',
    };

    function login(signInData) {
      // This is not a standard API call as it sets cookies (its the old laravel login) so need to
      // use explicit path, ie dashboard.hurdle.bio..
      const loc = window.location;
      const baseUrl = `${loc.protocol}//${loc.hostname}${loc.port ? `:${loc.port}` : ''}`;
      const parsedQueryString = queryString.parse(window.location.search);

      axios
        .post(`${baseUrl}/api/v2/partners/auth/login`, signInData)
        .then(() => {
          getAccessToken({
            email: signInData.email,
            password: signInData.password,
            onSuccess: (response) => {
              if (parsedQueryString.shopify) {
                connectToStorefront({
                  shopify: parsedQueryString.shopify,
                  onSuccess: () => {
                    navigate('/admin/clinics/storefront');
                  },
                  onError: (message) => {
                    showErrorSnackbar(message)(dispatch);
                  },
                });
              } else {
                const redirect = response?.data?.redirect || parsedQueryString.redirect || '/';
                navigate(redirect);
              }
            },
            onError: () => {
              navigate('complete');
            },
          });
        })
        .catch(() => {
          navigate('complete');
        });
    }

    const createUser = async () => {
      try {
        // Create User
        await axios.post(`${apiUrl}v1/register-partner`, body);
        showSuccessSnackbar('The Account was created with success.')(dispatch);
        login({ email: yourDetails.email, password: yourDetails.password });
      } catch (error) {
        showErrorSnackbar([getFirstApiError(error), 'Error: please try again'].join(' '))(dispatch);
      }
    };

    createUser();
  }

  return (
    <div>
      <div className="text-3xl font-medium mb-4">Register Your Details!</div>
      <div className="mb-7">Please provide your details</div>

      <PartnerUserForm
        defaultValues={initialYourDetailsValues}
        countries={countryCodeList}
        onSubmit={(formData) => {
          dispatch(updateRegisterAction(formData, 'yourDetails'));
          onSubmit(formData);
        }}
        onCancel={handleBack}
      />
    </div>
  );
};

export default RegisterPartnerYourDetails;
