import _ from 'lodash';
import { ORDER_PATHS } from '../app/admin/order';

export const PROFILE_TYPES = {
  LAB: 'lab',
  CLINIC: 'clinic',
};

export const PAYMENT_TYPES = {
  INVOICE: 'invoice',
  CARD: 'card',
};

export const isPaymentTypeCard = (paymentType) => paymentType?.toLowerCase() === PAYMENT_TYPES.CARD;
export const isPaymentTypeInvoice = (paymentType) => paymentType?.toLowerCase() === PAYMENT_TYPES.INVOICE;

export const isClinicBillable = ({ clinic, orders }) =>
  orders && (isPaymentTypeCard(clinic?.payment_type) || isPaymentTypeInvoice(clinic?.payment_type));

export const getOrdersLandingHref = ({ clinic, orders }) => {
  const canAccessOrderLandingPage =
    isClinicBillable({
      clinic,
      orders,
    }) || !orders;
  if (canAccessOrderLandingPage) {
    return ORDER_PATHS.BASE;
  }
  return ORDER_PATHS.HISTORY;
};

export const getIsLabUser = (partnerType) => partnerType === PROFILE_TYPES.LAB;
export const getIsClinicUser = (partnerType) => partnerType === PROFILE_TYPES.CLINIC;

/**
 * @description allows to specify multiple permissions per item and intersects them with back-end
 * @returns {unknown[]|null}
 */
export const getIntersectionOfPermissionsAndRoutes = (route, permissions) => {
  if (Array.isArray(route.permissions)) {
    return _.intersection(route.permissions, Object.keys(permissions));
  }

  return null;
};

/**
 *
 * @param array
 * @param permissions
 * @returns {*[]}
 */
export const filterBasedOnPermissions = (array = [], permissions = {}) =>
  array.filter(
    (route) => !route.permissions || permissions[route.permissions] || getIntersectionOfPermissionsAndRoutes(route, permissions),
  );
