import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductsEndpoint } from '../../../api/productServiceApiRequests';
import { getShippingCountriesHref } from '../../../api/countriesApiRequest';
import { CreateOrderForm } from './CreateOrderForm';
import { OrderConfirmation } from './OrderConfirmation';
import { Loader } from '../../../../components';
import { useDataApi } from '../../../../hooks';
import { formatShippingCountries } from '../../../api/formatShippingCountries';

export const PRODUCT_SERVICE_ERROR_MESSAGE = 'An error has occurred. Please contact an account manager.';
export const NO_PRODUCTS_ERROR_MESSAGE = 'Please contact an account manager to configure products for your account.';
export const ORDER_CREATED_SUCCESS = 'Order created!';
export const ORDER_NOT_CREATED_ERROR = 'Order was not created, please try again';

export const CreateOrder = () => {
  const [orderNumber, setOrderNumber] = useState('');
  const clinicId = useSelector(({ user }) => user.profile.clinic.id);
  const [{ isLoading: isShippingCountryListLoading, data: countryCodeList, isError: hasShippingCountryListError }] = useDataApi(
    getShippingCountriesHref(),
    [],
    true,
  );

  const [{ isLoading: isProductListLoading, data: productList, isError: hasProductListError }, setProductListUrl] = useDataApi(
    getProductsEndpoint(),
    [],
    !!clinicId,
  );

  const isDataLoading = useMemo(
    () => isProductListLoading || isShippingCountryListLoading,
    [isProductListLoading, isShippingCountryListLoading],
  );

  const isError = useMemo(() => hasShippingCountryListError || hasProductListError, [hasProductListError, hasShippingCountryListError]);
  const hasProducts = useMemo(() => Array.isArray(productList?.data) && productList?.data?.length > 0, [productList]);
  const hasCountries = useMemo(() => Array.isArray(countryCodeList?.data) && countryCodeList?.data?.length > 0, [countryCodeList]);

  useEffect(() => {
    if (clinicId) {
      setProductListUrl(getProductsEndpoint());
    }
  });

  return (
    <div className="bg-white pt-20 px-5 pb-32">
      {isDataLoading && <Loader />}
      {!orderNumber && !isDataLoading && isError && <p className="mb-2">{PRODUCT_SERVICE_ERROR_MESSAGE}</p>}
      {!orderNumber && !isDataLoading && !isError && !hasProducts && <p className="mb-2">{NO_PRODUCTS_ERROR_MESSAGE}</p>}
      {!orderNumber && !isDataLoading && !isError && hasProducts && hasCountries && (
        <CreateOrderForm
          onSubmit={setOrderNumber}
          countryCodeList={formatShippingCountries({ data: countryCodeList })}
          productList={productList.data.sort((a, b) => a.title.localeCompare(b.title))}
        />
      )}
      {orderNumber && !isDataLoading && <OrderConfirmation orderNumber={orderNumber} />}
    </div>
  );
};
