import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { useParams } from '@reach/router';
import { StorefrontAddButtonLink } from '../common/StorefrontAddButtonLink';
import { useDataApi } from '../../../../hooks';
import { StorefrontProductsTable } from '../products/StorefrontProductsTable';
import { StorefrontPage } from '../common/StorefrontPage';
import { getStorefrontStore } from '../../../api/storefrontApiRequests';

export const StorefrontStore = () => {
  const params = useParams();

  const { storeId } = params;
  const [{ isLoading: isStoreLoading, data: storeData, isError: hasError }, setUrl] = useDataApi(
    getStorefrontStore({ storeId }),
    null,
    true,
  );
  const hasProducts = storeData?.associatedProducts?.total !== 0;

  return (
    <StorefrontPage
      header={
        <Box component="span" className="flex justify-between">
          <Typography variant="h4" color="inherit" noWrap>
            {/** https://chronomics.atlassian.net/browse/HEX-658 * */}
            {_.capitalize(storeData?.store?.title)}
          </Typography>
          <StorefrontAddButtonLink storeId={storeId} />
        </Box>
      }
    >
      {!isStoreLoading && !hasProducts && <Typography variant="body1">No products added.</Typography>}
      <Box className="w-full h-full grid grid-rows-1">
        {hasProducts && (
          <StorefrontProductsTable
            rows={storeData?.associatedProducts?.data || []}
            hasError={hasError}
            isLoading={isStoreLoading}
            onChange={(pageSize, pageNumber) => setUrl(getStorefrontStore({ storeId, pageNumber, pageSize }))}
            rowCount={storeData?.associatedProducts?.total || 0}
          />
        )}
      </Box>
    </StorefrontPage>
  );
};
