import { Box, Link, Typography } from '@mui/material';
import config from '../../../../../../config';
import { TEST_USER_DASHBOARD_ENVIRONMENT_URLS } from '../../../../../../data/constants';

const getTestUserDashboardUrl = () => TEST_USER_DASHBOARD_ENVIRONMENT_URLS[config.env];

const getTestUserResultsUrl = () => new URL(`dashboard`, getTestUserDashboardUrl()).href;

export const SimulateFormResultResponse = () => (
  <Box>
    <Typography variant="h6">URL to View Results</Typography>
    <div className="mt-2 mb-4">Follow this link to view emulated results on user dashboard</div>
    <Link href={getTestUserResultsUrl()} target="_blank" rel="noreferrer">
      {getTestUserResultsUrl()}
    </Link>
  </Box>
);
