import { Button } from '@mui/material';
import { Header } from '../../components';

const RegisterComplete = () => {
  const handleLogin = () => {
    window.location = '/login';
  };
  return (
    <div>
      <Header element="h1">Thank you for registering with the Hurdle platform</Header>
      <div className="flex justify-center">
        <Button className="" onClick={handleLogin}>
          Login
        </Button>
      </div>
    </div>
  );
};

export default RegisterComplete;
