// @ts-nocheck
const TermsAndConditions = () => (
  <div>
    <div>
      <p style="text-align: center">
        <strong>TERMS AND CONDITIONS FOR LABORATORY TESTING SERVICES</strong>
      </p>
      <p style="text-align: right">
        <strong>Version 0.1. 06 November 2020</strong>
      </p>
      <p style="text-align: right">
        <strong />
      </p>
      <p>
        <strong />
      </p>
      <p style="text-align: left">
        <div type="button">1 definitions And interpretation</div>
      </p>
      <p>1.1 In this Agreement unless the context requires otherwise, the following definitions apply:</p>
      <table border="0" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Agreement&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>these Terms and Conditions, together with a Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Applicable Law&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any applicable law, statute, bye-law, regulation, orders, regulatory policy, guidance or industry code, rule of court or
                directive or requirement or notice of any regulatory body, delegated or subordinate legislation;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                &#8220;BCDR Plan&#8221;
                <strong />
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the business continuity and disaster recovery plan for the Services (and the people and facilities used to provide them) to
                minimise the effect of any unplanned interruption or event that would significantly impact on the ability of the Service
                Provider to perform the Services, in whole or in part, in accordance with the terms of this Agreement;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Charges&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the charges for the Services set out in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Chronomics&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                Chronomics Limited (company number <strong>11120038</strong>) whose registered office is at BizSpace Wimbledon, 8 Lombard
                Road, London, SW19 3TZ;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Chronomics Materials&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                all documents, information, software, items, data, databases and materials (including any Test Materials and/or Samples) in
                any form, to be provided by Chronomics to the Service Provider to enable the Service Provider to provide the Services;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong> &#8220;Chronomics Test Materials&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>any reagents and test materials supplied by Chronomics to the Service Provider for the purpose of the Services;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Client Premises&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the premises of any client of Chronomics at which the Service Provider may provide all or part of the Services, as set out
                in the Scope of Work;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Commencement Date&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the date of commencement of the Services set out in the Scope of Work, or if no such date is set out in the Scope of Work,
                the date of signature of the Scope of Work or the date the Service Provider registers for a Registration Account;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Confidential Information&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any and all confidential information in whatever form (whether written, oral, visual, electronic, magnetic or other media),
                however conveyed or presented, disclosed by a Party or its employees, officers, representatives, agents, contractors or
                advisers (<strong>&#8220;Personnel&#8221;</strong>
                ) to the other Party and/or its Personnel concerning the business, affairs, operations, customers, prospective customers,
                processes, budgets, pricing policies, products, strategies, opportunities, developments, trade secrets, know-how, designs,
                software, personnel and suppliers of the disclosing Party or any member of the disclosing Party&#8217;s Group together with
                all information derived by the other Party from any such information and any other information which ought reasonably be
                considered to be confidential or proprietary having regard to the nature of the information and the circumstances of the
                disclosure (whether or not it is marked &#8220;confidential&#8221;);
                <strong />
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Data Sharing and Processing Agreement&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the agreement relating to the sharing and/or processing of personal data as set out in Schedule 1;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Deliverables&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                all documents, data, reports and materials developed by the Service Provider or its agents, sub-contractors, consultants
                and/or employees in relation to the Services in any form, including without limitation the Results;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Dispute&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any dispute or claim arising out of or in connection with this Agreement or its subject matter or formation (including
                non-contractual disputes and claims);
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Documentation&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any documents or information provided by Chronomics relating to the of any use, storage or disposal of any Chronomics
                Materials;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Facility&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the laboratory or testing facility for the provision of the Services (if any) as set out in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Failed Test&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>a sample for which the Result fails to meet the quality metrics in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Good Industry Practice&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the exercise of that degree of skill, care, prudence, efficiency, foresight and timeliness as would be expected from a
                skilled and experienced company within the relevant industry or business sector, under the same or similar circumstances;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Group Company&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>in relation to a company, any member of its Group;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Group&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                in relation to a company, that company, any subsidiary or holding company from time to time of that company, and any
                subsidiary from time to time a holding company of that company;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Initial Period&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the initial period of this Agreement as set out in the Scope of Work, or if no such period is set out in a Scope of Work,
                the period of 12 months from the Commencement Date;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Insolvency Event&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>means:</p>
              <p>
                a) any petition is presented (and has not been dismissed, discharged or withdrawn after five (5) Working Days of being
                advertised), application made (and, if not made by the Party itself, has not been dismissed, discharged or withdrawn after
                fourteen (14) days), resolution proposed, notice of meeting given or other action, proceedings, procedure or step taken
                whether by a Party or any third party for, or which may (other than for the sole purpose of a scheme for a solvent
                amalgamation or solvent reconstruction of that Party) lead to:
              </p>
              <p>
                i) the suspension of payments, winding up, dissolution, administration, receivership (whether administrative or otherwise)
                or reorganisation using a voluntary arrangement, scheme of arrangement or otherwise) of a Party;
              </p>
              <p>
                ii) the appointment of a liquidator (both provisional and following a winding up), receiver (including a fixed charge
                receiver), administrative receiver, administrator, nominee, supervisor, compulsory manager or other similar officer in
                respect of a Party or any of its assets; or
              </p>
              <p>
                b) any event similar to any of those set out above in this definition occurs in relation to a Party (including in any
                jurisdiction to which it is subject);
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong> &#8220;Intellectual Property Rights&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                patents, utility models, rights to inventions, copyright and neighbouring and related rights, trade marks and service marks,
                business names and domain names, rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair
                competition, rights in designs, database rights, rights to use, and protect the confidentiality of, confidential information
                (including know-how and trade secrets), and all other intellectual property rights, in each case whether registered or
                unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to
                claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist
                now or in the future in any part of the world;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Losses&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                losses (including all direct losses, which may include loss of profit and loss of reputation), claims, damages, liabilities,
                fines, interest, penalties, costs, charges, expenses, demands and legal and other professional costs (calculated on a full
                indemnity basis);
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Order&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>each order for Services in placed accordance with clause 3;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Registration Account&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the Service Provider&#8217;s online account with Chronomics setting out the variable and parameters of the details of the
                Services including (without limitation) name and contact details of the Service Provider, the address(es) of the Facility;
                the Charges for the Services; the Service Specification and the Service Provider&#8217;s Representatives and Named Contacts.
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Representatives&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                means the persons nominated by each Party in writing to the other from time to time to represent the Parties respectively in
                the management of the relationship between the Parties and duly authorised to settle Disputes on behalf of the respective
                Parties;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Results&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                means the result of clinical tests forming part of the Services including any medical report in respect of such results;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Sample&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                a blood, saliva or other biological sample of any person provided to the Service Provider for testing in connection with the
                Services;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Service Levels&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the service levels, standards and/or performance targets applicable to the Services as set out the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Service Provider&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the provider of laboratory testing services as set out in the Scope of Work.</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Scope of Work&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                a description of the Services setting out the variables and parameters of the Services, as agreed and signed by the parties,
                or if no Scope of Work is signed, the variables and parameters set out within the Service Provider&#8217;s Registration
                Account;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong> &#8220;Service Provider Equipment&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                any equipment, including tools, systems, software, cabling or facilities, used directly or indirectly by the Service
                Provider in the supply of the Services;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong> &#8220;Service Provider Materials&#8221; </strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                all documents, information, software, items, data, databases, reagents and materials in any form, to be provided by the
                Service Provider in connection with the Services;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Service Specification&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>the specification for the Services as set out in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Services&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the laboratory testing services to be provided by the Service Provider under this Agreement, as further described in the
                Service Specification, together with any other services which Chronomics agrees to take from the Service Provider;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Specified Equipment&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>any equipment which the Service Provider must use in the provision of the Services, as set out in the Scope of Work;</p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Standards&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>
                the quality schemes or standards determined by Chronomics for the operation of the Facility or the Service Provider
                Equipment and the supply of the Services, as set out in the Scope of Work;
              </p>
            </td>
          </tr>
          <tr>
            <td width="209" valign="top">
              <p>
                <strong>&#8220;Working Day&#8221;</strong>
              </p>
            </td>
            <td width="362" valign="top">
              <p>a day (other than a Saturday, a Sunday or a public holiday in England) on which banks in London are open for business.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <div type="button"> 1.2 In this Agreement (except where the context </div>
        otherwise requires):
      </p>
      <p>
        1.2.1 reference to &#8220;a person&#8221; includes a natural person, company or unincorporated body (whether or not having separate
        legal personality);
      </p>
      <p>1.2.2 references to legislation are to that legislation as amended, extended or re-enacted from time to time;</p>
      <p>
        1.2.3 a reference to a statute or statutory provision shall include all subordinate legislation made from time to time under that
        statute or statutory provision;
      </p>
      <p>
        1.2.4 any words following the terms &#8220;including&#8221;, &#8220;include&#8221;, &#8220;in particular&#8221; or any similar terms
        shall be construed as illustrative only and shall not limit the sense of the words preceding those terms;
      </p>
      <p>1.2.5 any obligation on a Party not to do something includes an obligation not to allow that thing to be done.</p>
      <p>
        1.3 The Schedules form part of this Agreement and shall have effect as if set out in full in the body of this Agreement. Any
        reference to this Agreement includes the Schedules.
      </p>
      <p>
        <div type="button">
          1.4 The terms and conditions of this Agreement apply to the provision of Services by the Service Provider to Chronomics to the
          exclusion of any other terms that the Service Provider may seek to impose or incorporate, or which are implied by law, trade
          custom, practice or course of dealing.
        </div>
      </p>
      <p>
        <div type="button">2 Supply of Services</div>
      </p>
      <p>
        2.1 During the term of this Agreement, the Service Provider shall provide the Services to Chronomics in accordance these terms and
        conditions and the Scope of Work.
      </p>
      <p>
        <div type="button">
          2.2 Chronomics appoints the Service Provider to provide the Services on a non-exclusive basis and nothing in this Agreement shall
          prevent Chronomics from arranging for any other person to provide for Chronomics, services which are the same as or similar to the
          Services or from providing such services for itself.
        </div>
      </p>
      <p>
        2.3 The Service Provider shall meet any Service Levels for the Services specified in the Scope of Work, or that Chronomics notifies
        to the Service Provider in writing from time to time, and time is of the essence in relation to any of those Service Levels.
      </p>
      <p>
        <div type="button">2.4 In providing the Services, the Service Provider shall:</div>
      </p>
      <p>
        <div type="button">
          2.4.1 co-operate with Chronomics in all matters relating to the Services, and comply with all instructions of Chronomics;
        </div>
      </p>
      <p>
        <div type="button">
          2.4.2 perform the Services with the due care, skill and diligence in accordance with best practice in the provision of laboratory
          testing services;
        </div>
      </p>
      <p>
        2.4.3 obtain at its own expense all licences, permits and consents necessary for the provision of the Services in its country of
        operation;
      </p>
      <p>
        <div type="button">
          2.4.4 use personnel who are suitably skilled and experienced to perform tasks assigned to them in connection with the Services,
          and in sufficient number to ensure that the Service Provider&apos;s obligations are fulfilled in accordance with the Agreement;
        </div>
      </p>
      <p>
        <div type="button">
          2.4.5 use the best quality equipment, materials, standards, methods and techniques in carrying out the Services, and ensure that
          all Service Provider Equipment used in the Services will be free from defects in workmanship, installation and design;
        </div>
      </p>
      <p>
        2.4.6 comply with any instructions or directions of Chronomics in use, storage or disposal of any Chronomics Materials, as set out
        in the Documentation or notified by Chronomics to the Service Provider from time to time;
      </p>
      <p>
        <div type="button">
          2.4.7 observe all health and safety rules and regulations and any other security requirements that apply at any of Client
          Premises, as notified by Chronomics from time to time;
        </div>
      </p>
      <p>
        <div type="button">
          2.4.8 obtain any government approval required for this Agreement in the country of the Service Provider before the Commencement
          Date, and shall provide Chronomics with a certified copy of such approval on request;
        </div>
      </p>
      <p>
        2.4.9 not do or omit to do anything which may cause Chronomics to lose any licence, authority, consent or permission on which it
        relies for the purposes of conducting its business.
      </p>
      <p>
        2.5 The Service Provider shall use Chronomic&#8217;s Laboratory Information Management System for the purpose of results reporting,
        and shall comply with all Chronomics terms of use in respect of such use.
      </p>
      <p>
        <div type="button">3 DEMAND Planning and </div>
        Orders
      </p>
      <p>3.1 The Parties shall frequently by telephone (or another appropriate means of communication) to discuss:</p>
      <p>
        3.1.1 Chronomics&#8217; projected testing requirements based on a rolling weekly projection (or other projection as agreed by the
        Parties) (&#8220;Projected Testing Requirements&#8221;); and
      </p>
      <p>
        3.1.2 the Service Provider&#8217;s maximum available testing capacity for such week or other projected period (&#8220;Available
        Testing Capacity&#8221;).
      </p>
      <p>3.2 Projected Testing Requirements shall be discussed in good faith, but shall not be binding on Chronomics.</p>
      <p>
        3.3 Chronomics shall provide purchase orders (&#8220;Orders&#8221;) to the Service Provider for the Services on an as-needed basis
        subject to demand for the Services.
      </p>
      <p>
        3.4 Chronomics&#8217; may, in its discretion confirm its testing requirements up to 24 hours in advance of any Order
        (&#8220;Confirmed Testing Requirements&#8221;); and the Service Provider shall make available testing capacity to meet such
        Confirmed Testing Requirements (&#8220;Confirmed Testing Capacity&#8221;). Confirmed Testing Requirements shall be binding on the
        Service Provider.
      </p>
      <p>
        3.5 Each Order shall set out the total volume of Services ordered, on a per unit basis. Chronomics may deliver or cause to be
        delivered to the Service Provider, Samples, in single units or batches, up to the total volume set out in an Order.
      </p>
      <p>
        3.6 The Service Provider shall accept any Orders placed in accordance with this Agreement, but shall not be required to accept
        Orders for Services in excess of the Available Testing Capacity unless agreed by Service Provider (acting reasonably).
      </p>
      <p>3.7 Each Party shall cooperate with the other with regards to:</p>
      <p>3.7.1 projections and available testing capacity;</p>
      <p>3.7.2 submission and fulfilment of Orders;</p>
      <p>
        3.7.3 delivery maintenance of any Chronomics Test Materials (pursuant to clause 4) in sufficient quantities for the Service Provider
        to carry out Services covered by an Order;
      </p>
      <p>3.7.4 advising each other of anticipated bottle necks or increase or demand for the Services.</p>
      <p>
        <div type="button">4 facility, equipment AND TEST MATERIALS</div>
      </p>
      <p>
        4.1 The Service Provider shall carry out the Services at the Facility, and not at any other premises or location without the prior
        written consent of Chronomics.
      </p>
      <p>
        4.2 Chronomics may request that all or part of the Services are carried out at any Client Premises, and Chronomics shall be
        responsible for obtaining all necessary permissions and consents for the Service Provider to enter the Client Premises.
      </p>
      <p>
        4.3 Where Chronomics requires the Service Provider to use any Specified Equipment for the performance of the Services (as set out in
        the Scope of Work) the Service Provider shall, prior to the Commencement Date, at its cost, procure, install and test the Specified
        Equipment at the Facility in accordance with the specification set out in the Scope of Work. Chronomics shall provide reasonable
        assistance to the Service Provider in the procurement and installation of such Specified Equipment.
      </p>
      <p>4.4 The Service Provider shall:</p>
      <p>4.4.1 operate a clean laboratory facility in accordance with any applicable standards;</p>
      <p>
        <div type="button">
          4.4.2 provide all Service Provider Equipment and Service Provider Materials, and such other items as are required to provide the
          Services, other than any Chronomics Materials;
        </div>
      </p>
      <p>
        4.4.3 during the term of the Agreement, ensure the Service Provider Equipment is available, in good working order, and maintained
        throughout the term of this Agreement.
      </p>
      <p>
        4.5 Responsibility for reagents and materials used in the Services will be agreed by the Parties and set out in the Scope of Work.
        Where it is agreed that Chronomics Test Materials will be used in the Services, the Supplier shall not use any other reagents of
        materials to carry out the Services without the prior written consent of Chronomics.
      </p>
      <p>
        4.6 Where the Service Provider is responsible for sourcing and providing reagents and materials used in the Services as set out in
        the Scope of Work (&#8220;
        <strong>Service Provider Test Materials</strong>
        &#8221;), the Service Provider shall
      </p>
      <p>4.6.1 maintain sufficient stocks of Service Provider Test Materials to meet any Orders; and</p>
      <p>4.6.2 ensure Service Provider Test Materials are of goods quality, free from defects, and comply with Applicable Laws;</p>
      <p>
        4.7 Where Chronomics Test Materials are to be used in the Services (as set out in the Scope of Work), Chronomics shall deliver to
        the Service Provider, prior to the date of commencement of any Services, sufficient quantities of Chronomics Test Materials
        (together with applicable Documentation) as required to meet any Order for the Services.
      </p>
      <p>
        4.8 Chronomics shall arrange shipping of any Chronomics Test Materials, and may seek reimbursement of shipping costs by the Service
        Provider where agreed by the Parties and set out in the Scope of Work.
      </p>
      <p>
        4.9 The Service Provider shall comply with any Documentation relating to Chronomics Materials, and shall only make use of Chronomics
        Test Materials for the purposes authorised in this Agreement and for no other purpose.
      </p>
      <p>
        <div type="button">5 Quality control</div>
        and Audit
      </p>
      <p>
        <div type="button">5.1 The Service Provider shall ensure that the Services comply with:</div>
      </p>
      <p>5.1.1 the Specification;</p>
      <p>
        5.1.2 the Standards and any other specifications, standards and directions relating to the Services as set out in the Scope of Work
        or notified in writing by Chronomics from time to time; and
      </p>
      <p>5.1.3 all Applicable Laws relating to the provision of the Services.</p>
      <p>5.2 The Service Provider shall promptly provide Chronomics on request with copies of all:</p>
      <p>5.2.1 communications, relating to the Services with any regulatory, industry or other authority; and</p>
      <p>
        5.2.2 any audit reports, checklists, evidence of corrective actions and other information relating to the Service Provider&#8217;s
        compliance with the Standards.
      </p>
      <p>
        5.3 The Service Provider shall allow Chronomics and any regulators or auditors of or other advisers to Chronomics to access the
        Facility, Service Provider&#8217;s Personnel and relevant records as may be reasonably required in order to:
      </p>
      <p>5.3.1 fulfil any legally enforceable request by any regulatory body; or</p>
      <p>5.3.2 undertake verifications of the accuracy of the Charges or identify suspected fraud; or</p>
      <p>
        5.3.3 undertake verification that the Services are being provided and all obligations of the Service Provider are being performed in
        accordance with this Agreement.
      </p>
      <p>
        5.4 Chronomics shall use its reasonable endeavours to ensure that the conduct of each audit does not unreasonably disrupt the
        Service Provider or delay the provision of the Services by the Service Provider and that, where possible, individual audits are
        co-ordinated with each other to minimise any disruption.
      </p>
      <p>
        5.5 Subject to clause 12, the Service Provider shall provide Chronomics (and its auditors and other advisers) with all reasonable
        co-operation, access and assistance in relation to each audit.
      </p>
      <p>
        5.6 Chronomics shall provide at least five (5) Working Days&apos; notice of its intention to conduct an audit unless such audit is
        conducted in respect of a suspected fraud, in which event no notice shall be required.
      </p>
      <p>
        5.7 The Parties shall bear their own costs and expenses incurred in respect of compliance with their obligations under this
        <a href="https://uk.practicallaw.thomsonreuters.com/0-202-4551?transitionType=Default&amp;contextData=(sc.Default)&amp;firstPage=true#co_anchor_a446105">
          clause 5
        </a>
        , unless the audit identifies a material breach of this Agreement by the Service Provider, in which case the Service Provider shall
        reimburse Chronomics for all its reasonable costs incurred in the course of the audit.
      </p>
      <p>
        <div type="button">6 Remedies</div>
      </p>
      <p>
        <div type="button">
          6.1 If the Service Provider fails to perform the Services in accordance with the applicable Service Levels and/or in accordance
          with clause
        </div>
        2 or clause 5.1, Chronomics shall, without limiting or affecting other rights or remedies available to it, have one or more of the
        following rights:
      </p>
      <p>
        <div type="button">6.1.1 to invoke the remediation procedure set out in clauses</div>
        6.4 to 6.7 (inclusive);
      </p>
      <p>6.1.2 to claim liquidated damages in accordance with clause 6.2;</p>
      <p>6.1.3 to terminate the Agreement with immediate effect by giving written notice to the Service Provider;</p>
      <p>
        <div type="button">
          6.1.4 to refuse to accept any subsequent performance of the Services which the Service Provider attempts to make;
        </div>
      </p>
      <p>
        <div type="button">
          6.1.5 to recover from the Service Provider any costs incurred by Chronomics in obtaining substitute services from a third party;
        </div>
      </p>
      <p>
        <div type="button">
          6.1.6 to require a refund from the Service Provider of sums paid in advance for Services that the Service Provider has not
          provided; and
        </div>
      </p>
      <p>
        <div type="button">
          6.1.7 to claim damages for any additional costs, loss or expenses incurred by Chronomics which are in any way attributable to the
          Service Provider&apos;s failure to meet such Service Levels or its obligations under clause
        </div>
        2 or clause 5.1.
      </p>
      <p>
        <div type="button">
          6.2 If the Services are not performed in accordance with the Service Levels, Chronomics may, where set out in the Scope of Work,
          claim or deduct by way of liquidated damages an amount (set out in the Scope of Work) in respect of each Sample for which results
          are not returned within the specified response times.
        </div>
      </p>
      <p>
        <div type="button">
          6.3 The Service Provider shall, upon Chronomics&#8217; request, provide Chronomics with details of any complaints it has received
          relating to the Services together with reports on the manner in which such complaints are being, or have been, dealt with, and
          shall comply with any reasonable directions given by Chronomics in respect of such complaints.
        </div>
      </p>
      <p>
        <div type="button">
          6.4 Chronomics may deliver a remediation notice to the Service Provider which shall specify the failure and the actions the
          Service Provider needs to take with respect to remedying the failure.
        </div>
      </p>
      <p>
        <div type="button">
          6.5 Within five (5) Working Days of receipt of receipt of a notice pursuant to clause 6.6, the Service Provider shall
        </div>
        submit a plan for remediation of the failure (&#8220;
        <strong>Remediation Plan</strong>
        &#8221;), even if it disputes that it is responsible for the matters which are the subject of the remediation notice.
      </p>
      <p>
        6.6 Upon Chronomics approval of the Remediation Plan, the Service Provider shall immediately start work on the actions set out in
        the Remediation Plan.
      </p>
      <p>
        <div type="button">
          6.7 If Chronomics fails to implement or successfully complete the Remediation Plan by the required completion date, Chronomics
          may:
        </div>
      </p>
      <p>
        <div type="button">6.7.1 terminate this Agreement in accordance with clause</div>
        14.2; or
      </p>
      <p>
        <div type="button">
          6.7.2 give the Service Provider a further opportunity to resume full implementation of the Remediation Plan; or
        </div>
      </p>
      <p>
        <div type="button">
          6.7.3 refer any issues arising out of the failure to implement the Remediation Plan for resolution under clause
        </div>
        22.
      </p>
      <p>
        <div type="button">
          6.8 The Service Provider shall indemnify Chronomics against all liabilities, costs, expenses, damages and losses (including but
          not limited to any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and
          legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses) suffered or incurred
          by Chronomics arising out of or in connection with any claim made against Chronomics by a third party as a result of the Services
          Provider&#8217;s breach of its obligations under this Agreement.
        </div>
      </p>
      <p>
        <div type="button">
          6.9 Chronomics&#8217; rights and remedies under the Agreement are in addition to, and not exclusive of, any rights and remedies
          implied by statute and common law.
        </div>
      </p>
      <p>
        <div type="button">7 Cooperation</div>
      </p>
      <p>
        <div type="button">
          7.1 Prior to the Service Commencement Date, each party shall nominate a Representative. Details of the Representatives shall be
          set out in the Scope of Work.
        </div>
      </p>
      <p>
        7.2 The Service Provider&#8217;s Representative shall have appropriate qualifications, skill and experience to manage the day to day
        operation of the Services, and in addition to its obligations in this clause 7, shall act as a named point of contact identified for
        communication of Sample status and ad-hoc issues or escalations.
      </p>
      <p>
        7.3 A Party may change its Representative from time to time with another representative of similar qualifications, skill and
        experience, subject to the prior written consent of the other Party (such agreement not to be unreasonably withheld or delayed).
      </p>
      <p>
        <div type="button">
          7.4 Chronomic&#8217;s Representative and Service Provider&#8217;s Representative shall meet frequently though not less than
          monthly (via telephone, video call or other appropriate method of communication ) after the Services Commencement Date to review
          the performance of this Agreement, the achievement of the Service Levels and the provision of the Services and at any additional
          time upon the reasonable request of Chronomics.
        </div>
        All relevant information and reports relating to performance in accordance with Service Levels shall be circulated no later than
        three (3) Working Days prior to any such meeting.
      </p>
      <p>
        7.5 Prior to each meeting pursuant to clause 7.4, Chronomics Representative shall notify the Service Provider&#8217;s
        Representative, and vice versa, of any problems relating to the provision of the Services for discussion at the meeting. At the
        meeting, the Parties shall in good faith endeavour to agree a plan to address such problems. Progress in implementing the plan shall
        be included in the agenda for the next meeting.
      </p>
      <p>
        7.6 The Service Provider shall appoint one or more employees (&#8220;
        <strong>Named Contacts</strong>
        &#8221;) who shall be the only persons authorised to receive and sign for the Samples and ensure barcodes are scanned in accordance
        with the Scope of Work. The Named Contacts shall be set out in the Scope of Work. The Service Provider may from time to time appoint
        alternative Named Contacts on prior written notice to Chronomics.
      </p>
      <p>
        <div type="button">8 Change Control</div>
      </p>
      <p>
        <div type="button">
          8.1 Either Party may propose changes to the Services, but no proposed changes shall come into effect until a relevant Change Order
          has been signed by both parties. A Change Order shall be a document setting out the proposed changes and the effect that those
          changes will have on
        </div>
        the Services; the Specification; the Charges; the Service Levels; and any of the other aspects of the Scope of Work.
      </p>
      <p>8.2 If the Service Provider wishes to make a change to the Services, it shall provide a draft Change Order to Chronomics.</p>
      <p>
        <div type="button">8.3 If Chronomics wishes to make a change to the Services:</div>
      </p>
      <p>
        <div type="button">
          8.3.1 it shall notify the Service Provider and provide as much detail as the Service Provider reasonably requires of the proposed
          changes, including the timing of the proposed change; and
        </div>
      </p>
      <p>
        8.3.2 the Service Provider shall, as soon as reasonably practicable after receiving the information at clause 8.3.1, provide a draft
        Change Order to Chronomics.
      </p>
      <p>
        8.4 The Service Provider shall not be relieved of its obligations to supply the Services in accordance with the terms of this
        Agreement nor be entitled to an increase in the Charges as the result of a change in Applicable Law where the change is of a general
        legislative nature, or which generally affects or relates to the supply of services which are the same as, or similar to, the
        Services.
      </p>
      <p>8.5 If the Parties:</p>
      <p>8.5.1 agree to a Change Order, they shall sign it and that Change Order shall amend this Agreement; or</p>
      <p>
        8.5.2 are unable to agree a Change Order, in which case the Parties shall negotiate in good faith any amendments required for the
        Change Order to be agreed. Either Party may require the dispute to be dealt with in accordance with the dispute resolution procedure
        in clause 22.
      </p>
      <p>
        <div type="button">8.6 No Change Order shall come into effect until signed by both Parties</div>. Until such time as a Change Order
        is agreed in accordance with this clause 7, the Service Provider shall continue to perform this Agreement in compliance with its
        terms before such Change Order.
      </p>
      <p>
        <div type="button">9 PAYMENTS</div>
      </p>
      <p>
        <div type="button">
          9.1 The Charges for the Services shall be set out in the Scope of Work, and shall be the full and exclusive remuneration of the
          Service Provider in respect of the performance of the Services. Unless otherwise agreed in writing by Chronomics, the Charges
          shall include every cost and expense of the Service Provider directly or indirectly incurred in connection with the performance of
          the Services.
        </div>
      </p>
      <p>
        <div type="button">
          9.2 The Service Provider shall invoice Chronomics on completion of all Services under an Order. Each invoice shall include such
          supporting information required by Chronomics to verify the accuracy of the invoice, including the relevant purchase order number.
        </div>
      </p>
      <p>
        <div type="button">
          9.3 Chronomics shall pay the invoiced amounts within 30 days of the date of a correctly rendered invoice to a bank account
          nominated in writing by the Service Provider.
        </div>
        Payments shall be paid in Pounds Sterling
      </p>
      <p>
        <div type="button">
          9.4 All amounts payable by Chronomics under the Agreement are exclusive of amounts in respect of value added tax chargeable for
          the time being (&#8220;
        </div>
        VAT&#8221;). Where any taxable supply for VAT purposes is made under the Agreement by the Service Provider to Chronomics, Chronomics
        shall, on receipt of a valid VAT invoice from the Service Provider, pay to the Service Provider such additional amounts in respect
        of VAT as are chargeable on the supply of the Services at the same time as payment is due for the supply of the Services.
      </p>
      <p>
        <div type="button">
          9.5 If Chronomics fails to make a payment due to the Service Provider under the Agreement by the due date, then Chronomics shall
          pay interest on the overdue sum from the due date until payment of the overdue sum, whether before or after judgment. Interest
          under this
        </div>
        clause 9.5 will accrue each day at 4% a year above the Bank of England&apos;s base rate from time to time, but at 4% a year for any
        period when that base rate is below 0%.
      </p>
      <p>
        <div type="button">
          9.6 Chronomics may at any time, without notice to the Service Provider, set off any liability of the Service Provider to
          Chronomics against any liability of Chronomics to the Service Provider, whether either liability is present or future, liquidated
          or unliquidated, and whether or not either liability arises under the Contract.
        </div>
      </p>
      <p>
        <div type="button">10 intellectual property rights</div>
      </p>
      <p>
        <div type="button">
          10.1 All Intellectual Property Rights in the Service Provider&#8217;s Equipment or Service Provider&#8217;s Materials and in
          relation to the Services shall be owned by the Service Provider.
        </div>
      </p>
      <p>
        10.2 All Intellectual Property Rights in or arising out of or in connection with the Services (including any Deliverables) shall be
        owned by the Chronomics. The Service Provider hereby assigns to Chronomics with full title guarantee all of its right title and
        interest in the Intellectual Property Rights arising out of or in connection with the Services (including any Deliverables).
      </p>
      <p>
        <div type="button">10.3 All Chronomics Materials are the exclusive property of Chronomics</div>
      </p>
      <p>
        10.4 Chronomics grants the Service Provider a fully paid-up, non-exclusive, royalty-free non-transferable licence use any Chronomics
        Materials provided by Chronomics to the Service Provider for the term of the Agreement strictly for the purpose of providing the
        Services to Chronomics.
      </p>
      <p>
        <div type="button">
          10.5 The Service Provider shall indemnify Chronomics against all liabilities, costs, expenses, damages and losses (including but
          not limited to any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and
          legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses) suffered or incurred
          by Chronomics arising out of or in connection with
        </div>
        <div type="button">
          any claim brought against Chronomics for actual or alleged infringement of a third party&apos;s intellectual property rights
          arising out of, or in connection with, the receipt, use or supply of the Services (excluding any claim arising out of or in
          connection with the Chronomics Materials)
        </div>
        .
      </p>
      <p>
        <div type="button">11 limitations on liability</div>
      </p>
      <p>
        <div type="button">11.1 Nothing in this Agreement shall limit or exclude the liability or remedy of either Party:</div>
      </p>
      <p>11.1.1 for death or personal injury caused by its negligence, or that of its employees, agents or sub-contractors;</p>
      <p>11.1.2 for fraud or fraudulent misrepresentation;</p>
      <p>11.1.3 (subject to clause 11.2), under any indemnity in this Agreement;</p>
      <p>11.1.4 for breach of statutory duty; or</p>
      <p>11.1.5 for any act, omission or matter, liability for which may not be excluded or limited under Applicable Law.</p>
      <p>
        <div type="button">11.2 Subject to clause </div>
        11.1, the Service Provider&#8217;s liability to Chronomics under or in respect of any of the indemnities in clauses 6.8, 10.5 or
        12.8 shall be
      </p>
      <p>
        <div type="button">11.3 Subject to clause </div>
        11.1, and except as set out in clause 11.2, each Party&#8217;s total liability to the other Party arising under or in connection
        with this Agreement, whether arising in contract, tort (including negligence) or restitution, of for breach of statutory duty or
        misrepresentation, or otherwise howsoever, shall in all circumstances be limited either:
      </p>
      <p>11.3.1 as set out in the Scope of Work; or</p>
      <p>
        11.3.2 to the greater of &#163;1,000,000, and the 100% of the total Charges in the contract year in which the breaches occurred.
      </p>
      <p>
        11.4 Subject to clauses 11.1 and 11.5, and except as expressly provided to the contrary in this Agreement, neither Party will be
        liable to the other for any indirect, special or consequential loss or damage.
      </p>
      <p>
        <div type="button">
          11.5 Notwithstanding any other provision of this Agreement, Chronomics may recover as Losses, all direct loss including (without
          limitation):
        </div>
      </p>
      <p>11.5.1 any reasonable additional operational and/or administrative costs and expenses;</p>
      <p>11.5.2 any reasonable expenditure or charges incurred by Chronomics rendered unnecessary default;</p>
      <p>
        11.5.3 any reasonable additional cost of replacement services for the remainder of what would have been the term of this Agreement
        had it not been terminated; and
      </p>
      <p>11.5.4 any fines, expenses or other losses arising from a breach by the Service Provider of any Applicable Law,</p>
      <p>in each case arising from the Service Provider&apos;s breach of this Agreement.</p>
      <p>
        11.6 The Service Provider shall maintain, for the term of this Agreement and for six (6) years thereafter with a reputable insurance
        broker or provider such insurance policies as are appropriate to cover its liabilities under this Agreement.
      </p>
      <p>
        <div type="button">12 CONFIDENTIALITY</div>
      </p>
      <p>
        <div type="button">
          12.1 During the term of this Agreement and thereafter each Party, in relation to the Confidential Information of the other Party
          that it receives or has received, shall, save as expressly permitted by this clause
        </div>
        12:
      </p>
      <p>12.1.1 keep the Confidential Information confidential and not make or release copies of it;</p>
      <p>
        12.1.2 not disclose the Confidential Information to any other person other than with the prior written consent of the other Party;
      </p>
      <p>
        12.1.3 not use the Confidential Information for any purpose except the performance of its obligations and/or the exercise of its
        rights under this Agreement; and
      </p>
      <p>12.1.4 not use any Confidential Information so as to procure any commercial advantage over the other Party.</p>
      <p>
        <div type="button">
          12.2 During the term of this Agreement a Party may disclose the Confidential Information of the other Party to its Personnel but
          only to the extent reasonably necessary to perform properly its obligations and/or exercise its rights under this Agreement and
          provided that:
        </div>
      </p>
      <p>
        12.2.1 before disclosure of any of the Confidential Information to any of the Personnel it procures that each such Related Person is
        aware of the obligations in clause 12.1 and that each such Related Person undertakes to observe the obligations in clause 12.1; and
      </p>
      <p>
        <div type="button">12.2.2 it shall be responsible for any failure by any of the Personnel to observe the obligations in clause</div>
        12.1 as though it were a breach of clause 12.1 committed by that Party.
      </p>
      <p>
        <div type="button">12.3 The obligations contained in clauses </div>
        12.1 and 12.2 shall not apply to any Confidential Information which:
      </p>
      <p>
        <div type="button">
          12.3.1 is or becomes generally available to the public other than through breach of this Agreement by the Party receiving the
          Confidential Information (the <strong>&#8220;Receiving Party&#8221;</strong>
          );
        </div>
      </p>
      <p>
        12.3.2 can be shown by the Receiving Party to the reasonable satisfaction of the other Party to have been known by, or available (on
        a basis that did not require it to be maintained as confidential) to, the Receiving Party before it was disclosed by the other
        Party;
      </p>
      <p>
        12.3.3 subsequently comes lawfully into the possession of the Receiving Party from a person who has not derived it directly or
        indirectly from the other Party, who is rightfully in possession of such Confidential Information and who is not bound as to its use
        or disclosure by an obligation of confidence or secrecy to the other Party; or
      </p>
      <p>12.3.4 the Parties agree in writing is not confidential or may be disclosed.</p>
      <p>12.4 A Party may disclose Confidential Information to the extent such disclosure is required by:</p>
      <p>
        12.4.1 any applicable law or by any governmental or administrative authority or by an order of any court or other authority of
        competent jurisdiction; or
      </p>
      <p>
        12.4.2 regulations of any recognised investment, stock or securities exchange on which that Party&#8217;s securities are traded or
        by the Panel on Takeovers and Mergers or other regulatory organisation,
      </p>
      <p>provided that, to the extent legally permitted, that Party gives the other Party as much notice of such disclosure as possible.</p>
      <p>
        12.5 Each Party acknowledges and agrees that if the Confidential Information is used or disclosed other than in accordance with the
        provisions of this Agreement, damages alone would not be an adequate remedy and the Party whose Confidential Information has been
        used or disclosed shall, without proof of special damage, be entitled to an injunction or other equitable relief for any threatened
        or actual breach of the provisions of this clause 12 in addition, and without prejudice, to any damages or other remedy to which it
        may be entitled.
      </p>
      <p>
        12.6 Each Party reserves all rights in its Confidential Information. No rights or obligations in respect of a Party&#8217;s
        Confidential Information are granted to the other Party or to be implied from this Agreement.
      </p>
      <p>
        12.7 Neither Party makes any express or implied warranty or representation concerning its Confidential Information including as to
        accuracy, completeness or otherwise whatsoever.
      </p>
      <p>
        <div type="button">
          12.8 Each Party (the &#8220;
          <strong>Indemnifying Party</strong>
          &#8221;) shall indemnify and keep indemnified the other Party in full and hold the other harmless on demand from and against any
          and all Losses suffered or incurred by the other Party arising out of or in connection with any breach of this clause
        </div>
        12 by the Indemnifying Party and from any failure of a Related Person as described in clause 12.2.2.
      </p>
      <p>
        12.9 The provisions of this clause 12 shall apply during the term of this Agreement and indefinitely following its expiry or
        termination.
      </p>
      <p>
        <div type="button">13 data protection</div>
      </p>
      <p>13.1 The parties shall comply with their data protection obligations as set out in the Data Sharing and Processing Agreement.</p>
      <p>
        <div type="button">14 Duration and termination</div>
      </p>
      <p>
        <div type="button">
          14.1 This Agreement shall commence on the Commencement Date and shall continue (unless terminated earlier in accordance with this
          Agreement):
        </div>
      </p>
      <p>14.1.1 for the Initial Term; and</p>
      <p>
        14.1.2 after the end of the Initial Term unless and until terminated by either Party giving to the other not less than ninety (90)
        days&#8217; notice, to expire at any time on or after the end of the Initial Term.
      </p>
      <p>
        <div type="button">
          14.2 Either Party may, without liability to the other, terminate this Agreement with immediate effect on giving notice to the
          other Party:
        </div>
      </p>
      <p>14.2.1 if the other Party suffers an Insolvency Event;</p>
      <p>
        14.2.2 If the other Party suspends or ceases to carry on (or threatens to suspend or cease to carry on) all or a material part of
        its business;
      </p>
      <p>14.2.3 if the other Party commits a material breach of this Agreement which is incapable of remedy;</p>
      <p>
        14.2.4 if the other Party commits a material breach of this Agreement which is capable of remedy and fails to remedy that breach
        within thirty (30) days of being notified in writing of the breach; or
      </p>
      <p>
        14.2.5 if the other Party repeatedly commits breaches of this Agreement such as to reasonably justify the opinion that its conduct
        is inconsistent with it having the intention or ability to give effect to the terms of this Agreement.
      </p>
      <p>
        14.3 Chronomics may, without liability to the Service Provider, terminate this Agreement with immediate effect on giving notice to
        the Service Provider if there is a change of control of the Service Provider (within the meaning of section 1124 of the Corporation
        Tax Act 2010).
      </p>
      <p>
        <div type="button">15 CONSEQUENCES OF TERMINATION</div>
      </p>
      <p>
        <div type="button">
          15.1 On expiry or termination of this Agreement for any reason and subject to any express provisions set out elsewhere in this
          Agreement:
        </div>
      </p>
      <p>15.1.1 all outstanding and undisputed sums payable by Chronomics to the Service Provider shall become due and payable;</p>
      <p>15.1.2 all rights and licences granted pursuant to this Agreement shall cease;</p>
      <p>
        15.1.3 each Party shall return to the other Party all documents and materials containing the other&#8217;s Confidential Information
        and shall erase all the other&#8217;s Confidential Information from its computer and other software or media storage systems,
        provided that a Receiving Party may retain materials containing Confidential Information to the extent required by Applicable Law or
        any applicable governmental, administrative or regulatory authority or by an order of any court or other authority of competent
        jurisdiction.
      </p>
      <p>
        <div type="button">15.1.4 the Service Provider shall </div>
        on request, immediately deliver to Chronomics, all Chronomics Materials (to the extent such Chronomics Materials are unused at the
        date of termination or expiry).
      </p>
      <p>
        <div type="button">
          15.2 Termination of the Contract shall not affect any of the rights, remedies, obligations or liabilities of the parties that have
          accrued up to the date of termination, including the right to claim damages in respect of any breach of the Contract which existed
          at or before the date of termination
        </div>
        .
      </p>
      <p>
        15.3 Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after
        termination of the Agreement shall remain in full force and effect
      </p>
      <p>
        <div type="button">16 DATA SECURITY</div>
        and disposal of samples
      </p>
      <p>16.1 The Service Provider shall:</p>
      <p>
        16.1.1 preserve so far as possible the security of any Results and prevent any loss, destruction, disclosure, theft, manipulation or
        interception of any Results;
      </p>
      <p>
        16.1.2 comply with any and all requirements of Chronomics as communicated to the Service Provider from time to time in relation to
        any or all of the transfer of Results, IT systems, data integrity or data formats;
      </p>
      <p>
        16.1.3 ensure that its IT systems are fit for the purpose of securing Results in accordance with Good Industry Practice and this
        Agreement and are regularly maintained and, if necessary, upgraded to ensure this.
      </p>
      <p>
        16.2 Following processing of any Samples and the transfer of Results in respect of such Samples, the Service Provider shall retain
        or dispose of Samples in accordance with the Scope of Work.
      </p>
      <p>17 Business Continuity and Disaster Recovery</p>
      <p>
        <div type="button">17.1 The Service Provider shall:</div>
      </p>
      <p>
        17.1.1 implement a BCDR Plan, and provide Chronomics with a copy of such BCDR Plan, within 20 Working Days following the
        Commencement Date, and upon request from time to time;
      </p>
      <p>17.1.2 ensure that it is able to implement the provisions of the BCDR Plan at any time in accordance with its terms</p>
      <p>
        <div type="button">17.1.3 test the BCDR Plan on a regular basis, and in any event at least once every six (6) months; and</div>
      </p>
      <p>17.1.4 review and (where necessary) update the BCDR Plan at least once every twelve (12) months.</p>
      <p>
        <div type="button">17.2 Following each test pursuant to clause </div>
        17.1.3, the Service Provider shall:
      </p>
      <p>
        <div type="button">17.2.1 send to Chronomics a written report summarising the results of the test; and</div>
      </p>
      <p>
        <div type="button">
          17.2.2 promptly implement any actions or remedial measures which Chronomics considers to be necessary as a result of those tests.
        </div>
      </p>
      <p>
        <div type="button">
          17.3 The Service Provider shall implement the Business Continuity Plan if the Services are not available for more than twenty four
          (24) continuous hours
        </div>
        , or otherwise where notified by Chronomics to do so.
      </p>
      <p>
        <div type="button">18 FORCE MAJEURE</div>
      </p>
      <p>
        <div type="button">
          18.1 Neither Party shall be in breach of this agreement nor liable for delay in performing, or failure to perform, any of its
          obligations under this Agreement if such delay or failure result from events, circumstances or causes beyond its reasonable
          control. In such circumstances the affected Party shall be entitled to a reasonable extension of the time for performing such
          obligations.
        </div>
      </p>
      <p>18.2 The affected Party shall:</p>
      <p>
        18.2.1 as soon as reasonably practicable after the start of the Force Majeure Event, notify the other party of the Force Majeure
        Event, the date on which it started, its likely or potential duration, and the effect of the Force Majeure Event on its ability to
        perform any of its obligations under the Agreement; and
      </p>
      <p>18.2.2 use all reasonable endeavours to mitigate the effect of the Force Majeure Event on the performance of its obligations.</p>
      <p>
        18.3 If the period of delay or non-performance continues for thirty (30) days, the Party not affected may terminate this Agreement
        by giving five (5) Working Days&#8217; written notice to the affected Party.
      </p>
      <p>
        <div type="button">
          18.4 A Party shall not be entitled to relief from liability or from being in breach of this Agreement under clause
        </div>
        18.1 if the effect that the Force Majeure Event has on the performance of the obligation:
      </p>
      <p>18.4.1 is attributable to its wilful act or neglect;</p>
      <p>
        18.4.2 could have been reasonably foreseen by the Party and the Party ought reasonably to have taken precautions to avoid or
        mitigate the effect but did not; or
      </p>
      <p>
        18.4.3 in the case of the Service Provider, is one which is covered by the BCDR Plan and the Service Provider has failed to invoke
        the BCDR Plan.
      </p>
      <p>
        <div type="button">19 Non-solicitation and employment</div>
      </p>
      <p>
        <div type="button">
          19.1 Neither Party shall, without the prior written consent of the other, at any time from the Commencement Date to the expiry of
          12 months after the completion of the Services, solicit or entice away from the Other party or employ or attempt to employ any
          person who is, or has been, engaged as an employee of the other Party.
        </div>
      </p>
      <p>
        19.2 In order to protect the legitimate business interests of Chronomics and any member o its Group, the Service Provider covenants
        with Chronomics, for itself and as agent for each of its Group Companies:
      </p>
      <p>
        19.2.1 that it shall not (and shall procure that no Group Company shall) be involved with the provision of services similar to the
        Services to any Restricted Customer.
      </p>
      <p>
        19.2.2 that it shall not (and shall procure that no Group Company shall) (except with the prior written consent of Chronomics)
        solicit or entice away (or attempt to solicit or entice away) from Chronomics or any of its Group Companies the business or custom
        of any Restricted Customer.
      </p>
      <p>
        19.3 The Service Provider shall be bound by the covenants set out in
        <a href="https://uk.practicallaw.thomsonreuters.com/Document/Ib8bf32a1192211e798dc8b09b4f043e0/View/FullText.html?navigationPath=Search%2Fv1%2Fresults%2Fnavigation%2Fi0ad740160000017477bc856c16658fa2%3FNav%3DKNOWHOW_UK%26fragmentIdentifier%3DIb8bf32a1192211e798dc8b09b4f043e0%26parentRank%3D0%26startIndex%3D1%26contextData%3D%2528sc.Search%2529%26transitionType%3DSearchItem&amp;listSource=Search&amp;listPageSource=c3bcc2aa57a455bcf4ecf57e133f1b32&amp;list=KNOWHOW_UK&amp;rank=1&amp;sessionScopeId=9560731fdac5c6348726a63a23f6b36061068006d00295d9eae58361a3b87442&amp;originationContext=Search%20Result&amp;transitionType=SearchItem&amp;contextData=(sc.Search)&amp;comp=pluk&amp;view=hidealldraftingnotes#co_anchor_a887767">
          clause
        </a>
        6.3 during the term of this agreement, and for a period of 12 months after termination of this agreement.
      </p>
      <p>
        19.4 For the purposes of
        <a href="https://uk.practicallaw.thomsonreuters.com/Document/Ib8bf32a1192211e798dc8b09b4f043e0/View/FullText.html?navigationPath=Search%2Fv1%2Fresults%2Fnavigation%2Fi0ad740160000017477bc856c16658fa2%3FNav%3DKNOWHOW_UK%26fragmentIdentifier%3DIb8bf32a1192211e798dc8b09b4f043e0%26parentRank%3D0%26startIndex%3D1%26contextData%3D%2528sc.Search%2529%26transitionType%3DSearchItem&amp;listSource=Search&amp;listPageSource=c3bcc2aa57a455bcf4ecf57e133f1b32&amp;list=KNOWHOW_UK&amp;rank=1&amp;sessionScopeId=9560731fdac5c6348726a63a23f6b36061068006d00295d9eae58361a3b87442&amp;originationContext=Search%20Result&amp;transitionType=SearchItem&amp;contextData=(sc.Search)&amp;comp=pluk&amp;view=hidealldraftingnotes#co_anchor_a643265">
          clause
        </a>
        6.3, a Restricted Customer shall mean any firm, company or person who is or has been at any time during the immediately preceding 12
        months a customer or prospective customer of, or in the habit of dealing with, Chronomics or any of its Group Companies.
      </p>
      <p>
        <div type="button">20 ASSIGNMENT</div>
      </p>
      <p>
        20.1 This Agreement is personal to the Parties and neither Party shall assign, transfer, mortgage, charge, sub-contract, or deal in
        any other manner with any or all of its rights and/or obligations under this Agreement without the prior written consent of the
        other Party (such consent not to be unreasonably withheld or delayed).
      </p>
      <p>20.2 Each Party confirms it is acting on its own behalf and not for the benefit of any other person.</p>
      <p>
        <div type="button">21 SUB-CONTRACTING</div>
      </p>
      <p>
        21.1 The Service Provider shall not enter into any sub-contract in respect of the performance of any of its obligations under this
        Agreement without Chronomics&#8217; prior written consent (such consent not to be unreasonably withheld or delayed).
      </p>
      <p>
        21.2 To enable a proper assessment of whether to consent to the sub-contract, the Service Provider shall provide Chronomics with
        such information as Chronomics may reasonably require about the proposed sub-contractor and the effect of the proposed sub-contract
        on the performance of this Agreement.
      </p>
      <p>21.3 Chronomics confirms it has consented to the sub-contracts and sub-contractors listed in the Scope of Work.</p>
      <p>
        21.4 Chronomics may, by written notice, require the Service Provider to terminate a sub-contract with effect from a date stated in
        the notice, where the right to terminate this Agreement under clause 14.2 has arisen and is attributable to acts or omissions under
        that sub-contract.
      </p>
      <p>
        21.5 If the Service Provider enters into a sub-contract in respect of the performance of any of its obligations under this
        Agreement:
      </p>
      <p>
        21.5.1 the Service Provider shall not be relieved from its obligations to Chronomics in respect of the subject matter of the
        sub-contract and shall remain responsible to Chronomics for the performance of its obligations under this Agreement; and
      </p>
      <p>
        21.5.2 any obligation in this Agreement on the Service Provider to do or refrain from doing any act or thing shall be construed as
        including an obligation to procure that its sub-contractors do or refrain from doing such act or thing.
      </p>
      <p>
        <div type="button">22 ALTERNATIVE DISPUTE RESOLUTION</div>
      </p>
      <p>
        <div type="button">
          22.1 The Parties shall procure that the Representatives shall meet and use their reasonable endeavours to resolve any Dispute. If
          the Dispute is not resolved between the Representatives within twenty (20) Working Days of receipt of a written request from
          either Party (&#8220;
          <strong>Dispute Notice</strong>
          &#8221;), the Dispute shall be referred to the Chief Executive Officer of each of the Parties within five (5) Working Days of the
          date of receipt of the Dispute Notice to attempt to settle the Dispute in good faith.
        </div>
      </p>
      <p>
        <div type="button">
          22.2 If the Dispute is not resolved within a further period of ten (10) from the date of receipt of the Dispute Notice, either
          Party may by written notice to the other elect to attempt to settle the Dispute by mediation in accordance with
        </div>
        the Model Mediation Procedure (<strong>&#8220;MMP&#8221;</strong>) of the Centre of Dispute Resolution (
        <strong>&#8220;CEDR&#8221;</strong>)
      </p>
      <p>
        <div type="button">
          22.3 Save in relation to injunctive relief, neither Party may commence any court proceedings or arbitration in relation to any
          Dispute until it has attempted to settle the Dispute in accordance with clause
        </div>
        22.1 and, where a Mediation Notice has been served, until it has attempted to settle the Dispute by mediation in accordance with
        clause 22.2 and either the mediation has terminated or the other Party has failed to participate in the mediation.
      </p>
      <p>
        <div type="button">22.4 Subject to clause </div>
        22.3, if and to the extent that the Parties do not resolve the Dispute or any related issue in accordance with clauses 22.1 to 22.2
        inclusive, either Party may commence court proceedings in respect of such unresolved Dispute or issue.
      </p>
      <p>
        <div type="button">23 N</div>
        <div type="button">OTICES</div>
      </p>
      <p>23.1 Any notice to a party under or in connection with this Agreement shall be in writing and shall be:</p>
      <p>
        <div type="button">
          23.1.1 delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a
          company) or its principal place of business (in any other case); or
        </div>
      </p>
      <p>
        23.1.2 sent by email to the address specified in the Schedule, provided the notice shall be confirmed as soon as practicable in
        accordance with clause 23.1.1
      </p>
      <p>23.2 Any notice shall be deemed to have been received:</p>
      <p>23.2.1 if delivered by hand, at the time the notice is left at the proper address;</p>
      <p>
        23.2.2 if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the second Working Day after
        posting; or
      </p>
      <p>
        <div type="button">
          23.2.3 if sent by email, at the time of transmission, or, if this time falls outside business hours in the place of receipt, when
          business hours resume. In this clause
        </div>
        23.2.3 business hours means 9.00am to 5.00pm Monday to Friday on a Working Day.
      </p>
      <p>
        23.3 This clause does not apply to the service of any proceedings or other documents in any legal action or any arbitration or other
        method of dispute resolution other than clause 22.
      </p>
      <p>
        <div type="button">24 General</div>
      </p>
      <p style="text-align: left">
        24.1 Nothing in this Agreement is intended to, or shall be deemed to, establish any partnership or joint venture between the
        Parties, constitute either Party the agent of the other Party, or authorise either Party to make or enter into any commitments for
        or on behalf of on its own behalf and not for the benefit of any other person. <strong />
      </p>
      <p>
        24.2 Neither Party shall make, or permit any person to make, any public announcement concerning this Agreement without the prior
        written consent of the other Party [(such consent not to be unreasonably withheld or delayed)] except as required by law, any
        governmental or regulatory authority (including any relevant stock, securities or investment exchange), any court or other authority
        of competent jurisdiction.
      </p>
      <p>
        24.3 This Agreement, including its Schedules sets out the entire agreement between the parties relating to its subject matter and
        supersedes all prior oral or written agreements, arrangements, or understandings between them relating to such subject matter. The
        Parties acknowledge that they are not relying on any representation, agreement, term, or condition that is not set out in this
        Agreement.
      </p>
      <p>24.4 No variation of this Agreement shall be valid unless it is in writing and signed by or on behalf of each of the Parties.</p>
      <p style="text-align: left">
        24.5 Neither Party may assign or sub-contract any of its rights or o
        <div type="button">
          bligations under this Agreement without the written consent of the other Party or as otherwise set out in this Agreement.{' '}
          <strong />
        </div>
      </p>
      <p>
        <div type="button">
          24.6 If any provision or part-provision of this Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed
          deleted, but that shall not affect the validity and enforceability of the rest of this Agreement. If any provision or
          part-provision of this Agreement is deemed deleted the parties shall negotiate in good faith to agree a replacement provision
          that, to the greatest extent possible, achieves the intended commercial result of the original provision
        </div>
      </p>
      <p>
        24.7 No term of this Agreement shall be enforceable under the Contracts (Rights of Third Parties) Act 1999 by any person who is not
        a Party to this Agreement.
      </p>
      <p style="text-align: left">
        24.8 No failure or delay by a Party to exercise any right or remedy provided under this Agreement or by law shall constitute a
        waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or
        remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other
        right or remedy.
        <strong />
      </p>
      <p>
        <div type="button">25 GOVERNING LAW AND JURISDICTION</div>
      </p>
      <p style="text-align: left">
        25.1 This Agreement and any Dispute shall be governed by and construed in accordance with the law of England and each Party
        irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any Dispute.
      </p>
      <p>IN WITNESS of which the parties have caused this Agreement to be duly executed the day and year first above written.</p>
      <br style="clear:both" />
      <p style="text-align: left">
        <div type="button">
          <strong />
        </div>
      </p>
      <p>
        <div type="button">Annex 1</div>
      </p>
      <p>
        <div type="button">Data Sharing and Processing Agreement</div>
      </p>
      <p>
        <strong />
      </p>
      <p>
        <strong>INTRODUCTION</strong>
      </p>
      <p>
        <strong />
      </p>
      <p>i. In the course of providing Services to Chronomics pursuant to the Agreement, the Service Provider may process Personal Data:</p>
      <p>
        &#167; independently of Chronomics, acting as a Controller (see
        <strong>
          <u>Part A</u>
        </strong>{' '}
        below); and/or
      </p>
      <p>
        &#167; on behalf of Chronomics, acting as a Processor (see
        <strong>
          <u>Part B</u>
        </strong>{' '}
        below).
      </p>
      <p>
        ii. Annex 1 sets out those aspects of the Services in respect of which the Service Provider will be considered a Controller or
        Processor of Personal Data.
      </p>
      <p>iii. The Parties agree to comply with the following provisions, each acting reasonably and in good faith.</p>
      <p style="text-align: left">
        <strong />
      </p>
      <p style="text-align: left">
        <strong>TERMS</strong>
      </p>
      <h1>
        <strong>1. </strong>
        <strong>Definitions</strong>
      </h1>
      <h2>1.1 For the purposes of this entire Agreement, the following terms have the meanings set out below:</h2>
      <h3>
        1.1.1 &#8216;
        <strong>Chronomics Personal Data</strong>
        &#8217; means all Personal Data which is owned, controlled or processed by Chronomics and which is provided by or on behalf of
        Chronomics to the Service Provider, or which comes into the possession of the Service Provider or is collected or processed by the
        Service Provider as a result of or in connection with the supply of the Services.
      </h3>
      <h3>
        1.1.2 &#8216;
        <strong>Data Protection Laws</strong>
        &#8217; means all applicable laws, rules, regulations, and governmental requirements relating in any way to the privacy,
        confidentiality, security, integrity and protection of Personal Data, including without limitation, the General Data Protection
        Regulation (EU) 2016/679 ( <strong>&#8216;GDPR&#8217;</strong>
        ), as amended, reenacted or superseded from time to time, any national implementing legislation, and the Data Protection Act 2018
        (as amended).
      </h3>
      <h3>
        1.1.3 &#8216;
        <strong>Data Subject Request</strong>
        &#8217;
        <strong> </strong>
        means any request from a Data Subject relating to his/her right under Data Protection Laws (in each case where applicable): (i) of
        access to Personal Data; (ii) to rectify Personal Data; (iii) to restrict processing of Personal Data; (iv) to erase Personal Data;
        (v) to port Personal Data; (vi) to object to Personal Data processing; or (vii) not to be subject to automated individual decision
        making.
      </h3>

      <h3>
        1.1.4 &#8216;
        <strong>Subprocessor</strong>
        &#8217; means any further Processor engaged by the Service Provider or any member of the Alere or Abbott group of companies, when
        the Service Provider acts as a Processor on Chronomic&#8217;s behalf.
      </h3>

      <h2>
        1.2 The terms &#8216;
        <strong>Controller</strong>
        &#8217;, &#8216;
        <strong>Data Subject</strong>
        &#8217;, &#8216;
        <strong>Personal Data</strong>
        &#8217;, &#8216;
        <strong>Personal Data Breach</strong>
        &#8217;, &#8216; <strong>Processing&#8217;</strong>, &#8216;
        <strong>Processor</strong>
        &#8217;, and &#8216;
        <strong>Supervisory Authority</strong>
        &#8217; have the meanings given to them in Data Protection Laws.
      </h2>
      <h2>1.3 Any words or terms used in this Agreement but not defined have the meanings given to them in Data Protection Laws.</h2>
      <u>
        <br style="clear:both" />
      </u>
      <h1>
        <strong>
          <u>PART A:</u> SERVICE PROVIDER AS CONTROLLER
        </strong>
      </h1>
      <h1>
        <strong>2. </strong>
        <strong>Applicability of Part A</strong>
      </h1>
      <h2>
        2.1 This Part A applies only when each Party acts as a Controller of Shared Personal Data pursuant to the Agreement, as set out at
        Part 1 of Annex 1.
      </h2>
      <h2>
        2.2 This Part A sets out the framework for the sharing of the Shared Personal Data between the Parties as independent Controllers.
      </h2>
      <h2>
        2.3 Each Party acknowledges that Chronomics will disclose, to the Service Provider, Shared Personal Data collected by Chronomics for
        the Agreed Purposes.
      </h2>

      <h1>
        <strong>3. </strong>
        <strong>Part A definitions</strong>
      </h1>
      <h2>For the purposes of this Part A, the following terms have the meanings set out below:</h2>
      <h2>
        3.1 &#8216;
        <strong>Agreed Purposes</strong>
        &#8217; means the Processing of the Shared Personal Data necessary for the provision of the Services to Chronomics, as set out at
        Part 1 of Annex 1.
      </h2>
      <h2>
        3.2 &#8216;
        <strong>Data Discloser</strong>
        &#8217; means the Party that discloses Shared Personal Data to the other Party.
      </h2>
      <h2>
        3.3 &#8216;
        <strong>Permitted Recipients</strong>
        &#8217; means selected employees of the Service Provider and any third parties engaged by the Service Provider in connection with
        the Services provided.
      </h2>
      <h2>
        3.4 &#8216;
        <strong>Shared Personal Data&#8217;</strong> means the Personal Data shared or exchanged between the Parties to use for the Agreed
        Purposes and to the extent required to perform or receive the Services, including but not limited to [name, date of birth, date and
        type of test, test result, and any additional relevant information necessary for the Services]; and
      </h2>
      <h1>
        <strong>4. </strong>
        <strong>Data Sharing Obligations</strong>
      </h1>

      <h2>4.1 Each Party will:</h2>
      <h3>4.1.1 be responsible for its compliance obligations imposed on a Controller by the Data Protection Laws; and</h3>
      <h3>4.1.2 it will not do anything that causes or is likely to cause the other Party to be in breach of the Data Protection Laws.</h3>
      <h3>
        4.1.3 ensure that it has all necessary notices, permissions and consents in place as required in accordance with the Data Protection
        Laws to enable lawful disclosure of Shared Personal Data to the other Party (and in the case of the Service Provider, to the
        Permitted Recipients) for the Agreed Purposes.
      </h3>
      <h2>4.2 The Service Provider will:</h2>
      <h3>
        4.2.1 give all information required in accordance with the Data Protection Laws to any Data Subject whose Personal Data may be
        processed under Part A of this Agreement;
      </h3>
      <h3>4.2.2 process Shared Personal Data only for the Agreed Purposes, and at all times in accordance with Data Protection Laws;</h3>
      <h3>4.2.3 not disclose or allow access to Shared Personal Data to anyone other than the Permitted Recipients;</h3>
      <h3>
        4.2.4 not retain or Process the Shared Personal Data for longer than is necessary and in accordance with legal retention obligations
        to carry out the Agreed Purposes;
      </h3>
      <h3>
        4.2.5 it will not disclose or transfer the Shared Personal Data to a third party located outside of the European Economic Area
        unless it complies with the provisions of all Data Protection Laws;
      </h3>
      <h3>
        <div type="button">
          4.2.6 ensure that all Permitted Recipients are subject to written contractual obligations concerning Shared Personal Data
          (including obligations of confidentiality) which are no less onerous than those imposed by this Agreement; and
        </div>
      </h3>
      <h3>
        4.2.7 implement appropriate technical and organisational measures to protect the Shared Personal Data against Personal Data
        Breaches.
      </h3>
      <h1>
        <strong>5. </strong>
        <strong>Assistance</strong>
      </h1>
      <h2>
        5.1 The Service Provider will assist Chronomics in complying with all applicable requirements of Data Protection Laws in relation to
        the Shared Personal Data.
      </h2>
      <h2>5.2 In particular, the Service Provider will:</h2>
      <h3>5.2.1 consult with Chronomics about any notices given to Data Subjects in relation to Shared Personal Data;</h3>
      <h3>
        5.2.2 promptly inform Chronomics about the receipt of any Data Subject Request or any correspondence from Supervisory Authorities
        which relates to the Processing of the Shared Personal Data under Part A of this Agreement or to either Party&#8217;s compliance
        with the Data Protection Laws;
      </h3>
      <h3>5.2.3 respond to Data Subject Requests it receives promptly and in accordance with Data Protection Laws;</h3>
      <h3>
        5.2.4 provide Chronomics with reasonable assistance in complying with any Data Subject Request relating to the Shared Personal Data;
      </h3>
      <h3>
        5.2.5 assist Chronomics in ensuring compliance with its obligations under Data Protection Laws with respect to security, Personal
        Data Breach notifications, data protection impact assessments and consultations with Supervisory Authorities;
      </h3>
      <h3>
        5.2.6 notify Chronomics without undue delay on becoming aware of any suspected, potential or actual breaches of Data Protection Laws
        (including the loss of the Shared Personal Data and any breaches of security which may compromise the security of the Shared
        Personal Data);
      </h3>
      <h3>
        5.2.7 at the written direction of Chronomics, securely dispose or return Shared Personal Data and copies thereof to Chronomics on
        termination of the Occupational Health Contract unless required otherwise by applicable laws; and
      </h3>
      <h3>
        5.2.8 maintain complete and accurate records and information relating to its Processing of the Shared Personal Data to demonstrate
        its compliance with this Part A.
      </h3>
      <h2>
        5.3 Each Party will provide the other Party with contact details of at least one employee as a point of contact for all issues
        arising out of Data Protection Laws.
      </h2>
      <p>
        <strong>
          <u>PART B:</u> SERVICE PROVIDER AS PROCESSOR
        </strong>
      </p>
      <p>
        <strong />
      </p>
      <h1>
        <strong>6. </strong>
        <strong>Applicability of Part B</strong>
      </h1>
      <h2>
        6.1 This Part B applies only when the Service Provider acts as a Processor of Chronomics Personal Data, as specified at Part 2 of
        Annex 1<em>(Service Provider as Controller or Processor)</em>.
      </h2>
      <h2>
        6.2 The Parties acknowledge that Chronomics is a Controller and the Service Provider is a Processor with regard to the Processing of
        Chronomics Personal Data to which this Part B applies.
      </h2>
      <p>
        <strong />
      </p>
      <h1>
        <strong>7. </strong>
        <strong>Description of the Processing </strong>
      </h1>
      <h2>
        7.1 <u>Subject matter</u>
      </h2>
      <p>
        Chronomics instructs the Service Provider to carry out Services on its behalf under Agreement. This includes obtaining, accessing
        and/or collecting Personal Data required for the Service Provider carry out testing of samples on behalf of Chronomics.
      </p>
      <h2>
        7.2 <u>Duration</u>
      </h2>
      <p>The term of the Agreement.</p>
      <h2>
        7.3 <u>Nature and purpose of Processing</u>
      </h2>
      <h2>
        7.4 Chronomics instructs the Service Provider to carry out Services in relation to test subjects for [diagnostic purposes] or [for
        the identification of risk of chronic illness in test subjects and
        <br />
        to predict the success of certain treatments]
      </h2>
      <h2>
        7.5 <u>Types of Personal Data</u>
      </h2>
      <p>Name, date of birth, test results.</p>
      <h2>
        7.6 <u>Categories of Data Subjects</u>
      </h2>
      <p>Employees and contractors of Chronomic&#8217;s customers</p>
      <p>Individuals who submit to Chronomics a biological sample for testing.</p>
      <h2>
        7.7 <u>Retention</u>
      </h2>
      <p>
        Records containing Chronomics Personal Data will be retained by the Service Provider for seven years (the recommended retention
        period for personnel records) but the Samples themselves will only be stored in accordance with the directions and periods set out
        in the Scope of Work.
      </p>
      <h1>
        <strong>8. </strong>
        <strong>General Obligations of Processor</strong>
      </h1>
      <h2>
        8.1 The Service Provider will process Chronomics Personal Data only on documented instructions of Chronomics as set out in the
        Agreement, and not for any other purpose, or in any other manner, unless specifically instructed by Chronomics in writing to do so,
        or as required by Data Protection Laws. In the event that the Service Provider is required by Data Protection Laws to process
        Chronomics Personal Data for any other purpose or in any other manner, the Service Provider will inform Chronomics of that legal
        requirement before Processing, unless that law prohibits such information on important grounds of public interest.
      </h2>

      <h2>
        8.2 The Service Provider will ensure that its employees, agents and/or Subprocessors authorised to process or access Chronomics
        Personal Data are informed of the confidential nature of the Chronomics Personal Data and are subject to an enforceable obligation
        of confidentiality.
      </h2>

      <h1>
        <strong>9. </strong>
        <strong>Technical and Organisational Measures</strong>
      </h1>
      <h2>
        9.1 The Service Provider will implement appropriate technical and organisational measures to safeguard Chronomics Personal Data and
        to protect Chronomics Personal Data against accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or
        access to personal data Processed by it.
      </h2>
      <h1>
        <strong>10. </strong>
        <strong>Return or Deletion of Personal Data</strong>
      </h1>
      <h2>
        10.1 At the choice of Chronomics, the Service Provider will securely delete or return all Chronomics Personal Data (and copies
        thereof) to Chronomics on termination of the provision of Toxicology Services, except to the extent any applicable law requires
        storage of Chronomics Personal Data by the Service Provider.
      </h2>
      <h1>
        <strong>11. </strong>
        <strong>Subprocessing</strong>
      </h1>
      <h2>
        11.1 The Service Provider may only be permitted to appoint a Subprocessor to Process the Chronomics Personal Data, with the prior
        written consent of Chronomics, and if:
      </h2>
      <h3>
        11.1.1 the Service Provider enters into a written contract with the Subprocessor on the same terms as those set out in this
        Agreement;
      </h3>
      <h3>
        11.1.2 the Service Provider informs Chronomics of any intended changes concerning the addition or replacement of any Subprocessor
        and gives Chronomics the opportunity to object to such changes within 30 days of the Service Provider supplying Chronomics with full
        details of such Subprocessor; and
      </h3>
      <h3>
        11.1.3 where a Subprocessor fails to fulfil its data protection obligations, the Service Provider will remain fully liable to
        Chronomics for the performance of the Subprocessor&#8217;s obligations.
      </h3>
      <h2>
        11.2 Chronomics agrees to the use of current Service Provider Sub-processors named as permitted sub-contractors in the Scope of
        Work.
      </h2>
      <h1>
        <strong>12. </strong>
        <strong>Data Subject Requests</strong>
      </h1>
      <h2>
        12.1 Taking into account the nature of the Processing, the Service Provider will provide reasonable assistance to Chronomics by
        appropriate technical and organisational measures, insofar as this is possible, in respect of any Data Subject Requests relating to
        Chronomics Personal Data.
      </h2>

      <h1>
        <strong>13. </strong>
        <strong>Personal Data Breach</strong>
      </h1>
      <h2>
        13.1 The Service Provider will notify Chronomics without undue delay (and in any event within 2 calendar days) after becoming aware
        of an actual or potential Personal Data Breach, such notices shall include full and complete details relating to such Personal Data
        Breach, and shall provide reasonable assistance to Chronomics in connection with its third-party notification and communication
        obligations under Data Protection Laws, taking into account the nature of the Processing and the information available to the
        Service Provider.
      </h2>
      <h1>
        <strong>14. </strong>
        <strong>Data Protection Impact Assessments</strong>
      </h1>
      <h2>
        14.1 The Service Provider will provide reasonable assistance to Chronomics in connection with its obligations under the GDPR to
        carry out a data protection impact assessment (and, where required by Data Protection Laws, consulting with the relevant Supervisory
        Authority in respect of any such data protection impact assessment).
      </h2>
      <h1>
        <strong>15. </strong>
        <strong>Audit</strong>
      </h1>
      <h2>
        <div type="button">
          15.1 The Service Provider will ensure that Chronomics is able to verify compliance with the obligations of the Service Provider in
          accordance with GDPR Article 28. The Service Provider undertakes to give Chronomics the necessary information to demonstrate
          compliance on request and, in particular, to demonstrate the execution of the technical and organisational measures.
        </div>
      </h2>
      <h2>
        <div type="button">15.2 Evidence of such measures under clause </div>
        15.1 could include any of the following:
      </h2>
      <h3>15.2.1 the Service Provider&#8217;s compliance with approved codes of conduct pursuant to GDPR Article 40;</h3>
      <h3>
        15.2.2 the Service Provider&#8217;s certification to an approved certification procedure in accordance with GDPR Article 42; or
      </h3>
      <h3>
        15.2.3 current auditors&#8217; certificates or reports or excerpts from reports provided by independent bodies (for example,
        auditor, data protection officer, IT security department, data privacy auditor, quality auditor).
      </h3>
      <h2>
        <div type="button">
          15.3 Chronomics may, upon advance reasonable notice and no more than once per annum, carry out audits or inspections of the
          Service Provider (or to have them carried out by an auditor mandated by Chronomics) in order for Chronomics to demonstrate the
          Service Provider&#8217;s compliance with its obligations under this Agreement. The Service Provider shall permit and provide the
          necessary information and access for such audits or inspections.
        </div>
      </h2>
      <h2>
        15.4 The limit to the frequency of audits or inspections in clause 15.3 shall not apply if Chronomics reasonably believes that an
        actual or potential Personal Data Breach has occurred or is occurring, or the Service Provider is in breach of any of its
        obligations under this Agreement.
      </h2>
      <p>
        <strong>
          <u>PART C:</u> GENERAL PROVISIONS
        </strong>
      </p>
      <h1>
        <strong>16. </strong>
        <strong>Ex-EEA Data Transfers</strong>
      </h1>
      <h2>
        16.1 It may sometimes be necessary for affiliates and third party partners of the Service Provider to process Chronomics Personal
        Data and/or Shared Personal Data to enable the Service Provider to provide Services to Chronomics. Such affiliates may be located
        outside of the European Economic Area (&#8216;
        <strong>EEA</strong>
        &#8217;), in countries that do not offer an equivalent standard of protection for Personal Data (&#8216;
        <strong>Third Countries</strong>
        &#8217;).
      </h2>
      <h2>
        16.2 The Service Provider shall not transfer, or other directly or indirectly disclose, Chronomics Personal Data and/or Shared
        Personal Data from the EEA to Third Countries without prior written consent of Chronomics unless:
      </h2>
      <h3>
        16.2.1 the Service Provider is permitted to transfer the Chronomics Personal Data and/or Shared Personal Data to Third Countries in
        accordance with the requirements of the Data Protection Law, including by any approved international framework, contractual clauses,
        certification mechanism or any other mechanism permitted pursuant to the Data Protection Laws; or
      </h3>
      <h3>
        16.2.2 the Service Provider is required to transfer the Chronomics Personal Data and/or Shared Personal Data by the laws of the
        member states of the EU or EU law (and shall inform Chronomics of that legal requirement before the transfer, unless those laws
        prevent it doing so);
      </h3>
      <h1>
        <div type="button">
          <strong>17. </strong>
          <strong>Indemnity</strong>
        </div>
        <strong> </strong>
      </h1>
      <p>
        The Service Provider shall indemnify Chronomics against: (i) all direct losses, claims, damages, liabilities, fines, interest,
        penalties, costs, charges, expenses, demands and legal and other professional costs directly arising out of or in connection with
        any breach by the Service Provider of this Agreement; and (ii) all amounts paid or payable by Chronomics to a third party which it
        was legally obliged to pay and which would not have been paid or payable if the Service Provider&#8217;s breach of this Agreement
        had not occurred.
      </p>
      <h1>
        <strong>18. </strong>
        <strong>Compliance with Law</strong>
      </h1>
      <p>Each Party will, at all times, comply with its respective obligations under Data Protection Laws.</p>
      <h1>
        <strong>19. </strong>
        <strong>Governing Law and Jurisdictions</strong>
      </h1>
      <h2>19.1 This Agreement will adopt the governing law provision in the relevant act.</h2>
      <h2>
        19.2 This Data Sharing and Processing Schedule will be governed by the laws of England and Wales and any disputes will be subject to
        the exclusive jurisdiction of the courts of England and Wales.
      </h2>
      <h1>
        <strong>20. </strong>
        <strong>Interpretation</strong>
      </h1>

      <h2>20.1 Where this Schedule and the Agreement conflict:</h2>
      <h3>20.1.1 this Agreement takes precedence in relation to Processing of Personal Data; and</h3>
      <h3>20.1.2 all other provisions of the Agreement will continue to apply.</h3>
    </div>
    <br style="clear:both" />
    <p>ANNEX 1 &#8211; Service Provider as Controller or Processor</p>
    <p>
      <strong>Part 1 </strong>
    </p>
    <p>
      The Service Provider is a Controller in respect of any aspect of the Services it provides to Chronomics in accordance with the
      services schedule in the Agreement, which concerns:
    </p>
    <p>&#167; reporting of results to a government or regulatory authority;</p>
    <p>&#167; retention of samples and/or results in accordance with its own retention policies and procedures</p>
    <p>
      <strong>Part 2 </strong>
    </p>
    <p>
      The Service Provider is a Processor in respect of any aspect of the Services
      <strong>
        <u> </u>
      </strong>{' '}
      it provides to Chronomics in accordance with the services schedule in the Agreement, other than as set out in Part 1 of this Annex 1.
    </p>
  </div>
);

export default TermsAndConditions;
