import { TableBody, TableContainer, TableHead, TableRow as MuiTableRow } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as CustomMuiStyle from '../../app/admin/covid-results/shared.style';
import { TableRow } from './TableRow';

const Table = ({ rows = [], columns, alignColumn = 'left' }) => {
  const classes = CustomMuiStyle.useStyles();
  return (
    <TableContainer className={classes.container}>
      <CustomMuiStyle.StyledTable stickyHeader aria-label="sticky table">
        <TableHead>
          <MuiTableRow>
            {columns.map((column) => (
              <CustomMuiStyle.StyledTableCellHeader key={_.snakeCase(column)} align={alignColumn}>
                {column}
              </CustomMuiStyle.StyledTableCellHeader>
            ))}
          </MuiTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              rowClassNames={row?.classNames}
              customDivider={row?.shouldDisplayDivider}
              row={row}
              columns={columns}
              alignColumn={alignColumn}
              key={index}
              index={index}
            />
          ))}
        </TableBody>
      </CustomMuiStyle.StyledTable>
    </TableContainer>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.shape({ container: PropTypes.string.isRequired }),
  alignColumn: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rowClassNames: PropTypes.string,
};

export { Table };
