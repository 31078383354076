import { Button } from '@mui/material';
import { Link } from '@reach/router';
import styled from 'styled-components';

export const StyledButton = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledVerticalSpace = styled.div`
  height: ${({ height }) => height}px;
`;

export const StyledFormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : 'center')};
  flex-wrap: wrap;
  & > button {
    width: 100%;
    margin-bottom: 8px;
  }

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;

    & > button {
      width: auto;
      margin-bottom: initial;
    }
  }
`;

export const StyledBox = styled.div`
  text-align: ${({ align }) => align || 'left'};
  display: flex;
  flex-direction: row;
  ${({ colSpan }) => (colSpan ? `grid-column: ${colSpan};` : '')};

  &:not(:last-child) {
    padding: 10px 0;
  }

  &:last-child {
    padding-top: 10px;
  }
`;

export const StyledNavButton = styled(Button)`
  &&:disabled {
    cursor: not-allowed;
    text-transform: none;
  }
`;

export const StyledNavBoxedButton = styled(Button)`
  && {
    color: #555;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    transition: 250ms;
    text-decoration: none;

    &:hover {
      border-color: #5b7bff;
      box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.075);
    }
  }
`;

export const StyledNavButtonLink = styled(Link)`
  color: #555;
  padding: 29px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  transition: 250ms;
  text-decoration: none;

  &:hover {
    border-color: #5b7bff;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.075);
  }
`;

export const StyledGroupTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, ${({ repeat }) => repeat || '1fr'});
  grid-gap: ${({ gap }) => gap || 16}px;
`;
