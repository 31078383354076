import { useDispatch } from 'react-redux';
import { requestOrdersExport } from '../../api/phpApiRequests';
import { DropDownDeprecated } from '../covid-results/covid-19.dropdown';
import { Export } from '../../../icons';
import * as CustomMuiStyle from '../covid-results/shared.style';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../store/snackbar/snackbar.action';
import { DATA_EXPORT_SUCCESS_MESSAGE, DATA_EXPORT_FAILURE_MESSAGE } from '../../../data/constants';

export const ORDER_HISTORY_EXPORT_LABEL = 'All orders';

const handleOrderExportClick = async (dispatch) => {
  try {
    const response = await requestOrdersExport();
    if (response && response.status === 200) {
      showSuccessSnackbar(DATA_EXPORT_SUCCESS_MESSAGE)(dispatch);
    } else {
      showErrorSnackbar(DATA_EXPORT_FAILURE_MESSAGE)(dispatch);
    }
  } catch {
    showErrorSnackbar(DATA_EXPORT_FAILURE_MESSAGE)(dispatch);
  }
};

export const OrderHistoryExport = () => {
  const dispatch = useDispatch();

  return (
    <DropDownDeprecated
      variant="transparent"
      label="EXPORT"
      icon={Export}
      itemList={[
        {
          label: ORDER_HISTORY_EXPORT_LABEL,
          onClick: () => handleOrderExportClick(dispatch),
        },
      ]}
    >
      <CustomMuiStyle.StyledDropDownTitle>Export table data to a CSV file</CustomMuiStyle.StyledDropDownTitle>
    </DropDownDeprecated>
  );
};
