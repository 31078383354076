import { Link } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';

export const INACTIVE_LABEL = 'View';
export const ACTIVE_LABEL = 'Hide';

export const OrderHistoryTrackingLink = ({ onClick, isActive, orderNumber }) => {
  const handleOnClick = () => {
    if (onClick && !isActive) {
      onClick(orderNumber);
    }

    if (onClick && isActive) {
      onClick(null);
    }
  };

  return (
    <div role="button" className="text-primary cursor-pointer" onClick={handleOnClick}>
      <Link className="text-primary">{isActive ? ACTIVE_LABEL : INACTIVE_LABEL}</Link>
      {!isActive && <KeyboardArrowDownIcon className="text-sm" />}
      {isActive && <KeyboardArrowUpIcon className="text-sm" />}
    </div>
  );
};

OrderHistoryTrackingLink.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool.isRequired,
  orderNumber: PropTypes.string.isRequired,
};
