import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';

export const CollapsibleFields = ({ className, defaultExpanded, header, children }) => (
  <Accordion className={className} defaultExpanded={defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{header}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

CollapsibleFields.propTypes = {
  className: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  header: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

CollapsibleFields.defaultProps = {
  className: '',
  defaultExpanded: true,
};
