export const props = {
  variant: 'outlined',
};

export const overrides = {
  root: {
    padding: '16px 24px',
    borderRadius: 2,
  },
};
