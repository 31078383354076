import { Router } from '@reach/router';
import Default from './Default';
import Add from './Add';
import Edit from './Edit';

export default function UsersIndex() {
  return (
    <Router path="/" className="h-full">
      <Default path="/" />
      <Add path="/add" />
      <Edit path="/edit/:userId" />
    </Router>
  );
}
