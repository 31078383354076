const ERROR_TYPES = {
  required: 'required',
  maxLength: 'maxLength',
  minLength: 'minLength',
  max: 'max',
  min: 'min',
  pattern: 'pattern',
};

/**
 * @typedef {Object} FieldError
 * @property {string} type - type of error, one of the ERROR_TYPES below
 * @property {HTMLInputElement} ref - ref to the invalid input
 * @property {string} [message] - error message, specified for the field
 */

/**
 * @param {FieldError} fieldError - error from https://react-hook-form.com/api#formState
 * @param {number} [failedConstraint] - constraint that was not met, ie, minLength value
 * @returns {string} errorMessage
 */
export function formErrorMessages(fieldError, failedConstraint) {
  if (!fieldError) return ' ';
  if (fieldError.message) return fieldError.message;

  switch (fieldError.type) {
    case ERROR_TYPES.required:
      return 'Field is required.';
    case ERROR_TYPES.minLength:
      return failedConstraint ? `Should be at least ${failedConstraint} characters long.` : 'Too short.';
    case ERROR_TYPES.maxLength:
      return failedConstraint ? `Should be up to ${failedConstraint} characters long.` : 'Too long';
    case ERROR_TYPES.pattern:
      return 'Invalid format.';
    default:
      return 'Invalid.';
  }
}
