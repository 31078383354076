import * as React from 'react';

function SvgEye(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <g transform="translate(0 5)" stroke="currentColor" fill="none" fillRule="evenodd">
        <path d="M12 .5c4.194 0 7.977 2.362 11.382 7-3.406 4.638-7.188 7-11.382 7S4.023 12.138.618 7.5C4.024 2.862 7.806.5 12 .5z" />
        <circle cx={12} cy={7.5} r={4} />
      </g>
    </svg>
  );
}

export default SvgEye;
