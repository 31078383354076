import moment from 'moment/moment';
import { ActionsColumnCell } from '../../../../components/paginated-data-grid/ActionsColumnCell';

const dateFormatter = (date) => date.format('D MMM yy');

export const getDefaultColumns = ({ actions }) => [
  {
    field: 'keyName',
    headerName: 'API key name',
    flex: 1.5,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    flex: 1,
    sortable: false,
    valueFormatter: (params) => dateFormatter(/** @type {Date} */ moment(params.value)),
  },
  {
    field: 'id',
    headerName: 'Action',
    flex: 1,
    sortable: false,
    renderCell: (params) => <ActionsColumnCell id={params.value} actions={actions} />,
  },
];
