import { UPDATE_REGISTER } from './register.action';

export const initialRegisterState = {
  lab: {
    labName: null,
    labAddress1: null,
    labAddress2: null,
    city: null,
    postCode: null,
    country: null,
  },
  partner: {
    partnerName: null,
    partnerAddress1: null,
    partnerAddress2: null,
    city: null,
    postCode: null,
    country: null,
  },
  yourDetails: {
    firstName: null,
    lastName: null,
    countryCode: null,
    phoneNumber: null,
    email: null,
    password: null,
    agree: false,
  },
  acceptTerms: {
    agree: false,
  },
};

export const registerReducer = (state = initialRegisterState, action = '') => {
  switch (action.type) {
    case UPDATE_REGISTER:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
