import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconAction } from '../../../../../components/paginated-data-grid/actions';
import { Dialog } from '../../../../../components/dialog/Dialog';
import { Trash } from '../../../../../icons';
import { revokeApiKey } from '../../../../api/partnerApiKeyServiceApiRequests';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../../store/snackbar/snackbar.action';

export const SUCCESS = 'The API key has been revoked successfully!';
export const ERROR = 'Something has gone wrong, please retry later.';

const onClickPrimary = async ({ id, partnerId, partnerType, onCloseDialog, onClickPrimaryCallback, setIsProcessingAction, dispatch }) => {
  setIsProcessingAction(true);
  try {
    const response = await revokeApiKey(id, partnerId, partnerType);

    if (!response) {
      showErrorSnackbar(ERROR)(dispatch);
      return;
    }

    showSuccessSnackbar(SUCCESS)(dispatch);
  } catch (error) {
    showErrorSnackbar(ERROR)(dispatch);
    return;
  }

  if (onClickPrimaryCallback) onClickPrimaryCallback(id);
  onCloseDialog();
  setIsProcessingAction(false);
};

export const DeleteIconAction = ({ id, partnerId, partnerType, onClickPrimaryCallback }) => {
  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState(false);
  const [isProcessingAction, setIsProcessingAction] = useState(false);

  const onOpenDialog = () => setShowDialog(true);
  const onCloseDialog = () => setShowDialog(false);

  return (
    <IconAction name="Delete User" icon={Trash} onClick={onOpenDialog} title="Revoke api key">
      <Dialog
        title="Are you sure you want to revoke it?"
        message=" "
        primaryButtonLabel="Yes"
        secondaryButtonLabel="No"
        open={showDialog}
        onClose={onCloseDialog}
        onClickPrimary={async () =>
          onClickPrimary({ id, setIsProcessingAction, onCloseDialog, partnerId, partnerType, onClickPrimaryCallback, dispatch })
        }
        onClickSecondary={onCloseDialog}
        isPrimaryActionClickable={!isProcessingAction}
        isSecondaryActionClickable={!isProcessingAction}
      />
    </IconAction>
  );
};

DeleteIconAction.propTypes = {
  id: PropTypes.string.isRequired,
  partnerId: PropTypes.number.isRequired,
  partnerType: PropTypes.string.isRequired,
  onClickPrimaryCallback: PropTypes.func,
};
