export const getOverrides = () => ({
  root: {
    borderRadius: 2,
  },
});

export const props = {
  variant: 'contained',
  color: 'primary',
  disableElevation: true,
};
