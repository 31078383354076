import * as React from 'react';

function SvgBagArrowsCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <path d="M4.5 5.5h7v6h-7zM10.5 5.5a2.5 2.5 0 00-5 0" />
        <path d="M3.815 14.225A7.5 7.5 0 0014.37 4.04m-2.374-2.388A7.5 7.5 0 001.697 12.067" strokeLinecap="round" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M12.777.568v1.96h-2M3.301 15.506v-1.96h2" />
      </g>
    </svg>
  );
}

export default SvgBagArrowsCircle;
