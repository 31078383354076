import crypto from 'crypto';
import { USER_FORM_LABELS } from '../CreateOrderUserFormGroup';
import { DATE_FIELDS } from '../../../../../components/datefield/DateField';

export const US_COUNTRY_CODE = 'US';

const generatePartnerUserId = (email, partnerId) =>
  // Use a hash of the email address + partner ID to ensure it is the same every time,
  // unique across all partners and meets the format required for the PUID field
  crypto.createHash('sha256').update(`${email}-${partnerId}`).digest('hex');

/**
 * Get a formatted request object based on provided data.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.formData - The form data containing product and user information.
 * @param {boolean} options.isUSOrder - A boolean indicating if it's a US order.
 * @returns {Object} A formatted request object with the following properties:
 *
 *   - productCode: The product code from the provided form data.
 *   - quantity: The quantity from the provided form data.
 *   - delivery: An object containing delivery information with the following properties:
 *     - firstName: The first name from the delivery information in the form data.
 *     - lastName: The last name from the delivery information in the form data.
 *     - address: An object containing address details with properties:
 *        - addressLine1
 *        - addressLine2
 *        - city
 *        - postcode
 *        - countryCode
 *        - county or state depending on the isUSOrder flag.
 *     - email: The email address from the delivery information in the form data.
 *     - mobileNumber: The mobile number from the delivery information in the form data.
 *   - user: An object containing user information with properties:
 *      - firstName
 *      - lastName
 *      - email
 *      - mobileNumber
 *      - gender
 *      - dateOfBirth
 *      - partnerUserId.
 *   - physicianDetails (only if isUSOrder): An object containing physician details with properties:
 *      - physicianId
 *      - npi
 *      - firstName
 *      - lastName
 *      - physicianAddress
 *      - and organizationAddress
 */

export const getFormattedRequest = ({ formData, isUSOrder, partnerId, hasUserDetails }) => {
  const dateOfBirth = formData.user?.dateOfBirth || {};
  return {
    productCode: formData.product?.productCode,
    quantity: formData.quantity,
    delivery: {
      firstName: formData.delivery[USER_FORM_LABELS.FIRST_NAME],
      lastName: formData.delivery[USER_FORM_LABELS.LAST_NAME],
      address: {
        addressLine1: formData.delivery?.address1,
        ...(formData.delivery?.address2 && { addressLine2: formData.delivery?.address2 }),
        city: formData.delivery?.city,
        postcode: formData.delivery?.postcode,
        countryCode: formData.delivery?.countryCode?.country_code,
        ...(!isUSOrder && formData?.delivery?.county && { county: formData.delivery?.county }),
        ...(isUSOrder && formData.delivery?.state && { state: formData.delivery?.state }),
      },
      email: formData.delivery[USER_FORM_LABELS.EMAIL],
      mobileNumber: formData.delivery[USER_FORM_LABELS.PHONE_NUMBER],
    },
    ...(hasUserDetails
      ? {
          user: {
            ...(formData.user?.title && { title: formData.user?.title }),
            firstName: formData.user[USER_FORM_LABELS.FIRST_NAME],
            lastName: formData.user[USER_FORM_LABELS.LAST_NAME],
            email: formData.user[USER_FORM_LABELS.EMAIL],
            mobileNumber: formData.user[USER_FORM_LABELS.PHONE_NUMBER],
            gender: formData.user?.gender,
            dateOfBirth: [
              dateOfBirth[DATE_FIELDS.YEAR.property] && dateOfBirth[DATE_FIELDS.YEAR.property].toString(),
              dateOfBirth[DATE_FIELDS.MONTH.property] && dateOfBirth[DATE_FIELDS.MONTH.property].toString().padStart(2, '0'),
              dateOfBirth[DATE_FIELDS.DAY.property] && dateOfBirth[DATE_FIELDS.DAY.property].toString()?.padStart(2, '0'),
            ]
              .filter(Boolean)
              .join('-'),
            // Generate PUID so user doesn't need to enter one manually
            partnerUserId: generatePartnerUserId(formData.user[USER_FORM_LABELS.EMAIL], partnerId),
          },
        }
      : {}),
    ...(isUSOrder && {
      physicianDetails: {
        // TODO: Physician details should be preset elsewhere in partner dashboard
        physicianId: 'xxx',
        npi: 'xxx',
        firstName: 'xxx',
        lastName: 'xxx',
        physicianAddress: {
          country: 'xxx',
          state: 'xxx',
          city: 'xxx',
          zipCode: 'xxx',
          addressLine1: 'xxx',
          addressLine2: 'xxx',
        },
        organizationAddress: {
          country: 'xxx',
          state: 'xxx',
          city: 'xxx',
          zipCode: 'xxx',
          addressLine1: 'xxx',
          addressLine2: 'xxx',
        },
      },
    }),
  };
};
