import * as React from 'react';

function SvgLabDashboardImage(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 498 301"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <defs>
        <linearGradient x1="100%" y1="50%" x2="0%" y2="37.763%" id="lab-dashboard-image_svg__a">
          <stop stopColor="#835BFF" offset="0%" />
          <stop stopColor="#5B7BFF" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="url(#lab-dashboard-image_svg__a)" d="M658 1h433v301H658z" transform="translate(-593 -1)" />
        <g transform="matrix(-1 0 0 1 433 7)">
          <ellipse fill="#5B7BFF" fillRule="nonzero" cx={366} cy={258.318} rx={60.4} ry={13.62} />
          <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={371.6} cy={254.312} rx={60.4} ry={13.62} />
          <ellipse fill="#5B7BFF" fillRule="nonzero" cx={273.8} cy={122.318} rx={12} ry={12.018} />
          <ellipse fill="#5B7BFF" fillRule="nonzero" cx={322.2} cy={122.318} rx={12} ry={12.018} />
          <ellipse fill="#5B7BFF" fillRule="nonzero" cx={370.6} cy={122.318} rx={12} ry={12.018} />
          <g transform="translate(53.8 31.573)">
            <g transform="translate(0 1.395)">
              <ellipse fill="#F86B75" fillRule="nonzero" cx={4.8} cy={7.229} rx={1.2} ry={1.202} />
              <ellipse fill="#FAD375" fillRule="nonzero" cx={8.4} cy={7.229} rx={1.2} ry={1.202} />
              <ellipse fill="#8BCC55" fillRule="nonzero" cx={12} cy={7.229} rx={1.2} ry={1.202} />
              <path fill="#5B7BFF" fillRule="nonzero" d="M26.6 25.856H109v25.638H26.6z" />
              <path stroke="#3F3D56" strokeWidth={0.5} d="M0 3.223h145.6V89.75H0zM0 9.776h145.6" />
              <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={5.2} cy={6.428} rx={1.2} ry={1.202} />
              <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={8.8} cy={6.428} rx={1.2} ry={1.202} />
              <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={12.4} cy={6.428} rx={1.2} ry={1.202} />
              <path stroke="#3F3D56" strokeWidth={0.5} d="M31.6 20.849H114v25.638H31.6z" />
              <path
                fill="#F2F2F2"
                fillRule="nonzero"
                d="M16.2 62.71h110.4v3.315H16.2zM16.2 70.667h110.4v3.315H16.2zM16.2 78.624h110.4v3.315H16.2z"
              />
              <path
                stroke="#3F3D56"
                strokeWidth={0.5}
                d="M17.4 61.108h110.4v3.315H17.4zM17.4 69.065h110.4v3.315H17.4zM17.4 77.021h110.4v3.315H17.4z"
              />
              <path fill="#79758C" fillRule="nonzero" d="M76.84 3.354L71.51 6.31l5.682-2.007z" />
              <ellipse
                fill="#3F3D56"
                fillRule="nonzero"
                transform="rotate(-17.994 78.315 3.41)"
                cx={78.315}
                cy={3.41}
                rx={1.487}
                ry={2.999}
              />
              <path fill="#3F3D56" fillRule="nonzero" d="M78.658 1.678L82.192.401l.751 2.083-3.42 2.314z" />
            </g>
            <ellipse
              fill="#3F3D56"
              fillRule="nonzero"
              transform="rotate(-17.994 82.95 2.518)"
              cx={82.949}
              cy={2.518}
              rx={1.122}
              ry={2.117}
            />
          </g>
          <g transform="translate(114.6 148.946)">
            <ellipse fill="#F86B75" fillRule="nonzero" cx={4.8} cy={9.425} rx={1.2} ry={1.202} />
            <ellipse fill="#FAD375" fillRule="nonzero" cx={8.4} cy={9.425} rx={1.2} ry={1.202} />
            <ellipse fill="#8BCC55" fillRule="nonzero" cx={12} cy={9.425} rx={1.2} ry={1.202} />
            <path fill="#5B7BFF" fillRule="nonzero" d="M26.6 28.053H109v25.638H26.6z" />
            <path stroke="#3F3D56" strokeWidth={0.5} d="M145.6 23.045v68.902H0V5.419h136.8M0 11.972h136.8" />
            <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={5.2} cy={8.624} rx={1.2} ry={1.202} />
            <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={8.8} cy={8.624} rx={1.2} ry={1.202} />
            <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={12.4} cy={8.624} rx={1.2} ry={1.202} />
            <path stroke="#3F3D56" strokeWidth={0.5} d="M31.6 23.045H114v25.638H31.6z" />
            <path
              fill="#F2F2F2"
              fillRule="nonzero"
              d="M5.4 63.705h25.2v21.632H5.4zM52 63.905h25.2v21.632H52zM98.6 64.106h25.2v21.632H98.6z"
            />
            <path
              stroke="#3F3D56"
              strokeWidth={0.5}
              d="M9 59.699h25.2v21.632H9zM55.6 59.899h25.2v21.632H55.6zM102.2 60.1h25.2v21.632h-25.2z"
            />
            <path fill="#79758C" fillRule="nonzero" d="M79.24 4.749L73.91 7.705l5.682-2.007z" />
            <ellipse
              fill="#3F3D56"
              fillRule="nonzero"
              transform="rotate(-17.994 80.715 4.805)"
              cx={80.715}
              cy={4.805}
              rx={1.487}
              ry={2.999}
            />
            <ellipse
              fill="#3F3D56"
              fillRule="nonzero"
              transform="rotate(-17.994 85.35 2.518)"
              cx={85.349}
              cy={2.518}
              rx={1.122}
              ry={2.117}
            />
            <path fill="#3F3D56" fillRule="nonzero" d="M81.058 3.073l3.534-1.277.751 2.083-3.42 2.314z" />
          </g>
          <ellipse fill="#F86B75" fillRule="nonzero" cx={256.2} cy={89.069} rx={1.2} ry={1.202} />
          <ellipse fill="#FAD375" fillRule="nonzero" cx={259.8} cy={89.069} rx={1.2} ry={1.202} />
          <ellipse fill="#8BCC55" fillRule="nonzero" cx={263.4} cy={89.069} rx={1.2} ry={1.202} />
          <path stroke="#3F3D56" strokeWidth={0.5} d="M251.4 85.063H397v86.527H251.4zM251.4 91.616H397" />
          <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={256.6} cy={88.268} rx={1.2} ry={1.202} />
          <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={260.2} cy={88.268} rx={1.2} ry={1.202} />
          <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={263.8} cy={88.268} rx={1.2} ry={1.202} />
          <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={275.8} cy={120.315} rx={12} ry={12.018} />
          <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={324.2} cy={120.315} rx={12} ry={12.018} />
          <ellipse stroke="#3F3D56" strokeWidth={0.5} cx={372.6} cy={120.315} rx={12} ry={12.018} />
          <path
            fill="#F2F2F2"
            fillRule="nonzero"
            d="M257.6 142.547h32.8v3.205h-32.8zM306.4 142.547h32.8v3.205h-32.8zM355.2 142.547H388v3.205h-32.8z"
          />
          <path
            stroke="#3F3D56"
            strokeWidth={0.5}
            d="M259.2 141.346H292v3.205h-32.8zM308 141.346h32.8v3.205H308zM356.8 141.346h32.8v3.205h-32.8z"
          />
          <path fill="#79758C" fillRule="nonzero" d="M339.514 82.318l-11.224 6.228 11.97-4.229z" />
          <ellipse
            fill="#3F3D56"
            fillRule="nonzero"
            transform="rotate(-17.994 342.624 82.436)"
            cx={342.624}
            cy={82.436}
            rx={3.132}
            ry={6.317}
          />
          <ellipse
            fill="#3F3D56"
            fillRule="nonzero"
            transform="rotate(-17.994 352.386 77.619)"
            cx={352.386}
            cy={77.619}
            rx={2.363}
            ry={4.461}
          />
          <path fill="#3F3D56" fillRule="nonzero" d="M343.346 78.788l7.445-2.692 1.582 4.39-7.205 4.874z" />
          <path
            d="M342.634 94.927l2-4.406s4.8-12.82.4-10.416c-4.4 2.404-6 8.813-6 8.813l-1.2 4.006 4.8 2.003zM397.434 161.425s-6.8 13.22-8.8 13.62c-2 .4-2-12.018-2-12.018s4-3.605 5.6-3.205c1.6.401 5.2 1.603 5.2 1.603z"
            fill="#A0616A"
            fillRule="nonzero"
          />
          <path
            d="M387.434 244.747s3.6 2.003 2 6.41c-1.6 4.406-2.4 3.204-3.2 6.81-.8 3.605-8 3.204-8.4 1.602-.4-1.602-1.2-8.012.8-10.415 2-2.404 8.8-4.407 8.8-4.407zM351.834 244.747s-3.6 2.003-2 6.41c1.6 4.406 2.4 3.204 3.2 6.81.8 3.605 8 3.204 8.4 1.602.4-1.602 1.2-8.012-.8-10.415-2-2.404-8.8-4.407-8.8-4.407z"
            fill="#55536E"
            fillRule="nonzero"
          />
          <path
            d="M385.434 164.63s3.2 2.002 4 14.42c.8 12.419-.4 26.44-.8 29.644-.4 3.205.4 8.012 1.2 10.416.8 2.403-2 20.83-2 20.83a9.4 9.4 0 010 8.012c-2 4.407-8 8.813-9.6 4.407a10.88 10.88 0 00-1.2-4.407c-1.2-2.403.8-12.018.8-12.018s-1.6-15.222-1.2-19.228c.4-4.006-4.8-27.64-5.6-31.646-.8-4.006-.4-7.211-.8-6.01-.4 1.203-7.6 34.451-7.6 34.451s.8 21.232.4 24.036c-.4 2.804 1.6 8.412.4 10.415-1.2 2.003-3.6 9.214-8 4.807-4.4-4.406-3.6-6.009-4-8.012-.4-2.003 0-4.406 0-7.61 0-3.206-3.2-21.232-1.6-24.036 1.6-2.804.8-6.41.4-12.819-.4-6.41-.8-37.255 3.2-42.863 4-5.608 32 7.21 32 7.21z"
            fill="#3F3D56"
            fillRule="nonzero"
          />
          <ellipse fill="#A0616A" fillRule="nonzero" cx={375.634} cy={79.905} rx={9.6} ry={9.614} />
          <path
            d="M381.834 83.71s-.4 14.422 1.6 16.825c2 2.404-17.2-2.003-17.2-2.003s4.4-9.213 1.6-15.222c-2.8-6.009 14 .4 14 .4z"
            fill="#A0616A"
            fillRule="nonzero"
          />
          <path
            d="M385.434 98.933s-.8-3.205-5.6-4.006c-4.8-.801-10.476-1.602-12.238 0-1.762 1.602-2.562 3.605-3.762 3.605h-5.6l-8.8 15.623s4.4 5.208 3.2 14.822c-1.2 9.614-3.2 27.24-3.2 27.24a88.15 88.15 0 018.4 13.62c4 8.012 31.6 7.21 30-6.81 0 0-2-3.605-1.2-6.81.8-3.205 3.2-24.836 3.2-24.836l9.2-24.436s-12.8-6.41-13.6-8.012z"
            fill="#55536E"
            fillRule="nonzero"
          />
          <path
            d="M396.234 106.544h2.8s1.6 9.214 1.2 14.822c-.4 5.608-.4 18.828-.4 18.828s1.6 3.204 1.2 6.008c-.4 2.805-1.2 10.416-1.2 10.416s-.4 10.816-8 5.207c0 0-2.4-1.201-1.2-6.409s-.8-10.816-.8-10.816 1.2-3.605.8-6.009c-.4-2.403-2-10.014-2-10.014l7.6-22.033zM360.234 99.734l-2-1.202s-2.8-1.201-6.8 0c-4 1.202-6 .802-7.6.4-1.6-.4-.4-4.406-.4-4.406s-3.2-5.608-6.4-2.403c-3.2 3.205-3.2 9.614-3.2 9.614s-2 4.006 5.2 6.41c7.2 2.403 10.4 6.008 10.4 6.008h8l2.8-14.42z"
            fill="#55536E"
            fillRule="nonzero"
          />
          <path
            d="M374.983 91.38a1.857 1.857 0 00-1.15-1.654 5.237 5.237 0 00-1.367-.27c-1.362-.176-2.763-.676-3.556-1.718-1.057-1.388-.768-3.329-1.615-4.835a16.766 16.766 0 00-1.29-1.699c-1.478-1.98-1.739-4.508-1.873-6.914a2.973 2.973 0 01.163-1.445c.253-.414.56-.793.912-1.127a3.75 3.75 0 00.905-2.5c-.043-1.863 2.619-3.115 4.504-3.759 1.885-.643 3.93-.745 5.937-.773 1.155-.015 2.347-.002 3.408.42a7.84 7.84 0 011.708 1.02c2.224 1.63 4.313 3.634 5.06 6.17.292 1.159.429 2.351.405 3.545.035.371-.02.746-.159 1.091-.267.538-.896.829-1.303 1.285-.76.85-.624 2.083-.72 3.184a7.296 7.296 0 01-3.578 5.517c-.41.201-.787.462-1.119.775a4.92 4.92 0 00-.617 1.079c-.8 1.537-2.816 2.554-4.655 2.609z"
            fill="#3F3D56"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLabDashboardImage;
