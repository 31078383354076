import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

export const StorefrontPage = ({ header, subHeader, children }) => {
  const padding = 'px-4';
  const headerClassName = 'w-full my-4';
  return (
    <Paper className={`flex flex-col w-full h-full ${padding}`}>
      <Box component="span" className={headerClassName}>
        <Typography variant="h4" color="inherit" noWrap>
          {header}
        </Typography>
      </Box>
      {subHeader && (
        <Box className={headerClassName}>
          <Typography variant="h6" color="inherit" noWrap>
            {subHeader}
          </Typography>
        </Box>
      )}
      {children}
    </Paper>
  );
};

StorefrontPage.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]).isRequired,
  subHeader: PropTypes.string,
  children: PropTypes.node.isRequired,
};
