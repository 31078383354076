import { Link } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PropTypes from 'prop-types';

export const ERROR_MESSAGE = 'An error occurred while fetching the information, please contact support.';

export const OrderHistoryTrackingList = ({ consignmentTrackers }) =>
  consignmentTrackers?.map(({ trackingCode, trackingUrl, trackerType }, index) => (
    <div key={[trackingCode, index].join('-')}>
      <span className="font-medium mx-1">{trackerType}: </span>
      {trackingUrl && (
        <Link href={trackingUrl} target="_blank" rel="noopener noreferrer" className="mx-1">
          {trackingCode}
        </Link>
      )}
      {!trackingUrl && <span className="text-medium px-1">{trackingCode}</span>}
      {trackingCode && (
        <div
          role="button"
          className="border-2 rounded-md inline-block cursor-pointer mx-1 text-sm py-1 px-2"
          onClick={async () => {
            // only works when served with https on supported browsers
            await navigator?.clipboard?.writeText(trackingUrl || trackingCode);
          }}
        >
          <ContentCopyIcon fontSize="inherit" /> Copy
        </div>
      )}
      {!trackingUrl && !trackingCode && ERROR_MESSAGE}
    </div>
  )) || null;

OrderHistoryTrackingList.propTypes = {
  consignmentTrackers: PropTypes.arrayOf(
    PropTypes.shape({
      trackingUrl: PropTypes.string,
      trackingCode: PropTypes.string,
    }),
  ),
};
