import {
  FETCH_STOREFRONT_PRODUCTS_REQUEST,
  FETCH_STOREFRONT_PRODUCTS_REQUEST_FAILURE,
  FETCH_STOREFRONT_PRODUCTS_REQUEST_SUCCESS,
  SELECT_STOREFRONT_PRODUCT,
} from './products.action';

const initialState = {
  products: {},
  selectedProduct: null,
};

export const productsReducer = (state = initialState, action = '') => {
  switch (action.type) {
    case FETCH_STOREFRONT_PRODUCTS_REQUEST:
      return {
        ...state,
        isStoreLoading: true,
      };

    case FETCH_STOREFRONT_PRODUCTS_REQUEST_SUCCESS: {
      return {
        ...state,
        products: action.products,
        isStoreLoading: false,
      };
    }

    case FETCH_STOREFRONT_PRODUCTS_REQUEST_FAILURE: {
      return {
        ...state,
        isStoreLoading: false,
        error: action.error,
      };
    }

    case SELECT_STOREFRONT_PRODUCT:
      return {
        ...state,
        isStoreLoading: false,
        selectedProduct: action.selectedProduct,
      };
    default:
      return state;
  }
};
