import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export const PRIMARY_ACTION_LABEL = 'Save';
export const SECONDARY_ACTION_LABEL = 'Cancel';

export const WebhookComponentActions = ({ isDisabledSecondary, isDisabledPrimary, onClickSecondary }) => {
  const baseClassName = 'uppercase';
  return (
    <Box className="my-4 flex justify-end">
      <Button
        className={`${baseClassName} mr-3`}
        variant="outlined"
        type="button"
        onClick={onClickSecondary}
        disabled={isDisabledSecondary}
      >
        {SECONDARY_ACTION_LABEL}
      </Button>
      <Button className={`${baseClassName}`} variant="contained" type="submit" disabled={isDisabledPrimary}>
        {PRIMARY_ACTION_LABEL}
      </Button>
    </Box>
  );
};

WebhookComponentActions.propTypes = {
  isDisabledPrimary: PropTypes.bool.isRequired,
  isDisabledSecondary: PropTypes.bool.isRequired,
  onClickSecondary: PropTypes.func.isRequired,
};
