import { Tab } from '@mui/material';
import { Link } from '@reach/router';
import { isClinicBillable } from '../../../utils/permissions';
import OrderHistory from './OrderHistory';
import Orders from './Orders';
import { useDataApi } from '../../../hooks';
import { getOrdersUrl } from '../../api/phpApiRequests';

export const ORDER_PATHS = {
  BASE: '/admin/clinics/order',
  HISTORY: '/admin/clinics/order_history',
};

const getTabs = ({ clinic, orders }) =>
  [
    isClinicBillable({ clinic, orders }) && {
      label: 'Create an order',
      value: ORDER_PATHS.BASE,
      to: ORDER_PATHS.BASE,
    },
    {
      label: 'Order history',
      value: ORDER_PATHS.HISTORY,
      to: ORDER_PATHS.HISTORY,
    },
  ]
    .filter((xs) => Boolean(xs))
    .map((props, index) => <Tab {...props} index={index} key={index + 1} component={Link} />);

const initialPageState = {
  limit: 10,
  page: 1,
  search: null,
};
const defaultOrdersData = {
  data: [],
  meta: { total: 0 },
};
export default function OrderIndex({ clinic, isEnabledViaConfiguration, path }) {
  const getTabsWithClinic = () => getTabs({ clinic, orders: isEnabledViaConfiguration });
  const selectedTab = `/admin/${path}`;
  const shouldDisplayCreateOrder = isClinicBillable({ clinic, orders: isEnabledViaConfiguration }) && ORDER_PATHS.BASE === selectedTab;
  const [{ isLoading: isOrdersLoading, data: orders }, setUrl] = useDataApi(getOrdersUrl(initialPageState), defaultOrdersData, true);
  return (
    <>
      {shouldDisplayCreateOrder && (
        <Orders
          path={ORDER_PATHS.BASE}
          tabs={ORDER_PATHS}
          selectedTab={selectedTab}
          getTabs={getTabsWithClinic}
          ariaLabel="Create or view orders"
        />
      )}
      {ORDER_PATHS.HISTORY === selectedTab && (
        <OrderHistory
          path={ORDER_PATHS.HISTORY}
          tabs={ORDER_PATHS}
          selectedTab={selectedTab}
          getTabs={getTabsWithClinic}
          ariaLabel="Create or view orders"
          isOrdersLoading={isOrdersLoading}
          orders={orders}
          getOrders={setUrl}
          initialPageState={initialPageState}
        />
      )}
    </>
  );
}
