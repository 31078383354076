import PropTypes from 'prop-types';

export const OrderConfirmation = ({ orderNumber }) => (
  <div className="flex flex-col items-center">
    <h3>Order confirmed</h3>
    <p>Order number: {orderNumber.toUpperCase()}</p>
  </div>
);

OrderConfirmation.propTypes = {
  orderNumber: PropTypes.string.isRequired,
};
