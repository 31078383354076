import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export const IconAction = ({ name, icon, onClick, children }) => (
  <>
    {children}
    <SvgIcon key={name} title={name} component={icon} onClick={onClick} className="cursor-pointer" />
  </>
);

IconAction.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
