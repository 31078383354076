export const getProps = (theme) => ({
  TabIndicatorProps: {
    style: {
      background: `linear-gradient(to right, ${theme.colors.primary.DEFAULT}, ${theme.colors.primary.purple})`,
      height: 3,
      borderRadius: 3,
    },
  },
});

export const getOverrides = (theme) => ({
  root: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.gray[300],
  },
});
