import { Router } from '@reach/router';

import RegisterWelcome from './RegisterWelcome';

import RegisterLab from './lab/RegisterLab';
import RegisterLabYourDetails from './lab/RegisterLabYourDetails';
import RegisterLabAcceptTerms from './lab/RegisterLabAcceptTerms';

import RegisterPartner from './partner/RegisterPartner';
import RegisterPartnerYourDetails from './partner/RegisterPartnerYourDetails';

import RegisterComplete from './RegisterComplete';
import { GuestLayout } from '../../components/layouts/GuestLayout';

const Register = () => (
  <GuestLayout logoUrl="/register" shouldApplyDefaultBranding={true}>
    <Router className="w-full h-full flex p-4 md:px-24" path="/">
      <RegisterWelcome path="/" />

      <RegisterLab path="lab" />
      <RegisterLabYourDetails path="lab/your-details" />
      <RegisterLabAcceptTerms path="lab/accept-terms" />

      <RegisterPartner path="partner" />
      <RegisterPartnerYourDetails path="partner/your-details" />

      <RegisterComplete path="partner/complete" />
      <RegisterComplete path="lab/complete" />
    </Router>
  </GuestLayout>
);

export default Register;
