export const FETCH_PARTNER_BRANDING_REQUEST = 'FETCH_PARTNER_BRANDING_REQUEST';
export const FETCH_PARTNER_BRANDING_SUCCESS = 'FETCH_PARTNER_BRANDING_SUCCESS';
export const FETCH_PARTNER_BRANDING_FAILURE = 'FETCH_PARTNER_BRANDING_FAILURE';
export const NO_PARTNER_BRANDING = 'NO_PARTNER_BRANDING';

export const getPartnerBrandingRequest = (params) => ({
  type: FETCH_PARTNER_BRANDING_REQUEST,
  ...params,
});

export const getPartnerBrandingSuccess = (params) => ({
  type: FETCH_PARTNER_BRANDING_SUCCESS,
  ...params,
});

export const getPartnerBrandingFailure = (params) => ({
  type: FETCH_PARTNER_BRANDING_FAILURE,
  ...params,
});
