import { useState, useEffect } from 'react';

import { Header, Loader } from '../../../components';
import getStoreRedirectToken from '../../../components/auth/getStoreRedirectToken';

const RedirectStore = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    function redirect() {
      getStoreRedirectToken({
        onSuccess: (response) => {
          window.location.href = response.redirect_url;
        },
        onError: (response) => {
          setError(response);
        },
      });
    }
    redirect();
  }, []);

  if (error) {
    return (
      <div className="h-full flex flex-col justify-center">
        <Header element="h2">Error: {error}</Header>
        <p>Please try again.</p>
      </div>
    );
  }

  return <Loader />;
};

export default RedirectStore;
