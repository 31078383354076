import { Button } from '@mui/material';
import { navigate } from '@reach/router';
import { func } from 'prop-types';

import * as LS from './lab.style';
import { LabSLA } from './lab-sla';
import { CircleArrow } from '../../../icons';

const LabAcceptTerms = ({ setShowList }) => {
  const handleBack = () => {
    navigate('/admin/clinics/lab');
  };
  return (
    <LS.StyledLabWrapper>
      <LS.StyledGroup gridTemplateColumns="150px 1fr">
        <LS.StyledButton color="primary" type="button" onClick={handleBack}>
          <CircleArrow className="mr-2" />
          <span>Lab details</span>
        </LS.StyledButton>

        <LabSLA />
      </LS.StyledGroup>

      <LS.StyledFormFooter alignItems="flex-end">
        <LS.StyledGroupTwo repeat="auto">
          <Button onClick={setShowList}>Accept and Continue</Button>
        </LS.StyledGroupTwo>
      </LS.StyledFormFooter>
    </LS.StyledLabWrapper>
  );
};

LabAcceptTerms.propTypes = {
  setShowList: func.isRequired,
};

export default LabAcceptTerms;
