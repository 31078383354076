import * as React from 'react';

function SvgManifest(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      className="svg-icon"
      {...props}
    >
      <path d="M16 0c-1.101 0-2.105.449-2.828 1.172a3.997 3.997 0 00-1.091 3.63l.107.531 7.622.008.109-.534a3.995 3.995 0 00-1.091-3.635A3.995 3.995 0 0016 0zm0 1.333c.74 0 1.402.298 1.885.781s.781 1.145.781 1.885v.008l-5.333-.005v-.003c0-.74.298-1.402.781-1.885s1.145-.781 1.885-.781zM22 12a.667.667 0 01.12 1.323l-.12.011H10a.667.667 0 01-.12-1.323L10 12zm0 6.667a.667.667 0 01.12 1.323l-.12.011H10a.667.667 0 01-.12-1.323l.12-.011zm0 6.666a.667.667 0 01.12 1.323l-.12.011H10a.667.667 0 01-.12-1.323l.12-.011zM24.667 4H7.334a3.333 3.333 0 00-3.333 3.333v21.333a3.333 3.333 0 003.333 3.333h17.333A3.333 3.333 0 0028 28.666V7.333A3.333 3.333 0 0024.667 4zM7.333 5.333h17.333a2 2 0 012 2v21.333a2 2 0 01-2 2H7.333a2 2 0 01-2-2V7.333a2 2 0 012-2zm2-.333c0 .863.656 1.573 1.496 1.658l.17.009h10c.863 0 1.573-.656 1.658-1.496l.009-.17h1.333a3 3 0 01-2.795 2.993l-.205.007h-10a3 3 0 01-2.993-2.795l-.007-.205z" />
    </svg>
  );
}

export default SvgManifest;
