import { Alert } from '@mui/material';
import { Link } from '@reach/router';
import { getBillingPathname } from '../../../../constants';

export const StorefrontWarning = () => (
  <Alert severity="warning" className="text-primary w-full my-2">
    <p>You have no billing method setup.</p>
    <p>
      In order for us to process orders you must setup a <Link to={getBillingPathname()}>billing method.</Link>
    </p>
    <p>You will only be charged once your customers place a successful order.</p>
  </Alert>
);
