import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import { formErrorMessages } from '../../../../../../utils/form-error-messages';
import { REGEX } from '../../../../../../data/constants';
import { passwordValidator } from '../../../../../../utils/validators';
import { ProductsSelect } from '../../../../../../components/select/ProductsSelect';

// TODO: rename/lift some of the props in later iteration to be more conventional
const SimulateFormNewOrderPanel = ({
  children,
  className,
  descriptionNew,
  emailInputProps,
  isFormDisabled,
  isProductVariationsDisabled,
  onFormSubmit,
  product,
  productVariations,
  setProduct,
  type,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      product,
    },
  });
  return (
    <form className={className} onSubmit={handleSubmit((data) => onFormSubmit(data, type, product))} data-testid="form">
      <div className="flex flex-row pt-5">
        <div>
          <p className="mb-4">{descriptionNew}</p>
          <div className="max-w-lg">
            <TextField
              className="mb-4 mr-4"
              label="Email"
              id="email"
              name="email"
              disabled={isFormDisabled}
              helperText={formErrorMessages(errors.email)}
              inputRef={register({ pattern: REGEX.EMAIL })}
              error={!!errors.email}
              InputProps={emailInputProps}
            />
            <TextField
              className="mb-4 mr-4"
              label="Password"
              id="password"
              name="password"
              disabled={isFormDisabled}
              helperText={formErrorMessages(errors.password)}
              inputRef={register({ required: true, validate: passwordValidator })}
              error={!!errors.password}
            />

            <Controller
              name="product"
              className="mb-4 mr-4"
              control={control}
              render={() => (
                <ProductsSelect
                  onChange={(e) => setProduct(e.target.value)}
                  defaultValue=""
                  products={productVariations}
                  isDisabled={isProductVariationsDisabled || isFormDisabled}
                />
              )}
            />

            <Button type="submit" disabled={isFormDisabled || !product} className="mt-4">
              Generate
            </Button>
            <p>
              <i>This may take a little while to emulate, please be patient and wait for the results to display.</i>
            </p>
          </div>
          {children}
        </div>
      </div>
    </form>
  );
};

SimulateFormNewOrderPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  descriptionNew: PropTypes.string.isRequired,
  emailInputProps: PropTypes.object,
  isFormDisabled: PropTypes.bool.isRequired,
  isProductVariationsDisabled: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  product: PropTypes.string,
  productVariations: PropTypes.array.isRequired,
  setProduct: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export { SimulateFormNewOrderPanel };
