import styled from 'styled-components';
import { Button, Step, Stepper, Typography, Tabs, Tab } from '@mui/material';

export const StyledLabNavigationLink = styled.div`
  text-decoration: none;
  flex: 1;
  margin: 10px;
  min-width: 246px;
  max-width: 246px;
  height: 246px;
  cursor: ${({ termsAgreed }) => (termsAgreed ? 'default' : 'pointer')};
  background-color: #fff;
  color: #5b7bff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 21px;
  line-height: 28px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.075);

  &[disabled] {
    color: #555;
  }
`;
export const StyledLabLabel = styled.div`
  padding: 0 10px;
  text-align: center;
`;

export const StyledLabStatus = styled.div`
  font-size: 21px;
`;

export const StyledButtonUpload = styled(Button)`
  justify-self: start;
  align-self: center;
`;

export const StyledButton = styled(Button)`
  justify-self: start;
  align-self: start;
`;

export const StyledGroup = styled.div`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns || '1fr 2fr'};
  grid-gap: ${({ gap }) => gap || 16}px;
`;

export const StyledBox = styled.div`
  text-align: ${({ align }) => align || 'left'};
  ${({ colSpan }) => (colSpan ? `grid-column: ${colSpan};` : '')};

  &:not(:last-child) {
    padding: 10px 0;
  }

  &:last-child {
    padding-top: 10px;
  }
`;

export const StyledTaC = styled.div`
  background-color: white;
  padding: 40px 80px;
  height: calc(100vh - 190px);
  overflow-y: auto;

  h1 {
    margin-bottom: 20px;
  }

  h2 {
    border-bottom: 1px solid #cccaca;
    margin-bottom: 10px;
  }

  section {
    margin-bottom: 30px;
  }

  iframe {
    margin-top: 20px;
    height: 100%;
    width: 100%;
  }
  table {
    border-bottom: 1px solid black;
    tbody {
      td {
        min-width: 100px;
        text-align: left;
        padding: 5px;
        vertical-align: top;

        &:nth-child(1) {
          min-width: 40px;
        }
      }
    }
  }
`;

export const StyledLabTaC = styled.div`
  background-color: white;
  padding: 30px;
  overflow-y: auto;
`;

export const StyledGroupTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, ${({ repeat }) => repeat || '1fr'});
  grid-gap: ${({ gap }) => gap || 16}px;
`;

export const StyledLabWrapper = styled.div`
  padding: 20px 0;
`;

export const StyledFormFooter = styled.div`
  display: flex;
  height: 84px;
  justify-content: flex-end;
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : 'center')};
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  box-shadow: -1px -1px 4px #e8e8e8;
  padding: 20px 58px 20px 0;

  & > button {
    width: 100%;
    margin-bottom: 8px;
  }

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;

    & > button {
      width: auto;
      margin-bottom: initial;
    }
  }
`;

export const StyledLabMessage = styled.div`
  position: relative;
  top: -16px;
  font-size: 16px;
`;

export const StyledLabNavigationLinkContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledLabNavigation = styled.nav`
  margin-top: -123px;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const StyledLabTitle = styled.div`
  color: white;
  line-height: 65px;
  font-size: 35px;
  font-weight: 500;
`;

export const StyledLabDescription = styled.p`
  color: white;
  line-height: 28px;
  font-size: 21px;
  width: 45%;
`;

export const ContentNoMargin = styled.div`
  margin-left: -30px;
  width: calc(100% + 60px);
`;

export const StyledLabHeader = styled.div`
  background-image: #1b1d26;
  height: 412px;
  padding-top: 109px;
  padding-left: 54px;
  padding-right: 54px;
`;

export const StyledUploadFileDescription = styled(Typography)`
  && {
    color: #555555;
    margin-top: 46px;
    margin-bottom: 64px;

    & a {
      color: #555555;
      text-decoration: none;
      padding-bottom: 1px;
      border-bottom: 1px solid #555555;
    }
  }
`;

export const StyledFormFile = styled.div`
  box-shadow: 0px 1px 1px #ccc;
  height: 52px;
  line-height: 52px;
  padding: 0 1rem;
  background-color: #f9f9f9;
  color: #8d8d8d;
`;

export const StyledUploadFormFooter = styled.div`
  border-top: 1px solid #ccc;
  padding-top: 12px;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
`;

export const StyledUploadForm = styled.div`
  padding: 1rem 164px;
`;

export const StyledUploadFormContent = styled.div`
  padding: 1rem 24px;
`;

export const StyledGridTableHeadCell = styled.div`
  font-weight: bold;
  white-space: nowrap;
  justify-self: center;
`;

export const StyledGridTable = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(${({ repeat }) => repeat || 3}, 1fr);
`;

export const StyledTableCell = styled.div`
  background-color: #f7f7f7;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  padding: 0 1rem;
  position: relative;

  &::after {
    content: ${({ isRight }) => (isRight ? '' : `''`)};
    position: absolute;
    top: 0;
    bottom: 0;
    right: -12px;
    width: 12px;
    background-color: #f7f7f7;
  }
`;

export const StyledStepper = styled(Stepper)`
  && .MuiSvgIcon-root {
    width: 36px;
    height: 36px;
    border: 1px solid #5b7bff;
    border-radius: 50%;
    color: #5b7bff;

    & .MuiStepIcon-text {
      fill: #5b7bff;
    }

    & circle {
      display: none;
    }
  }
`;

export const StyledStep = styled(Step)`
  & .MuiStepIcon-text {
    fill: ${({ isBlue }) => (isBlue ? '#5b7bff' : '#cccccc')};
  }

  && .MuiSvgIcon-root {
    border: 1px solid ${({ isBlue }) => (isBlue ? '#5b7bff' : '#cccccc')};

    & .MuiStepIcon-text {
      fill: ${({ isBlue }) => (isBlue ? '#5b7bff' : '#cccccc')};
    }
  }
`;

export const StyledStepLegend = styled(Typography)`
  && {
    font-weight: bold;
    padding: 0.2rem 0;
    margin: 1.5rem 0;
    border-bottom: 1px solid #cccccc;
  }
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    flex-direction: column;
    border-bottom: 1px solid #cccccc;
  }

  && {
    overflow: initial;
    font-family: futura-pt, Arial, Helvetica, sans-serif;
  }

  && .MuiTabs-scroller {
    overflow: initial;
    margin-bottom: -2px;
  }

  & .MuiTabs-indicator {
    height: 3px;
  }

  @media screen and (min-width: 768px) {
    .MuiTabs-flexContainer {
      flex-direction: row;
    }
  }
`;

export const StyledGridHeaderSubTitle = styled(Tab)`
  margin-right: 36px;
  height: 48px;
  color: ${({ active }) => (active ? '#555555' : '#9B9B9B')};
  border-bottom: 1px solid ${({ active }) => (active ? '#5B7BFF' : 'transparent')};

  &:hover {
    color: #555555;
  }

  && {
    text-transform: capitalize;
    font-size: 21px;
    line-height: 27px;
    font-weight: normal;
    font-family: futura-pt, Arial, Helvetica, sans-serif;
  }
`;
