const developmentEnvironments = ['local', 'test', 'dev', 'development'];

const isDevelopment =
  developmentEnvironments.includes(process.env.REACT_APP_ENV_NAME) || developmentEnvironments.includes(window?.env?.REACT_APP_ENV_NAME);

const environmentObject = isDevelopment ? process.env : window?.env;

const config = {
  apiUrl: environmentObject?.REACT_APP_API_URL,
  datadogRumApplicationId: environmentObject?.REACT_APP_DATADOG_RUM_APPLICATION_ID,
  datadogRumClientToken: environmentObject?.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
  datadogRumEnable: environmentObject?.REACT_APP_DATADOG_RUM_ENABLE === 'true',
  isDevelopment,
  // Requires specific env var over NODE_ENV as this is always set to 'production' in sandbox mode
  env: environmentObject?.REACT_APP_ENV_NAME,
  notificationApiUrl: environmentObject?.REACT_APP_NOTIFICATION_API_URL,
  orderApiUrl: environmentObject?.REACT_APP_ORDER_API_URL,
  partnerApiKeyApiUrl: environmentObject?.REACT_APP_PARTNER_API_KEY_API_URL,
  partnerServiceUrl: environmentObject?.REACT_APP_PARTNER_SERVICE_URL,
  shippingApiUrl: environmentObject?.REACT_APP_SHIPPING_API_URL,
  simulatorApiUrl: environmentObject?.REACT_APP_SIMULATOR_API_URL,
  shouldUseWhileLabelling: environmentObject?.REACT_APP_USE_WHITELABELLING === 'true',
};

export const getConfig = () => config;

export default config;
