import { useParams } from '@reach/router';
import { memo, useRef } from 'react';
import { StyledTaC } from './lab.style';
import { labMenu } from './lab.data';

const LabSLAaC = () => {
  const TaCRef = useRef(null);
  const params = useParams();
  const { slaId } = params;
  const sla = labMenu.find((item) => item.slaId === slaId);

  return (
    <StyledTaC ref={TaCRef}>
      {sla ? (
        <>
          <h1 className="text-3xl">{sla.label}</h1>
          <section>
            <h2 className="text-2xl">Services Description</h2>
            <h3 className="text-xl">Tests</h3>
            <p>&#8226;{sla.sla.service_description.tests}</p>
            <h3 className="text-xl">Description of Services</h3>
            <p>&#8226;{sla.sla.service_description.description_of_services}</p>
            <h3 className="text-xl">Test Materials</h3>
            <p>&#8226;{sla.sla.service_description.test_materials}</p>
            <h3 className="text-xl">Service Levels</h3>
            <p>&#8226;{sla.sla.service_description.service_levels}</p>
            {sla.sla.service_description.liquidated_damages && <h3 className="text-xl">Liquidated Damages</h3>}
            {sla.sla.service_description.liquidated_damages && <p>&#8226;{sla.sla.service_description.liquidated_damages}</p>}
          </section>

          <section>
            <h2 className="text-2xl">Facility and Equipment</h2>
            <h3 className="text-xl">Applicable Standards</h3>
            <p>&#8226;{sla.sla.facility_and_equipment.applicable_standards}</p>
          </section>

          {sla.sla.charges && (
            <section>
              <h2 className="text-2xl">Charges</h2>
              <h3 className="text-xl">Price per Test</h3>
              <p>&#8226;{sla.sla.charges.price_per_test}</p>
            </section>
          )}

          {sla.sla.limitation_of_liability && (
            <section>
              <h2 className="text-2xl">Limitation of liability</h2>
              <h3 className="text-xl">General limitation of liability (pursuant to clause 10.3)</h3>
              <p>&#8226;{sla.sla.limitation_of_liability.general_limitation_of_liability}</p>
              <h3 className="text-xl">Limitation of liability in relation to any Indemnity (pursuant to clause 10.2)</h3>
              <p>&#8226;{sla.sla.limitation_of_liability.limitation_of_liability_in_relation_to_any_indemnity}</p>
            </section>
          )}

          <section>
            <h2 className="text-2xl">Permitted Subcontractors</h2>
            <p>&#8226;{sla.sla.permitted_subcontractors}</p>
          </section>
        </>
      ) : (
        <h1 className="text-3xl"> There is no SLA with that identification.</h1>
      )}
    </StyledTaC>
  );
};

export const LabSLA = memo(LabSLAaC);
