import {
  FETCH_PARTNER_BRANDING_FAILURE,
  FETCH_PARTNER_BRANDING_REQUEST,
  FETCH_PARTNER_BRANDING_SUCCESS,
  NO_PARTNER_BRANDING,
} from './partnerBranding.action';

export const initialState = {
  headerBackground: null,
  headerLogoUrl: null,
  pageBackground: null,
  footerBackground: null,
  footerTextColour: null,
  primaryColour: null,
  navLinkColour: null,
  displayedFeatures: null,
};

export const partnerBrandingReducer = (state = initialState, action = '') => {
  switch (action.type) {
    case FETCH_PARTNER_BRANDING_REQUEST:
      return {
        ...state,
        isPartnerBrandingLoading: true,
        hasBranding: false,
      };

    case FETCH_PARTNER_BRANDING_SUCCESS: {
      return {
        ...state,
        ...action.partnerBranding,
        isPartnerBrandingLoading: false,
        hasBranding: true,
      };
    }

    case NO_PARTNER_BRANDING: {
      return {
        ...state,
        isPartnerBrandingLoading: false,
        hasBranding: false,
      };
    }

    case FETCH_PARTNER_BRANDING_FAILURE: {
      return {
        ...state,
        isPartnerBrandingLoading: false,
        hasBranding: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
