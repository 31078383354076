import { Router } from '@reach/router';
import { Storefront } from './Storefront';
import { StorefrontStore } from './stores/StorefrontStore';
import { StorefrontCreateProduct } from './products/StorefrontCreateProduct';
import { StorefrontAddProduct } from './products/StorefrontAddProduct';

export const StorefrontRouting = () => (
  <Router className="h-full w-full">
    <StorefrontStore path="/store/:storeId" />
    <StorefrontCreateProduct
      path="/products/create"
      headerLabel="Create Product"
      subheaderLabel="Select the product you want to start from."
    />
    <StorefrontAddProduct path="/products/add" />
    <Storefront default />
  </Router>
);
