module.exports = {
  baseTheme: {
    fontFamily: {
      futuraPT: "'futura-pt', 'Arial', 'Helvetica', 'sans-serif'",
    },
    colors: {
      black: '#000',
      white: '#FFF',
      current: 'currentColor',
      background: {
        DEFAULT: '#FFFFFF',
        header: '#FFFFFF',
      },
      primary: {
        DEFAULT: '#1B1D26',
        purple: '#1B1D26',
      },
      secondary: {
        DEFAULT: '#FFAF4B',
        orange: '#FFAF4B',
      },
      success: {
        DEFAULT: '#07bc0c',
      },
      error: {
        DEFAULT: '#B3261E',
      },
      gray: {
        100: '#F8F8F7',
        200: '#e8e8e8',
        300: '#EEEEEB',
        400: '#b5b5b5',
        500: '#8d8d8d',
        600: '#1B1D26',
      },
      blue: {
        200: '#EDF1FF',
      },
    },
    extend: {
      inset: {
        em: '1em',
        '-em': '-1em',
      },
      fontSize: {
        '3xl': ['1.75rem', { lineHeight: '2.18rem' }],
      },
      borderColor: (theme) => ({
        DEFAULT: theme('colors.gray.300', 'currentColor'),
      }),
    },
  },
};
