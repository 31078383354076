import { Redirect, Router } from '@reach/router';
import { RegisterIframe } from './RegisterIframe';
import { UserDashboardIframe } from './UserDashboardIframe';
import { getConfig } from '../../../config';

export const DemoRouting = () => {
  if (getConfig().env === 'prod') return <Redirect to="/admin/clinics/dashboard" />;
  return (
    <Router className="h-full">
      <RegisterIframe path="/register" />
      <UserDashboardIframe path="/dashboard" />
    </Router>
  );
};
