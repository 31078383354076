import { format } from 'date-fns';

export const initialState = {
  status: 'registered',
  rows: [],
  count: {},
  axiosRequestId: 0,
};

export const testStatusesMeta = {
  registered: {
    label: 'Registered',
  },
  processing: {
    label: 'Processing',
  },
  processed: {
    label: 'Results',
  },
  all: {
    label: 'All',
  },
};

export const testStatusesOrder = ['registered', 'processing', 'processed', 'all'];

const testResultsMeta = {
  positive: {
    text: 'Positive',
    style: {
      color: '#cc0000',
    },
  },
  negative: {
    text: 'Negative',
    style: {
      color: '#00b300',
    },
  },
};

const convertTestResultToDisplay = (inValue) => {
  let meta = testResultsMeta[inValue];
  if (!meta) {
    meta = {
      text: inValue,
      style: {},
    };
  }

  const outValue = <div style={meta.style}>{meta.text}</div>;
  return outValue;
};

/**
 * @param {number} seconds - datetime in seconds since Epoch
 * @returns {string} formattedDate
 */
const convertDateToDisplay = (seconds) => {
  let outValue;
  if (seconds) {
    outValue = format(seconds * 1000, 'MMM dd H:mm');
  }
  return outValue;
};

export const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.name,
  },
  {
    id: 'orderId',
    label: 'Order number',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.orderId,
  },
  {
    id: 'productTestType',
    label: 'Test Type',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.productTestType,
  },
  {
    id: 'productPurpose',
    label: 'Purpose',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => row.productPurpose,
  },
  {
    id: 'latestResults',
    label: 'Latest result',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => convertTestResultToDisplay(row.latestResults),
    hideOn: ['registered', 'processing'],
  },
  {
    id: 'toRerun',
    label: 'To rerun',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => (row.toRerun ? 'Yes' : 'No'),
    hideOn: ['registered', 'processing'],
  },
  {
    id: 'dateRegistered',
    label: 'Date Registered',
    minWidth: 70,
    formatForDisplay: (row) => convertDateToDisplay(row.dateRegistered),
    align: 'left',
  },
  {
    id: 'dateReceived',
    label: 'Date Received',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => convertDateToDisplay(row.dateReceived),
    hideOn: ['registered'],
  },
  {
    id: 'dateResults',
    label: 'Date Results',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => convertDateToDisplay(row.dateResults),
    hideOn: ['registered', 'processing'],
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 70,
    align: 'left',
    formatForDisplay: (row) => convertDateToDisplay(row.createdAt),
  },
];
