import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import { Menu, MenuItem, Button, AppBar } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { Down } from '../../../icons';
import SandboxToggle from '../../../components/SandboxToggle';
import { LogoWithLink } from '../../../components/layouts/LogoWithLink';
import { getBillingPathname, getLoginPathname, getLogoutPathname, getProfilePathname } from '../../../constants';

export const TopBar = () => {
  const { id, fname, lname } = useSelector(({ user }) => user.profile);
  const { isPartnerBrandingLoading, hasBranding, ...partnerBranding } = useSelector((state) => state.partnerBranding);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const openMenu = (event) => setMenuAnchorEl(event.currentTarget);
  const closeMenu = () => setMenuAnchorEl(null);

  const goToProfile = () => {
    navigate(getProfilePathname());
    closeMenu();
  };

  const goToBilling = () => {
    navigate(getBillingPathname());
    closeMenu();
  };

  const handleLogout = async () => {
    localStorage.removeItem('access_token');
    closeMenu();
    await axios.post(getLogoutPathname());
    await navigate(getLoginPathname());
  };

  return (
    <AppBar position="static">
      <div className="w-60 text-center h-full">
        {isPartnerBrandingLoading === false && (
          <LogoWithLink customLogoUrl={partnerBranding?.headerLogoUrl} path="/" className="text-primary" />
        )}
      </div>
      <div className="text-center">
        <SandboxToggle />
      </div>
      <div className="w-60 text-center">
        {id && (
          <>
            <Button onClick={openMenu} endIcon={<Down />} variant="text" color="primary">{`${fname} ${lname}`}</Button>
            <Menu anchorEl={menuAnchorEl} keepMounted open={!!menuAnchorEl} onClose={closeMenu}>
              <MenuItem onClick={goToProfile}>Profile</MenuItem>
              <MenuItem onClick={goToBilling}>Billing</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </div>
    </AppBar>
  );
};
