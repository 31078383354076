import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { StorefrontPage } from '../common/StorefrontPage';
import { Loader } from '../../../../components';

export const StorefrontAddProduct = () => {
  const { selectedProduct } = useSelector((state) => ({
    selectedProduct: state.storefront.selectedProduct,
  }));

  if (!selectedProduct) {
    navigate(-1);
  }

  return (
    <StorefrontPage header="Add Product">
      {selectedProduct && <div>Base product: {selectedProduct.title}</div>}
      {!selectedProduct && <Loader />}
    </StorefrontPage>
  );
};
