import PropTypes from 'prop-types';
import { Component } from 'react';
import * as CustomMuiStyle from '../../app/admin/covid-results/shared.style';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withTabs(WrappedComponent, { tabsHeader }, Actions = null) {
  class WithTabs extends Component {
    render() {
      return (
        <CustomMuiStyle.StyledCovid19>
          <CustomMuiStyle.StyledGridHeaderWrapper>
            <CustomMuiStyle.StyledGridHeader>
              <h1 className="text-3xl mb-4">{tabsHeader}</h1>
            </CustomMuiStyle.StyledGridHeader>
            <CustomMuiStyle.StyledGridSubHeader>
              <CustomMuiStyle.StyledGridSWrapper>
                <CustomMuiStyle.StyledTabs
                  value={this.props.selectedTab}
                  aria-label={this.props.ariaLabel}
                  TabIndicatorProps={{
                    style: {
                      background: '#1B1D26',
                      borderRadius: '2px',
                    },
                  }}
                >
                  {this.props.getTabs()}
                </CustomMuiStyle.StyledTabs>
              </CustomMuiStyle.StyledGridSWrapper>

              {Actions && (
                <CustomMuiStyle.StyledGridSWrapper>
                  <Actions />
                </CustomMuiStyle.StyledGridSWrapper>
              )}
            </CustomMuiStyle.StyledGridSubHeader>
          </CustomMuiStyle.StyledGridHeaderWrapper>
          <WrappedComponent {...this.props} />
        </CustomMuiStyle.StyledCovid19>
      );
    }
  }

  WithTabs.displayName = `WithTabs(${getDisplayName(WrappedComponent)})`;
  WithTabs.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    getTabs: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired,
    tabs: PropTypes.object.isRequired,
  };
  return WithTabs;
}
