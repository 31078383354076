import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import ReloadRoute from '../../../components/ReloadRoute';
import { getIsClinicUser, getIsLabUser } from '../../../utils/permissions';
import { CovidResults } from '../covid-results';
import { LabDashboard, PartnerDashboard, SandboxDashboard } from '../dashboard';
import { Developer } from '../developer';
import { Lab } from '../lab';
import Team from '../team';
import { TestResults } from '../test-results';
import Orders from '../order';
import { WithAuthorization } from './WithAuthorization';
import isSandboxEnv from '../../../utils/sandbox';
import { COMPONENTS, PERMISSIONS_PER_COMPONENT } from './permissionsPerComponent';
import { DemoRouting } from '../demo';
import { StorefrontRouting } from '../storefront';

const DeveloperComponent = WithAuthorization(Developer);
const TestResultsComponent = WithAuthorization(TestResults);
const LabComponent = WithAuthorization(Lab);
const TeamComponent = WithAuthorization(Team);
const CovidResultsComponent = WithAuthorization(CovidResults);
const LabDashboardComponent = WithAuthorization(LabDashboard);
const PartnerDashboardComponent = WithAuthorization(PartnerDashboard);
const PartnerSandboxDashboardComponent = WithAuthorization(SandboxDashboard);
const OrdersComponent = WithAuthorization(Orders);
const DemoComponent = WithAuthorization(DemoRouting);
const StorefrontComponent = WithAuthorization(StorefrontRouting);

export const AvailableRoutes = ({
  clinic,
  fname,
  isDeveloperEnabled,
  isOrdersEnabled,
  isTeamEnabled,
  isStorefrontEnabled,
  partnerType,
  permissions,
}) => {
  const userPermissions = Object.keys(permissions);
  return (
    <Router path="/" className="h-full w-full p-8">
      {getIsClinicUser(partnerType) && isSandboxEnv() && (
        <PartnerSandboxDashboardComponent
          path="clinics/dashboard"
          fname={fname}
          key="Dashboard"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.SANDBOX_DASHBOARD]}
          userPermissions={userPermissions}
        />
      )}
      {getIsClinicUser(partnerType) && !isSandboxEnv() && (
        <PartnerDashboardComponent
          path="clinics/dashboard"
          fname={fname}
          key="Dashboard"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.DASHBOARD]}
          userPermissions={userPermissions}
        />
      )}
      {getIsLabUser(partnerType) && (
        <LabDashboardComponent
          path="clinics/dashboard"
          fname={fname}
          key="Dashboard"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.LAB_DASHBOARD]}
          userPermissions={userPermissions}
        />
      )}
      {
        <OrdersComponent
          path="clinics/order"
          key="Orders"
          clinic={clinic}
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.ORDERS]}
          userPermissions={userPermissions}
          isEnabledViaConfiguration={isOrdersEnabled}
        />
      }
      {
        <OrdersComponent
          path="clinics/order_history"
          key="Orders History"
          clinic={clinic}
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.ORDERS]}
          userPermissions={userPermissions}
          isEnabledViaConfiguration={isOrdersEnabled}
        />
      }
      {
        <CovidResultsComponent
          path="clinics/covid_results"
          key="COVID-19 Test Results"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.COVID_TEST_RESULTS]}
          userPermissions={userPermissions}
        />
      }
      {
        <TeamComponent
          path="clinics/team/*"
          key="Team"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.TEAM]}
          userPermissions={userPermissions}
          isEnabledViaConfiguration={isTeamEnabled}
        />
      }
      {
        <LabComponent
          path="clinics/lab/*"
          key="Lab"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.LAB]}
          userPermissions={userPermissions}
        />
      }
      {
        <TestResultsComponent
          path="clinics/test_results"
          key="Test Results"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.TEST_RESULTS]}
          userPermissions={userPermissions}
        />
      }
      {
        <DeveloperComponent
          path="clinics/developer/*"
          key="Developer"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.DEVELOPER]}
          userPermissions={userPermissions}
          isEnabledViaConfiguration={isDeveloperEnabled}
        />
      }
      {
        <StorefrontComponent
          path="clinics/storefront/*"
          key="Storefront"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.STOREFRONT]}
          userPermissions={userPermissions}
          isEnabledViaConfiguration={isStorefrontEnabled}
        />
      }
      {
        <DemoComponent
          path="demo/*"
          key="Demo"
          requiredPermission={PERMISSIONS_PER_COMPONENT[COMPONENTS.DEMO]}
          userPermissions={userPermissions}
        />
      }
      <ReloadRoute default />
    </Router>
  );
};

AvailableRoutes.proptypes = {
  clinic: PropTypes.object,
  fname: PropTypes.string,
  isDeveloperEnabled: PropTypes.bool.isRequired,
  isOrdersEnabled: PropTypes.bool.isRequired,
  isStorefrontEnabled: PropTypes.bool.isRequired,
  isTeamEnabled: PropTypes.bool.isRequired,
  partnerType: PropTypes.string,
  permissions: PropTypes.object,
};
