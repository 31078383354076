import { Button, CircularProgress } from '@mui/material';

export const ResetPasswordButton = ({ isLoading, partnerPrimaryColour = null }) => (
  <Button
    className="float-right mt-4"
    type="submit"
    disabled={isLoading}
    startIcon={isLoading && <CircularProgress />}
    style={{ backgroundColor: partnerPrimaryColour }}
  >
    Reset password
  </Button>
);
