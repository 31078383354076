import _ from 'lodash';
import { TableRow as MuiTableRow } from '@mui/material';
import PropTypes from 'prop-types';
import * as CustomMuiStyle from '../../app/admin/covid-results/shared.style';

const TableRow = ({ columns, row, alignColumn, index, rowClassNames, shouldDisplayDivider }) => (
  <>
    <CustomMuiStyle.StyledTableRow className={rowClassNames} hover role="checkbox" tabIndex={-1}>
      {columns.map((column) => (
        <CustomMuiStyle.StyledTableCell key={`${_.snakeCase(column)}-${index}`} align={alignColumn}>
          {row[column]}
        </CustomMuiStyle.StyledTableCell>
      ))}
    </CustomMuiStyle.StyledTableRow>
    {shouldDisplayDivider && (
      <MuiTableRow tabIndex={-1}>
        <CustomMuiStyle.StyledTableDividerCell colSpan="8" />
      </MuiTableRow>
    )}
  </>
);

TableRow.propTypes = {
  rowClassNames: PropTypes.string,
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  alignColumn: PropTypes.string,
};

TableRow.defaultProps = {
  shouldDisplayDivider: true,
};

export { TableRow };
