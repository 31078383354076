import axios from 'axios';
import config from '../../config';

export const API_KEYS_PATH = 'api-keys';

/**
 * best practice is to use new URL().href but this cuts off anything after the third /
 * this truncates part of the url in non-dev environments
 * a fix could be to make sure our local environment includes /partner-key-management
 * to start using new URL().href again
 * */

const getApiKeysEndpoint = (partnerApiKeyApiUrl = config.partnerApiKeyApiUrl) => `${partnerApiKeyApiUrl}${API_KEYS_PATH}`;

export const getApiKeys = async ({ partnerId, endpoint = getApiKeysEndpoint() }) => {
  try {
    const listApiKeysUrl = `${endpoint}?partnerType=clinic&partnerId=${partnerId}&revoked=false`;
    const response = await axios.get(listApiKeysUrl);
    return response?.data || [];
  } catch (error) {
    // TODO: report error to Sentry
    return [];
  }
};

// eslint-disable-next-line
export const createApiKey = async (requestBody, clinicOrLabId, partnerType, endpoint = getApiKeysEndpoint()) => {
  return axios.post(`${endpoint}?partnerId=${clinicOrLabId}&partnerType=${partnerType}`, requestBody);
};

export const revokeApiKey = async (id, partnerId, partnerType) => {
  const updateApiKeysUrl = `${getApiKeysEndpoint()}/${id}?partnerId=${partnerId}&partnerType=${partnerType}`;
  return axios
    .patch(updateApiKeysUrl, { revoked: true })
    .then(() => true)
    .catch(() => false);
};
